import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import Login from '../../view/dialog/login';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [countryList, setCountryList] = useState([]);
    const [data, setData] = useState({mobile_code:Constant?.mobile_code?.default});
    const [errors, setErrors] = useState({});
    const [loginMethod, setLoginMethod] = useState('email');

    const context = useContext(Context);
    const navigate = useNavigate();

    const toggleLoginMethod = () =>
    {
        setLoginMethod(loginMethod?.toLowerCase() === "email" ? "mobile" : "email");
    };

    const resetPassword = () =>
    {
        context?.setDialog("PasswordReset", i18n.t("page.reset_password"), "bg-secondary-lightest", "sm");
    };

    const login = (stepData) =>
    {
        setData({...data, ...stepData});

        let newErrors = {};

        if(loginMethod?.toLowerCase() === "email")
        {
            if(!Validator.notEmpty(stepData?.email))
            {
                newErrors["email"] = i18n.t('error.invalid_email');            
            }
            else if(!Validator.email(stepData?.email))
            {
                newErrors["email"] = i18n.t('error.invalid_email');
            }
        }
        else if(loginMethod?.toLowerCase() === "mobile")
        {
            if(!Validator.notEmpty(stepData?.mobile))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }
            
            if(!Validator.numeric(stepData?.mobile))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }        
            
            if(!Validator.notEmpty(stepData?.mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }
            
            if(!Validator.numeric(stepData?.mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }   
        }     
        
        if(!Validator.notEmpty(stepData?.password))
        {
            newErrors["password"] = i18n.t('error.invalid_password');            
        }
        else if(!Validator.longPassword(stepData?.password))
        {
            newErrors["password"] = i18n.t('error.short_password');
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setLoading(true);

            setErrors({});

            let params = 
            {
                password: stepData?.password,
                identifier_type: loginMethod?.toLowerCase(),
                authorization_type: loginMethod?.toLowerCase() === "email" ? "email" : "sms"
            };

            if(loginMethod?.toLowerCase() === "email")
            {
                params["email"] = stepData?.email;
            }
            else
            {
                params["mobile_code"] = stepData?.mobile_code;
                params["mobile"] = stepData?.mobile;
            }

            isMounted && HTTP.post(Endpoint?.crm?.user?.token, params).then(response =>
            {
                if(response?.is_successful)
                {
                    if(response?.data?.info?.is_password_change_required)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "0"
                        });

                        context?.setDialog("PasswordUpdate", i18n.t("page.password_expired"), "bg-secondary-lightest", "sm");
                    }
                    else if(response?.data?.info?.is_mobile_verification_required)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "0"
                        });

                        context?.setDialog("OTP", i18n.t("page.verify_mobile"), "bg-secondary-lightest", "sm", false, false, true, false, {action:"verify_mobile", channel:null, destination:null});
                    }
                    else if(response?.data?.info?.is_account_update_required && response?.data?.info?.has_captain_profile && response?.data?.info?.is_captain_profile_update_required)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "0"
                        });

                        context?.setDialog("Signup", i18n.t("page.update_profile"), "bg-secondary-lightest", "sm", false, false, true, false, {persona:"captain", subscription_code:null, merchant_code:null, invitation_code:null, email:null, action:"update_account_and_captain_profile"});
                    }
                    else if(response?.data?.info?.is_account_update_required)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "0"
                        });

                        context?.setDialog("Signup", i18n.t("page.update_account"), "bg-secondary-lightest", "sm", false, false, true, false, {persona:null, subscription_code:null, merchant_code:null, invitation_code:null, email:null, action:"update_account"});
                    }
                    else if(response?.data?.info?.has_captain_profile && response?.data?.info?.is_captain_profile_update_required)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "0"
                        });

                        context?.setDialog("Signup", i18n.t("page.update_profile"), "bg-secondary-lightest", "sm", false, false, true, false, {persona:"captain", subscription_code:null, merchant_code:null, invitation_code:null, email:null, action:"update_captain_profile"});
                    }
                    else if(response?.data?.info?.is_2fa_required)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "0"
                        });

                        context?.setDialog("OTP", i18n.t("page.otp"), "bg-secondary-lightest", "sm", false, false, true, false, {action:"login", channel:loginMethod?.toLowerCase() === "email" ? "email" : "sms", destination:response?.data?.info?.otp_destination_value});
                    }
                    else
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "user_first_name": response?.data?.info?.user?.first_name ?? "",
                            "user_last_name": response?.data?.info?.user?.last_name ?? "",
                            "user_avatar": response?.data?.info?.user?.avatar_img ?? "",
                            "is_logged_in": "1"
                        });

                        navigate("/directory");

                        context?.setDialog(null);
                    }
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                    
                    setLoading(false);
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }
    };

    useEffect(() =>
    {
        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);
            
            setLoading(false);
        });
    }, [isMounted]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <Login
            isLoading={isLoading}
            loginMethod={loginMethod}
            data={data}
            errors={errors}
            prefs={context?.prefs}
            countryList={countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
            login={login}
            resetPassword={resetPassword}
            toggleLoginMethod={toggleLoginMethod}
        />
    );
}

export default Dialog;