import Formatter from "../../util/formatter";

const View = (props) =>
{
    return (
        <div className="flex flex-col">
            {props?.data?.map((entry, index) =>
            {
                let width = (entry?.current > 2) ? "w-[" + Math.round((parseFloat(entry?.current)/parseFloat(entry?.max))*100) + "%]" : "w-[2%]";

                return(
                    <div key={index} className="my-3">
                        <div className="text-start mb-3">
                            {entry?.name} ({Formatter.stringToNumberWithCommas(entry?.current)})
                        </div>
                        <div className="w-full h-[10px] bg-neutral-high-regular transition ease-in-out duration-normal rounded-full">
                            <div className={`h-[10px] bg-gradient-to-r from-accent-regular to-accent-dark transition ease-in-out duration-normal rounded-full ${width}`}/>
                        </div>
                        <div className="flex justify-between">
                            <div>
                                {Formatter.stringToNumberWithCommas(entry?.min)}
                            </div>
                            <div>
                                {Formatter.stringToNumberWithCommas(entry?.max)}
                            </div>
                        </div>                        
                    </div>
                );
            })}
        </div>
    )
}

export default View;