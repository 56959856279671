import { memo, useMemo, useRef, useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import Constant from "../../config/constant";

import MapFence from "./mapFence";
import MapMarkers from "./mapMarkers";
import MapPathCluster from './mapPathCluster';
import MapDraggableMarkers from './mapDraggableMarkers';

const MemoizedView = memo(function MemoizedView(props)
{
    const [location, setLocation] = useState({lat:null, long:null});

    const mapRef = useRef(null);

    const { isLoaded } = useJsApiLoader(
    {
        id:props?.id ?? "google-map-view",
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLEMAP_API_KEY}`
    });

    const handleRecentering = () =>
    {
        setLocation({lat:mapRef?.current?.center?.lat(), long: mapRef?.current?.center?.lng()})
    };

    const handleDragging = useCallback((newLocation) =>
    {
        if(props?.handleDragging)
        {
            props?.handleDragging(newLocation);
        }

    }, [props]);

    const handleLoad = (map) =>
    {
        mapRef.current = map;
    }    

    const renderGoogleMap = useMemo(() =>
    {
        const mapOptions =
        {
            zoomControl: props?.showZoomControl,
            mapTypeControl: props?.showMapTypeControl,
            scaleControl: props?.showScaleControl,
            streetViewControl: props?.showStreetViewControl,
            rotateControl: props?.showRotateControl,
            fullscreenControl: props?.showFullscreenControl,
            clickableIcons: props?.showClickableIcons,
            scrollwheel: props?.showScrollwheel,
            minZoom: props?.minZoom,
            maxZoom: props?.maxZoom,
            styles:Constant?.color?.map?.[props?.theme]
        };

        return (
            <div className={props?.className ?? "w-full h-full"}>
                <GoogleMap
                    id={props?.id ?? "google-map-view"}
                    ref={mapRef}
                    options={mapOptions}
                    mapContainerStyle={{width:'100%', height:'100%'}}
                    mapContainerClassName={props?.mapClassName ?? ""}
                    zoom={props?.initZoom}
                    center={{lat:location.lat ?? parseFloat(props?.lat), lng:location.long ?? parseFloat(props?.long)}}
                    onLoad={handleLoad}
                    onDragEnd={handleRecentering}
                >
                    {(props?.fence) ? <MapFence data={props?.fence}/> : null}
                    {(props?.markers) ? <MapMarkers data={props?.markers} showTitle={props?.showMarkerTitle} markerDialogInfo={props?.markerDialogInfo} markerDialogDataID={props?.markerDialogDataID}/> : null}
                    {(props?.path) ? <MapPathCluster data={props?.path}/> : null}
                    {(props?.draggableMarker) ? <MapDraggableMarkers data={props?.draggableMarker} handleDragging={handleDragging}/> : null}
                </GoogleMap>
            </div>
        )
    }, [props, handleDragging, location.lat, location.long]);

    return(isLoaded ? renderGoogleMap : null);
});

export default MemoizedView;
