import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';

import DashboardOverview from '../../view/page/dashboardOverview';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isSnapshotLoading, setSnapshotLoading] = useState(true);
    const [isSubscriptionInfoLoading, setSubscriptionInfoLoading] = useState(true);
    const [isDeliveryListLoading, setDeliveryListLoading] = useState(true);
    const [snapshot, setSnapshot] = useState({});
    const [deliveryList, setDeliveryList] = useState([]);
    const [subscriptionInfo, setSubscriptionInfo] = useState({});

    const context = useContext(Context);

    const showDialog = (dialogID, dialogTitle, dialogDataID) =>
    {
        context?.setDialog(dialogID, dialogTitle, "bg-neutral-high-lightest", "sm", true, true, true, false, {id:dialogDataID});
    };

    const upgradeSubscription = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("MerchantSubscription", i18n.t("page.subscription"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    useEffect(() =>
    {
        let snapshotParams = {};

        snapshotParams["snapshot_date"] = "2024-03-31";

        isMounted && HTTP.get(Endpoint?.bi?.snapshot?.data, snapshotParams).then(response =>
        {
            if(response?.is_successful && response?.data?.info)
            {
                setSnapshot(response?.data?.info);
            }

            setSnapshotLoading(false);
        });

        isMounted && HTTP.get(Endpoint?.crm?.merchant?.data).then(response =>
        {
            if(response?.is_successful && response?.data?.info?.subscription)
            {
                setSubscriptionInfo(response?.data?.info?.subscription);
            }

            setSubscriptionInfoLoading(false);
        });

        let deliveryListParams = {};

        deliveryListParams["page"] = 1;
        deliveryListParams["timestamp"] = `${new Date().toISOString()}`;

        isMounted && HTTP.get(Endpoint?.oms?.delivery?.list, deliveryListParams).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setDeliveryList(response?.data?.list?.map((delivery) =>{return ({...delivery, captain_id: delivery?.captain?.user_id ?? null, status_name:i18n.t(`delivery.status_${delivery?.status_code}`)})}));
            }

            setDeliveryListLoading(false);
        });

    }, [isMounted]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_overview');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardOverview
            prefs={context?.prefs}
            isSnapshotLoading={isSnapshotLoading}
            isSubscriptionInfoLoading={isSubscriptionInfoLoading}
            isDeliveryListLoading={isDeliveryListLoading}
            snapshot={snapshot}
            deliveryList={deliveryList}
            subscriptionInfo={subscriptionInfo}
            showDialog={showDialog}
            upgradeSubscription={upgradeSubscription}
        />
    );
}

export default Controller;