import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from "react-router-dom";

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';

import DashboardOverview from '../../view/page/dashboardSearchResults';

const Controller = props => 
{
    const [paginationTimestamp] = useState(`${new Date().toISOString()}`);

    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [listPage, setListPage] = useState(1);
    const [searchParams] = useSearchParams();

    const context = useContext(Context);

    const showDialog = (dialogID, dialogTitle, dialogDataID) =>
    {
        context?.setDialog(dialogID, dialogTitle, "bg-neutral-high-lightest", "sm", true, true, true, false, {id:dialogDataID});
    };

    const goPrev = () =>
    {
        if(listPage > 1)
        {
            setListPage(listPage-1);
        }
    };

    const goNext = () =>
    {
        setListPage(listPage+1);

    };

    useEffect(() =>
    {
        setLoading(true);

        let keyword = searchParams?.get("keyword");

        let params = {};

        params["page"] = listPage;
        params["timestamp"] = paginationTimestamp;
        params["keyword"] = keyword;

        isMounted && HTTP.get(Endpoint?.oms?.delivery?.list, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setList(response?.data?.list);
            }

            setLoading(false);
        });

    }, [isMounted, listPage, searchParams, paginationTimestamp]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_search');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardOverview
            recordsPerPage={50}
            prefs={context?.prefs}
            list={list?.map((delivery) => {return ({...delivery, captain_id: delivery?.captain?.user_id ?? null, captain_name:`${(delivery?.captain?.first_name && delivery?.captain?.last_name) ? delivery?.captain?.first_name + " " + delivery?.captain?.last_name : i18n.t("common.none")}`, status_name:i18n.t(`delivery.status_${delivery?.status_code}`), handling:delivery?.is_public ? i18n.t("delivery.is_public") : i18n.t("delivery.is_private")})})}
            listPage={listPage}
            isLoading={isLoading}            
            showDialog={showDialog}
            setListPage={setListPage}
            goPrev={goPrev}
            goNext={goNext}
        />
    );
}

export default Controller;