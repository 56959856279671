import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import i18n from '../../util/i18n';
import Constant from '../../config/constant';

import InputText from '../component/inputText';
import Button from '../component/button';

import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';
import TrashDarkIconImage from '../../assets/vector/icon_trash_dark.svg';
import TrashLightIconImage from '../../assets/vector/icon_trash_light.svg';

const View = (props) =>
{
    const [data, setData] = useState(
    {
		name_ar:"",
        name_en:""
	});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <table className='w-full my-5'>
                <tbody>

                {(props?.list?.length > 0)
                ?
                    props?.list?.map((skill, index) => {

                    return (
                        <tr key={index} className={(index === props?.list?.length - 1) ? "mt-4" : "border-neutral-high-light border-b-[1px] mt-4"}>
                            <td className="px-0 py-2 text-center">
                                {index+1}
                            </td>
                            <td className="p-2 whitespace-nowrap min-w-[20%] text-center">
                                {skill?.name_ar}
                            </td>
                            <td className="p-2 whitespace-nowrap min-w-[20%] text-center">
                                {skill?.name_en}
                            </td>
                            <td className="px-0 py-2 text-end min-w-[20%]">
                                <Button 
                                    type="neutral-low-outline" 
                                    className="min-w-[50px]" 
                                    icon={(props?.prefs?.theme === "dark") ? TrashLightIconImage : TrashDarkIconImage}
                                    iconHover={(props?.prefs?.theme === "dark") ? TrashDarkIconImage : TrashLightIconImage}
                                    isLoading={props?.isLoading}  
                                    isDisabled={props?.isLoading} 
                                    onClick={() => props?.remove(skill?.skill_id)}
                                />                                 
                            </td>
                        </tr>
                    )})
                :
                    <tr>
                        <td colSpan={4} className='w-full'>
                            <div className="w-full text-center text-neutral-low-light">
                                {i18n.t("error.no_skills")}
                            </div>
                        </td>
                    </tr>
                }

                </tbody>
            </table> 
            <div className="border-t-[1px] border-neutral-high-light pt-5">
                <div className="flex gap-2">
                    <div className="grow">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            isDisabled={props?.isLoading}
                            placeholder={i18n.t("common.name_ar")}
                            defaultValue={""}
                            onChange={(e) => update("name_ar", e?.target?.value)}
                        />                     
                    </div>
                    <div className="grow">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            isDisabled={props?.isLoading}
                            placeholder={i18n.t("common.name_en")}
                            defaultValue={""}
                            onChange={(e) => update("name_en", e?.target?.value)}
                        />                     
                    </div>
                    <div>
                        <Button 
                            type="neutral-low-solid" 
                            className="min-w-[120px]" 
                            text={i18n.t("common.add")} 
                            isLoading={props?.isLoading}  
                            isDisabled={false} onClick={() => props?.add(data)}
                            icon={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                            iconHover={(props?.prefs?.theme === "light") ? AddDarkIconImage : AddLightIconImage}                     
                        />
                    </div>
                </div>
                <AnimatePresence>
                    {props?.errors?.name && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm my-2`}
                        >
                            {props?.errors?.name}
                        </motion.div>
                    )}
                </AnimatePresence>   
            </div>                          
        </div>
    );
}

export default View;
