import { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate, Outlet, useSearchParams } from "react-router-dom";

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';

import Dashboard from '../../view/page/dashboard';

const Controller = props => 
{ 
    const context  = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();

    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [isRouteChanging, setRouteChanging] = useState(false);
    const [currentRoute, setRoute] = useState(location.pathname?.toLowerCase());
    const [searchParams] = useSearchParams();
    const [headsup, setHeadsup] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [shouldSilenceNotifications, silenceNotifications] = useState(false);

    const [mapCenter, setMapCenter] = useState({lat:Constant?.map?.center?.lat, long:Constant?.map?.center?.long});
    const [mapType, setMapType] = useState("delivery");
    const [mapStatus, setMapStatus] = useState("dispatched");
    const [mapZone, setMapZone] = useState(null);
    const [mapDeliveryList, setMapDeliveryList] = useState([]);
    const [mapCaptainList, setMapCaptainList] = useState([]);
    const [mapZoneList, setMapZoneList] = useState([]);
    const [mapDeliveryDialogInfo] = useState(
    {
        id:"DeliveryInfo",
        title:i18n.t("page.delivery_info"), 
        bgColor:"bg-neutral-high-lightest", 
        size:"sm", 
        isScrollable:true, 
        isFullscreen:true, 
        isClosable:true,
        shouldRefreshOnClose:false
    });
    const [mapCaptainDialogInfo] = useState(
    {
        id:"UserInfo",
        title:i18n.t("page.user_info"), 
        bgColor:"bg-neutral-high-lightest", 
        size:"sm", 
        isScrollable:true, 
        isFullscreen:false, 
        isClosable:true,
        shouldRefreshOnClose:false
    });

    const changeLang = () =>
    {
        let newLangCode = (context?.prefs?.lang?.toLowerCase() === "ar") ? "en" : "ar";

        context?.setPrefs({"lang": newLangCode});            

        i18n.changeLanguage(newLangCode);

        isMounted && HTTP.put(Endpoint?.crm?.user?.data, {lang_code:newLangCode});       
    };

    const search = (keyword) =>
    {
        navigate(`/dashboard/search?keyword=${keyword}`);
    };

    const toggleTheme = () =>
    {
        context?.setPrefs(
        {
            "theme": context?.prefs?.theme === "dark" ? "light" : "dark"
        });
    };
    
    const internalRedirect = (url) =>
    {
        navigate(url);
        context?.setDialog(null);   
    };  
    
    const externalRedirect = (url) =>
    {
        window.open(url);
    };  
    
    const showDialog = (id, title, bgColor, size, isScrollable, isFullscreen, isClosable, shouldRefreshOnClose, metadata) =>
    {
        context?.setDialog(id, title, bgColor, size, isScrollable, isFullscreen, isClosable, shouldRefreshOnClose, metadata);
    };  

    const showNotificationCenter = () =>
    {
        silenceNotifications(true);
        context?.setDialog("NotificationCenter",  `${i18n.t("page.notifications")} (${notifications?.length})`, "bg-neutral-high-lightest", "sm", true, false, true, false, {notifications:notifications});
    };

    const openDialog = (id, title) =>
    {
        context?.setDialog(id, title, "bg-neutral-high-lightest", "sm");
    };

    const redirectTo = (url) =>
    {
        setRoute(url);

        setRouteChanging(true);

        setTimeout(() =>
        {
            navigate(url);

            setRouteChanging(false);

        }, Constant.animation.speed.fast);
    };

    useEffect(() =>
    {   
        if(mapType && mapZone && mapStatus)
        {
            if(mapType?.toLowerCase() === "delivery")
            {
                setLoading(true);

                let zoneInfo = mapZoneList?.find(item => item?.zone_id === mapZone);

                let params = {};

                params["status_code"] = mapStatus;
                params["zone_id"] = zoneInfo?.zone_id;

                isMounted && HTTP.get(Endpoint?.oms?.map?.merchant, params).then(response =>
                {
                    if(response?.is_successful && response?.data?.list)
                    {
                        setMapDeliveryList(response?.data?.list);
                    }

                    setMapCenter({lat:zoneInfo?.center_lat, long:zoneInfo?.center_long});

                    setLoading(false);
                });
            }
            else if(mapType?.toLowerCase() === "captain")
            {
                setLoading(true);

                setMapCaptainList(
                    [{
                        lat:24.81885264076808, 
                        long:46.66032941265156,
                        user_id:"ABCD"
                    }]?.map((user) => { return {...user, ...{type:"user"}}})
                );

               setLoading(false);
            }
        }

    }, [isMounted, mapStatus, mapType, mapZone, mapZoneList]);

    useEffect(() =>
    {
        setLoading(true);

        let params = {};

        params["country_code"] = context?.prefs?.merchant_country_code;
        params["set_type"] = "full";
        params["type_code"] = "private";

        isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
        {            
            if(response?.is_successful && response?.data?.list)
            {
                if(response?.data?.list?.[0]?.zone_id && response?.data?.list?.[0]?.center_lat && response?.data?.list?.[0]?.center_long)
                {
                    setMapZone(response?.data?.list?.[0]?.zone_id);

                    setMapCenter({lat:response?.data?.list?.[0]?.center_lat, long:response?.data?.list?.[0]?.center_long});
                }

                setMapZoneList(response?.data?.list);
            }
            
            setLoading(false);
        });

    }, [isMounted, context?.prefs?.merchant_country_code]);

    useEffect(() =>
    {
        let params = (context?.prefs?.notification_last_update !== "") ? {type_code:"merchant", last_update:context?.prefs?.notification_last_update}: {type_code:"merchant"};

        isMounted && HTTP.get(Endpoint?.cms?.announcement?.list, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                let temp = [];

                for(var i=0; i<response?.data?.list.length; i++)
                {
                    if(response?.data?.list[i]?.type_code === "announcement")
                    {
                        temp?.push({destination:null, type:"message", date:response?.data?.list[i]?.created_at, id:null, title:null, bgColor:null, size:null, isScrollable:null, isFullscreen:null, isClosable:null, shouldRefreshOnClose:null, metadata:null, msg:response?.data?.list[i]?.msg});
                    }
                    else if(response?.data?.list[i]?.type_code === "cr_is_expired")
                    {
                        temp?.push({destination:"url_internal", type:"danger", date:response?.data?.list[i]?.created_at, URL:"dashboard/settings", msg:i18n.t("error.cr_is_expired")});
                    }
                    else if(response?.data?.list[i]?.type_code === "subscription_has_expired")
                    {
                        temp?.push({destination:"url_internal", type:"danger", date:response?.data?.list[i]?.created_at, URL:"dashboard/settings", msg:i18n.t("error.subscription_has_expired")});
                    }
                    else if(response?.data?.list[i]?.type_code === "email_not_verified")
                    {
                        temp?.push({destination:"dialog", type:"warning", date:response?.data?.list[i]?.created_at, id:"UserAccount", title:i18n.t("page.subscription"), bgColor:"bg-neutral-high-lightest", size:"sm", isScrollable:false, isFullscreen:false, isClosable:true, shouldRefreshOnClose:false, metadata:{}, msg:i18n.t("error.email_not_verified")});
                    }    
                    else if(response?.data?.list[i]?.type_code === "mobile_not_verified")
                    {
                        temp?.push({destination:"dialog", type:"warning", date:response?.data?.list[i]?.created_at, id:"UserAccount", title:i18n.t("page.subscription"), bgColor:"bg-neutral-high-lightest", size:"sm", isScrollable:false, isFullscreen:false, isClosable:true, shouldRefreshOnClose:false, metadata:{}, msg:i18n.t("error.mobile_not_verified")});
                    }                                                            
                }

                if(temp?.length > 0)
                {
                    setNotifications(temp);            
    
                    if(temp?.filter(item => item?.type?.toLowerCase() === "danger")?.[0])
                    {
                        setHeadsup(temp?.filter(item => item?.type?.toLowerCase() === "danger")?.[0]);
                    }                
                }
            }
        });

    }, [isMounted, context?.prefs?.notification_last_update]);

    useEffect(() =>
    {
        if(context?.prefs?.is_logged_in !== "1" || context?.prefs?.merchant_id === "")
        {
            navigate("/");
        }

    }, [context?.prefs?.is_logged_in, context?.prefs?.merchant_id, navigate]);

    useEffect(() =>
    {  
        const checkConnection = (isOnline) =>
        {
            if(!isOnline)
            {
                navigate("/error/disconnected");
            }     
        };

        window.addEventListener('online', () => checkConnection(true));
        window.addEventListener('offline', () => checkConnection(false));

        return () =>
        {
            window.removeEventListener('online', () => checkConnection(true));
            window.removeEventListener('offline', () => checkConnection(false));
        };
    }, [navigate]); 

    useEffect(() =>
    {        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <Dashboard
            prefs={context?.prefs}
            isLoading={isLoading}
            keyword={searchParams?.get("keyword")}
            headsup={headsup}
            notifications={notifications}
            shouldSilenceNotifications={shouldSilenceNotifications}
            isRouteChanging={isRouteChanging}
            currentRoute={currentRoute}
            mapCenter={mapCenter} 
            mapType={mapType}
            mapDeliveryList={mapDeliveryList}
            mapCaptainList={mapCaptainList}
            mapDeliveryDialogInfo={mapDeliveryDialogInfo}
            mapCaptainDialogInfo={mapCaptainDialogInfo}
            changeLang={changeLang}
            search={search}
            toggleTheme={toggleTheme}
            showNotificationCenter={showNotificationCenter}
            openDialog={openDialog}
            redirectTo={redirectTo}
            internalRedirect={internalRedirect}
            externalRedirect={externalRedirect}
            showDialog={showDialog}
        >
            <Outlet
                context={[mapType, setMapType, mapStatus, setMapStatus, mapZone, setMapZone, mapZoneList, isLoading]}
            />
        </Dashboard>
    );
}

export default Controller;