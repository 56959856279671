import i18n from '../../util/i18n';

import Image from '../component/image';

import ImageForSection05AndElement01 from '../../assets/image/section_05_01.webp';

const View = (props) =>
{
    return(
        <div id="section-5" className="container max-w-screen-lg mx-auto py-[100px] px-10 font-secondary">
            <div className="text-center lg:text-start text-secondary-darkest font-primary text-xl pb-20 pt-40">
                {i18n.t("home.section_05_title_01")}
            </div>            
            <div className="h-[320vh] lg:h-[200vh]">
                <div className="h-full">
                    <div className="float-start w-[100%] sm:w-[60%] md:w-[75%]">
                        <div className="gap-5 columns-1 lg:columns-2">
                            <div className="mt-0">
                                <div className="p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                                    <div className="font-primary text-lg text-center pb-5 mt-3 bg-[url('../../assets/image/yellow_highlight.webp')] bg-contain bg-no-repeat bg-center">
                                        {i18n.t("home.section_05_title_02")}
                                    </div>
                                    <div className="text-center leading-10 my-6">
                                        {i18n.t("home.section_05_content_02")}
                                    </div>
                                    <div className="font-primary text-accent-regular text-center leading-8 mt-12">
                                        {i18n.t("home.section_05_footnote_02")}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12">
                                <div className="p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                                    <div className="font-primary text-lg text-center pb-5 mt-3 bg-[url('../../assets/image/yellow_highlight.webp')] bg-contain bg-no-repeat bg-center">
                                        {i18n.t("home.section_05_title_03")}
                                    </div>
                                    <div className="text-center leading-10 my-6">
                                        {i18n.t("home.section_05_content_03")}
                                    </div>
                                    <div className="font-primary text-accent-regular text-center leading-8 mt-12">
                                        {i18n.t("home.section_05_footnote_03")}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12">                                
                                <div className="p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                                    <div className="font-primary text-lg text-center pb-5 mt-3 bg-[url('../../assets/image/yellow_highlight.webp')] bg-contain bg-no-repeat bg-center">
                                        {i18n.t("home.section_05_title_04")}
                                    </div>
                                    <div className="text-center leading-10 my-6">
                                        {i18n.t("home.section_05_content_04")}
                                    </div>
                                    <div className="font-primary text-accent-regular text-center leading-8 mt-12">
                                        {i18n.t("home.section_05_footnote_04")}
                                    </div>
                                </div>
                            </div>
                            <div className="h-0 lg:h-48"/>
                            <div className="mt-12">                                
                                <div className="p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                                    <div className="font-primary text-lg text-center pb-5 mt-3 bg-[url('../../assets/image/yellow_highlight.webp')] bg-contain bg-no-repeat bg-center">
                                    {i18n.t("home.section_05_title_05")}
                                    </div>
                                    <div className="text-center leading-10 my-6">
                                        {i18n.t("home.section_05_content_05")}
                                    </div>
                                    <div className="font-primary text-accent-regular text-center leading-8 mt-12">
                                        {i18n.t("home.section_05_footnote_05")}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12">                                
                                <div className="p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                                    <div className="font-primary text-lg text-center pb-5 mt-3 bg-[url('../../assets/image/yellow_highlight.webp')] bg-contain bg-no-repeat bg-center">
                                        {i18n.t("home.section_05_title_06")}
                                    </div>
                                    <div className="text-center leading-10 my-6">
                                        {i18n.t("home.section_05_content_06")}
                                    </div>
                                    <div className="font-primary text-accent-regular text-center leading-8 mt-12">
                                        {i18n.t("home.section_05_footnote_06")}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12">                                
                                <div className="p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                                    <div className="font-primary text-lg text-center pb-5 mt-3 bg-[url('../../assets/image/yellow_highlight.webp')] bg-contain bg-no-repeat bg-center">
                                        {i18n.t("home.section_05_title_07")}
                                    </div>
                                    <div className="text-center leading-10 my-6">
                                        {i18n.t("home.section_05_content_07")}
                                    </div>
                                    <div className="font-primary text-accent-regular text-center leading-8 mt-12">
                                        {i18n.t("home.section_05_footnote_07")}
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="hidden sm:inline-block float-start sticky top-[20%] w-[100%] sm:w-[40%] md:w-[25%]">
                        <Image src={ImageForSection05AndElement01} alt="" className="w-80 m-auto transition-all duration-normal ease-in-out"/>                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default View;
