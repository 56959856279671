import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';
import Formatter from '../../util/formatter';

import WalletDebit from '../../view/dialog/walletDebit';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isWalletLoading, setWalletLoading] = useState(true);
    const [isHistoryListLoading, setHistoryListLoading] = useState(true);
    const [isBankAccountListLoading, setBankAccountListLoading] = useState(true);
    const [tab, setTab] = useState("new");
    const [walletInfo, setWalletInfo] = useState({});
    const [historyList, setHistoryList] = useState([]);
    const [bankAccountList, setBankAccountList] = useState([]);
    const [errors, setErrors] = useState({});
    const [viewID, setViewID] = useState(Math.random());
    
    const context = useContext(Context);

    const changeTab = (tab) =>
    {
        setTab(tab);
        setViewID(Math.random());
    };

    const createBankAccount = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("BankAccountCreate", i18n.t("page.bank_account_create"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const next = (data) =>
    {
        let newErrors = {};

        if(!Validator.notEmpty(data?.amount))
        {
            newErrors["amount"] = i18n.t('error.invalid_amount');
        }
        else if(!Validator.numeric(data?.amount))
        {
            newErrors["amount"] = i18n.t('error.invalid_amount');
        } 
        else if(data?.amount <= 0)
        {
            newErrors["amount"] = i18n.t('error.invalid_amount');
        }  

        if(!Validator.notEmpty(data?.bank_account_id))
        {
            newErrors["bank_account_id"] = i18n.t('error.invalid_bank_code');            
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});
            setWalletLoading(true);
            setHistoryListLoading(true);
            setBankAccountListLoading(true);
            
            data["type_code"] = "merchant";

            isMounted && HTTP.post(Endpoint?.fms?.debit?.directDeposit, data).then(response =>
            {
                if(response?.is_successful)
                {
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  

                    }, Constant.animation?.speed?.normal);
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setWalletLoading(false);
                    setHistoryListLoading(false);
                    setBankAccountListLoading(false);                       
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }  
    };        

    useEffect(() =>
    {
        setWalletLoading(true);
        setHistoryListLoading(true);
        setBankAccountListLoading(true);

        isMounted && HTTP.get(Endpoint?.fms?.wallet?.data, {"type_code": "merchant"}).then(response =>
        {
            if(response?.is_successful && response?.data?.info)
            {
                setWalletInfo(response?.data?.info);
            }

            setWalletLoading(false);
        });

        isMounted && HTTP.get(Endpoint?.fms?.debit?.directDeposit, {"type_code": "merchant"}).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setHistoryList(response?.data?.list);
            }

            setHistoryListLoading(false);
        });

        isMounted && HTTP.get(Endpoint?.fms?.bankAccount?.list, {"type_code": "merchant"}).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setBankAccountList(response?.data?.list?.map((account) => {return {title:`${Formatter.mask(account?.bank_account_number, 3)}`, value:account?.bank_account_id}}));
            }

            setBankAccountListLoading(false);
        });        

    }, [isMounted]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <WalletDebit
            key={viewID}
            tab={tab}
            prefs={context?.prefs}
            isWalletLoading={isWalletLoading}
            isHistoryListLoading={isHistoryListLoading}
            isBankAccountListLoading={isBankAccountListLoading}
            walletInfo={walletInfo}
            historyList={historyList}
            bankAccountList={bankAccountList}
            errors={errors}
            setTab={changeTab}
            createBankAccount={createBankAccount}
            next={next}
        />
    );
}

export default Dialog;