import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Button from '../component/button';

const View = (props) =>
{
    return(
        <div className="p-7 font-secondary text-sm">
            <div className="text-center py-10 leading-8">
                {i18n.t("message.signup_complete")}                                                            
            </div>
            <div className="flex justify-center gap-5 mb-5">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.app_store_apple")} onClick={() => window.open(Constant?.url?.app_store?.apple, "_blank")}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.app_store_google")} onClick={() => window.open(Constant?.url?.app_store?.google, "_blank")}/>
            </div>  
        </div>
    );
}

export default View;
