import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import i18n from '../../util/i18n';

import Error from '../../view/page/error';

const Controller = props => 
{
    const navigate = useNavigate();

    useEffect(() =>
    {  
        const checkConnection = (isOnline) =>
        {
            if(isOnline)
            {
                navigate("/");   
            }     
        };

		window.addEventListener('online', () => checkConnection(true));
        window.addEventListener('offline', () => checkConnection(false));

        return () =>
        {
            window.removeEventListener('online', () => checkConnection(true));
            window.removeEventListener('offline', () => checkConnection(false));
        };
    }, [navigate]);

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('error.no_internet');

    }, []);

    return (
        <Error
            type = "no_internet"
            label = {i18n.t("common.try_again")}
            msg = {i18n.t("error.no_internet")}
            action = {() => {}}
        />
    );
}

export default Controller;