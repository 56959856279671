import i18n from '../../util/i18n';

import Formatter from '../../util/formatter';

import Button from '../component/button';
import Skelton from '../component/skelton';

const View = (props) =>
{
    return(
        <div className="font-secondary text-sm text-neutral-low-dark">          
            <div className="pt-10 font-secondary text-sm">
                <div className="text-center">
                    {i18n.t("common.amount")}
                </div>
                <div className="font-primary text-center text-lg mt-2">
                    {(props?.isLoading)
                    ?
                        <div className="w-[200px] m-auto pt-3"><Skelton className="bg-secondary-light mb-5" count={1}/></div>
                    :
                        <span>{Formatter.stringToNumberWithCommasAndDicimals(props?.deliveryInfo?.collection_amount)} {i18n.t("currency." + props?.prefs?.currency)}</span>
                    }
                </div>
                <div className="text-center mt-2 text-xs">
                    {(props?.isLoading) ? "" : <span>{i18n.t("delivery.tracking_number")} {props?.deliveryInfo?.tracking_number}</span>}
                </div> 
                {(props?.step === 1)
                ?                 
                    <div className="flex justify-end pt-24 px-7 pb-7">
                        <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.next()}/>
                    </div>
                :                     
                    props?.children             
                }
            </div>                                   
        </div>
    );
}

export default View;
    