import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        if(key === "country_code" && value?.toLowerCase() !== data?.[key]?.toLowerCase() && (props?.data?.persona?.toLowerCase() === "captain"))
        {
            props?.updateZoneList(value);
        }

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">
                {(props?.data?.persona?.toLowerCase() === "captain")
                ?
                    <>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("form.you_will_work_in_country")}
                            </div>
                            <InputDropdown
                                selectorClassName={"bg-secondary-light mb-2"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={props?.countryList}
                                listValue={data?.country_code ?? props?.data?.country_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("country_code", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.country_code && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.country_code}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("form.you_will_work_in_zone")}
                            </div>
                            <InputDropdown
                                selectorClassName={"bg-secondary-light mb-2"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={props?.zoneList}
                                listValue={data?.zone_id ?? props?.data?.zone_id}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading || !data?.country_code}
                                isListSorted={true}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("zone_id", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.zone_id && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.zone_id}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div>
                    </>   
                :
                    <>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("form.country_of_establishment")}
                            </div>
                            <InputDropdown
                                selectorClassName={"bg-secondary-light mb-2"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={props?.countryList}
                                listValue={data?.country_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("country_code", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.country_code && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.country_code}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.legal_name")}
                                placeholder={i18n.t("form.legal_name")}
                                defaultValue={data?.legal_name}
                                errorMsg={props?.errors?.legal_name}
                                onChange={(e) => {update("legal_name", e?.target?.value)}}
                            />              
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.display_name")}
                                placeholder={i18n.t("form.display_name")}
                                defaultValue={data?.display_name}
                                errorMsg={props?.errors?.display_name}
                                onChange={(e) => {update("display_name", e?.target?.value)}}
                            />              
                        </div>                                                                     
                    </>                      
                }                
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={false} onClick={() => props?.goPrev({...props?.data, ...data})}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
