import parse from 'html-react-parser';

import i18n from '../../util/i18n';
import formatter from '../../util/formatter';

import Skelton from '../component/skelton';

const View = (props) =>
{
    return( 
            <div id="static-page" className="font-secondary">
                {props?.isLoading ? <div className="container max-w-screen-lg mx-auto px-10 pt-[150px] min-h-[500px]"><Skelton className="bg-secondary-light mb-5" count={20}/></div> : null}

                {!props?.isLoading ? <div className="container max-w-screen-lg mx-auto px-10 pt-[150px] min-h-[500px]">
                    <div className="font-primary text-xl leading-10 pt-[50px]">
                        {props?.pageData?.title}
                    </div>
                    <div className="text-md leading-10 pt-[25px]">
                        {props?.pageData?.content ? parse(props?.pageData?.content) : null}
                    </div> 
                    <div className="leading-10 pt-[50px] text-end text-md">
                        {i18n.t("common.last_updated_at")} {props?.pageData?.last_update ? formatter.dateToString(props?.pageData?.last_update, "date") : i18n.t("common.unknown")}
                    </div>                
                </div> : null}

                <svg viewBox="0 0 1920 326" width="105%" preserveAspectRatio="none" className="p-0">
                    <path className="fill-secondary-dark" d="M 1919 327 C 1719 121 1709 -24 1169 100 L 0 330 V 330 H 1920 Z"/>
                </svg>
            </div>
    );
}

export default View;
