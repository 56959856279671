import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';
import Formatter from '../../util/formatter';

import DashboardCollection from '../../view/page/dashboardCollection';

const Controller = props => 
{
    const [paginationTimestamp] = useState(`${new Date().toISOString()}`);

    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [listPage, setListPage] = useState(1);

    const context = useContext(Context);

    const createCollection = (deliveryID) =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("CollectionInfo", i18n.t("page.cash_collection_info"), "bg-neutral-high-lightest", "sm", true, false, true, false, {id:deliveryID});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const showDialog = (dialogID, dialogTitle, dialogDataID) =>
    {
        context?.setDialog(dialogID, dialogTitle, "bg-neutral-high-lightest", "sm", true, false, true, false, {id:dialogDataID});
    };

    const goPrev = () =>
    {
        if(listPage > 1)
        {
            setListPage(listPage-1);
        }
    };

    const goNext = () =>
    {
        setListPage(listPage+1);

    };

    useEffect(() =>
    {
        setLoading(true);

        let params = {};

        params["page"] = listPage;
        params["timestamp"] = paginationTimestamp;

        isMounted && HTTP.get(Endpoint?.oms?.collection?.list, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setList(response?.data?.list);
            }

             setLoading(false);
        });

    }, [isMounted, listPage, paginationTimestamp]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_finance_manager');
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardCollection
            recordsPerPage={50}
            prefs={context?.prefs}
            list={list?.map((collection) => {return {
                ...collection, ...{bill_amount:Formatter.stringToNumberWithCommasAndDicimals(collection?.bill_amount), collection_amount:Formatter.stringToNumberWithCommasAndDicimals(collection?.collection_amount), created_at:Formatter.dateToString(collection?.created_at, "date")}
            }})}
            listPage={listPage}
            isLoading={isLoading}            
            showDialog={showDialog}
            setListPage={setListPage}
            createCollection={createCollection}
            goPrev={goPrev}
            goNext={goNext}
        />
    );
}

export default Controller;