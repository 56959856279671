import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import Formatter from '../../util/formatter';
import useMediaQuery from '../../hook/useMediaQuery';

const View = (props) =>
{
    const isScreenXSmall  = useMediaQuery("(max-width: 400px)");
    const isScreenSmall   = useMediaQuery("(max-width: 640px)");
    const isScreenMedium  = useMediaQuery("(max-width: 768px)");
    const isScreenLarge   = useMediaQuery("(max-width: 1024px)");
    const isScreenXLarge  = useMediaQuery("(max-width: 1280px)");
    const isScreen2XLarge = useMediaQuery("(max-width: 1536px)");

    const CustomizedXAxisTick = ({ x, y, payload, index }) =>
    {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={20} y={10} textAnchor="start" fill={(props?.theme === "light") ? "#000000" : "#ffffff"}>
                    {payload?.value}
                </text>
            </g>
        );
    };

    const CustomizedYAxisTick = ({ x, y, payload, index }) =>
    {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} textAnchor="start" fill={(props?.theme === "light") ? "#000000" : "#ffffff"}>
                    {new Intl.NumberFormat().format(payload?.value)}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) =>
    {
        if (active && payload && payload.length)
        {
        return (
            <div className="bg-neutral-high-light p-1 border-[1px] border-neutral-low-regular">
            <p className="text-neutral-low-regular">{`${label} (${Formatter.stringToNumberWithCommas(payload[0].value)})`}</p>
            </div>
        );
        }
    }

    return (
        <div className="flex justify-center">
            <LineChart 
                width={(isScreenXSmall) ? 200 : (isScreenSmall) ? 280 : (isScreenMedium) ? 450 : (isScreenLarge) ? 450 : (isScreenXLarge) ? 200 : (isScreen2XLarge) ? 400 : 450} 
                height={(isScreenXSmall) ? 132 : (isScreenSmall) ? 185 : (isScreenMedium) ? 300 : (isScreenLarge) ? 300 : (isScreenXLarge) ? 132 : (isScreen2XLarge) ? 265 : 300} 
                data={props?.data} 
                margin={{top: 20, right: 30, left:0, bottom: 0}}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={<CustomizedXAxisTick/>}/>
                <YAxis tick={<CustomizedYAxisTick/>}/>
                <Tooltip content={<CustomTooltip />}/>
                <Line type="monotone" dataKey="value" stroke="#f13675" activeDot={{ r: 8 }} />
            </LineChart>
      </div>
    )
}

export default View;