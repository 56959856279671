import { Routes, Route, Navigate } from 'react-router-dom';

import Home from './controller/page/home';
import StaticPage from './controller/page/staticPage';
import FAQs from './controller/page/faqs';
import Callback from './controller/page/callback';

import Logout from './controller/page/logout';
import Directory from './controller/page/directory';
import Dashboard from './controller/page/dashboard';

import DashboardOverview from './controller/page/dashboardOverview';
import DashboardLiveMap from './controller/page/dashboardLiveMap';
import DashboardDeliveryList from './controller/page/dashboardDeliveryList';
import DashboardUserList from './controller/page/dashboardUserList';
import DashboardCollection from './controller/page/dashboardCollection';
import DashboardWallet from './controller/page/dashboardWallet';
import DashboardSettings from './controller/page/dashboardSettings';
import DashboardSearchResults from './controller/page/dashboardSearchResults';

import ErrorNotFound from './controller/page/errorNotFound';
import ErrorNoInternet from './controller/page/errorNoInternet';

import Layout from './controller/core/layout';
import Dialog from './controller/core/dialog';
import Notification from './controller/core/notification';

const App = props => 
{
	return (
		<>
			<Notification/>
			<Dialog/>
			<Routes>
				<Route path={"/"} element={<Layout />}>
					<Route path="/" element={<Home/>}/>
					<Route path="page/faqs" element={<FAQs/>}/>
					<Route path="page/:page_code" element={<StaticPage/>}/>
					
					<Route path="logout" element={<Logout/>}/>
					<Route path="directory" element={<Directory/>}/>
					<Route path="dashboard" element={<Dashboard/>}>
						<Route index element={<DashboardOverview/>}/>
						<Route path="overview" element={<DashboardOverview/>}/>
						<Route path="map" element={<DashboardLiveMap/>}/>
						<Route path="delivery" element={<DashboardDeliveryList/>}/>
						<Route path="delivery/:status_code" element={<DashboardDeliveryList/>}/>
						<Route path="user" element={<DashboardUserList/>}/>
						<Route path="collection" element={<DashboardCollection/>}/>
						<Route path="wallet" element={<DashboardWallet/>}/>
						<Route path="settings" element={<DashboardSettings/>}/>
						<Route path="settings/account" element={<DashboardSettings tab={"account"}/>}/>
						<Route path="settings/zone" element={<DashboardSettings tab={"zone"}/>}/>
						<Route path="settings/trip" element={<DashboardSettings tab={"trip"}/>}/>
						<Route path="settings/bank-account" element={<DashboardSettings tab={"bank-account"}/>}/>
						<Route path="search" element={<DashboardSearchResults/>}/>
						<Route path="*" element={<Navigate to="/error/404"/>}/>
					</Route>

					<Route path="callback/:param1/:param2" element={<Callback/>}/>
					<Route path="callback/:param1/:param2/:param3" element={<Callback/>}/>
					<Route path="callback/:param1/:param2/:param3/:param4" element={<Callback/>}/>
					<Route path="callback/:param1/:param2/:param3/:param4/:param5" element={<Callback/>}/>

					<Route path="error/disconnected" element={<ErrorNoInternet/>}/>
					<Route path="error/:error_code" element={<ErrorNotFound/>}/>
					
					<Route path="*" element={<Navigate to="/error/404"/>}/>
				</Route>
			</Routes>
		</>
	);
}

export default App;