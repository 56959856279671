import { useState } from 'react';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputTextArea from '../component/inputTextArea';
import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="mb-7">
                <div className="mb-2">
                    {i18n.t("common.country")}
                </div>
                <InputDropdown
                    list={props?.countryList}
                    listValue={data?.country_code ?? props?.data?.country_code}
                    isDisabled={true}
                    theme={props?.prefs?.theme}
                />                                               
            </div>
            <div className="mb-7 flex gap-2">
                <InputText 
                    type={"text"}
                    enableAutoComplete={false}
                    label={i18n.t("common.name_ar")}
                    value={data?.name_ar ?? props?.data?.name_ar}
                    errorMsg={props?.errors?.name_ar}
                    onChange={(e) => {update("name_ar", e?.target?.value)}}
                />                                     
                <InputText 
                    type={"text"}
                    enableAutoComplete={false}
                    label={i18n.t("common.name_en")}
                    value={data?.name_en ?? props?.data?.name_en}
                    errorMsg={props?.errors?.name_en}
                    onChange={(e) => {update("name_en", e?.target?.value)}}
                />                                     
            </div> 
            <div className="mb-7 flex gap-2">
                <InputText 
                    type={"text"}
                    enableAutoComplete={false}
                    label={i18n.t("delivery.lat")}
                    value={data?.center_lat ?? props?.data?.center_lat}
                    errorMsg={props?.errors?.center_lat}
                    onChange={(e) => {update("center_lat", e?.target?.value)}}
                />                                     
                <InputText 
                    type={"text"}
                    enableAutoComplete={false}
                    label={i18n.t("delivery.long")}
                    value={data?.center_long ?? props?.data?.center_long}
                    errorMsg={props?.errors?.center_long}
                    onChange={(e) => {update("center_long", e?.target?.value)}}
                />                                     
            </div> 
            <div className="mb-7">
                <InputTextArea 
                    type={"text"}
                    rows={3}
                    enableAutoComplete={false}
                    label={i18n.t("common.geofence")}
                    value={data?.geofence ? JSON.stringify(data?.geofence) : props?.data?.geofence ? JSON.stringify(props?.data?.geofence) : ""}
                    errorMsg={props?.errors?.geofence}
                    onChange={(e) => {update("geofence", e?.target?.value)}}
                />                                     
            </div>
            <div className="flex justify-between">
                <Button type="accent-outline" className="min-w-[120px]" text={i18n.t("common.remove")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.remove()}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.submit(data)}/>
            </div>                                     
        </div>
    );
}

export default View;
