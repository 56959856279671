import { useContext, useRef, useEffect } from "react";

import AppConsumer from "../../util/context";

import Dialog from '../../view/core/dialog';

import BankAccountCreate from '../dialog/bankAccountCreate';
import BankAccountInfo from '../dialog/bankAccountInfo';
import CollectionInfo from '../dialog/collectionInfo';
import DeliveryCreate from '../dialog/deliveryCreate';
import DeliveryFileUpload from '../dialog/deliveryFileUpload';
import DeliveryInfo from '../dialog/deliveryInfo';
import DeliveryLocationUpdate from '../dialog/deliveryLocationUpdate';
import DeliverySurvey from '../dialog/deliverySurvey';
import IntroVideo from '../dialog/introVideo';
import Login from '../dialog/login';
import MerchantInfo from '../dialog/merchantInfo';
import MerchantSubscription from '../dialog/merchantSubscription';
import MerchantPublicDelivery from '../dialog/merchantPublicDelivery';
import NotificationCenter from '../dialog/notificationCenter';
import OTP from '../dialog/otp';
import PasswordReset from '../dialog/passwordReset';
import PasswordUpdate from '../dialog/passwordUpdate';
import Signup from '../dialog/signup';
import TripInfo from '../dialog/tripInfo';
import UserAccount from '../dialog/userAccount';
import UserInfo from '../dialog/userInfo';
import UserInvite from '../dialog/userInvite';
import UserOnboarding from '../dialog/userOnboarding';
import UserSkill from '../dialog/userSkill';
import WalletCredit from '../dialog/walletCredit';
import WalletDebit from '../dialog/walletDebit';
import ZoneCreate from '../dialog/zoneCreate';
import ZoneInfo from '../dialog/zoneInfo';

const Controller = props => 
{
    const context = useContext(AppConsumer);

    const dialogRef = useRef(null);

    const scrollToTop = () =>
    {
        dialogRef?.current?.scroll({ top: 0, behavior: "smooth" });
    };

    const close = () =>
    {
        if(context?.dialog?.should_refresh_on_close)
        {
            window.location.reload();
        }

        if(context?.dialog?.is_closable)
        {
            context?.setDialog(null);
        }
    };
    
    useEffect(() =>
    {
        scrollToTop();

    }, [context?.dialog?.id]);
        
    return (
        <Dialog
            dialog={context?.dialog}
            close={close}
            dialogRef={dialogRef}
        >
            {
                (context?.dialog?.id === "BankAccountCreate") ? <BankAccountCreate prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "BankAccountInfo") ? <BankAccountInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "CollectionInfo") ? <CollectionInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "DeliveryCreate") ? <DeliveryCreate prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "DeliveryFileUpload") ? <DeliveryFileUpload prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "DeliveryInfo") ? <DeliveryInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "DeliveryLocationUpdate") ? <DeliveryLocationUpdate prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "DeliverySurvey") ? <DeliverySurvey prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "IntroVideo") ? <IntroVideo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "Login") ? <Login prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "MerchantInfo") ? <MerchantInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "MerchantPublicDelivery") ? <MerchantPublicDelivery prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "MerchantSubscription") ? <MerchantSubscription prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "NotificationCenter") ? <NotificationCenter prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "OTP") ? <OTP prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/>:
                (context?.dialog?.id === "PasswordReset") ? <PasswordReset prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/>:
                (context?.dialog?.id === "PasswordUpdate") ? <PasswordUpdate prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/>:
                (context?.dialog?.id === "Signup") ? <Signup prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "TripInfo") ? <TripInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "UserAccount") ? <UserAccount prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "UserInfo") ? <UserInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "UserInvite") ? <UserInvite prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "UserOnboarding") ? <UserOnboarding prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "UserSkill") ? <UserSkill prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "WalletCredit") ? <WalletCredit prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "WalletDebit") ? <WalletDebit prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "ZoneCreate") ? <ZoneCreate prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> :
                (context?.dialog?.id === "ZoneInfo") ? <ZoneInfo prefs={context?.prefs} dialog={context?.dialog} scrollToTop={scrollToTop}/> : null
            }
        </Dialog>
    );
}

export default Controller;