
import { useState, useEffect } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';
import Validator from '../../util/validator';

import Image from '../component/image';
import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import Tooltip from '../component/tooltip';

import DollarDarkIcon from '../../assets/vector/icon_dollar_dark.svg';
import DollarLightIcon from '../../assets/vector/icon_dollar_light.svg';
import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';

import Button from '../component/button';

const View = (props) =>
{
    const [viewDelayRate, setViewDelayRate] = useState(Constant?.animation?.speed?.fast/1000);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [openedSections, openSection] = useState(props?.data?.map(item => item.status_code));
    const sections =
    [
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_new") + ")",
            key:"new"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_assigned") + ")",
            key:"assigned"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_confirmed") + ")",
            key:"confirmed"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_dispatched") + ")",
            key:"dispatched"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_delivered") + ")",
            key:"delivered"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_complete") + ")",
            key:"complete"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_rejected_by_customer_before_dispatch") + ")",
            key:"rejected_by_customer_before_dispatch"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_rejected_by_customer_after_dispatch") + ")",
            key:"rejected_by_customer_after_dispatch"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_returned_after_rejection") + ")",
            key:"returned_after_rejection"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_onhold") + ")",
            key:"onhold"            
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_cancelled") + ")",
            key:"cancelled"            
        }
    ];

    const toggleSection = (key) =>
    {
        let clone = [...openedSections];

        let index = openedSections?.indexOf(key);

        if(index !== -1)
        {
            clone?.splice(index, 1);
        }
        else
        {
            clone?.push(key);
        }

        openSection(clone);
    };

    const handleValueChange = (statusCode, key, value) =>
    {
        let clone = {...data};

        if(!clone?.[statusCode])
        {
            clone[statusCode] = {};
        }

        clone[statusCode][key] = value;

        setData(clone); 
    };

    const progress = (direction) =>
    {
        let clone = [...props?.data];
        let newErrors = {};

        Object.keys(data)?.forEach(statusCode =>
        {
            let index = clone?.findIndex(obj => obj.status_code === statusCode);
            let checkpoint = {};

            if(index >= 0)
            {
                checkpoint = clone?.[index];
            }
            else
            {
                checkpoint =
                {
                    status_code:statusCode,
                    period_in_hour:null,
                    emails:[],
                    mobile_numbers:[],
                    lang_code:null,
                };
            }

            if(data[statusCode]?.["period_in_hour"])
            {
                checkpoint["period_in_hour"] = data[statusCode]?.["period_in_hour"];
            }
            else
            {
                newErrors[`${statusCode}_period_in_hour`] = i18n.t("error.invalid_period_in_hour");
            }

            if(data[statusCode]?.["lang_code"])
            {
                checkpoint["lang_code"] = data[statusCode]?.["lang_code"];
            }
            else
            {
                newErrors[`${statusCode}_lang_code`] = i18n.t("error.invalid_lang_code");
            }

            let emailsFound = 0;

            for (let i = 0; i < 5; i++)
            {
                const key = `email_${i}`;

                if(data[statusCode]?.[key])
                {
                    if(Validator.email(data[statusCode]?.[key]))
                    {
                        if(checkpoint?.emails?.length >= i + 1)
                        {
                            checkpoint["emails"][i] = data[statusCode]?.[key];
                        }
                        else
                        {
                            checkpoint["emails"]?.push(data[statusCode]?.[key]);
                        }
                    }
                    else
                    {
                        newErrors[`${statusCode}_emails`] = i18n.t("error.invalid_email");
                    }

                    emailsFound++;
                }

            }

            let mobileCodesFound = 0;

            for (let i = 0; i < 5; i++)
            {
                const key = `mobile_code_${i}`;

                if(data[statusCode]?.[key])
                {
                    if(Validator.numeric(data[statusCode]?.[key]))
                    { 
                        if(checkpoint?.mobile_numbers?.length >= i + 1)
                        {
                            checkpoint["mobile_numbers"][i]["mobile_code"] = data[statusCode]?.[key];
                        }
                        else
                        {
                            checkpoint["mobile_numbers"]?.push({"mobile_code":data[statusCode]?.[key], "mobile":null});
                        }
                    }
                    else
                    {
                        newErrors[`${statusCode}_mobile_numbers`] = i18n.t("error.invalid_mobile");
                    }        

                    mobileCodesFound++;
                }
            }            

            let mobileFound = 0;

            for (let i = 0; i < 5; i++)
            {
                const key = `mobile_${i}`;

                if(data[statusCode]?.[key])
                {
                    if(Validator.numeric(data[statusCode]?.[key]))
                    {
                        if(checkpoint?.mobile_numbers?.length >= i + 1)
                        {
                            checkpoint["mobile_numbers"][i]["mobile"] = data[statusCode]?.[key];
                        }
                        else
                        {
                            checkpoint["mobile_numbers"]?.push({"mobile_code":null, "mobile":data[statusCode]?.[key]});
                        }
                    }
                    else
                    {
                        newErrors[`${statusCode}_mobile_numbers`] = i18n.t("error.invalid_mobile");
                    }                         

                    mobileFound++;
                }
            }

            if((emailsFound === 0 && mobileFound === 0) || (emailsFound === 0 && mobileCodesFound === 0))
            {
                newErrors[`${statusCode}_emails`] = i18n.t("error.either_email_or_sms_is_required");
                newErrors[`${statusCode}_mobile_numbers`] = i18n.t("error.either_email_or_sms_is_required");
            }

            if(mobileCodesFound !== mobileFound)
            {
                newErrors[`${statusCode}_mobile_numbers`] = i18n.t("error.invalid_mobile");
            }            

            if(index >= 0)
            {
                clone[index] = checkpoint;
            }
            else
            {
                clone.push(checkpoint);
            }
        });

        if(Object.keys(newErrors)?.length > 0)
        {
            setErrors(newErrors);

            props?.showErrorNotification(i18n.t("error.some_fields_are_missing"));

            props?.scrollToTop();
        }
        else
        {
            setErrors([]);

            if(direction === "prev")
            {
                props?.prev(clone);
            }
            else if(direction === "next")
            {
                props?.next(clone);
            }
        }
    };

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setViewDelayRate(0);
        }, Constant?.animation?.speed?.slow);
    
        return () => clearTimeout(timer);
        }, []);

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
                {i18n.t("trip.tip_03")}
            </div>
            {sections?.map((section, index) =>
                {
                    return (
                        <div key={"section_" + index} className="border-[1px] border-neutral-high-dark rounded-lg mb-7 shadow-sm">
                            <div className={`flex justify-between p-4 cursor-pointer transition duration-normal ease-in-out hover:bg-neutral-high-light ${openedSections?.includes(section?.key) ? "rounded-t-lg border-b-[1px] border-neutral-high-dark" : "rounded-lg"}`} onClick={() => toggleSection(section?.key)}>
                                <div className="font-primary">
                                    {section?.title}
                                </div>
                                <div className="my-auto">
                                    {openedSections?.includes(section?.key)
                                    ?
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronUpLightIconImage : ChevronUpDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    :
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronDownLightIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    }
                                </div>
                            </div>
                            <AnimatePresence>
                                {openedSections?.includes(section?.key) && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ delay: viewDelayRate, duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    >
                                        <div className="p-4">
                                            <table className="w-full">
                                                <tbody>
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-2">
                                                            {i18n.t("trip.sla_period_in_hour")}
                                                        </td>
                                                        <td className="w-full ps-3 pb-2">
                                                            <InputDropdown
                                                                list={[
                                                                        {title:"1 " + i18n.t("time.hour"), value:1}, 
                                                                        {title:"2 " + i18n.t("time.hours_singular"), value:2},
                                                                        {title:"3 " + i18n.t("time.hours_plural"), value:3},
                                                                        {title:"4 " + i18n.t("time.hours_plural"), value:4},
                                                                        {title:"5 " + i18n.t("time.hours_plural"), value:5},
                                                                        {title:"6 " + i18n.t("time.hours_plural"), value:6},
                                                                        {title:"7 " + i18n.t("time.hours_plural"), value:7},
                                                                        {title:"8 " + i18n.t("time.hours_plural"), value:8},
                                                                        {title:"9 " + i18n.t("time.hours_plural"), value:9},
                                                                        {title:"10 " + i18n.t("time.hours_plural"), value:10},
                                                                        {title:"11 " + i18n.t("time.hours_singular"), value:11},
                                                                        {title:"12 " + i18n.t("time.hours_singular"), value:12},
                                                                        {title:"24 " + i18n.t("time.hours_singular"), value:24},
                                                                        {title:"36 " + i18n.t("time.hours_singular"), value:36},
                                                                        {title:"48 " + i18n.t("time.hours_singular"), value:48},
                                                                        {title:"60 " + i18n.t("time.hours_singular"), value:60},
                                                                        {title:"72 " + i18n.t("time.hours_singular"), value:72},
                                                                        {title:"84 " + i18n.t("time.hours_singular"), value:84},
                                                                        {title:"96 " + i18n.t("time.hours_singular"), value:96}
                                                                     ]}
                                                                listValue={data?.[section.key]?.["period_in_hour"] ?? props?.data?.find(obj => obj?.status_code === section?.key)?.period_in_hour ?? ""}
                                                                isDisabled={props?.isLoading}
                                                                isListSorted={false}
                                                                isListReversed={false}
                                                                isSearchable={false}
                                                                isCompact={true}
                                                                theme={props?.prefs?.theme}
                                                                handleValueUpdate={(value) => handleValueChange(section.key, 'period_in_hour', value)}
                                                            />
                                                        </td>
                                                    </tr>   

                                                    <tr>
                                                        <td></td>
                                                        <td className="w-full ps-3">
                                                            <AnimatePresence>
                                                                {errors?.[`${section.key}_period_in_hour`] && (
                                                                    <motion.div 
                                                                        initial={{ height:0, opacity:0 }}
                                                                        animate={{ height: "auto", opacity:1 }}
                                                                        exit={{ height:0, opacity:0 }}
                                                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                                                    >
                                                                        {errors?.[`${section.key}_period_in_hour`]}
                                                                    </motion.div>
                                                                )}
                                                            </AnimatePresence>                                                                                                             
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap align-top pt-4">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.sla_emails")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <div className="w-full flex flex-wrap">
                                                                {[...Array(5)].map((_, i) =>
                                                                { 
                                                                    return (
                                                                        <div key={"email_" + index + "_" + i} className={`w-[50%] pb-1 ${(i%2 !== 0) ? "ps-0" : "pe-1"}`}>
                                                                            <InputText 
                                                                                type={"text"}
                                                                                enableAutoComplete={false}
                                                                                placeholder={"email@example.com"}
                                                                                isDisabled={props?.isLoading}
                                                                                defaultValue={props?.data?.find(obj => obj?.status_code === section?.key)?.emails?.[i] ?? ""}
                                                                                onChange={(e) => handleValueChange(section.key, "email_" + i, e?.target?.value)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}   
                                                            </div>
                                                        </td>
                                                    </tr>   

                                                    <tr>
                                                        <td></td>
                                                        <td className="w-full ps-3">                                                            
                                                            <AnimatePresence>
                                                                {errors?.[`${section.key}_emails`] && (
                                                                    <motion.div 
                                                                        initial={{ height:0, opacity:0 }}
                                                                        animate={{ height: "auto", opacity:1 }}
                                                                        exit={{ height:0, opacity:0 }}
                                                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                                                    >
                                                                        {errors?.[`${section.key}_emails`]}
                                                                    </motion.div>
                                                                )}
                                                            </AnimatePresence>                                                                                                          
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap align-top pt-4">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.sla_mobile_numbers")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <div className="w-full flex flex-wrap">
                                                                {[...Array(5)].map((_, i) =>
                                                                { 
                                                                    return (
                                                                        <div key={"mobile_" + index + "_" + i} className={`w-[50%] pb-1 ${(i%2 !== 0) ? "ps-3" : "pe-3"}`}>
                                                                            <div dir="ltr" className="flex gap-1">
                                                                                <div className="w-[150px]">
                                                                                    <InputDropdown
                                                                                        list={props?.countryList}
                                                                                        listValue={data?.[section.key]?.['mobile_code_' + i] ?? props?.data?.find(obj => obj?.status_code === section?.key)?.mobile_numbers?.[i]?.["mobile_code"] ?? ""}
                                                                                        searchInputPlaceholder={i18n.t("common.search_generic")}
                                                                                        isDisabled={props?.isLoading}
                                                                                        isListSorted={true}
                                                                                        isListReversed={false}
                                                                                        isSearchable={false}
                                                                                        isCompact={false}
                                                                                        theme={props?.prefs?.theme}
                                                                                        handleValueUpdate={(value) => handleValueChange(section.key, 'mobile_code_' + i, value)}
                                                                                    />                        
                                                                                </div>                    
                                                                                <div className="w-full">
                                                                                    <InputText 
                                                                                        type={"text"}
                                                                                        enableAutoComplete={false}
                                                                                        placeholder={"5XXXXXXXX"}
                                                                                        isDisabled={props?.isLoading}
                                                                                        defaultValue={props?.data?.find(obj => obj?.status_code === section?.key)?.mobile_numbers?.[i]?.["mobile"] ?? ""}
                                                                                        onChange={(e) => handleValueChange(section.key, 'mobile_' + i, e?.target?.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}                                                                                                     
                                                            </div>
                                                        </td>
                                                    </tr>   

                                                    <tr>
                                                        <td></td>
                                                        <td className="w-full ps-3">  
                                                            <AnimatePresence>
                                                                {errors?.[`${section.key}_mobile_numbers`] && (
                                                                    <motion.div 
                                                                        initial={{ height:0, opacity:0 }}
                                                                        animate={{ height: "auto", opacity:1 }}
                                                                        exit={{ height:0, opacity:0 }}
                                                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                                                    >
                                                                        {errors?.[`${section.key}_mobile_numbers`]}
                                                                    </motion.div>
                                                                )}
                                                            </AnimatePresence>                                                                                                                     
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-1">
                                                            {i18n.t("trip.sla_lang_code")}
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <InputDropdown
                                                                list={[{title:i18n.t("lang.ar"), value:"ar"}, {title:i18n.t("lang.en"), value:"en"}]}
                                                                listValue={data?.[section.key]?.['lang_code'] ?? props?.data?.find(obj => obj?.status_code === section?.key)?.lang_code ?? ""}
                                                                isDisabled={props?.isLoading}
                                                                isListSorted={false}
                                                                isListReversed={false}
                                                                isSearchable={false}
                                                                isCompact={false}
                                                                theme={props?.prefs?.theme}
                                                                handleValueUpdate={(value) => handleValueChange(section.key, 'lang_code', value)}
                                                            />
                                                        </td>
                                                    </tr>   

                                                    <tr>
                                                        <td></td>
                                                        <td className="w-full ps-3">                                                             
                                                            <AnimatePresence>
                                                                {errors?.[`${section.key}_lang_code`] && (
                                                                    <motion.div 
                                                                        initial={{ height:0, opacity:0 }}
                                                                        animate={{ height: "auto", opacity:1 }}
                                                                        exit={{ height:0, opacity:0 }}
                                                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                                                    >
                                                                        {errors?.[`${section.key}_lang_code`]}
                                                                    </motion.div>
                                                                )}
                                                            </AnimatePresence>                                                                                                              
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>                            
                        </div>
                    )
                })
            }  
            <div className="flex justify-between">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => progress("prev")}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => progress("next")}/>
            </div>                                                
        </div>
    );
}

export default View;
