const constant =
{
	lang:
	{
		default: 'ar',
		values: ["ar", "en"]
	},

	direction:
	{
		default: 'rtl',
		alternative: 'ltr',
		values: ["rtl", "ltr"]
	},

	currency:
	{
		default: 'sar',
		values: ["sar"]
	},

	theme:
	{
		default: 'light',
		values: ["light", "dark"]
	},

	otp:
	{
		channel:
		{
			default: "sms",
			values: ["sms", "email"]
		},
		duration: 120,
		length: 6
	},

	country:
	{
		default: 'sa',
	},

	mobile_code:
	{
		default: '966',
	},

	mobile:
	{
		prefix: '5',
		length: 12,
	},

	payment:
	{
		min: 250,
		max: 25000
	},

	pagination:
	{
		menu:
		{
			default: 3
		},
		list:
		{
			default: 25
		}
	},

	animation:
	{
		speed:
		{
			slowest: 5000,
			slow: 2500,
			normal: 1000,
			fast: 500,
			fastest: 200
		}
	},

	color:
	{
		chart:
		{
			default:
			[
				'#f13675', '#e97fb6', '#ac67cf', '#517bec', '#1cb0e3', '#00cbd0', '#4a5288', '#232b37', '#f9b01f', '#f68a1c', '#db5d1f'
			]
		},
		map:
		{
			default:
			[
				{
				featureType: "poi",
				stylers: [
					{ visibility: "off" }
				]   
				}				
			],

			light:
			[
				{
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#f0f3f8"
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#f0f3f8"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#bdbdbd"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#eeeeee"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#e5e5e5"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#dadada"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "transit.line",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#e5e5e5"
						}
					]
				},
				{
					"featureType": "transit.station",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#eeeeee"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#c9c9c9"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				}
			],
			
			dark:
			[
				{
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#31363f"
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#31363f"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.locality",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#bdbdbd"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#222831"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#1b1b1b"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#3c434d"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#8a8a8a"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#3c434d"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#4f5763"
						}
					]
				},
				{
					"featureType": "road.highway.controlled_access",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#4f5763"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#222831"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#3d3d3d"
						}
					]
				}
			],

			extra_dark:
			[
				{
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#212121"
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#212121"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.locality",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#bdbdbd"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#181818"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#1b1b1b"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#2c2c2c"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#8a8a8a"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#373737"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#3c3c3c"
						}
					]
				},
				{
					"featureType": "road.highway.controlled_access",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#4e4e4e"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#000000"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#3d3d3d"
						}
					]
				}
			]
		}
	},

	map:
	{
		zoom:
		{
			default: 10,
			min: 9,
			max: 21,
			closeUp: 14,
		},
		center:
		{
			lat: 24.712077,
			long: 46.672032
		}
	},

	file:
	{
		size:
		{
			default: 3
		}
	},

	url:
	{
		callback:
		{
			invite_captain: "https://dev.lastmile.sa/callback/invite_captain/"
		},
		social_media:
		{
			facebook: 'https://www.facebook.com/LastMileApp',
			x: 'https://x.com/LastMileApp',
			snapchat: 'https://snapchat.com/add/LastMileApp',
			instagram: 'https://instagram.com/LastMile_App',
			linkedin: 'https://www.linkedin.com/company/LastMile-App',
			tiktok: 'https://www.tiktok.com/@lastmileapp',
			youtube: 'https://www.youtube.com/@LastMileApp'
		},
		app_store:
		{
			apple: "https://apps.apple.com/us/app/lastmile/id1574918027",
			google: "https://play.google.com/store/apps/details?id=com.lastmile.sa"
		},
		file:
		{
			sample_delivery_upload_json:"https://cdn-dev.lastmile.sa/sample/sample_delivery_upload.json",
			sample_delivery_upload_xlsx:"https://cdn-dev.lastmile.sa/sample/sample_delivery_upload.xlsx"
		},
		misc:
		{
			intro_video_ar: "https://www.youtube.com/embed/NE07JtpYPtU?rel=0&autoplay=1",
			intro_video_en: "https://www.youtube.com/embed/NE07JtpYPtU?rel=0&autoplay=1",
			wikipedia_riyadh_ar: "https://ar.wikipedia.org/wiki/Riyadh",
			wikipedia_riyadh_en: "https://en.wikipedia.org/wiki/Riyadh"
		}
	},

	interceptor:
	{
		token: [1017]
	},

	misc:
	{
		inception_year: 2019
	},
};

export default constant;