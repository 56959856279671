import { useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import Button from '../component/button';
import Skelton from '../component/skelton';
import Image from '../component/image';
import Tooltip from '../component/tooltip';

import TickIconImage from '../../assets/vector/icon_check_dark.svg';
import InfoIconImage from '../../assets/vector/icon_info_dark.svg';

const View = (props) =>
{
    const [subscriptionCode, setSubscriptionCode] = useState("basic");

    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll(
    {
        target: targetRef,
        offset: ["start end", "end start"]
    });

    const textScale      = useTransform(scrollYProgress, [0.1, 0.2], [0.8, 1]);
    const textTranslateY = useTransform(scrollYProgress, [0.1, 0.2], [100, 0]);
    const textOpacity    = useTransform(scrollYProgress, [0.1, 0.2], [0.4, 1]);
    
    const tableScale      = useTransform(scrollYProgress, [0.2, 0.3], [0.8, 1]);
    const tableTranslateY = useTransform(scrollYProgress, [0.2, 0.3], [100, 0]);
    const tableOpacity    = useTransform(scrollYProgress, [0.2, 0.3], [0.4, 1]);

    return(
        <div ref={targetRef} id="section-9" className="container max-w-screen-lg mx-auto py-[100px] px-10 font-secondary">
            <motion.div style={{ scale:textScale, translateY:textTranslateY, opacity:textOpacity }} className="text-secondary-darkest text-center font-primary text-xl pb-12">
                {i18n.t("home.section_09_title_01")}
            </motion.div>

            <motion.div style={{ scale:tableScale, translateY:tableTranslateY, opacity:tableOpacity }} className="hidden lg:block w-full my-auto bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                <table className="table-auto w-full">
                    <thead>
                        <tr >
                            <th className={`p-5 text-center bg-primary-regular font-primary font-normal ${(props?.prefs?.dir === "rtl") ? "rounded-tr-xl" : "rounded-tl-xl"}`}>
                                {i18n.t("home.subscription_legend_feature")}
                            </th>
                            <th className="p-5 text-center bg-primary-regular font-primary font-normal">
                                {i18n.t("subscription.basic")}
                            </th>
                            <th className="p-5 text-center bg-primary-regular font-primary font-normal">
                                {i18n.t("subscription.essentials")}
                            </th>
                            <th className="p-5 text-center bg-primary-regular font-primary font-normal">
                                {i18n.t("subscription.premium")}
                            </th>
                            <th className={`p-5 text-center bg-primary-regular font-primary font-normal  ${(props?.prefs?.dir === "rtl") ? "rounded-tl-xl" : "rounded-tr-xl"}`}>
                                {i18n.t("subscription.enterprise")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                <div>
                                    {i18n.t("home.subscription_legend_monthly_price")}
                                </div>
                                <div className="my-auto">
                                    <Tooltip text={i18n.t("home.subscription_tooltip_what_is_monthly_fee")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                        <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="p-5 text-center font-primary text-accent-regular">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : props?.pricingData?.basic_fee === undefined ? i18n.t("common.unknown") : props?.pricingData?.basic_fee > 0 ? `${Formatter?.stringToNumberWithCommas(props?.pricingData?.basic_fee)} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.free")}
                            </td>
                            <td className="p-5 text-center font-primary text-accent-regular">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.essentials_fee ? i18n.t("common.unknown") : `${Formatter?.stringToNumberWithCommas(props?.pricingData?.essentials_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center font-primary text-accent-regular">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.premium_fee ? i18n.t("common.unknown") : `${Formatter?.stringToNumberWithCommas(props?.pricingData?.premium_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center font-primary text-accent-regular">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.enterprise_fee ? i18n.t("common.unknown") : `${Formatter?.stringToNumberWithCommas(props?.pricingData?.enterprise_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                <div>
                                    {i18n.t("home.subscription_legend_delivery_quota")}
                                </div>
                                <div className="my-auto">
                                    <Tooltip text={i18n.t("home.subscription_tooltip_what_is_delivery_quota")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                        <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.basic_delivery_quota ? i18n.t("common.unknown") :  Formatter?.stringToNumberWithCommas(props?.pricingData?.basic_delivery_quota)}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.essentials_delivery_quota ? i18n.t("common.unknown") :  Formatter?.stringToNumberWithCommas(props?.pricingData?.essentials_delivery_quota)}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.premium_delivery_quota ? i18n.t("common.unknown") :  Formatter?.stringToNumberWithCommas(props?.pricingData?.premium_delivery_quota)}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.enterprise_delivery_quota ? i18n.t("common.unknown") :  Formatter?.stringToNumberWithCommas(props?.pricingData?.enterprise_delivery_quota)}
                            </td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                <div>
                                    {i18n.t("home.subscription_legend_delivery_fee_you_deliver")}
                                </div>
                                <div className="my-auto">
                                    <Tooltip text={i18n.t("home.subscription_tooltip_what_is_you_deliver")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                        <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.basic_base_private_delivery_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.basic_base_private_delivery_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.essentials_base_private_delivery_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.essentials_base_private_delivery_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.premium_base_private_delivery_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.premium_base_private_delivery_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.enterprise_base_private_delivery_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.enterprise_base_private_delivery_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                <div>
                                    {i18n.t("home.subscription_legend_delivery_fee_we_deliver")}
                                </div>
                                <div className="my-auto">
                                    <Tooltip text={i18n.t("home.subscription_tooltip_what_is_we_deliver")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                        <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.basic_base_public_delivery_successful_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.basic_base_public_delivery_successful_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.essentials_base_public_delivery_successful_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.essentials_base_public_delivery_successful_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.premium_base_public_delivery_successful_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.premium_base_public_delivery_successful_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.enterprise_base_public_delivery_successful_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.enterprise_base_public_delivery_successful_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_wallet_withdrawal")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.basic_withdrawal_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.basic_withdrawal_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.essentials_withdrawal_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.essentials_withdrawal_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.premium_withdrawal_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.premium_withdrawal_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.enterprise_withdrawal_fee ? i18n.t("common.unknown") :  `${Formatter?.stringToNumberWithCommas(props?.pricingData?.enterprise_withdrawal_fee)} ${i18n.t("currency." + props?.prefs?.currency)}`}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                <div>
                                    {i18n.t("home.subscription_legend_customize_journey")}
                                </div>
                                <div className="my-auto">
                                    <Tooltip text={i18n.t("home.subscription_tooltip_what_is_extra_charges_for_addons")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                        <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                <div>
                                    {i18n.t("home.subscription_legend_pay_as_you_go")}
                                </div>
                                <div className="my-auto">
                                    <Tooltip text={i18n.t("home.subscription_tooltip_what_is_no_contract")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                        <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                    </Tooltip>
                                </div>
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_admin_users")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_captain_users")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_api_bandwidth")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                            </td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_support")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_live_tracker")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_daily_reports")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                        </tr>
                        <tr className="bg-secondary-light">
                            <td className="whitespace-nowrap text-center p-5">
                                {i18n.t("home.subscription_legend_reconciliation")}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                            <td className="p-5 text-center">
                                {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                            </td>
                        </tr>
                        <tr>
                            <td className="p-5"/>
                            <td className="p-5 py-10 text-center">
                                <Button type="secondary-solid" text={i18n.t("home.start_now")} onClick={() => {props?.showSignupDialog("basic")}}/>
                            </td>
                            <td className="p-5 py-10 text-center">
                                <Button type="secondary-solid" text={i18n.t("home.start_now")} onClick={() => {props?.showSignupDialog("essentials")}}/>
                            </td>
                            <td className="p-5 py-10 text-center">
                                <Button type="secondary-solid" text={i18n.t("home.start_now")} onClick={() => {props?.showSignupDialog("premium")}}/>
                            </td>
                            <td className="p-5 py-10 text-center">
                                <Button type="secondary-solid" text={i18n.t("home.start_now")} onClick={() => {props?.showSignupDialog("enterprise")}}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </motion.div>  

            <motion.div style={{ scale:tableScale, translateY:tableTranslateY, opacity:tableOpacity }} className="lg:hidden w-full my-auto rounded-xl shadow-2xl shadow-shadow-light">
                <div className="lg:hidden flex">
                    <div className={`flex cursor-pointer grow ${(props?.prefs?.dir === "rtl") ? "rounded-tr-xl" : "rounded-tl-xl"} bg-${(subscriptionCode === "basic") ? "secondary-lightest" : "primary-regular"}`} onClick={() => {setSubscriptionCode("basic")}}>
                        <div className="m-auto p-5">{i18n.t("subscription.basic")}</div>
                    </div>
                    <div className={`flex cursor-pointer grow bg-${(subscriptionCode === "essentials") ? "secondary-lightest" : "primary-regular"}`} onClick={() => {setSubscriptionCode("essentials")}}>
                        <div className="m-auto p-5">{i18n.t("subscription.essentials")}</div>
                    </div>
                    <div className={`flex cursor-pointer grow bg-${(subscriptionCode === "premium") ? "secondary-lightest" : "primary-regular"}`} onClick={() => {setSubscriptionCode("premium")}}>
                        <div className="m-auto p-5">{i18n.t("subscription.premium")}</div>
                    </div>
                    <div className={`flex cursor-pointer grow ${(props?.prefs?.dir === "rtl") ? "rounded-tl-xl" : "rounded-tr-xl"} bg-${(subscriptionCode === "enterprise") ? "secondary-lightest" : "primary-regular"}`} onClick={() => {setSubscriptionCode("enterprise")}}>
                        <div className="m-auto p-5">{i18n.t("subscription.enterprise")}</div>
                    </div>
                </div>
                <div className="lg:hidden w-full my-auto rounded-xl">
                    <table className="table-auto w-full">
                        <tbody>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                    <div>
                                        {i18n.t("home.subscription_legend_monthly_price")}
                                    </div>
                                    <div className="my-auto">
                                        <Tooltip text={i18n.t("home.subscription_tooltip_what_is_monthly_fee")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                            <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className="text-center text-accent-regular font-primary">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : props?.pricingData?.[subscriptionCode + "_fee"] === undefined ? i18n.t("common.unknown") : props?.pricingData?.[subscriptionCode + "_fee"] > 0 ? `${Formatter?.stringToNumberWithCommas(props?.pricingData?.[subscriptionCode + "_fee"])} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.free")}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                    <div>
                                        {i18n.t("home.subscription_legend_delivery_quota")}
                                    </div>
                                    <div className="my-auto">
                                        <Tooltip text={i18n.t("home.subscription_tooltip_what_is_delivery_quota")} className="text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                            <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                        </Tooltip>
                                    </div>
                                </td>                            
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.[subscriptionCode + "_delivery_quota"] ? i18n.t("common.unknown") : Formatter?.stringToNumberWithCommas(props?.pricingData?.[subscriptionCode + "_delivery_quota"])}
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                    <div>
                                        {i18n.t("home.subscription_legend_delivery_fee_you_deliver")}
                                    </div>
                                    <div className="my-auto">
                                        <Tooltip text={i18n.t("home.subscription_tooltip_what_is_you_deliver")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                            <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                        </Tooltip>
                                    </div>
                                </td>                            
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.[subscriptionCode + "_base_private_delivery_fee"] ? i18n.t("common.unknown") : `${Formatter?.stringToNumberWithCommas(props?.pricingData?.[subscriptionCode + "_base_private_delivery_fee"])} ${i18n.t("currency." + props?.prefs?.currency)}`}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                    <div>
                                        {i18n.t("home.subscription_legend_delivery_fee_we_deliver")}
                                    </div>
                                    <div className="my-auto">
                                        <Tooltip text={i18n.t("home.subscription_tooltip_what_is_we_deliver")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                            <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                        </Tooltip>
                                    </div>
                                </td>                            
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.[subscriptionCode + "_base_public_delivery_successful_fee"] ? i18n.t("common.unknown") : `${Formatter?.stringToNumberWithCommas(props?.pricingData?.[subscriptionCode + "_base_public_delivery_successful_fee"])} ${i18n.t("currency." + props?.prefs?.currency)}`}
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_wallet_withdrawal")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : !props?.pricingData?.[subscriptionCode + "_withdrawal_fee"] ? i18n.t("common.unknown") : `${Formatter?.stringToNumberWithCommas(props?.pricingData?.[subscriptionCode + "_withdrawal_fee"])} ${i18n.t("currency." + props?.prefs?.currency)}`}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                    <div>
                                        {i18n.t("home.subscription_legend_customize_journey")}
                                    </div>
                                    <div className="my-auto">
                                        <Tooltip text={i18n.t("home.subscription_tooltip_what_is_extra_charges_for_addons")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                            <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5 flex justify-center gap-2">
                                    <div>
                                        {i18n.t("home.subscription_legend_pay_as_you_go")}
                                    </div>
                                    <div className="my-auto">
                                        <Tooltip text={i18n.t("home.subscription_tooltip_what_is_no_contract")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                            <Image src={InfoIconImage} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                        </Tooltip>
                                    </div>
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_admin_users")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_captain_users")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_api_bandwidth")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> :  i18n.t("home.subscription_legend_unlimited")}
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_support")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_live_tracker")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                                </td>
                            </tr>
                            <tr>
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_daily_reports")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                                </td>
                            </tr>
                            <tr className="bg-secondary-light">
                                <td className="whitespace-nowrap text-center p-5">
                                    {i18n.t("home.subscription_legend_reconciliation")}
                                </td>
                                <td className="p-5 text-center">
                                    {props?.isLoading ? <Skelton className="bg-secondary-regular"/> : <Image src={TickIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>}
                                </td>
                            </tr>
                            <tr>
                                <td className="p-5"/>
                                <td className="p-5 py-10 text-center">
                                    <Button type="secondary-solid" text={i18n.t("home.start_now")} onClick={() => {props?.showSignupDialog(subscriptionCode)}}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            </motion.div>                  
        </div>
    );
}

export default View;
