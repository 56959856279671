import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import PasswordUpdate from '../../view/dialog/passwordUpdate';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const save = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(!Validator.notEmpty(stepData?.password_old))
        {
            newErrors["password_old"] = i18n.t('error.invalid_old_password');            
        }
        else if(!Validator.longPassword(stepData?.password_old))
        {
            newErrors["password_old"] = i18n.t('error.short_password');            
        }

        if(!Validator.notEmpty(stepData?.password_new))
        {
            newErrors["password_new"] = i18n.t('error.invalid_new_password');            
        }
        else if(!Validator.longPassword(stepData?.password_new))
        {
            newErrors["password_new"] = i18n.t('error.short_password');            
        }

        if(stepData?.password_new === stepData?.password_old)
        {
            newErrors["password_new"] = i18n.t('error.new_password_similar_to_old');            
        }

        if(!Validator.notEmpty(stepData?.password_confirmation) || stepData?.password_new !== stepData?.password_confirmation)
        {
            newErrors["password_confirmation"] = i18n.t('error.passwords_do_not_match');            
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            params = 
            {
                old_password:stepData?.password_old,
                new_password:stepData?.password_new
            };

            isMounted && HTTP.post(Endpoint?.crm?.user?.password, params).then(response =>
            {
                if(response?.is_successful)
                {
                    isMounted && HTTP.delete(Endpoint?.crm?.user?.token); 

                    context.clearPrefs();

                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.login_again"));

                    context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");                    
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });            
        }
        else
        {
            setErrors(newErrors);
        }        
    };

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <PasswordUpdate
            isLoading={isLoading}
            errors={errors}
            prefs={context?.prefs}
            save={save}
        />
    );
}

export default Dialog;