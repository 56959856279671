import { NavLink, useNavigate } from "react-router-dom";
import { Link } from 'react-scroll';

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Image from './image';
import Button from './button';

import LinkedInImage from '../../assets/image/social_icons_linkedin.webp';
import XImage from '../../assets/image/social_icons_x.webp';
import FacebookImage from '../../assets/image/social_icons_facebook.webp';
import InstagramImage from '../../assets/image/social_icons_instagram.webp';
import SnapchatImage from '../../assets/image/social_icons_snapchat.webp';
import YouTubeImage from '../../assets/image/social_icons_youtube.webp';

import AppStoreAppleImage from '../../assets/vector/app_store_apple_dark.svg';
import AppStoreGoogleleImage from '../../assets/vector/app_store_google_dark.svg';

const View = (props) =>
{
    const navigate = useNavigate();

    return(
        <div className="font-secondary text-secondary-lightest">
            {(!props?.isMinimal)
            ?
                <div className="bg-secondary-dark py-5 px-10">
                    <div className="container max-w-screen-lg mx-auto">
                        <div className="grid grid-cols-2 md:flex md:justify-between md:flex-row">
                            <div className="py-10">
                                <div className="text-primary-regular font-primary mb-5 text-lg">
                                    {i18n.t("footer.section_account_management")}
                                </div>
                                <hr className="w-[60px] h-grid-xxs bg-primary-regular border-none rounded-full"/>
                                <div className="mt-5">
                                    <Button type="naked" text={i18n.t("page.signup_as_captain")} onClick={props?.showCaptainSignUpDialog}/>
                                </div>
                                <div className="mt-5">
                                    <Button type="naked" text={i18n.t("page.signup_as_merchant")} onClick={props?.showMerchantSignUpDialog}/>
                                </div>
                                <div className="mt-5">
                                    <Button type="naked" text={i18n.t("page.reset_password")} onClick={props?.showPasswordResetDialog}/>
                                </div>
                                <div className="mt-5">
                                    <Button type="naked" text={i18n.t("page.dashboard")} onClick={props?.showLoginDialog}/>
                                </div>                            
                            </div>
                            <div className="py-10">
                                <div className="text-primary-regular font-primary mb-5 text-lg">
                                    {i18n.t("footer.section_info")}
                                </div>
                                <hr className="w-[60px] h-grid-xxs bg-primary-regular border-none rounded-full"/>
                                <div className="mt-5">
                                    <Link to="static-page" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => navigate("/page/privacy-policy")}>
                                            <Button type="naked" text={i18n.t("page.privacy_policy")} onClick={() => {}}/>
                                    </Link>
                                </div>
                                <div className="mt-5">
                                    <Link to="static-page" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => navigate("/page/account-deletion")}>
                                            <Button type="naked" text={i18n.t("page.account_deletion")} onClick={() => {}}/>
                                    </Link>
                                </div>                                
                                <div className="mt-5">
                                    <Link to="static-page" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => navigate("/page/terms-of-use")}>
                                        <Button type="naked" text={i18n.t("page.terms_of_use")} onClick={() => {}}/>
                                    </Link>
                                </div>
                                <div className="mt-5">
                                    <Link to="static-page" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => navigate("/page/captain-agreement")}>
                                        <Button type="naked" text={i18n.t("page.captain_agreement")} onClick={() => {}}/>
                                    </Link>
                                </div>
                                <div className="mt-5">
                                    <NavLink to="/page/faqs">
                                        <Button type="naked" text={i18n.t("page.faqs")} onClick={() => {}}/>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="py-10">
                                <div className="text-primary-regular font-primary mb-5 text-lg">
                                    {i18n.t("footer.section_developers")}
                                </div>
                                <hr className="w-[60px] h-grid-xxs bg-primary-regular border-none rounded-full"/>
                                <div className="mt-5">
                                    <Link to="static-page" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => navigate("/page/developer-docs")}>
                                        <Button type="naked" text={i18n.t("page.developer_documentation")} onClick={() => {}}/>
                                    </Link>
                                </div>
                                <div className="mt-5">
                                    <Link to="static-page" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => navigate("/page/developer-sample")}>
                                        <Button type="naked" text={i18n.t("page.developer_sample_code")} onClick={() => {}}/>
                                    </Link>
                                </div>
                            </div>
                            <div className="py-10">
                                <div className="text-primary-regular font-primary mb-5 text-lg">
                                    {i18n.t("footer.section_download_app")}
                                </div>
                                <hr className="w-[60px] h-grid-xxs bg-primary-regular border-none rounded-full"/>
                                <div className="mt-5">
                                    <a href={Constant?.url?.app_store?.apple}  target="_blank" rel="noopener noreferrer">
                                        <Image src={AppStoreAppleImage} alt="" className="max-h-[50px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                    </a>
                                </div>
                                <div className="mt-5">
                                    <a href={Constant?.url?.app_store?.google}  target="_blank" rel="noopener noreferrer">
                                        <Image src={AppStoreGoogleleImage} alt="" className="max-h-[50px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                null
            }
            
            <div className="bg-secondary-darkest py-5 px-10">
                <div className="grid grid-cols-1 md:container md:max-w-screen-lg md:mx-auto md:flex md:gap-4 md:justify-between">
                    <div className="text-center md:text-start text-sm mb-10 md:my-auto">
                        {i18n.t("footer.copyrights")} <a href={(props?.prefs?.lang === "ar") ? Constant?.url?.misc?.wikipedia_riyadh_ar : Constant?.url?.misc?.wikipedia_riyadh_en} target="_blank" rel="noopener noreferrer" className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 border-b border-1 border-dashed border-secondary-lightest">{i18n.t("footer.copyrights_city")}</a>
                    </div>
                    <div className="flex justify-center md:justify-end gap-3 my-auto">
                        <a href={Constant?.url?.social_media?.linkedin} className="bg-secondary-dark p-3 rounded-full transition duration-normal ease-in-out opacity-100 hover:opacity-50" target="_blank" rel="noopener noreferrer">
                            <Image src={LinkedInImage} alt="" className="max-w-[15px] max-h-[15px] cursor-pointer"/>
                        </a>
                        <a href={Constant?.url?.social_media?.x} className="bg-secondary-dark p-3 rounded-full transition duration-normal ease-in-out opacity-100 hover:opacity-50" target="_blank" rel="noopener noreferrer">
                            <Image src={XImage} alt="" className="max-w-[15px] max-h-[15px] cursor-pointer"/>
                        </a>
                        <a href={Constant?.url?.social_media?.facebook} className="bg-secondary-dark p-3 rounded-full transition duration-normal ease-in-out opacity-100 hover:opacity-50" target="_blank" rel="noopener noreferrer">
                            <Image src={FacebookImage} alt="" className="max-w-[15px] max-h-[15px] cursor-pointer"/>
                        </a>
                        <a href={Constant?.url?.social_media?.instagram} className="bg-secondary-dark p-3 rounded-full transition duration-normal ease-in-out opacity-100 hover:opacity-50" target="_blank" rel="noopener noreferrer">
                            <Image src={InstagramImage} alt="" className="max-w-[15px] max-h-[15px] cursor-pointer"/>
                        </a>
                        <a href={Constant?.url?.social_media?.snapchat} className="bg-secondary-dark p-3 rounded-full transition duration-normal ease-in-out opacity-100 hover:opacity-50" target="_blank" rel="noopener noreferrer">
                            <Image src={SnapchatImage} alt="" className="max-w-[15px] max-h-[15px] cursor-pointer"/>
                        </a>
                        <a href={Constant?.url?.social_media?.youtube} className="bg-secondary-dark p-3 rounded-full transition duration-normal ease-in-out opacity-100 hover:opacity-50" target="_blank" rel="noopener noreferrer">
                            <Image src={YouTubeImage} alt="" className="max-w-[15px] max-h-[15px] cursor-pointer mt-[2px]"/>
                        </a>
                    </div>
                </div>
            </div>        
        </div>
    );
}

export default View;
