import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';

import Image from '../component/image';

import ImageForSection08AndElement01 from '../../assets/image/section_08_01.webp';
import ImageForSection08AndElement02 from '../../assets/image/section_08_02.webp';

const View = (props) =>
{
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll(
    {
        target: targetRef,
        offset: ["start end", "end start"]
    });

    const firstCardTranslateX = useTransform(scrollYProgress, [0.1, 0.4], [100, 0]);
    const firstCardOpacity    = useTransform(scrollYProgress, [0.1, 0.2], [0, 1]);
    
    const secondCardTranslateX = useTransform(scrollYProgress, [0.2, 0.4], [-100, 0]);
    const secondCardOpacity    = useTransform(scrollYProgress, [0.2, 0.2], [0, 1]);

    return(
        <div ref={targetRef} id="section-8" className="container max-w-screen-lg mx-auto py-[100px] px-10 font-secondary">
            <motion.div style={{ translateX:firstCardTranslateX, opacity:firstCardOpacity }} className="flex gap-12 mb-12 w-full">
                <div className="grow m-auto">
                    <Image src={ImageForSection08AndElement01} alt="" className="w-52 md:w-32 lg:w-48 transition-all duration-normal ease-in-out"/>                    
                </div>
                <div className="grow m-auto">
                    <div  className="text-secondary-darkest text-start font-primary text-xl pb-5">
                        {i18n.t("home.section_08_title_01")}
                    </div>  
                    <div className="text-md leading-10 lg:leading-[50px] text-start md:text-start">
                        {i18n.t("home.section_08_content_01")}
                    </div>  
                </div>
                <div className="hidden lg:inline-block mr-[250px]"/>
            </motion.div>  
            <motion.div className="flex w-full" style={{ translateX:secondCardTranslateX, opacity:secondCardOpacity }}>
                <div className="grow m-auto"/>
                <div className="m-auto">
                    <Image src={ImageForSection08AndElement02} alt="" className="w-full md:w-[500px] lg:w-[700px] transition-all duration-normal ease-in-out"/>                                        
                </div>
            </motion.div>
        </div>
    );
}

export default View;
