import { useEffect, useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import HTTP from '../../util/http';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);

    const context = useContext(Context);
    const navigate = useNavigate();

    useEffect(() =>
    {
        isMounted && HTTP.delete(Endpoint?.crm?.user?.token); 

        context.clearPrefs();

        navigate("/");

    }, [isMounted, context, navigate]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (<></>);
}

export default Controller;