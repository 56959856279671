import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';
import Formatter from '../../util/formatter';

import DashboardDeliveryList from '../../view/page/dashboardDeliveryList';

const Controller = props => 
{
    const context  = useContext(Context);
    const params   = useParams();
    const navigate = useNavigate();

    const [paginationTimestamp] = useState(`${new Date().toISOString()}`);

    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [listPage, setListPage] = useState(1);
    const [listFilterType, setListFilterType] = useState("status_code");
    const [listFilterValue, setListFilterValue] = useState(["pending_assignment", "pending_location", "new", "assigned", "confirmed", "dispatched", "delivered", "complete", "rejected_by_customer_before_dispatch", "rejected_by_customer_after_dispatch", "returned_after_rejection", "onhold", "cancelled"]?.includes(params?.status_code?.toLowerCase()) ? params?.status_code?.toLowerCase() : "new");
    const [zoneList, setZoneList] = useState([]);

    const createDelivery = (method) =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            if(method?.toLowerCase() === "manual")
            {
                context?.setDialog("DeliveryCreate", i18n.t("page.delivery_create_manual"), "bg-neutral-high-lightest", "md");
            }
            else if(method?.toLowerCase() === "excel")
            {
                context?.setDialog("DeliveryFileUpload", i18n.t("page.delivery_create_excel"), "bg-neutral-high-lightest", "md");
            }
            else if(method?.toLowerCase() === "api")
            {
                navigate("/page/developer-docs");
            }
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    const showDialog = (dialogID, dialogTitle, dialogDataID) =>
    {
        context?.setDialog(dialogID, dialogTitle, "bg-neutral-high-lightest", "sm", true, true, true, false, {id:dialogDataID});
    };

    const updateFilter = (key, value) =>
	{
        if(key === "listFilterValue" && value !== listFilterValue)
        {
            setListFilterValue(value);
        }
        else if(key === "listFilterType" && value !== listFilterType)
        {
            setListFilterType(value);
            
            setListFilterValue(null);

            if(value?.toLowerCase() === "zone_id")
            {
                setLoading(true);

                let params = {};

                params["country_code"] = context?.prefs?.merchant_country_code;
                params["set_type"] = "full";
                params["type_code"] = "private";
        
                isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
                {            
                    if(response?.is_successful && response?.data?.list)
                    {
                        setZoneList(response?.data?.list);
                    }

                    setLoading(false);
                });
            }
        }
    };

    const goPrev = () =>
    {
        if(listPage > 1)
        {
            setListPage(listPage-1);
        }
    };

    const goNext = () =>
    {
        setListPage(listPage+1);

    };

    useEffect(() =>
    {
        if(listFilterType && listFilterValue)
        {
            setLoading(true);
    
            let params = {};
    
            params["page"] = listPage;
            params["timestamp"] = paginationTimestamp;

            if(listFilterType === "created_at")
            {
                params["month"] = Formatter.dateToTimestamp(listFilterValue, "date")
            }
            else
            {
                params[listFilterType] = listFilterValue;
            }
    
            isMounted && HTTP.get(Endpoint?.oms?.delivery?.list, params).then(response =>
            {
                if(response?.is_successful && response?.data?.list)
                {
                    setList(response?.data?.list?.map((delivery) => {
                        return ({...delivery, captain_id: delivery?.captain?.user_id ?? null, captain_name:`${(delivery?.captain?.first_name && delivery?.captain?.last_name) ? delivery?.captain?.first_name + " " + delivery?.captain?.last_name : i18n.t("common.none")}`, status_name:i18n.t(`delivery.status_${delivery?.status_code}`), handling:delivery?.is_public ? i18n.t("delivery.is_public") : i18n.t("delivery.is_private")})}));
                }
    
                setLoading(false);
            });
        }

    }, [isMounted, listPage, listFilterValue, listFilterType, paginationTimestamp]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_delivery_list');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardDeliveryList
            recordsPerPage={50}
            prefs={context?.prefs}
            list={list}
            listPage={listPage}
            listFilterType={listFilterType}
            listFilterValue={listFilterValue}
            zoneList={zoneList?.map((zone) => {return {title:zone?.[`name_${context?.prefs?.lang}`], value:zone?.zone_id}})}
            isLoading={isLoading}            
            showDialog={showDialog}
            setListPage={setListPage}
            createDelivery={createDelivery}
            goPrev={goPrev}
            goNext={goNext}
            setListFilterType={(value) => {updateFilter("listFilterType", value)}}
            setListFilterValue={(value) => {updateFilter("listFilterValue", value)}}
        />
    );
}

export default Controller;