import React, { useEffect, useState, useRef } from 'react';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';

const Dialog = props => 
{
	const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const refs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
	const [counter, setCounter] = useState(props?.period);

	const handleChange = (index, e) =>
	{
		const value = e?.target?.value;

		const newOTP = [...otp];

		const altNumbers = {"٠":0, "١":1, "٢":2, "٣":3, "٤":4, "٥":5, "٦":6, "٧":7, "٨":8, "٩":9};
		
		if(value.length === 1 && /^-?(0|[1-9]\d*)$/.test(value?.toString()?.trim()))
		{
			newOTP[index] = value;

			if(index < 5)
			{
				refs[index+1]?.current?.focus();
			}
		}
		else if(value.length === 1 && Object.keys(altNumbers)?.includes(value))
		{
			newOTP[index] = altNumbers[value];

			if(index < 5)
			{
				refs[index+1]?.current?.focus();
			}
		}
		else
		{
			newOTP[index] = "";
		}

		setOTP(newOTP);
	};

	const handlePaste = (e) =>
	{
		const pastedText = e.clipboardData.getData('Text');

		const digits = pastedText.match(/\d/g);

		if(digits && digits.length === 6)
		{
			const newOTP = digits.slice(0, 6);

			setOTP(newOTP);

			refs[5]?.current?.focus();
		}
	};

	const handleKeyDown = (index, e) =>
	{
		if (e.key === 'Backspace' && !otp[index] && index !== 0)
		{
			if(index > 0)
			{
				refs[index-1]?.current?.focus();
			}
		}
	};

    useEffect(() =>
    {
        const timer = counter > 0 && setInterval(() =>
        {
            setCounter(counter - 1);

        }, 1000);

        return () => 
        {
            clearInterval(timer)
        };

    }, [counter]);

	return (
		<div className="p-7 font-secondary text-sm">
			<div className="my-7">
				{(props?.destination) ? `${i18n.t("message.otp_sent_to")} ${props?.destination}` : (props?.action === "verify_mobile") ? i18n.t("common.otp_sent_to_mobile") : (props?.action === "verify_email") ? i18n.t("common.otp_sent_to_email") : ""}
			</div>
			<div className="mb-7">
				<div className="grid grid-cols-6 gap-5 mb-2 ltr">
					{[...Array(6)].map((_, index) =>
					{
						return (
							<InputText 
								key={index}
								type={"text"}
								maxLength={1}
								inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular text-center"
								labelClassName="text-secondary-darkest"
								errorClassName="text-status-danger-regular"
								enableAutoComplete={false}
								reference={refs[index]}
								value={otp?.[index]}
								onChange={(e) => handleChange(index, e)}
								onPaste={(e) => handlePaste(e)}
								onKeyDown={(e) => handleKeyDown(index, e)}
							/>                             
						)
					})}
				</div>
			</div>
			<div className="mb-7 text-center flex justify-center gap-2">
				<div className="p-0">
					{i18n.t("message.otp_expires_in")}
				</div>
				<div className="ltr">
					{`${(Math.floor(counter/60) < 10) ? "0" + Math.floor(counter/60) : Math.floor(counter/60)} : ${((counter % 60) < 10) ? "0" + (counter % 60) : (counter % 60)}`}
				</div>
			</div>
            <div className="pt-7 flex justify-between">
                <Button type="secondary-outline" className="min-w-[120px]" text={i18n.t("common.resend")} isLoading={props?.isLoading} isDisabled={counter > 0} onClick={() => {props?.resend()}}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.verify")} isLoading={props?.isLoading}  isDisabled={counter === 0} onClick={() => {props?.verify(otp.join(''))}}/>
            </div>  						
		</div>
	);
}

export default Dialog;