import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import InputFile from '../component/inputFile';
import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState(
    {
        post_type:"public"
    });

    const handleValueChange = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="pb-7">
                <div className="mb-7">
                    <div className="mb-2">
                        {i18n.t("delivery.handling")}
                    </div>                    
                    <InputDropdown
                        list={[
                                {title:i18n.t("delivery.is_public_explained"), value:"public"}, 
                                {title:i18n.t("delivery.is_private_explained"), value:"private"}
                            ]}
                        listValue={data?.post_type ?? "public"}
                        isDisabled={props?.isLoading}
                        isListSorted={false}
                        isListReversed={false}
                        isSearchable={false}
                        isCompact={false}
                        theme={props?.prefs?.theme}
                        handleValueUpdate={(value) => handleValueChange("post_type", value)}
                    />
                    <AnimatePresence>
                        {props?.errors?.post_type && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.post_type}
                            </motion.div>
                        )}
                    </AnimatePresence>                                         
                </div>
                <div className="mb-7">
                    <div className="mb-2">
                        {i18n.t("common.zone")}
                    </div>                    
                    <InputDropdown
                        list={data?.post_type === "private" ? props?.privateZoneList : props?.publicZoneList}
                        listValue={data?.zone_id}
                        isDisabled={props?.isLoading}
                        isListSorted={false}
                        isListReversed={false}
                        isSearchable={false}
                        isCompact={false}
                        theme={props?.prefs?.theme}
                        handleValueUpdate={(value) => handleValueChange("zone_id", value)}
                    />
                    <AnimatePresence>
                        {props?.errors?.zone_id && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.zone_id}
                            </motion.div>
                        )}
                    </AnimatePresence>                                         
                </div>  
                <AnimatePresence>
                    {(data?.post_type !== "public") && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                        >
                            <div className="mb-7">
                                <div className="mb-2">
                                    {i18n.t("delivery.trip_code")}
                                </div>                                
                                <InputDropdown
                                    list={props?.tripList}
                                    listValue={data?.trip_id}
                                    isDisabled={props?.isLoading}
                                    isListSorted={false}
                                    isListReversed={false}
                                    isSearchable={false}
                                    isCompact={false}
                                    theme={props?.prefs?.theme}
                                    handleValueUpdate={(value) => handleValueChange("trip_id", value)}
                                />
                                <AnimatePresence>
                                    {props?.errors?.trip_id && (
                                        <motion.div 
                                            initial={{ height:0, opacity:0 }}
                                            animate={{ height: "auto", opacity:1 }}
                                            exit={{ height:0, opacity:0 }}
                                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                            className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                        >
                                            {props?.errors?.trip_id}
                                        </motion.div>
                                    )}
                                </AnimatePresence>  
                            </div>                                       
                        </motion.div>
                    )}
                </AnimatePresence>                                      
                <div className="mb-7">
                    <InputFile
                        enableAutoComplete={false}
                        label={i18n.t("delivery.file_xls")}
                        isDisabled={props?.isLoading}
                        errorMsg={props?.errors?.post_file}
                        onChange={(file) => {handleValueChange("post_file", file)}}
                    />
                </div>                
            </div>
            <div className="flex justify-between">
                <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.download_sample")} isLoading={props?.isLoading}  isDisabled={false} onClick={props?.downloadSample}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => {props?.next(data)}}/>
            </div>             
       </div>
    );
}

export default View;        