import { useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Context from '../../util/context';

import NotificationCenter from '../../view/dialog/notificationCenter';

const Dialog = props => 
{
    const context = useContext(Context); 
    const navigate = useNavigate();

    const markAllAsRead = () =>
    {
        const now = new Date();
        
        const isoString = now.toISOString();

        context?.setPrefs({"notification_last_update": isoString});   

        context?.setDialog(null);
    };  

    const internalRedirect = (url) =>
    {
        navigate(url);

        context?.setDialog(null);
    };  
    
    const externalRedirect = (url) =>
    {
        window.open(url);
    };  
    
    const showDialog = (id, title, bgColor, size, isScrollable, isFullscreen, isClosable, shouldRefreshOnClose, metadata) =>
    {
        context?.setDialog(id, title, bgColor, size, isScrollable, isFullscreen, isClosable, shouldRefreshOnClose, metadata);
    };                    

    return (
        <NotificationCenter
            prefs={context?.prefs}
            notifications={props?.dialog?.metadata?.notifications ?? []}
            internalRedirect={internalRedirect}
            externalRedirect={externalRedirect}
            showDialog={showDialog}
            markAllAsRead={markAllAsRead}
        />
    );
}

export default Dialog;