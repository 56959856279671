import { useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';
import useMediaQuery from '../../hook/useMediaQuery';

import Button from '../component/button';

const View = (props) =>
{
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({target: targetRef, offset: ["start end", "end start"]});

    const isScreenXSmall  = useMediaQuery("(max-width: 400px)");
    const isScreenSmall   = useMediaQuery("(max-width: 640px)");
    const isScreenMedium  = useMediaQuery("(max-width: 768px)");
    const isScreenLarge   = useMediaQuery("(max-width: 1024px)");
    const isScreenXLarge  = useMediaQuery("(max-width: 1280px)");
    const isScreen2XLarge = useMediaQuery("(max-width: 1536px)");

    const bgColor = useTransform(scrollYProgress, [0.2, 0.45, 0.55, 0.7], ["#ffffff", "#555555", "#262626", "#ffffff"]);
    const scale = useTransform(scrollYProgress, 
                               [0.2, 0.45, 0.55, 0.8], 
                               [1,
                                (isScreenXSmall) ? 1 : (isScreenSmall) ? 1.15 : (isScreenMedium) ? 1.1 : (isScreenLarge) ? 1.1 : (isScreenXLarge) ? 1.2 : (isScreen2XLarge) ? 1.3  : 1.35, 
                                (isScreenXSmall) ? 1 : (isScreenSmall) ? 1.15 : (isScreenMedium) ? 1.1 : (isScreenLarge) ? 1.1 : (isScreenXLarge) ? 1.2 : (isScreen2XLarge) ? 1.3  : 1.35, 
                                1]);

    return(
        <div id="section-4" className="mt-[150px]">
            <div ref={targetRef} className="h-[600vh] lg:h-[300vh]">
                <motion.div className="h-full" style={{backgroundColor:bgColor}}>
                    <div className="sticky top-[20%]">
                        <motion.div style={{scale:scale}}  className="sticky top-[100px] transition-all duration-normal ease-in-out">
                            
                            <div className="container max-w-screen-lg mx-auto px-12  pt-[50px] sm:pt-[75px] md:pt-[100px]">
                                <div className=" rounded-3xl py-5 px-5 md:px-24 bg-gradient-to-r from-secondary-darkest to-secondary-dark shadow-2xl shadow-shadow-darkest hero-container before:bg-primary-regular after:bg-primary-regular">
                                    <div className="text-secondary-lightest text-center font-primary text-xl leading-12 md:leading-[70px] mt-[50px]">
                                        {i18n.t("home.section_04_title_01")}
                                    </div>
                                    <div className="text-center py-10">
                                        <Button type="primary-solid" text={i18n.t("home.start_now")} onClick={props?.showSignupDialog} className="px-[40px]"/>
                                    </div>
                                    <div className="text-secondary-lightest text-center text-md leading-10 lg:leading-[50px] mb-[50px]">
                                        {i18n.t("home.section_04_content_01")}
                                    </div>
                                </div>
                            </div>

                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default View;

