import { useContext, useEffect } from 'react';

import Constant from '../../config/constant';

import AppConsumer from '../../util/context';

import Notification from '../../view/core/notification';

const Controller = props => 
{
    const context = useContext(AppConsumer);

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            context?.clearNotification();

        }, Constant.animation.speed.slow);

        return () => clearTimeout(timer);
        
    }, [context]);


    return (
        <Notification notification={context?.notification} prefs={context?.prefs} close={() => {context?.clearNotification()}}/>
    );
}

export default Controller;