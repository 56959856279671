import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';

import DashboardUserList from '../../view/page/dashboardUserList';

const Controller = props => 
{
    const [paginationTimestamp] = useState(`${new Date().toISOString()}`);

    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [listPage, setListPage] = useState(1);
    const [listFilterValue, setListFilterValue] = useState("captain");

    const context = useContext(Context);

    const inviteUser = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("UserInvite", i18n.t("page.invite_user"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    const ManageUserSkills = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("UserSkill", i18n.t("page.skills"), "bg-neutral-high-lightest", "md");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    const showDialog = (dialogID, dialogTitle, dialogDataID) =>
    {
        context?.setDialog(dialogID, dialogTitle, "bg-neutral-high-lightest", "md", true, false, true, false, {id:dialogDataID});
    };

    const goPrev = () =>
    {
        if(listPage > 1)
        {
            setListPage(listPage-1);
        }
    };

    const goNext = () =>
    {
        setListPage(listPage+1);

    };

    useEffect(() =>
    {
        setLoading(true);

        let params = {};

        params["page"] = listPage;
        params["timestamp"] = paginationTimestamp;
        params["role_code"] = listFilterValue;

        isMounted && HTTP.get(Endpoint?.crm?.merchant?.user, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setList(response?.data?.list);
            }

            setLoading(false);
        });

    }, [isMounted, listPage, listFilterValue, paginationTimestamp]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_user');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardUserList
            recordsPerPage={50}
            prefs={context?.prefs}
            list={list?.map((user) => {return {
                ...user, ...{name:(user?.first_name && user?.last_name) ? `${user?.first_name} ${user?.last_name}` : i18n.t("common.unknown"), is_active:user?.is_active ? i18n.t("common.active") : i18n.t("common.inactive"), is_suspended:user?.is_suspended ? i18n.t("common.is_user_suspended") : i18n.t("common.is_user_active"), gender:user?.gender ? i18n.t(`common.${user?.gender}`) : i18n.t("common.unknown")}
            }})}
            listPage={listPage}
            listFilterValue={listFilterValue}
            isLoading={isLoading}            
            showDialog={showDialog}
            setListPage={setListPage}
            inviteUser={inviteUser}
            ManageUserSkills={ManageUserSkills}
            goPrev={goPrev}
            goNext={goNext}
            setListFilterValue={setListFilterValue}
        />
    );
}

export default Controller;