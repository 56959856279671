import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';

import OTP from '../../view/dialog/otp';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [key, setKey] = useState(Math.random);

    const context = useContext(Context);
    const navigate = useNavigate();

    const resend = () =>
    {
        let channel = undefined;

        if(props?.dialog?.metadata?.action?.toLowerCase() === "verify_mobile")
        {
            channel = "sms";
        }
        else if(props?.dialog?.metadata?.action?.toLowerCase() === "verify_email")
        {
            channel = "email";
        }
        else if(props?.dialog?.metadata?.action?.toLowerCase() === "login")
        {
            if(["email", "sms"]?.includes(props?.dialog?.metadata?.channel?.toLowerCase()))
            {
                channel = props?.dialog?.metadata?.channel?.toLowerCase();
            }
        }

        if(channel)
        {
            let params =
            {
                callback_type: props?.dialog?.metadata?.action?.toLowerCase()
            };

            isMounted && HTTP.post(Endpoint?.crm?.authorization?.[channel], params).then(response =>
            {
                setLoading(false);

                if(response?.is_successful)
                {
                    setKey(Math.random);
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                }
            });
        }
    };

    const verify = (code) =>
    {
        if(props?.dialog?.metadata?.action?.toLowerCase() === "login")
        {
            if(["email", "sms"]?.includes(props?.dialog?.metadata?.channel?.toLowerCase()))
            {
                setLoading(true);
                
                let params = 
                {
                    callback_type: props?.dialog?.metadata?.action?.toLowerCase(),
                    authorization_code: code
                };

                isMounted && HTTP.put(Endpoint?.crm?.authorization?.[props?.dialog?.metadata?.channel?.toLowerCase()], params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "is_logged_in": "1"
                        });

                        navigate("/directory");

                        context?.setDialog(null);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                        setLoading(false);
                    }
                });
            }
        }
        else if(props?.dialog?.metadata?.action?.toLowerCase() === "verify_mobile" || props?.dialog?.metadata?.action?.toLowerCase() === "verify_email")
        {
            setLoading(true);
            
            let params = 
            {
                callback_type: props?.dialog?.metadata?.action?.toLowerCase(),
                authorization_code: code
            };

            isMounted && HTTP.put(Endpoint?.crm?.authorization?.[(props?.dialog?.metadata?.action?.toLowerCase() === "verify_mobile") ? "sms" : "email"], params).then(response =>
            {
                if(response?.is_successful)
                {
                    isMounted && HTTP.delete(Endpoint?.crm?.user?.token); 

                    context.clearPrefs();

                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.login_again"));

                    context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");                     
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });
        }
    };

    useEffect(() =>
    {
        let channel = undefined;

        if(props?.dialog?.metadata?.action?.toLowerCase() === "verify_mobile")
        {
            channel = "sms";
        }
        else if(props?.dialog?.metadata?.action?.toLowerCase() === "verify_email")
        {
            channel = "email";
        }

        if(channel)
        {
            let params =
            {
                callback_type: props?.dialog?.metadata?.action?.toLowerCase()
            };

            isMounted && HTTP.post(Endpoint?.crm?.authorization?.[channel], params);
        }

    }, [isMounted, props?.dialog?.metadata?.action]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <OTP
            key={key}
            action={props?.dialog?.metadata?.action?.toLowerCase()}
            period={Constant?.otp?.duration}
            destination={props?.dialog?.metadata?.otp_destination_value?.toLowerCase()}
            isLoading={isLoading}
            resend={resend}
            verify={verify}
        />
    );
}

export default Dialog;