import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Button from '../component/button';
import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import InputDate from '../component/inputDate';
import InputFile from '../component/inputFile';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="font-secondary text-sm text-neutral-low-dark">
            <div className="flex w-full">
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "name") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("name")}}>
                    <div className="m-auto p-4">{i18n.t("common.business_info")}</div>
                </div>
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "contact") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("contact")}}>
                    <div className="m-auto p-4">{i18n.t("common.contact_info_short")}</div>
                </div>
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "cr") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("cr")}}>
                    <div className="m-auto p-4">{i18n.t("common.cr_short")}</div>
                </div>
            </div>            
            <div className="px-7 pt-10 pb-7 font-secondary text-sm">
                {(props?.tab === "name")
                ?
                    <div className="m-0">
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                containerClassName="mb-5"
                                enableAutoComplete={false}
                                label={i18n.t("common.legal_name")}
                                isDisabled={props?.isLoading}
                                value={data?.legal_name ?? props?.data?.legal_name ?? ""}
                                errorMsg={props?.errors?.legal_name}
                                onChange={(e) => {update("legal_name", e?.target?.value)}}
                            />                                     
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                containerClassName="mb-5"
                                enableAutoComplete={false}
                                label={i18n.t("common.display_name")}
                                isDisabled={props?.isLoading}
                                value={data?.display_name ?? props?.data?.display_name ?? ""}
                                errorMsg={props?.errors?.display_name}
                                onChange={(e) => {update("display_name", e?.target?.value)}}
                            />                                     
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.address")}
                                isDisabled={props?.isLoading}
                                value={data?.contact_address ?? props?.data?.contact_address ?? ""}
                                errorMsg={props?.errors?.contact_address}
                                onChange={(e) => {update("contact_address", e?.target?.value)}}
                            />                                     
                        </div>
                        <div className="mb-7 flex gap-2">
                            <div className="w-full">
                                <div className="mb-2">
                                    {i18n.t("common.communication_method")}
                                </div>
                                <InputDropdown
                                    list={[{title:i18n.t("common.email"), value:"email"}, {title:i18n.t("common.sms"), value:"sms"}]}
                                    listValue={data?.communication_method ?? props?.data?.communication_method ?? ""}
                                    isDisabled={props?.isLoading}
                                    isListSorted={false}
                                    isListReversed={false}
                                    isSearchable={false}
                                    isCompact={false}
                                    theme={props?.prefs?.theme}
                                    handleValueUpdate={(value) => {update("communication_method", value)}}
                                />
                                <AnimatePresence>
                                    {props?.errors?.communication_method && (
                                        <motion.div 
                                            initial={{ height:0, opacity:0 }}
                                            animate={{ height: "auto", opacity:1 }}
                                            exit={{ height:0, opacity:0 }}
                                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                            className={`text-status-danger-regular font-secondary text-sm`}
                                        >
                                            {props?.errors?.communication_method}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                            <div className="w-full">
                                <div className="mb-2">
                                    {i18n.t("common.lang")}
                                </div>
                                <InputDropdown
                                    list={[{title:i18n.t("lang.ar"), value:"ar"}, {title:i18n.t("lang.en"), value:"en"}]}
                                    listValue={data?.lang_code ?? props?.data?.lang_code ?? ""}
                                    isDisabled={props?.isLoading}
                                    isListSorted={false}
                                    isListReversed={false}
                                    isSearchable={false}
                                    isCompact={false}
                                    theme={props?.prefs?.theme}
                                    handleValueUpdate={(value) => {update("lang_code", value)}}
                                />
                                <AnimatePresence>
                                    {props?.errors?.lang_code && (
                                        <motion.div 
                                            initial={{ height:0, opacity:0 }}
                                            animate={{ height: "auto", opacity:1 }}
                                            exit={{ height:0, opacity:0 }}
                                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                            className={`text-status-danger-regular font-secondary text-sm`}
                                        >
                                            {props?.errors?.lang_code}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>                                    
                        </div>                                                                                          
                    </div>
                :
                (props?.tab === "contact")
                ?
                    <div className="m-0">
                        <div className="mb-7 flex gap-2">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.first_name")}
                                isDisabled={props?.isLoading}
                                value={data?.contact_first_name ?? props?.data?.contact_first_name ?? ""}
                                errorMsg={props?.errors?.contact_first_name}
                                onChange={(e) => {update("contact_first_name", e?.target?.value)}}
                            />
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.last_name")}
                                isDisabled={props?.isLoading}
                                value={data?.contact_last_name ?? props?.data?.contact_last_name ?? ""}
                                errorMsg={props?.errors?.contact_last_name}
                                onChange={(e) => {update("contact_last_name", e?.target?.value)}}
                            />                                     
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.email")}
                                isDisabled={props?.isLoading}
                                value={data?.contact_email ?? props?.data?.contact_email ?? ""}
                                errorMsg={props?.errors?.contact_email}
                                onChange={(e) => {update("contact_email", e?.target?.value)}}
                            />
                        </div>
                        <div className="mb-7">
                            <div className="pb-2">
                                {i18n.t("common.mobile")}
                            </div>
                            <div dir="ltr" className="flex gap-2">
                                <div className="w-[150px] text-sm">
                                    <InputDropdown
                                        listClassName="bg-neutral-high-lightest w-full shadow-sm shadow-shadow-light rounded-lg mt-3 border-neutral-high-light border-[1px]"
                                        list={props?.countryList}
                                        listValue={data?.contact_mobile_code ?? props?.data?.contact_mobile_code  ?? ""}
                                        searchInputPlaceholder={i18n.t("common.search_generic")}
                                        isDisabled={props?.isLoading}
                                        isListSorted={true}
                                        isListReversed={false}
                                        isSearchable={false}
                                        isCompact={false}
                                        theme={props?.prefs?.theme}
                                        handleValueUpdate={(value) => {update("contact_mobile_code", value)}}
                                    />                        
                                </div>                    
                                <div className="w-full">
                                    <InputText 
                                        type={"text"}
                                        enableAutoComplete={false}
                                        isDisabled={props?.isLoading}
                                        placeholder={"5XXXXXXXXX"}
                                        value={data?.contact_mobile ?? props?.data?.contact_mobile ?? ""}
                                        onChange={(e) => {update("contact_mobile", e?.target?.value)}}
                                    />
                                </div>
                            </div> 
                            <AnimatePresence>
                            {props?.errors?.mobile && (
                                <motion.div 
                                    initial={{ height:0, opacity:0 }}
                                    animate={{ height: "auto", opacity:1 }}
                                    exit={{ height:0, opacity:0 }}
                                    transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    className={`text-status-danger-regular font-secondary text-sm mt-2`}
                                >
                                    {props?.errors?.mobile}
                                </motion.div>
                            )}
                            </AnimatePresence>                                                                
                        </div>
                    </div>
                :
                (props?.tab === "cr")
                ?
                    <div className="m-0">
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.cr_number")}
                                isDisabled={props?.isLoading}
                                value={data?.cr_number ?? props?.data?.cr_number}
                                errorMsg={props?.errors?.cr_number}
                                onChange={(e) => {update("cr_number", e?.target?.value)}}
                            />
                        </div>   
                        <div className="mb-7">
                            <div className="grid grid-cols-2 gap-5 mb-2">
                                <InputDate
                                    theme={props?.prefs?.theme}
                                    label={i18n.t("common.start_date")}
                                    pastDateOnly={true}
                                    locale={props?.prefs?.lang}
                                    showTimeSelect={false}
                                    isDisabled={props?.isLoading}
                                    value={data?.cr_start_date ?? props?.data?.cr_start_date ?? ""}
                                    errorMsg={props?.errors?.cr_start_date}
                                    onChange={(value) => {update("cr_start_date", value);}}
                                />
                                <InputDate
                                    theme={props?.prefs?.theme}
                                    label={i18n.t("common.end_date")}
                                    futureDateOnly={true}
                                    locale={props?.prefs?.lang}
                                    showTimeSelect={false}
                                    isDisabled={props?.isLoading}
                                    value={data?.cr_end_date ?? props?.data?.cr_end_date ?? ""}
                                    errorMsg={props?.errors?.cr_end_date}
                                    onChange={(value) => {update("cr_end_date", value);}}
                                />
                            </div>
                        </div> 
                        <div className="mb-7">
                            <InputFile
                                enableAutoComplete={false}
                                label={i18n.t("common.cr_image")}
                                isDisabled={props?.isLoading}
                                errorMsg={props?.errors?.cr_img}
                                onChange={(file) => {update("cr_img", file)}}
                            />
                        </div>                        
                    </div>
                :
                    null
                }

                <div className="flex justify-end pt-5">
                    <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.save({...props?.data, ...data})}/>
                </div>
            </div>
        </div>
    );
}

export default View;        