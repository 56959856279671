import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import MerchantPublicDelivery from '../../view/dialog/merchantPublicDelivery';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const save = (newData) =>
    {
        let newErrors = {};
        let data      = {};

        if(!Validator.notEmpty(newData?.public_delivery_commission_type))
        {
            newErrors["public_delivery_commission_type"] = i18n.t('error.invalid_public_delivery_commission_type');            
        }
        else if(!["percentage", "fixed"].includes(newData?.public_delivery_commission_type?.toLowerCase()))
        {
            newErrors["public_delivery_commission_type"] = i18n.t('error.invalid_public_delivery_commission_type');
        } 

        if(!Validator.notEmpty(newData?.public_delivery_commission_amount))
        {
            newErrors["public_delivery_commission_amount"] = i18n.t('error.invalid_public_delivery_commission_amount');            
        }
        else if(!Validator.numeric(newData?.public_delivery_commission_amount) || newData?.public_delivery_commission_amount > 100 || newData?.public_delivery_commission_amount < 0)
        {
            newErrors["public_delivery_commission_amount"] = i18n.t('error.invalid_public_delivery_commission_amount');
        }

        
        if(Object.keys(newErrors)?.length === 0)
        {
            setLoading(true);

            data["public_delivery_commission_type"]  = newData["public_delivery_commission_type"];
            data["public_delivery_commission_amount"] = newData["public_delivery_commission_amount"];

            isMounted && HTTP.put(Endpoint?.crm?.merchant?.data, data).then(response =>
            {
                if(response?.is_successful)
                {    
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                    setLoading(false);
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }            
    };
     
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <MerchantPublicDelivery
            isLoading={isLoading}
            prefs={context?.prefs}
            errors={errors}
            save={save}
            data={props?.dialog?.metadata?.merchantInfo}
        />
    );
}

export default Dialog;