import { useContext } from 'react';

import useMediaQuery from '../../hook/useMediaQuery';

import constant from '../../config/constant';

import Context from '../../util/context';
import i18n from '../../util/i18n';

const Dialog = props => 
{
    const context = useContext(Context);


    const isScreenSmall   = useMediaQuery("(max-width: 640px)");
    const isScreenMedium  = useMediaQuery("(max-width: 768px)");
    const isScreenLarge   = useMediaQuery("(max-width: 1024px)");

    return (
        <iframe
            className="rounded-b-xl" 
            width="100%" 
            height={isScreenSmall ? "250px" : isScreenMedium ? "350px" : isScreenLarge ? "500px" : "450px"}
            src={constant?.url?.misc?.[`intro_video_${context?.prefs?.lang}`]} 
            title={i18n.t("page.intro_video") }
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen
        />
    );
}

export default Dialog;