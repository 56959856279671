import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';

const View = (props) =>
{
    const backgroundRef = useRef(null);
    const menuRef = useRef(null);

    return (
        <>
            <AnimatePresence mode="wait" initial={false}>
                {props?.isShown &&
                <motion.div
                    ref={backgroundRef}
                    style={{overflow:"hidden"}}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1}}
                    exit={{ opacity: 0 }}
                    transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                    className="z-30 fixed top-0 left-0 w-screen h-screen overflow-y-auto bg-backdrop-regular backdrop-blur-md"
                    onClick={props?.toggle}
                >
                </motion.div>}
            </AnimatePresence>



            <AnimatePresence mode="wait" initial={false}>
                {props?.isShown &&
                <motion.div
                    ref={menuRef}
                    style={{overflow:"hidden"}}
                    initial={(props?.dir === "rtl") ? { right:-300 } : {left:-300}}
                    animate={(props?.dir === "rtl") ? { right:0 } : {left:0}}
                    exit={(props?.dir === "rtl") ? { right:-300 } : {left:-300}}
                    transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                    className={`z-40 fixed top-0 ${(props?.dir === "rtl") ? "right-0" : "left-0"}  w-screen h-screen max-w-[300px] bg-neutral-high-light shadow-lg`}
                >  
                    {props?.children}
                </motion.div>}
            </AnimatePresence>
        </>      
    )
}

export default View;