import i18n from '../../util/i18n';

import Table from '../component/table';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';
import InputDate from '../component/inputDate';

import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';

const View = (props) =>
{
    return( 
        <div className={`h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-gradient-regular via-gradient-dark to-gradient-darkest flex flex-col`}>
            <div className="border-t-[1px] border-neutral-high-regular grow text-neutral-low-dark overflow-scroll mt-[150px] lg:mt-[92px]" data-lenis-prevent>
                <div className="p-5 transition ease-in-out duration-normal">
                    <div className="mb-10 font-primary text-lg">
                        {i18n.t("page.dashboard_delivery_list")}
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap justify-between gap-1 mb-5">
                        <div className="pb-5 md:pb-0 relative">
                            <Button 
                                type="neutral-low-solid" 
                                className="min-w-[120px]" 
                                text={i18n.t("common.create_delivery")} 
                                icon={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                                iconHover={(props?.prefs?.theme === "light") ? AddDarkIconImage : AddLightIconImage} 
                                isLoading={props?.isLoading} 
                                list={[
                                        {title:i18n.t("common.create_delivery_manual"), value:"manual"}, 
                                        {title:i18n.t("common.create_delivery_from_excel"), value:"excel"},
                                        {title:i18n.t("common.create_delivery_via_api"), value:"api"}
                                    ]}
                                onClick={props?.createDelivery}
                            />
                        </div>
                        <div className="flex gap-2 justify-end w-full">
                            <InputDropdown
                                theme={props?.prefs?.theme}
                                selectorClassName={"text-neutral-low-dark bg-neutral-high-light border-neutral-high-regular active:bg-neutral-high-light hover:bg-neutral-high-light mb-2 w-full md:w-[180px]"}
                                listClassName={"bg-neutral-high-lightest"}
                                itemClassName={"text-neutral-low-dark bg-neutral-high-lightest hover:bg-neutral-high-light border-neutral-high-light"}
                                selectorPlaceholder={i18n.t("common.filter_by")}
                                list={[{title:i18n.t("common.filter_type_status_code"), value:"status_code"}, {title:i18n.t("common.filter_type_zone_id"), value:"zone_id"}, {title:i18n.t("common.filter_type_created_at"), value:"created_at"}]}
                                listValue={props?.listFilterType}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {props?.setListFilterType(value)}}
                            />

                            {(props?.listFilterType === "created_at")
                            ?
                                <div className="w-full md:w-[200px]">                        
                                    <InputDate
                                        theme={props?.prefs?.theme}                            
                                        inputClassName="text-neutral-low-dark border-neutral-high-regular bg-neutral-high-light active:bg-neutral-high-light hover:bg-neutral-high-light w-full"
                                        dateFormat="MMMM YYYY"
                                        locale={props?.prefs?.lang}
                                        showTimeSelect={false}
                                        showMonthYearPicker={true}
                                        isDisabled={props?.isLoading}
                                        value={props?.listFilterValue}
                                        onChange={(value) => {props?.setListFilterValue(value)}}
                                    />  
                                </div>
                            :  
                                <div className="w-full md:w-[200px]">                  
                                    <InputDropdown
                                        theme={props?.prefs?.theme}
                                        selectorClassName={"text-neutral-low-dark border-neutral-high-regular bg-neutral-high-light active:bg-neutral-high-light hover:bg-neutral-high-light mb-2 w-full"}
                                        listClassName={"bg-neutral-high-lightest"}
                                        itemClassName={"text-neutral-low-dark bg-neutral-high-lightest hover:bg-neutral-high-light border-neutral-high-light"}
                                        list={
                                            (props?.listFilterType === "status_code")
                                            ?
                                                [
                                                    {title:i18n.t("delivery.status_pending_assignment"), value:"pending_assignment"},
                                                    {title:i18n.t("delivery.status_pending_location"), value:"pending_location"},
                                                    {title:i18n.t("delivery.status_new"), value:"new"},
                                                    {title:i18n.t("delivery.status_assigned"), value:"assigned"},
                                                    {title:i18n.t("delivery.status_confirmed"), value:"confirmed"},
                                                    {title:i18n.t("delivery.status_dispatched"), value:"dispatched"},
                                                    {title:i18n.t("delivery.status_delivered"), value:"delivered"},
                                                    {title:i18n.t("delivery.status_complete"), value:"complete"},
                                                    {title:i18n.t("delivery.status_rejected_by_customer_before_dispatch"), value:"rejected_by_customer_before_dispatch"},
                                                    {title:i18n.t("delivery.status_rejected_by_customer_after_dispatch"), value:"rejected_by_customer_after_dispatch"},
                                                    {title:i18n.t("delivery.status_returned_after_rejection"), value:"returned_after_rejection"},
                                                    {title:i18n.t("delivery.status_onhold"), value:"onhold"},
                                                    {title:i18n.t("delivery.status_cancelled"), value:"cancelled"}
                                                ]
                                            :
                                                props?.zoneList
                                        }
                                        listValue={props?.listFilterValue}
                                        searchInputPlaceholder={i18n.t("common.search_generic")}
                                        isDisabled={props?.isLoading || !props?.listFilterType}
                                        isListSorted={(props?.listFilterType === "zone_id")}
                                        isListReversed={false}
                                        isSearchable={false}
                                        isCompact={!(props?.listFilterType === "zone_id" && props?.zoneList?.length > 2)}
                                        handleValueUpdate={(value) => {props?.setListFilterValue(value)}}
                                    />
                                </div>
                            }                                   
                        </div>
                    </div>
                    <div className="mb-5">
                        <Table
                            labels={
                            [
                                {order:1, id:"status_code", title:"", dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:true},
                                {order:2, id:"tracking_number", title:i18n.t("delivery.tracking_number"), dialogID:"DeliveryInfo", dialogTitle:i18n.t("page.delivery_info"), dialogDataID:"delivery_id", isStatusIndicator:false},
                                {order:3, id:"status_name", title:i18n.t("delivery.status_code"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minXL:true},
                                {order:4, id:"handling", title:i18n.t("delivery.handling"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minMD:true},
                                {order:5, id:"bill_reference_number", title:i18n.t("delivery.bill_reference_number"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minLG:true},
                                {order:6, id:"captain_name", title:i18n.t("delivery.captain"), dialogID:"UserInfo", dialogTitle:i18n.t("page.user_info"), dialogDataID:"captain_id", isStatusIndicator:false}
                            ]}
                            list={props?.list}
                            prefs={props?.prefs}
                            showDialog={props?.showDialog}
                            isLoading={props?.isLoading}
                        />            
                    </div>
                    <div className="flex justify-between gap-1 pt-4">
                        <div className="p-0">
                            <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={props?.listPage <= 1} onClick={props?.goPrev}/>
                        </div>
                        <div className="p-0">
                            <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading} isDisabled={props?.isLoading || props?.list?.length === 0 || props?.list?.length < props?.recordsPerPage} onClick={props?.goNext}/>
                        </div> 
                    </div>
                </div> 
            </div>
        </div>  
    );
}

export default View;    