import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputDate from '../component/inputDate';
import InputFile from '../component/inputFile';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">
                <div className="mb-7">
                    <InputText 
                        type={"text"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.insurance_name")}
                        isDisabled={props?.isLoading}
                        value={data?.insurance_company_name ?? props?.data?.insurance_company_name}
                        errorMsg={props?.errors?.insurance_company_name}
                        onChange={(e) => {update("insurance_company_name", e?.target?.value)}}
                    />                                               
                </div>   
                <div className="mb-7">                           
                    <div className="grid grid-cols-2 gap-5 mb-2"> 
                        <div className="m-0">
                            <div className="mb-2">
                                {i18n.t("common.insurance_code")}
                            </div>                                     
                            <InputDropdown
                                selectorClassName={"bg-secondary-light"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={[
                                        {title:i18n.t("common.insurance_type_comprehensive"), value:"comprehensive"}, 
                                        {title:i18n.t("common.insurance_type_liability"), value:"liability"}
                                    ]}
                                listValue={data?.insurance_code ?? props?.data?.insurance_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("insurance_code", value)}}
                            />  
                        </div>  
                        <div className="m-0">                                                      
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.insurance_number")}
                                isDisabled={props?.isLoading}
                                value={data?.insurance_policy_number ?? props?.data?.insurance_policy_number}
                                errorMsg={props?.errors?.insurance_policy_number}
                                onChange={(e) => {update("insurance_policy_number", e?.target?.value)}}
                            />
                        </div>                                  
                    </div>
                    <AnimatePresence>
                        {props?.errors?.insurance_code && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.insurance_code}
                            </motion.div>
                        )}
                    </AnimatePresence>                             
                </div>
                <div className="mb-7">
                    <InputDate
                        theme={props?.prefs?.theme}                    
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        label={i18n.t("common.insurance_expiration_date")}
                        futureDateOnly={true}
                        locale={props?.prefs?.lang}
                        showTimeSelect={false}
                        isDisabled={props?.isLoading}
                        value={data?.insurance_expiration_date ?? props?.data?.insurance_expiration_date}
                        errorMsg={props?.errors?.insurance_expiration_date}
                        onChange={(value) => {update("insurance_expiration_date", value)}}
                    />
                </div>                           
                <div className="mb-7">
                    <InputFile 
                            inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                            labelClassName="text-secondary-darkest"
                            errorClassName="text-status-danger-regular"
                            enableAutoComplete={false}
                            label={i18n.t("common.insurance_image")}
                            isDisabled={props?.isLoading}
                            errorMsg={props?.errors?.insurance_card_img}
                            onChange={(file) => {update("insurance_card_img", file)}}
                    />                     
                </div>                                                                                        
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={false} onClick={() => props?.goPrev({...props?.data, ...data})}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
