import { useState, useEffect, useContext } from 'react';

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';

import CollectionInfo from '../../view/dialog/collectionInfo';
import OTP from '../../view/dialog/otp';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [step, setStep] = useState(2);
    const [key, setKey] = useState(Math.random);

    const context = useContext(Context); 
    
    const next = () =>
    {
        setLoading(true);

        isMounted && HTTP.post(Endpoint?.oms?.collection?.approval, {delivery_id:props?.dialog?.metadata?.id}).then(response =>
        {
            setLoading(false);

            if(response?.is_successful)
            {        
                setStep(2);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }     
        });
    }

    const resend = () =>
    {
        setLoading(true);

        isMounted && HTTP.post(Endpoint?.oms?.collection?.approval, {delivery_id:props?.dialog?.metadata?.id}).then(response =>
        {
            setLoading(false);

            if(response?.is_successful)
            {
                setKey(Math.random);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }
        });
    };

    const verify = (code) =>
    {
        isMounted && HTTP.put(Endpoint?.oms?.collection?.approval, {delivery_id:props?.dialog?.metadata?.id, authorization_code:code}).then(response =>
        {
            if(response?.is_successful)
            {
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                setTimeout(() =>
                {
                    context?.setDialog(null);   
                
                    window.location.reload();  

                }, Constant.animation?.speed?.normal);                
            }
            else
            {
                setLoading(false);

                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }
        });
    };

    useEffect(() =>
    {
        setLoading(true);

        isMounted && HTTP.get(Endpoint?.oms?.delivery?.data, {delivery_id:props?.dialog?.metadata?.id}).then(response =>
        {
            setDeliveryInfo(response?.data?.info); 
            
            setLoading(false);
        });

    }, [isMounted, props?.dialog?.metadata?.id]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <CollectionInfo
            prefs={context?.prefs}
            isLoading={isLoading}
            deliveryInfo={deliveryInfo} 
            step={step}
            next={next}          
        >
            <OTP
                key={key}
                action={"What"}
                period={Constant?.otp?.duration}
                destination={props?.dialog?.metadata?.otp_destination_value?.toLowerCase()}
                isLoading={isLoading}
                resend={resend}
                verify={verify}
            /> 
        </CollectionInfo>           
    );
}

export default Dialog;