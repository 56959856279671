
import { useState, useEffect } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import Image from '../component/image';
import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import Tooltip from '../component/tooltip';

import DollarDarkIcon from '../../assets/vector/icon_dollar_dark.svg';
import DollarLightIcon from '../../assets/vector/icon_dollar_light.svg';
import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';

import Button from '../component/button';

const View = (props) =>
{
    const [viewDelayRate, setViewDelayRate] = useState(Constant?.animation?.speed?.fast/1000);
    const [data, setData] = useState(props?.data ?? []);
    const [openedSections, openSection] = useState(props?.data?.map(item => item.status_code));
    const sections =
    [
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_new") + ")",
            key:"new"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_assigned") + ")",
            key:"assigned"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_confirmed") + ")",
            key:"confirmed"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_dispatched") + ")",
            key:"dispatched"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_delivered") + ")",
            key:"delivered"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_complete") + ")",
            key:"complete"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_rejected_by_customer_before_dispatch") + ")",
            key:"rejected_by_customer_before_dispatch"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_rejected_by_customer_after_dispatch") + ")",
            key:"rejected_by_customer_after_dispatch"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_returned_after_rejection") + ")",
            key:"returned_after_rejection"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_onhold") + ")",
            key:"onhold"            
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_cancelled") + ")",
            key:"cancelled"            
        }
    ];

    const toggleSection = (key) =>
    {
        let clone = [...openedSections];

        let index = openedSections?.indexOf(key);

        if(index !== -1)
        {
            clone?.splice(index, 1);
        }
        else
        {
            clone?.push(key);
        }

        openSection(clone);
    };

    const handleValueChange = (section, index, key, value) =>
    {
        console.log(data, section, index, key, value);

        let clone = [...data];

        if(!clone?.[index])
        {
            clone[index] =
            {
                status_code:section,
                is_otp_from_sender_required:false,
                is_otp_from_recipient_required:false,
                is_otp_from_captain_required:false,
                is_serial_scan_required:false
            }
        }

        if(key === "callback_url" && value?.length === 0)
        {
            delete clone?.[index]?.[key];
        }
        else
        {
            clone[index][key] = value;
        }

        setData(clone);
    };

    useEffect(() =>
    {
        if(props?.data)
        {
            let i = 0;
            let newData = [];

            ["new", "assigned", "confirmed", "dispatched", "delivered", "complete", "rejected_by_customer_before_dispatch", "rejected_by_customer_after_dispatch", "returned_after_rejection", "onhold", "cancelled"].forEach(section =>
            {
                let matches = props?.data?.filter(item => item?.status_code === section);

                newData[i] =  matches[0]?.status_code ? matches[0] : undefined;

                i++;
            });

            setData(newData)
        }
    }, [props?.data]);

    useEffect(() =>
    {        
        const timer = setTimeout(() =>
        {
            setViewDelayRate(0);

        }, Constant?.animation?.speed?.slow);
    
        return () => clearTimeout(timer);

    }, []);
  
    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
                {i18n.t("trip.tip_02")}
            </div>
            {sections?.map((section, index) =>
                {
                    return (
                        <div key={index} className="border-[1px] border-neutral-high-dark rounded-lg mb-7 shadow-sm">
                            <div className={`flex justify-between p-4 cursor-pointer transition duration-normal ease-in-out hover:bg-neutral-high-light ${openedSections?.includes(section?.key) ? "rounded-t-lg border-b-[1px] border-neutral-high-dark" : "rounded-lg"}`} onClick={() => toggleSection(section?.key)}>
                                <div className="font-primary">
                                    {section?.title}
                                </div>
                                <div className="my-auto">
                                    {openedSections?.includes(section?.key)
                                    ?
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronUpLightIconImage : ChevronUpDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    :
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronDownLightIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    }
                                </div>
                            </div>
                            <AnimatePresence>
                                {openedSections?.includes(section?.key) && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ delay: viewDelayRate, duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    >
                                        <div className="p-4">
                                            <table className="w-full">
                                                <tbody>
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-1">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.is_otp_from_sender_required")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <InputDropdown
                                                                list={[{title:i18n.t("common.yes"), value:true}, {title:i18n.t("common.no"), value:false}]}
                                                                listValue={(data?.find(obj => obj?.status_code === section?.key)?.is_otp_from_sender_required ?? props?.data?.find(obj => obj?.status_code === section?.key)?.is_otp_from_sender_required) ?? false}
                                                                isDisabled={props?.isLoading}
                                                                isListSorted={false}
                                                                isListReversed={false}
                                                                isSearchable={false}
                                                                isCompact={false}
                                                                theme={props?.prefs?.theme}
                                                                handleValueUpdate={(value) => handleValueChange(section?.key, index, 'is_otp_from_sender_required', value)}
                                                            />                                                    
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-1">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.is_otp_from_recipient_required")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <InputDropdown
                                                                list={[{title:i18n.t("common.yes"), value:true}, {title:i18n.t("common.no"), value:false}]}
                                                                listValue={(data?.find(obj => obj?.status_code === section?.key)?.is_otp_from_recipient_required ?? props?.data?.find(obj => obj?.status_code === section?.key)?.is_otp_from_recipient_required) ?? false}
                                                                isDisabled={props?.isLoading}
                                                                isListSorted={false}
                                                                isListReversed={false}
                                                                isSearchable={false}
                                                                isCompact={false}
                                                                theme={props?.prefs?.theme}
                                                                handleValueUpdate={(value) => handleValueChange(section?.key, index, 'is_otp_from_recipient_required', value)}
                                                            />                                                    
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-1">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.is_otp_from_captain_required")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <InputDropdown
                                                                list={[{title:i18n.t("common.yes"), value:true}, {title:i18n.t("common.no"), value:false}]}
                                                                listValue={(data?.find(obj => obj?.status_code === section?.key)?.is_otp_from_captain_required ?? props?.data?.find(obj => obj?.status_code === section?.key)?.is_otp_from_captain_required) ?? false}
                                                                isDisabled={props?.isLoading}
                                                                isListSorted={false}
                                                                isListReversed={false}
                                                                isSearchable={false}
                                                                isCompact={false}
                                                                theme={props?.prefs?.theme}
                                                                handleValueUpdate={(value) => handleValueChange(section?.key, index, 'is_otp_from_captain_required', value)}
                                                            />                                                    
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-1">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.is_serial_scan_required")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <InputDropdown
                                                                list={[{title:i18n.t("common.yes"), value:true}, {title:i18n.t("common.no"), value:false}]}
                                                                listValue={(data?.find(obj => obj?.status_code === section?.key)?.is_serial_scan_required ?? props?.data?.find(obj => obj?.status_code === section?.key)?.is_serial_scan_required) ?? false}
                                                                isDisabled={props?.isLoading}
                                                                isListSorted={false}
                                                                isListReversed={false}
                                                                isSearchable={false}
                                                                isCompact={false}
                                                                theme={props?.prefs?.theme}
                                                                handleValueUpdate={(value) => handleValueChange(section?.key, index, 'is_serial_scan_required', value)}
                                                            />                                                    
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="my-auto whitespace-nowrap pb-1">
                                                            <div className="flex gap-1">
                                                                <div className="my-auto">
                                                                    {i18n.t("trip.callback_url")}
                                                                </div>
                                                                <div className="my-auto w-[24px]">
                                                                    <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                                                        <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="w-full ps-3 pb-1">
                                                            <InputText 
                                                                type={"text"}
                                                                enableAutoComplete={false}
                                                                placeholder={"https://example.com/path"}
                                                                isDisabled={props?.isLoading}
                                                                defaultValue={data?.find(obj => obj?.status_code === section?.key)?.callback_url ?? props?.data?.find(obj => obj?.status_code === section?.key)?.callback_url ?? ""}
                                                                onChange={(e) => handleValueChange(section?.key, index, 'callback_url', e?.target?.value)}
                                                            />                                                  
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>                            
                        </div>
                    )
                })
            }  
            <div className="flex justify-between">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.prev(data?.filter((item) => item !== undefined))}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props.next(data?.filter((item) => item !== undefined))}/>
            </div>                                                
        </div>
    );
}

export default View;
