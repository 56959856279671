import i18n from '../../util/i18n';

import Button from '../component/button';

const View = (props) =>
{
    return(
        <div className="p-7 font-secondary text-sm">
            <div className="text-center py-10 leading-8">
                {i18n.t("message.password_has_been_reset")}                                                            
            </div>
            <div className="text-center">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.close")} onClick={() => props?.closeDialog()}/>
            </div>  
        </div>
    );
}

export default View;
