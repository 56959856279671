import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Button from '../component/button';
import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="font-secondary text-sm text-neutral-low-dark">           
            <div className="px-7 pt-10 pb-7 font-secondary text-sm">
                <div className="mb-7 flex gap-2">
                    <div className="w-full">
                        <div className="mb-2">
                            {i18n.t("common.public_delivery_calculation_type")}
                        </div>
                        <InputDropdown
                            searchbarClassName="bg-neutral-high-light"
                            list={[{title:i18n.t("common.public_delivery_calculation_type_percentage"), value:"percentage"}, {title:i18n.t("common.public_delivery_calculation_type_fixed"), value:"fixed"}]}
                            listValue={data?.public_delivery_commission_type ?? props?.data?.public_delivery_commission_type}
                            isDisabled={props?.isLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {update("public_delivery_commission_type", value)}}
                        />
                        <AnimatePresence>
                            {props?.errors?.public_delivery_commission_type && (
                                <motion.div 
                                    initial={{ height:0, opacity:0 }}
                                    animate={{ height: "auto", opacity:1 }}
                                    exit={{ height:0, opacity:0 }}
                                    transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    className={`text-status-danger-regular font-secondary text-sm`}
                                >
                                    {props?.errors?.public_delivery_commission_type}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>                                  
                </div>   
                <div className="mb-7 flex">
                    <div className="w-[50%]">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("common.public_delivery_captain_share")}
                            defaultValue={data?.public_delivery_commission_amount}
                            errorMsg={props?.errors?.public_delivery_commission_amount}
                            onChange={(e) => {update("public_delivery_commission_amount", e?.target?.value)}}
                        />
                    </div>
                    <div className={`my-auto mx-3 ${(props?.errors?.public_delivery_commission_amount) ? "" : "pt-8"}`}>
                        {(data?.public_delivery_commission_type === "fixed") ? i18n.t("currency.sar") : "%"}
                    </div>                                     
                </div> 
                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        containerClassName="mb-5"
                        enableAutoComplete={false}
                        label={i18n.t("common.public_delivery_merchant_share")}
                        value={(data?.public_delivery_commission_type === "fixed") ? i18n.t("common.public_delivery_share_is_remainder") : `${(isNaN((parseFloat(data?.public_delivery_commission_amount))) || data?.public_delivery_commission_amount > 100 || data?.public_delivery_commission_amount < 0) ? 0 : Math.abs(100-data?.public_delivery_commission_amount)}%`}
                        isDisabled={true}
                    /> 
                </div>                                                                                                           
                
                <div className="flex justify-end pt-5">
                    <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.save({...props?.data, ...data})}/>
                </div>
            </div>
        </div>
    );
}

export default View;        