import { useState, useEffect, useContext } from 'react';

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import HTTP from '../../util/http';
import i18n from '../../util/i18n';

import DeliveryInfo from '../../view/dialog/deliveryInfo';
import Map from '../../view/component/map';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isDataLoading, setDataLoading] = useState(true);
    const [isSkillListLoading, setSkillListLoading] = useState(true);
    const [mapCenter, setMapCenter] = useState({lat:Constant?.map?.center?.lat, long:Constant?.map?.center?.long});
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [skillList, setSkillList] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [path, setPath] = useState(undefined);
    const [assignee, setAssignee] = useState([]);
    const [nextStatusList, setNextStatusList] = useState([]);
    const [refereshKey, setRefereshKey] = useState(Math.random());

    const context = useContext(Context);

    const assignCaptain = (userID) =>
    {
        setDataLoading(true);

        let data = {};

        data["delivery_id"]  = deliveryInfo?.delivery_id;
        data["status_code"]  = "assigned";
        data["process_type"] = "graceful";
        data["assignee_id"]  = userID;

        isMounted && HTTP.post(Endpoint?.oms?.delivery?.status, data).then(response =>
        {
            if(response?.is_successful)
            {
                setRefereshKey(Math.random());

                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setDataLoading(false);
            }
        }); 
    };

    const updateStatus = (newStatus) =>
    {
        setDataLoading(true);

        let data = {};

        data["delivery_id"]  = deliveryInfo?.delivery_id;
        data["status_code"]  = newStatus;
        data["process_type"] = "graceful";

        isMounted && HTTP.post(Endpoint?.oms?.delivery?.status, data).then(response =>
        {
            if(response?.is_successful)
            {
                setRefereshKey(Math.random());

                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setDataLoading(false);
            }
        }); 
    };

    const sendOTP = (persona) =>
    {
        setDataLoading(true);

        let data = {};

        data["delivery_id"]  = deliveryInfo?.delivery_id;
        data["callback_type"]  = `checkpoint_${persona?.toLowerCase()}`;

        isMounted && HTTP.post(Endpoint?.oms?.authorization?.sms, data).then(response =>
        {
            if(response?.is_successful)
            {

                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setDataLoading(false);
        });
    };

    const showSurveyResults = (persona) =>
    {
        if(deliveryInfo?.[`is_survey_filled_by_${persona?.toLowerCase()}`])
        {
            context?.setDialog("DeliverySurvey", i18n.t("page.delivery_survey"), "bg-secondary-lightest", "sm", true, false, true, false, {tracking_number:deliveryInfo?.tracking_number, persona:persona});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.title"), i18n.t("error.survey_not_found"));
        }
    };

    const resetNextStatusList = (newStatus) =>
    {
        let temp = [];

        if(newStatus === "onhold")
        {
            temp.push({title:i18n.t("delivery.status_action_new"), value:"new"});
            temp.push({title:i18n.t("delivery.status_action_cancelled"), value:"cancelled"});
        }

        if(newStatus === "new")
        {
            temp.push({title:i18n.t("delivery.status_action_cancelled"), value:"cancelled"});
        }

        if(newStatus === "assigned")
        {
            temp.push({title:i18n.t("delivery.status_action_confirmed"), value:"confirmed"});
            temp.push({title:i18n.t("delivery.status_action_onhold"), value:"onhold"});
        }

        if(newStatus === "confirmed")
        {
            temp.push({title:i18n.t("delivery.status_action_dispatched"), value:"dispatched"});
            temp.push({title:i18n.t("delivery.status_action_rejected_by_customer_before_dispatch"), value:"rejected_by_customer_before_dispatch"});
        }

        if(newStatus === "dispatched")
        {
            temp.push({title:i18n.t("delivery.status_action_delivered"), value:"delivered"});
            temp.push({title:i18n.t("delivery.status_action_rejected_by_customer_after_dispatch"), value:"rejected_by_customer_after_dispatch"});
        }

        if(newStatus === "rejected_by_customer_before_dispatch")
        {
            temp.push({title:i18n.t("delivery.status_action_onhold"), value:"onhold"});
        }

        if(newStatus === "rejected_by_customer_after_dispatch")
        {
            temp.push({title:i18n.t("delivery.status_action_returned_after_rejection"), value:"returned_after_rejection"});
        }

        if(newStatus === "returned_after_rejection")
        {
            temp.push({title:i18n.t("delivery.status_action_onhold"), value:"onhold"});
        }

        setNextStatusList(temp);
    };

    const viewCollectionInfo = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("CollectionInfo", i18n.t("page.cash_collection_info"), "bg-neutral-high-lightest", "sm", true, false, true, false, {id:props?.dialog?.metadata?.id});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    useEffect(() =>
    {
        setDataLoading(true);
        setSkillListLoading(true);

        let data = {};

        data["delivery_id"] = props?.dialog?.metadata?.id;

        isMounted && HTTP.get(Endpoint?.crm?.skill?.list, {skill_type:"all"}).then(response =>
        {                
            if(response?.is_successful && response?.data?.list)
            {        
                setSkillList(response?.data?.list); 
            }

            setSkillListLoading(false);
        });

        isMounted && HTTP.get(Endpoint?.oms?.delivery?.data, data).then(response =>
        {
            setDeliveryInfo(response?.data?.info);

            resetNextStatusList(response?.data?.info?.status_code);

            if(response?.data?.info?.navigation?.route)
            {
                setPath(response?.data?.info?.navigation?.route);
            }

            if(response?.data?.info?.recipient?.lat && response?.data?.info?.recipient?.long)
            {
                setMapCenter({lat:response?.data?.info?.recipient?.lat, long:response?.data?.info?.recipient?.long});
            }
            else if(response?.data?.info?.sender?.lat && response?.data?.info?.sender?.long)
            {
                setMapCenter({lat:response?.data?.info?.sender?.lat, long:response?.data?.info?.sender?.long});
            }
            else
            {
                setMapCenter({lat:response?.data?.info?.zone?.center_lat, long:response?.data?.info?.zone?.center_long});
            }

            let tempMarkers = [];

            if(response?.data?.info?.sender?.lat && response?.data?.info?.sender?.long)
            {
                tempMarkers.push({"title":i18n.t("delivery.sender"), "lat":response?.data?.info?.sender?.lat, "long":response?.data?.info?.sender?.long});
            }

            if(response?.data?.info?.recipient?.lat && response?.data?.info?.recipient?.long)
            {
                tempMarkers.push({"title":i18n.t("delivery.recipient"), "lat":response?.data?.info?.recipient?.lat, "long":response?.data?.info?.recipient?.long});
            }

            setMarkers(tempMarkers);

            if(["pending_assignment", "onhold", "new"].includes(response?.data?.info?.status_code?.toLowerCase()))
            {        
                isMounted && HTTP.get(Endpoint?.crm?.merchant?.fleet).then(response =>
                {
                    if(response?.data?.list)
                    {
                        setAssignee(response?.data?.list?.map((user) =>
                        {
                            return {
                                title: user?.first_name + " " + user?.last_name,
                                value: user?.user_id
                            };
                        }));
                    }

                    setDataLoading(false);
                }); 
            }
            else
            {
                setDataLoading(false);
            }          
        });

    }, [isMounted, props?.dialog?.metadata?.id, refereshKey]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };
    
    }, []);        

    return (
        <DeliveryInfo
            isDataLoading={isDataLoading}
            isSkillListLoading={isSkillListLoading}
            deliveryInfo={deliveryInfo}
            skillList={skillList}
            prefs={context?.prefs}
            assignee={assignee}
            nextStatusList={nextStatusList}
            assignCaptain={assignCaptain}
            updateStatus={updateStatus}
            sendOTP={sendOTP}
            showSurveyResults={showSurveyResults}
            viewCollectionInfo={viewCollectionInfo}
        >
            <Map
                initZoom={10}
                minZoom={9}
                maxZoom={20}
                showZoomControl={true}
                showMapTypeControl={false}
                showScaleControl={false}
                showStreetViewControl={false}
                showRotateControl={false}
                showFullscreenControl={false}
                showClickableIcons={false}
                showScrollwheel={false}  
                showMarkerTitle={true}                      
                lat={mapCenter?.lat}
                long={mapCenter?.long}
                theme={(context?.prefs?.theme === "dark") ? "dark" : "default"}
                markers={markers}
                path={path}
                mapClassName="rounded-xl"
            />
        </DeliveryInfo>
    );
}

export default Dialog;