import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputDropdown from '../component/inputDropdown';
import Image from '../component/image';

import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';

const View = (props) =>
{
    const menuRef = useRef(null);

    const setType = (value) =>
    {
        if(value?.toLowerCase() !== props?.type?.toLowerCase())
        {
            if(value === "captain")
            {
                props?.setStatus("online");
            }
            else
            {
                props?.setStatus("new");
            }

            props?.setType(value);
        }
    };

    return( 
        <div className="text-neutral-low-dark" data-lenis-prevent>
            <div className="absolute z-20 text-sm top-[170px] lg:top-[150px] end-5 shadow-2xl shadow-shadow-regular bg-neutral-high-lightest transition ease-in-out duration-normal p-5 w-[80%] max-w-[250px] rounded-xl">
                <div className="flex justify-between">
                    <div className="font-primary my-auto">
                        {i18n.t("map.display_options")}
                    </div>
                    <div className="my-auto border-[1px] border-neutral-low-darkest rounded-md p-[2px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50" onClick={props?.toggle}>
                        {(props?.prefs?.theme === "dark")
                        ?
                            <Image src={props?.isMenuShown ? ChevronUpLightIconImage : ChevronDownLightIconImage} alt="" className="h-[16px] w-[16px]"/>
                        :
                            <Image src={props?.isMenuShown ? ChevronUpDarkIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px]"/>
                        }
                    </div>
                </div>
                <AnimatePresence>
                    {props?.isMenuShown &&
                    <motion.div
                        ref={menuRef}
                        initial={{ opacity: 0, height:0 }}
                        animate={{ opacity: 1, height:"auto"}}
                        exit={{ opacity: 0, height:0 }}
                        transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                    > 
                        <div className="my-5">
                            <InputDropdown
                                theme={props?.prefs?.theme}
                                selectorClassName={"bg-neutral-high-light border-neutral-high-regular"}
                                listClassName={"bg-neutral-high-lightest"}
                                itemClassName={"border-neutral-high-regular hover:bg-neutral-high-light"}
                                list={[{title:i18n.t("map.deliveries"), value:"delivery"}, {title:i18n.t("map.captains"), value:"captain"}]}
                                listValue={props?.type}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {setType(value)}}
                            />  
                        </div>
                        <div className="mb-5">
                            <InputDropdown
                                theme={props?.prefs?.theme}
                                selectorClassName={"bg-neutral-high-light border-neutral-high-regular"}
                                listClassName={"bg-neutral-high-lightest"}
                                itemClassName={"border-neutral-high-regular hover:bg-neutral-high-light"}
                                list={
                                    (props?.type?.toLowerCase() === "captain")
                                    ?
                                        [{title:i18n.t("captain.status_online"), value:"online"}]
                                    :
                                        [{title:i18n.t("delivery.status_pending_assignment"), value:"pending_assignment"}, 
                                        {title:i18n.t("delivery.status_pending_location"), value:"pending_location"},
                                        {title:i18n.t("delivery.status_new"), value:"new"},
                                        {title:i18n.t("delivery.status_assigned"), value:"assigned"},
                                        {title:i18n.t("delivery.status_confirmed"), value:"confirmed"},
                                        {title:i18n.t("delivery.status_dispatched"), value:"dispatched"},
                                        {title:i18n.t("delivery.status_delivered"), value:"delivered"},
                                        {title:i18n.t("delivery.status_complete"), value:"complete"},
                                        {title:i18n.t("delivery.status_rejected_by_customer_before_dispatch"), value:"rejected_by_customer_before_dispatch"},
                                        {title:i18n.t("delivery.status_rejected_by_customer_after_dispatch"), value:"rejected_by_customer_after_dispatch"},
                                        {title:i18n.t("delivery.status_returned_after_rejection"), value:"returned_after_rejection"},
                                        {title:i18n.t("delivery.status_onhold"), value:"onhold"},
                                        {title:i18n.t("delivery.status_cancelled"), value:"cancelled"}]
                                    }
                                listValue={props?.status}
                                isDisabled={props?.isLoading || props?.type?.toLowerCase() === "captain"}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={true}
                                handleValueUpdate={props?.setStatus}
                            />  
                        </div>
                        <div className="m-0">
                            <InputDropdown
                                theme={props?.prefs?.theme}
                                selectorClassName={"bg-neutral-high-light border-neutral-high-regular"}
                                listClassName={"bg-neutral-high-lightest"}
                                itemClassName={"border-neutral-high-regular hover:bg-neutral-high-light"}
                                list={props?.zoneList}
                                listValue={props?.zone}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={(props?.zoneList?.length > 3)}
                                handleValueUpdate={props?.setZone}
                            />  
                        </div> 
                    </motion.div>}
                </AnimatePresence>             
            </div>
        </div>
    );
}

export default View;    