import React, { createContext, useState } from 'react';

import LocalStorage from './localStorage';

const AppContext = createContext();

export const AppConsumer = AppContext.Consumer;

export const Context = (props) =>
{
    const [prefs, updatePrefs]                      = useState(LocalStorage.getAll());
    const [notification, updateNotification]        = useState({id:null, title:null, msg:null, status:null});
    const [dialog, updateDialog]                    = useState({id:null, title:null, is_fullscreen:false, is_scrollable:false, metadata:{}});
    const [callback, updateCallback]                = useState({url:null, type:null});
    const [loadingPercentage, setLoadingPercentage] = useState(0);

    const setPrefs = (obj) => new Promise(function(resolve, reject)
    {
        let temp = {...prefs};

        for(const key of Object.keys(obj))
        {
            temp[key] = obj?.[key];

            LocalStorage?.set(key, obj[key]);
    
            if(key?.toLowerCase() === "lang")
            {
                if(["ar"]?.includes(obj[key]?.toLowerCase()))
                {
                    LocalStorage.set("dir", "rtl");
    
                    temp["dir"] = "rtl";
                    temp["dir_alt"] = "ltr";
                }
                else
                {
                    LocalStorage.set("dir", "ltr");
    
                    temp["dir"] = "ltr";
                    temp["dir_alt"] = "rtl";
                }
            }
        }

        updatePrefs(temp);

        resolve();
    });

    const unsetPrefs = (key) => new Promise(function(resolve, reject)
    {
        LocalStorage.unset(key);

        updatePrefs(LocalStorage.getAll());

        resolve();
    });

    const clearPrefs = () => new Promise(function(resolve, reject)
    {
        LocalStorage.destroy();

        LocalStorage.init();

        updatePrefs(LocalStorage.getAll());

        resolve();
    });

    const setLoadingProgress = (percentage) => new Promise(function(resolve, reject)
    {        
        if(!isNaN(parseFloat(percentage)))
        {
            setLoadingPercentage(parseFloat(percentage));
        }
        else
        {
            setLoadingPercentage(0);
        }

        resolve();
    });

    const setDialog = (id, title="", bgColor="", size="sm", isScrollable=false, isFullscreen=false, isClosable=true, shouldRefreshOnClose=false, metadata={}) => new Promise(function(resolve, reject)
    {
        let temp = {...dialog};

        temp["id"]                      = id;
        temp["title"]                   = title;
        temp["bg_color"]                = bgColor;
        temp["size"]                    = size;
        temp["is_scrollable"]           = isScrollable;
        temp["is_fullscreen"]           = isFullscreen;
        temp["is_closable"]             = isClosable;
        temp["should_refresh_on_close"] = shouldRefreshOnClose;
        temp["metadata"]                = metadata;

        updateDialog(temp);

        resolve();
    });

    const clearDialog = () => new Promise(function(resolve, reject)
    {
        let temp = {...dialog};

        temp["id"]            = null;
        temp["title"]         = null;
        temp["bg_color"]      = null;
        temp["size"]          = null;
        temp["is_scrollable"] = false;
        temp["is_fullscreen"] = false;
        temp["is_closable"]   = false;
        temp["metadata"]      = {};

        updateDialog(temp);

        resolve();
    });

    const setNotification = (status="", title="", msg="") => new Promise(function(resolve, reject)
    {
        let temp = {...notification};

        temp["id"]     = Math.random();
        temp["title"]  = title;
        temp["msg"]    = msg;
        temp["status"] = status;

        updateNotification(temp);

        resolve();
    });

    const clearNotification = () => new Promise(function(resolve, reject)
    {
        let temp = {...notification};

        temp["id"]     = null;
        temp["title"]  = null;
        temp["msg"]    = null;
        temp["status"] = null;

        updateNotification(temp);

        resolve();
    });

    const setCallback = (url, type) => new Promise(function(resolve, reject)
    {
        let temp = {...callback};

        temp["url"] = url;
        temp["type"] = type;

        updateCallback(temp);

        resolve();
    });

    return (
        <AppContext.Provider
            value=
            {{
                prefs:prefs,
                dialog:dialog,
                notification:notification,
                callback:callback,
                loadingProgress:loadingPercentage,

                setPrefs,
                unsetPrefs,
                clearPrefs,
                setDialog,
                updateDialog,
                clearDialog,
                setNotification,
                clearNotification,
                setCallback,
                setLoadingProgress
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export default AppContext;