import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

const View = (props) =>
{
    let className = "border-r-4 m-3 p-3 rounded-md text-sm w-[250px] shadow-sm shadow-shadow-light cursor-pointer ";

    if(props?.notification?.status?.toLowerCase() === "success")
    {
        className += "border-status-success-darkest bg-status-success-regular";
    }
    else if(props?.notification?.status?.toLowerCase() === "error")
    {
        className += "border-status-danger-darkest bg-status-danger-regular";
    }
    else if(props?.notification?.status?.toLowerCase() === "info")
    {
        className += "border-status-info-darkest bg-status-info-dark";
    }

    return(
        <AnimatePresence  mode="wait" initial={false}>
            {props?.notification?.id && (
                <motion.div
                    style={{overflow:"hidden"}}
                    initial={{top:-100}}
                    animate={{top:0}}
                    exit={{top:-100}}
                    transition={{ duration: Constant?.animation?.speed?.fast/1000, ease: easeInOut }}
                    className={`fixed z-50 top-0 ${(props?.prefs?.dir === "rtl") ? "left-0" : "right-0"}`}
                >
                    <div className={className} onClick={props?.close}>
                        <div className="font-primary text-secondary-lightest">
                            {props?.notification?.title}
                        </div>
                        <div className="font-secondary text-secondary-lightest mt-1 leading-6">
                            {props?.notification?.msg} 
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default View;
