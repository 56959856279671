import { useState } from "react";

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">

                <div className="mb-7">
                    <InputText 
                        type={"password"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.password_old")}
                        errorMsg={props?.errors?.password_old}
                        onChange={(e) => {update("password_old", e?.target?.value)}}
                    />           
                </div>
                <div className="mb-7">
                    <InputText 
                        type={"password"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.password_new")}
                        errorMsg={props?.errors?.password_new}
                        onChange={(e) => {update("password_new", e?.target?.value)}}
                    />           
                </div>
                <div className="mb-7">
                    <InputText 
                        type={"password"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.password_new_confirmation")}
                        defaultValue={data?.password_confirmation}
                        errorMsg={props?.errors?.password_confirmation}
                        onChange={(e) => {update("password_confirmation", e?.target?.value)}}
                    />           
                </div>                                
              
            </div>
            <div className="flex justify-end">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.save(data)}/>
            </div>  
        </div>
    );
}

export default View;
