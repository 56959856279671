
import { useState, useEffect } from 'react';

import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import Button from '../component/button';

const View = (props) =>
{
    const [cost, setCost] = useState([]);
    const [grandTotal, setGrandTotal] = useState([]);

    useEffect(() =>
    {
        let clone = [];
        let cloneTotal = parseFloat(props?.pricingData?.base_fee);

        clone.push(
        {
            title:"base",
            qty:1,
            unit_price:parseFloat(props?.pricingData?.base_fee)
        });


        if(props?.data?.survey_code?.toLowerCase() !== "none")
        {
            cloneTotal += parseFloat(props?.pricingData?.survey_fee);

            clone.push(
            {
                title:"survey",
                qty:1,
                unit_price:parseFloat(props?.pricingData?.survey_fee)
            });
        }

        if(props?.data?.auto_assignment_type_code?.toLowerCase() !== "none")
        {
            cloneTotal += parseFloat(props?.pricingData?.auto_assign_fee);

            clone.push(
            {
                title:"auto_assign",
                qty:1,
                unit_price:parseFloat(props?.pricingData?.auto_assign_fee)
            });
        }        


        if(props?.data?.checklist?.length > 0)
        {
            const uniqueTypes = new Set();

            props?.data?.checklist?.forEach(item =>
            {
                uniqueTypes.add(item?.status_code?.toLowerCase());
            });

            cloneTotal += parseFloat(props?.pricingData?.checklist_fee) * uniqueTypes?.size;

            clone.push(
            {
                title:"checklist",
                qty:uniqueTypes?.size,
                unit_price:parseFloat(props?.pricingData?.checklist_fee)
            });            
        }

        let totalEmails = 0;
        let totalMobileNumbers = 0;

        props?.data?.sla?.forEach((obj) =>
        {
            if(Array.isArray(obj?.emails) && obj?.emails?.length)
            {
                cloneTotal += parseFloat(props?.pricingData?.sla_email_fee) * obj?.emails?.length;
                totalEmails += obj?.emails?.length;
            }               

            if(Array.isArray(obj?.mobile_numbers) && obj?.mobile_numbers?.length)
            {
                cloneTotal += parseFloat(props?.pricingData?.sla_sms_fee) * obj?.mobile_numbers?.length;
                totalMobileNumbers += obj?.mobile_numbers?.length;
            }
        });
        
        clone.push(
        {
            title:"sla_email",
            qty:totalEmails,
            unit_price:parseFloat(props?.pricingData?.sla_email_fee)
        }); 

        clone.push(
        {
            title:"sla_sms",
            qty:totalMobileNumbers,
            unit_price:parseFloat(props?.pricingData?.sla_sms_fee)
        });            

        let totalOTPs = 0;
        let totalScans = 0;
        let totalCallbacks = 0;

        props?.data?.checkpoint?.forEach((obj) =>
        {
            if(obj?.is_otp_from_sender_required)
            {
                cloneTotal += parseFloat(props?.pricingData?.otp_fee);
                totalOTPs++;
            }

            if(obj?.is_otp_from_recipient_required)
            {
                cloneTotal += parseFloat(props?.pricingData?.otp_fee);
                totalOTPs++;
            }

            if(obj?.is_otp_from_captain_required)
            {
                cloneTotal += parseFloat(props?.pricingData?.otp_fee);
                totalOTPs++;
            }

            if(obj?.is_serial_scan_required)
            {
                cloneTotal += parseFloat(props?.pricingData?.serial_scan_fee);
                totalScans++;
            }

            if(obj?.callback_url)
            {
                cloneTotal += parseFloat(props?.pricingData?.callback_fee);
                totalCallbacks++;
            }
        });

        clone.push(
        {
            title:"otp",
            qty:totalOTPs,
            unit_price:parseFloat(props?.pricingData?.otp_fee)
        }); 

        clone.push(
        {
            title:"serial_scan",
            qty:totalScans,
            unit_price:parseFloat(props?.pricingData?.serial_scan_fee)
        }); 

        clone.push(
        {
            title:"callback",
            qty:totalCallbacks,
            unit_price:parseFloat(props?.pricingData?.callback_fee)
        }); 

        setCost(clone);
        setGrandTotal(cloneTotal);

    }, [props?.data, props?.pricingData])

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
                {i18n.t("trip.tip_06")}
            </div>
            <div className="mb-8">
                <table>
                    <tbody>
                        {cost?.map((item, index) =>
                        {
                            return(
                                <tr key={index}>
                                    <td className="ltr p-2 text-center whitespace-nowrap">
                                        {item?.qty}x
                                    </td>
                                    <td className="w-full p-2">
                                        {i18n.t(`trip.fee_${item?.title}`)} ({item?.unit_price})
                                    </td>
                                    <td className="p-2 text-start whitespace-nowrap">
                                        {Formatter.stringToNumberWithCommasAndDicimals(parseFloat(item?.qty)*parseFloat(item?.unit_price))} {i18n.t("currency." + props?.prefs?.currency)}
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className="p-2"/>
                            <td className="p-2 text-start whitespace-nowrap font-primary">
                                {i18n.t("trip.fee_grand_total")}
                            </td>
                            <td className="p-2 text-start whitespace-nowrap font-primary">
                                {Formatter.stringToNumberWithCommasAndDicimals(grandTotal)} {i18n.t("currency." + props?.prefs?.currency)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.prev()}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.next()}/>
            </div>                                                
        </div>
    );
}

export default View;
