import { useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';

import Image from './image';

const View = (props) =>
{
    const [isListOpen, setListOpen] = useState(false);
    const [hoverIcon, setHoverIcon] = useState(null);

    const listRef = useRef(null);

    let className =`outline-none whitespace-nowrap rounded-lg text-center border-2 transition duration-normal ease-in-out `;

    if(props?.type === "primary-solid")
    {
        className +=`text-secondary-darkest bg-primary-regular active:bg-primary-regular border-primary-regular ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-transparent hover:text-primary-regular"}`;
    }
    else if(props?.type === "secondary-solid")
    {
        className +=`text-primary-regular bg-secondary-darkest active:bg-secondary-darkest border-secondary-darkest ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-transparent hover:text-secondary-darkest"}`;
    }
    else if(props?.type === "accent-solid")
    {
        className +=`text-secondary-lightest bg-accent-regular active:bg-accent-regular border-accent-regular ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-transparent hover:text-accent-regular"}`;
    }
    else if(props?.type === "neutral-high-solid")
    {
        className +=`text-neutral-low-regular bg-neutral-high-regular active:bg-neutral-high-regular border-neutral-high-regular ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-transparent hover:text-neutral-low-regular"}`;
    }
    else if(props?.type === "neutral-low-solid")
    {
        className +=`text-neutral-low-lightest bg-neutral-low-darkest active:bg-neutral-high-regular border-neutral-low-darkest ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-transparent hover:text-neutral-low-darkest"}`;
    }

    else if(props?.type === "primary-outline")
    {
        className +=`text-primary-regular bg-transparent active:bg-transparent border-primary-regular ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-primary-regular hover:text-secondary-darkest"}`;
    }
    else if(props?.type === "secondary-outline")
    {
        className +=`text-secondary-darkest bg-transparent active:bg-transparent border-secondary-darkest ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-secondary-darkest hover:text-primary-regular"}`;
    }
    else if(props?.type === "accent-outline")
    {
        className +=`text-accent-regular bg-transparent active:bg-transparent border-accent-regular ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-accent-regular hover:text-secondary-lightest"}`;
    }
    else if(props?.type === "neutral-high-outline")
    {
        className +=`text-neutral-high-dark bg-transparent active:bg-transparent border-neutral-high-regular ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-neutral-high-regular hover:text-neutral-low-darkest"}`;
    }
    else if(props?.type === "neutral-low-outline")
    {
        className +=`text-neutral-low-darkest bg-transparent active:bg-transparent border-neutral-low-darkest ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:bg-neutral-low-darkest hover:text-neutral-high-lightest"}`;
    }

    else if(props?.type === "naked")
    {
        className =`outline-none transition duration-normal ease-in-out opacity-100 ${(props?.disableHovering || props?.isDisabled || props?.isLoading) ? null: "hover:opacity-50"}`;
    }

    else
    {
        className =`outline-none`;
    }

    if(props?.isDisabled || props?.isLoading)
    {
        className += " cursor-not-allowed opacity-25";
    }
    else
    {
        className += " cursor-pointer";
    }

    const onClick = (value) =>
    {
        if(props?.list)
        {
            setListOpen(true);
        }
        else
        {
            if(!props?.isDisabled && !props?.isLoading && props?.onClick)
            {
                props?.onClick();
            }
        }
    };

    const onMouseOver = () =>
    {
        if(props?.iconHover)
        {
            setHoverIcon(props?.iconHover);
        }
    };

    const onMouseOut = () =>
    {
        setHoverIcon(null);
    };

    const clickOutside = (e) =>
    {
        if(!listRef.current?.contains(e?.target))
        {
            setListOpen(false);
        }
    };

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);

        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);
    
    return (
        <>
            <button className={props?.className ? `${props?.className} ${className}` : className} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                {props?.isLoading
                ?
                    <div className={`flex w-full min-w-[50px] ${(props?.type === "naked") ? "" : "px-4 py-3"}`}>
                        <div className="inline-block h-4 w-4 m-auto animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"/>
                        </div>                     
                    </div>
                :
                    <div className={`flex justify-center gap-1 ${(props?.type === "naked") ? "" : "px-3 py-2"}`}>
                        {(props?.icon) ? <div className="inline-block w-[20px] my-auto"><Image src={hoverIcon ?? props?.icon} alt="" className="h-[20px] w-[20px]"/></div> : null}
                        {(props?.text) ? <div className="my-auto">{props?.text}</div> : null}
                    </div>
                }                    
            </button>
            <AnimatePresence>
                {isListOpen &&
                    <motion.div
                        ref={listRef}
                        style={{overflow:"hidden"}}
                        initial={{ opacity: 0, height:0 }}
                        animate={{ opacity: 1, height:"auto"}}
                        exit={{ opacity: 0, height:0 }}
                        transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                        className={`absolute mt-3 shadow-sm shadow-shadow-light rounded-lg min-w-[180px] bg-neutral-high-lightest`}
                    > 
                    {
                        props?.list?.map((item, index) =>
                        {
                            return (
                                <div key={index} onClick={() => {props?.onClick(item?.value); setListOpen(false);}} className={(index !== props?.list?.length -1) ? `border-b-[1px] p-3 transition-all duration-normal ease-in-out cursor-pointer text-neutral-low-dark bg-neutral-high-lightest hover:bg-neutral-high-light border-neutral-high-light` : `p-3  transition-all duration-normal ease-in-out cursor-pointer text-neutral-low-dark bg-neutral-high-lightest hover:bg-neutral-high-light border-neutral-high-light`}>
                                    {item?.title}
                                </div>
                            )
                        }) 
                    }
                </motion.div>}
            </AnimatePresence>
        </>
    );
};

export default View;