import { useState, useEffect, useContext } from 'react';

import { useNavigate } from "react-router-dom";

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';

import Directory from '../../view/page/directory';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [isRedirected, setRedirected] = useState(false);
    const [list, setList] = useState([]);
    
    const context = useContext(Context);
    const navigate = useNavigate();

    const changeLang = () =>
    {
        if(context?.prefs?.lang === "ar")
        {
            context?.setPrefs({"lang": "en"});            

            i18n.changeLanguage("en");
        }
        else
        {
            context?.setPrefs({"lang": "ar"});            

            i18n.changeLanguage("ar");
        }
    };

    const requestAccess = (merchantID) =>
    {        
        setLoading(true);

        let params =
        {
            merchant_id: merchantID
        };

        const merchantInfo = list?.find(merchant => merchant.merchant_id === merchantID);

        if(merchantInfo?.merchant_id && merchantInfo?.display_name)
        {
            if(merchantInfo?.role_code?.toLowerCase() === "captain")
            {
                context?.setNotification("error", i18n.t("error.captain_not_allowed_title"), i18n.t("error.captain_not_allowed_msg"));

                setLoading(false);
            }   
            else
            {
                isMounted && HTTP.post(Endpoint?.crm?.user?.access, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "merchant_country_code": response?.data?.info?.country_code?.toLowerCase(),
                            "merchant_id": merchantInfo?.merchant_id,
                            "merchant_display_name": merchantInfo?.display_name,
                            "merchant_wallet_id": response?.data?.info?.wallet_id,
                            "user_role_code": merchantInfo?.role_code?.toLowerCase()
                        });
    
                        navigate("/dashboard");
                    }
                    else
                    {
                        if(response?.error_code === 2065)
                        {
                            context?.setPrefs(
                            {
                                "merchant_id": merchantInfo?.merchant_id,
                                "user_role_code": merchantInfo?.role_code?.toLowerCase(),
                                "merchant_display_name": merchantInfo?.display_name
                            });
    
                            navigate("/dashboard");
                        }
                        else
                        {
                            context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                            
                            setLoading(false);
                        }
                    }
                });        
            }        
        }
    };

    const createMerchantAccount = () =>
    {
        context?.setDialog("Signup", i18n.t("page.signup_as_merchant"), "bg-secondary-lightest", "sm", false, false, true, false, {persona:"owner", subscription_code:"basic", merchant_code:null, invitation_code:null, email:null, action:"create_merchant_account"});
    };

    useEffect(() =>
    {
        setLoading(true);

        isMounted && HTTP.get(Endpoint?.crm?.user?.merchant).then(response =>
        {
            if(!response?.data?.list?.length || response?.data?.list?.length === 0)
            {
                setRedirected(false);
            }
            else
            {
                setList(response?.data?.list);
            }

            setLoading(false);
        });

    }, [isMounted]);

    useEffect(() =>
    {
        if(isRedirected)
        {
            context?.setDialog("UserOnboarding", i18n.t("page.onboarding"), "bg-primary-regular", "sm", false, false, true, false, {});

            navigate("/");
        }

    }, [isRedirected, context, navigate]);

    useEffect(() =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            navigate("/");
        }

    }, [context?.prefs?.is_logged_in, navigate]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    useEffect(() =>
    {  
        const checkConnection = (isOnline) =>
        {
            if(!isOnline)
            {
                navigate("/error/disconnected");
            }     
        };

        window.addEventListener('online', () => checkConnection(true));
        window.addEventListener('offline', () => checkConnection(false));

        return () =>
        {
            window.removeEventListener('online', () => checkConnection(true));
            window.removeEventListener('offline', () => checkConnection(false));
        };
    }, [navigate]); 

    return (
        <Directory
            isLoading={isLoading}
            list={list?.map((merchant) => {return {title:merchant?.display_name, value:merchant?.merchant_id}})}
            changeLang={changeLang}
            requestAccess={requestAccess}
            createMerchantAccount={createMerchantAccount}
            prefs={context?.prefs}
        />
    );
}

export default Controller;