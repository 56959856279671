import i18n from '../../util/i18n';

import Button from '../component/button';
import Skelton from '../component/skelton';

const View = (props) =>
{
    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="mb-7">
                <table className="p-0 w-full">
                    <tbody>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.bank_name")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.banksList?.filter((item) => item?.value === props?.data?.bank_code)?.[0]?.title ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.bank_account")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.bank_account_number ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.bank_iban")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.bank_iban_number ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.bank_account_holder")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.holder_name ?? i18n.t("common.unknown")}
                            </td>
                        </tr>                                                                                            
                    </tbody>
                </table>                                              
            </div> 
            <div className="flex justify-end">
                <Button type="accent-outline" className="min-w-[120px]" text={i18n.t("common.remove")} isLoading={props?.isLoading}  isDisabled={false} onClick={props?.remove}/>
            </div>                                     
        </div>
    );
}

export default View;
