import { useState } from 'react';

import i18n from '../../util/i18n';
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';

import Formatter from '../../util/formatter';

import Skelton from '../component/skelton';
import InputDropdown from '../component/inputDropdown';
import InputCheckBox from '../component/inputCheckBox';
import Button from '../component/button';
import InputText from '../component/inputText';

import AvatarImage from '../../assets/image/avatar.webp';

const Dialog = props => 
{
    const [isRiskLimitEnabled, setRiskLimitEnabled] = useState(false);
    const [riskLimit, setRiskLimit] = useState(props?.walletData?.risk_limit);

    const handleRiskButton = () =>
    {
        if(["owner","maintainer"]?.includes(props?.prefs?.user_role_code?.toLowerCase()))
        {
            if(!isRiskLimitEnabled)
            {
                setRiskLimitEnabled(true);
            }
            else
            {
                props?.resetRiskLimit(riskLimit ?? props?.walletData?.risk_limit);
            }            
        }
        else
        {
            props?.preventAdminAction();
        }
    };

	return (
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="mb-7">
                <table className="p-0 w-full">
                    <tbody>
						<tr>
							<td className="pe-5 align-top whitespace-nowrap" rowSpan={13}>
								<div className="w-14">
									{props?.isDataLoading ? <div className={"skeleton-animation bg-secondary-light rounded-full w-14 h-14"}/> : <img src={props?.data?.avatar_img ?? AvatarImage} alt="" className={`}w-14 h-14 rounded-full ${props?.data?.avatar_img ? "p-0" : "bg-neutral-high-regular p-2"}`}/>}
								</div>
							</td>
                            <td className="pb-5 whitespace-nowrap font-primary" colSpan={2}>
                                {i18n.t("common.personal_info")}
                            </td>
                        </tr>                                    
						<tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.first_name")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.first_name ?? i18n.t("common.unknown")}
                            </td>
                        </tr>						
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.last_name")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.last_name ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.email")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.email ? <a className="underline decoration-dotted" href={`mailto:${props?.data?.email}`} target="_blank" rel="noreferrer">{props?.data?.email}</a>: i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.mobile")}:
                            </td>
                            <td className={`pb-5 w-full ${(props?.prefs?.dir === "rtl") ? "ltr text-right pe-5" : "ps-5"}`}>
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.data?.mobile && props?.data?.mobile_code) ? <a className="underline decoration-dotted" href={`tel:+${props?.data?.mobile_code + "" + props?.data?.mobile}`} target="_blank" rel="noreferrer">{"+" + props?.data?.mobile_code + "" + props?.data?.mobile}</a>: i18n.t("common.unknown")}
                            </td>
                        </tr>           
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.gender")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.gender ? i18n.t(`common.${props?.data?.gender}`) : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.nationality")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isCountryListLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.countryList?.filter((item) => item?.value === props?.data?.nationality_code)?.[0]?.title ?? i18n.t("common.unknown")}
                            </td>
                        </tr>						
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.government_id")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.government_id ?? i18n.t("common.unknown")}
                            </td>
                        </tr>	
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.lang")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.lang_code ? i18n.t(`lang.${props?.data?.lang_code}`) : i18n.t("common.unknown")}
                            </td>
                        </tr>	

						
						<tr>
                            <td className="pt-10 pb-5 whitespace-nowrap font-primary" colSpan={2}>
                                {i18n.t("common.account_details")}
                            </td>
                        </tr> 
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.status")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.is_active ? i18n.t(`common.active`) : i18n.t("common.inactive")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.suspension")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.is_suspended ? i18n.t(`common.is_user_suspended`) : i18n.t("common.is_user_active")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-10 whitespace-nowrap">
                                {i18n.t("common.role")}:
                            </td>
                            <td className="pb-10 ps-5 w-full">
                                {(props?.isRoleLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.roleCode === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[
                                            {title:i18n.t("common.captain"), value:"captain"},
                                            {title:i18n.t("common.maintainer"), value:"maintainer"},
                                            {title:i18n.t("common.observer"), value:"observer"},
                                            {title:i18n.t("common.owner"), value:"owner"}
                                        ]}
										listValue={props?.roleCode}
										isDisabled={false}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateRole(value?.toLowerCase())}
									/>
								}                                
                            </td>
                        </tr>                         
                    </tbody>



                    <AnimatePresence>
                    {props?.roleCode?.toLowerCase() === "captain" &&
                    <motion.tbody
                        style={{overflow:"hidden"}}
                        initial={{ opacity: 0, height:0 }}
                        animate={{ opacity: 1, height:"auto"}}
                        exit={{ opacity: 0, height:0 }}
                        transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                    >
                        <tr>
							<td className="pe-5 align-top whitespace-nowrap" rowSpan={26}>
								<div className="w-14"/>
							</td>
                            <td className="pb-5 whitespace-nowrap font-primary" colSpan={2}>
                                {i18n.t("common.captain_profile")}
                            </td>
                        </tr>                                               
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.driver_license_type")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.driver_license_code ? i18n.t(`common.driver_license_type_${props?.data?.profiles?.captain?.driver_license_code?.toLowerCase()}`) : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.driver_license_number")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.driver_license_number ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.driver_license_exp_date")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.driver_license_expiration_date ? Formatter.dateToString(props?.data?.profiles?.captain?.driver_license_expiration_date, "date") : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-10 whitespace-nowrap">
                                {i18n.t("common.driver_license_img")}:
                            </td>
                            <td className="pb-10 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.driver_license_img ? <a className="underline decoration-dotted" href={props?.data?.profiles?.captain?.driver_license_img} target="_blank" rel="noreferrer">{i18n.t("common.view")}</a>: i18n.t("common.unknown")}
                            </td>
                        </tr>
						<tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.vehicle_type")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.vehicle_registration_code ? i18n.t(`common.vehicle_type_${props?.data?.profiles?.captain?.vehicle_registration_code?.toLowerCase()}`) : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.vehicle_plate_number")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.vehicle_plate_number ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.vehicle_registration_number")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.vehicle_registration_number ?? i18n.t("common.unknown")}
                            </td>
                        </tr>						
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.vehicle_registration_exp_date")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.vehicle_registration_expiration_date ? Formatter.dateToString(props?.data?.profiles?.captain?.insurance_expiration_date, "date") : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.vehicle_image")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.vehicle_img ? <a className="underline decoration-dotted" href={props?.data?.profiles?.captain?.vehicle_img} target="_blank" rel="noreferrer">{i18n.t("common.view")}</a>: i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-10 whitespace-nowrap">
                                {i18n.t("common.vehicle_registration_image")}:
                            </td>
                            <td className="pb-10 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.vehicle_registration_img ? <a className="underline decoration-dotted" href={props?.data?.profiles?.captain?.vehicle_registration_img} target="_blank" rel="noreferrer">{i18n.t("common.view")}</a>: i18n.t("common.unknown")}
                            </td>
                        </tr>
						<tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.insurance_code")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
								{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.insurance_code ? i18n.t(`common.insurance_type_${props?.data?.profiles?.captain?.insurance_code?.toLowerCase()}`) : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.insurance_name")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.insurance_company_name ?? i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.insurance_number")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.insurance_policy_number ?? i18n.t("common.unknown")}
                            </td>
                        </tr>						
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.insurance_expiration_date")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.insurance_expiration_date ? Formatter.dateToString(props?.data?.profiles?.captain?.insurance_expiration_date, "date") : i18n.t("common.unknown")}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-10 whitespace-nowrap">
                                {i18n.t("common.insurance_image")}:
                            </td>
                            <td className="pb-10 ps-5 w-full">
                                {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.data?.profiles?.captain?.insurance_card_img ? <a className="underline decoration-dotted" href={props?.data?.profiles?.captain?.insurance_card_img} target="_blank" rel="noreferrer">{i18n.t("common.view")}</a>: i18n.t("common.unknown")}
                            </td>
                        </tr>


                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.risk_limit")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {
                                (props?.isWalletLoading)
                                ?
                                    <Skelton className="bg-secondary-light" count={1}/>
                                :
                                (props?.walletData?.risk_limit)                               
                                ?
                                    <div className="flex gap-2">
                                        <div className={`p-0 ${!isRiskLimitEnabled ? "hidden" : ""}`}>
                                            <InputText 
                                                placeholder={i18n.t("common.risk_limit")}
                                                defaultValue={props?.walletData?.risk_limit}
                                                onChange={(e) => {setRiskLimit(e?.target?.value)}}
                                            />
                                        </div>
                                        <div className={`p-0 ${isRiskLimitEnabled ? "hidden" : ""}`}>
                                            <InputText 
                                                placeholder={i18n.t("common.risk_limit")}
                                                value={props?.walletData?.risk_limit ? Formatter.stringToNumberWithCommasAndDicimals(props?.walletData?.risk_limit) : ""}
                                                isDisabled={true}
                                            />
                                        </div> 
                                        <div className="p-0">
                                            <Button 
                                                type="neutral-low-solid" 
                                                className="w-[70px] h-[40px]" 
                                                text={isRiskLimitEnabled ? i18n.t("common.save") : i18n.t("common.edit")}
                                                onClick={handleRiskButton}
                                            />
                                        </div>
                                    </div>
								:
                                    i18n.t("common.unknown")}
                            </td>
                        </tr> 
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.zone")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {(props?.isZoneListLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.zone_id === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={props?.zoneList}
										listValue={props?.data?.profiles?.captain?.zone_id}
										isDisabled={props?.data?.profiles?.captain?.zone_id === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("zone_id", value)}
									/>
								}
                            </td>
                        </tr>                        
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.is_allowed_to_self_assign")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {(props?.isDataLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.is_allowed_to_self_assign === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[{title:i18n.t("common.yes"), value:"true"}, {title:i18n.t("common.no"), value:"false"}]}
										listValue={props?.data?.profiles?.captain?.is_allowed_to_self_assign ? "true" : "false"}
										isDisabled={props?.data?.profiles?.captain?.is_allowed_to_self_assign === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("is_allowed_to_self_assign", (value?.toLowerCase() === "true") ? true : false)}
									/>
								}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.is_eligible_for_commission")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {(props?.isDataLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.is_eligible_for_commission === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[{title:i18n.t("common.yes"), value:"true"}, {title:i18n.t("common.no"), value:"false"}]}
										listValue={props?.data?.profiles?.captain?.is_eligible_for_commission ? "true" : "false"}
										isDisabled={props?.data?.profiles?.captain?.is_eligible_for_commission === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("is_eligible_for_commission", (value?.toLowerCase() === "true") ? true : false)}
									/>
								}
                            </td>
                        </tr>						
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.is_eligible_for_private_delivery")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {(props?.isDataLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.is_eligible_for_private_delivery === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[{title:i18n.t("common.yes"), value:"true"}, {title:i18n.t("common.no"), value:"false"}]}
										listValue={props?.data?.profiles?.captain?.is_eligible_for_private_delivery ? "true" : "false"}
										isDisabled={props?.data?.profiles?.captain?.is_eligible_for_private_delivery === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("is_eligible_for_private_delivery", (value?.toLowerCase() === "true") ? true : false)}
									/>
								}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.is_eligible_for_public_delivery")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {(props?.isDataLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.is_eligible_for_public_delivery === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[{title:i18n.t("common.yes"), value:"true"}, {title:i18n.t("common.no"), value:"false"}]}
										listValue={props?.data?.profiles?.captain?.is_eligible_for_public_delivery ? "true" : "false"}
										isDisabled={props?.data?.profiles?.captain?.is_eligible_for_public_delivery === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("is_eligible_for_public_delivery", (value?.toLowerCase() === "true") ? true : false)}
									/>
								}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap">
                                {i18n.t("common.is_approved_for_private_delivery")}:
                            </td>
                            <td className="pb-5 ps-5 w-full">
                                {(props?.isDataLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.is_approved_for_private_delivery === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[{title:i18n.t("common.yes"), value:"true"}, {title:i18n.t("common.no"), value:"false"}]}
										listValue={props?.data?.profiles?.captain?.is_approved_for_private_delivery ? "true" : "false"}
										isDisabled={props?.data?.profiles?.captain?.is_approved_for_private_delivery === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("is_approved_for_private_delivery", (value?.toLowerCase() === "true") ? true : false)}
									/>
								}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-10 whitespace-nowrap">
                                {i18n.t("common.is_approved_for_public_delivery")}:
                            </td>
                            <td className="pb-10 ps-5 w-full">
                                {(props?.isDataLoading)
								?
									<Skelton className="bg-secondary-light" count={1}/>
								:
									(props?.data?.profiles?.captain?.is_approved_for_public_delivery === undefined)
								?
									i18n.t("common.unknown")
								:
									<InputDropdown
										list={[{title:i18n.t("common.yes"), value:"true"}, {title:i18n.t("common.no"), value:"false"}]}
										listValue={props?.data?.profiles?.captain?.is_approved_for_public_delivery ? "true" : "false"}
										isDisabled={props?.data?.profiles?.captain?.is_approved_for_public_delivery === undefined}
										isListSorted={false}
										isListReversed={false}
										isSearchable={false}
										isCompact={false}
										theme={props?.prefs?.theme}
										handleValueUpdate={(value) => props?.updateCaptainProfile("is_approved_for_public_delivery", (value?.toLowerCase() === "true") ? true : false)}
									/>
								}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap align-top">
                                {i18n.t("common.skills")}:
                            </td>
                            <td className="pb-5 ps-5 w-full align-top">
								{(props?.isSkillListLoading)
								? 
									<Skelton className="bg-secondary-light mb-5" count={5}/>
								:
									(props?.skillList?.length === 0)
								?
									i18n.t("error.missing_skills")
								:
									props?.skillList?.map((item, index) => {
										return (
											<div key={index} className="pb-5">
												<InputCheckBox
													label={item?.title}
													value={item?.value}
													isChecked={props?.data?.profiles?.captain?.skills.indexOf(item?.value) !== -1}
													handleValueChange={() => {props?.updateSkill(item?.value)}}
												/>
											</div>
										)
									})
								}
                            </td>
                        </tr>
                        <tr>
                            <td className="pb-5 whitespace-nowrap align-top">
                                {i18n.t("delivery.delivery_on_hand")}:
                            </td>
                            <td className="pb-5 ps-5 w-full align-top">
								{(props?.isDeliveryListLoading)
								? 
									<Skelton className="bg-secondary-light mb-5" count={5}/>
								:
									(props?.deliveryList?.length === 0)
								?
									i18n.t("error.missing_delivery_on_hand")
								:
									props?.deliveryList?.map((delivery, index) => {
										return (
											<div key={index} className="mb-5 flex">
                                                <div className="m-auto">
                                                    <div className={`h-[10px] w-[10px] ${Formatter?.statusCodeToColorCode(delivery?.status_code)} rounded-full`}/>
                                                </div>
                                                <div className="ps-2 w-full text-accent-regular cursor-pointer underline decoration-dotted" onClick={() => {props?.showDelivery(delivery?.delivery_id)}}>
                                                    {delivery?.tracking_number}
                                                </div>
											</div>
										)
									})
								}
                            </td>
                        </tr>																																														  						                                                                                 
                    </motion.tbody>}
                    </AnimatePresence>
                </table>                                              
            </div>  

            <div className="flex justify-end">
                <Button type="accent-outline" className="min-w-[120px]" text={i18n.t("common.remove")} isLoading={props?.isDataLoading}  isDisabled={props?.isDataLoading} onClick={() => props?.remove()}/>
            </div>                                                
        </div>
	);
}

export default Dialog;