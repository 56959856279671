import { useState, useEffect, useContext } from 'react';

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import UserInvite from '../../view/dialog/userInvite';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [tab, setTab] = useState("multiple");
    const [invitationURL, setInvitationURL] = useState("");
    const [viewID, setViewID] = useState(Math.random());
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const changeTab = (tab) =>
    {
        setTab(tab);
        setViewID(Math.random());
    };

    const invite = (data) =>
    {
        if(tab === "multiple")
        {
            navigator.clipboard.writeText(invitationURL);

            context?.setNotification("success", i18n.t("message.title"), i18n.t("message.copy_successful"));
        }
        else if(tab === "single")
        {    
            let newErrors = {};

            if(!Validator.notEmpty(data?.email))
            {
                newErrors["email"] = i18n.t('error.invalid_email');            
            }
            else if(!Validator.email(data?.email))
            {
                newErrors["email"] = i18n.t('error.invalid_email');
            }

            if(!Validator.notEmpty(data?.role_code))
            {
                newErrors["role_code"] = i18n.t('error.invalid_role');            
            }

            if(Object.keys(newErrors)?.length === 0)            
            {
                setErrors({});

                setLoading(true);

                let params = {
                    email:data?.email?.toLowerCase(),
                    role_code:data?.role_code?.toLowerCase()
                };

                isMounted && HTTP.post(Endpoint?.crm?.user?.invitation, params).then(response =>
                {                
                    if(response?.is_successful)
                    {        
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));
    
                        context?.clearDialog();                    
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                        setLoading(false);
                    }
                });
            }
            else
            {
                setErrors(newErrors);
            }            
        }        
    };

    useEffect(() =>
    {
        setLoading(true);

        isMounted && HTTP.get(Endpoint?.crm?.merchant?.data).then(response =>
        {                
            if(response?.is_successful && response?.data?.info?.merchant_code)
            {        
                let url = Constant?.url?.callback?.invite_captain + response?.data?.info?.merchant_code;

                setInvitationURL(url);
                setViewID(Math.random());          
            }

            setLoading(false);
        });

    }, [isMounted]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);    

    return (
        <UserInvite
            key={viewID}
            isLoading={isLoading}
            tab={tab}
            prefs={context?.prefs}
            invitationURL={invitationURL}
            errors={errors}
            setTab={changeTab}
            invite={invite}
        />
    );
}

export default Dialog;