import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';
import Formatter from '../../util/formatter';

import DashboardWallet from '../../view/page/dashboardWallet';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isListLoading, setListLoading] = useState(true);
    const [isWalletInfoLoading, setWalletInfoLoading] = useState(true);
    const [list, setList] = useState([]);
    const [walletInfo, setWalletInfo] = useState({});
    const [listFilterValue, setListFilterValue] = useState(new Date());

    const context = useContext(Context);

    const credit = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("WalletCredit", i18n.t("page.wallet_credit"), "bg-neutral-high-lightest", "sm", false, false, true, false, {walletInfo:walletInfo});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    const debit = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("WalletDebit", i18n.t("page.wallet_debit"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const showDialog = (dialogID, dialogTitle, dialogDataID) =>
    {
        context?.setDialog(dialogID, dialogTitle, "bg-neutral-high-lightest", "sm", true, false, true, false, {id:dialogDataID});
    };

    useEffect(() =>
    {
        setListLoading(true);

        let params = {};

        params["type_code"] = "merchant";

        params["year"]  = (listFilterValue instanceof Date) ? listFilterValue?.getFullYear() : 0;
        params["month"] = (listFilterValue instanceof Date) ? listFilterValue?.getMonth() + 1 : 0;

        isMounted && HTTP.get(Endpoint?.fms?.history?.balance, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setList(response?.data?.list);
            }

             setListLoading(false);
        });

    }, [isMounted, listFilterValue]);

    useEffect(() =>
    {
        setWalletInfoLoading(true);

        let params = {};

        params["type_code"] = "merchant";

        isMounted && HTTP.get(Endpoint?.fms?.wallet?.data, params).then(response =>
        {
            if(response?.is_successful && response?.data?.info)
            {
                setWalletInfo(response?.data?.info);
            }

            setWalletInfoLoading(false);
        });

    }, [isMounted]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_wallet');
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardWallet
            recordsPerPage={50}
            prefs={context?.prefs}
            walletInfo={walletInfo}
            list={list?.map((transaction) => {return(
            {
                credit:transaction?.amount >= 0 ? Formatter.stringToNumberWithCommasAndDicimals(transaction?.amount) : "",
                debit:transaction?.amount < 0 ? Formatter.stringToNumberWithCommasAndDicimals(transaction?.amount) : "",
                reference_type:transaction?.reference_type ? i18n.t(`wallet_transaction.type_${transaction?.reference_type}`) : i18n.t("common.unknown"),
                status_code:transaction?.status_code ? i18n.t(`wallet_transaction.status_${transaction?.status_code}`) : i18n.t("common.unknown"),
                posted_at:Formatter.dateToString(transaction?.posted_at),
            })})}
            isListLoading={isListLoading} 
            isWalletInfoLoading={isWalletInfoLoading}           
            showDialog={showDialog}
            credit={credit}
            debit={debit}
            setListFilterValue={setListFilterValue}
        />
    );
}

export default Controller;