import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import Skelton from '../component/skelton';
import InputDropdown from '../component/inputDropdown';
import InputText from '../component/inputText';
import InputTextArea from '../component/inputTextArea';
import InputDate from '../component/inputDate';
import InputCheckBox from '../component/inputCheckBox';
import Button from '../component/button';

import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';
import TrashDarkIconImage from '../../assets/vector/icon_trash_dark.svg';
import TrashLightIconImage from '../../assets/vector/icon_trash_light.svg';

const View = (props) =>
{
    const [removedItems, setRemovedItem] = useState([]);
    const [data, setData] = useState(
    {
        post_format:"single",
        post_type:"public", 
        sender_mobile_code:null,
        recipient_mobile_code:null,
        items:[],
        skills:[]
    });

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        if(key === "post_type")
        {
            temp["skills"] = [];
        }

        setData(temp);
    };

    const addItem = () =>
    {
        let temp = [...data?.items];

        temp.push(
        {
            "name": "",
            "serial_number": ""
            // "sku": "",
            // "width": "",
            // "height": "",
            // "depth": "",
            // "weight":"",
            // "is_fragile":"",
            // "price": ""            
        })

        update("items", temp);        
    };

    const updateItem = (index, key, value) =>
    {
        let temp = [...data?.items];

        temp[index][key] = value;

        update("items", temp);
    };

    const removeItem = (indexToRemove) =>
    {
        if(!removedItems?.includes(indexToRemove))
        {
            setRemovedItem([...removedItems, ...[indexToRemove]]);
        }
    };

    const toggleSkill = (skillID) =>
    {
        let temp = [...data?.skills];

        if(temp?.indexOf(skillID) !== -1)
        {
            temp = temp?.filter(item => { return item !== skillID })
        }
        else
        {
            temp.push(skillID);
        }

        update("skills", temp);        
    };    

    const next = () =>
    {
        let newItems = [];

        for (let i = 0; i < data?.items?.length; i++)
        {
            if(!removedItems?.includes(i))
            {
                newItems.push(data?.items[i]);
            }
        }

        props.next({...data, ...{items:newItems}})
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">

            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_general_info")}
                </div>
                <div className="mb-7 w-[50%]">
                    <div className="mb-2">
                        {i18n.t("delivery.handling")}
                        <span className="text-accent-regular">*</span>
                    </div>                    
                    <InputDropdown
                        list={[
                                {title:i18n.t("delivery.is_public_explained"), value:"public"}, 
                                {title:i18n.t("delivery.is_private_explained"), value:"private"}
                            ]}
                        listValue={data?.post_type}
                        isDisabled={props?.isDataLoading}
                        isListSorted={false}
                        isListReversed={false}
                        isSearchable={false}
                        isCompact={false}
                        theme={props?.prefs?.theme}
                        handleValueUpdate={(value) => update("post_type", value)}
                    />
                    <AnimatePresence>
                        {props?.errors?.post_type && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.post_type}
                            </motion.div>
                        )}
                    </AnimatePresence>                                         
                </div>
                <div className="mb-7 w-[50%]">
                    <div className="mb-2">
                        {i18n.t("common.zone")}
                        <span className="text-accent-regular">*</span>
                    </div>                    
                    <InputDropdown
                        list={data?.post_type === "private" ? props?.privateZoneList : props?.publicZoneList}
                        listValue={data?.zone_id}
                        isDisabled={props?.isZoneListLoading}
                        isListSorted={false}
                        isListReversed={false}
                        isSearchable={false}
                        isCompact={false}
                        theme={props?.prefs?.theme}
                        handleValueUpdate={(value) => update("zone_id", value)}
                    />
                    <AnimatePresence>
                        {props?.errors?.zone_id && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.zone_id}
                            </motion.div>
                        )}
                    </AnimatePresence>                                         
                </div> 
                <AnimatePresence>
                    {data?.post_type === "private" && (
                        <motion.div 
                            className="mb-7 w-[50%]"
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                              
                        >
                            <div className="mb-2">
                                {i18n.t("delivery.trip_code")}
                                <span className="text-accent-regular">*</span>
                            </div>                    
                            <InputDropdown
                                list={props?.tripList}
                                listValue={data?.trip_id}
                                isDisabled={props?.isTripListLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                theme={props?.prefs?.theme}
                                handleValueUpdate={(value) => update("trip_id", value)}
                            />
                            <AnimatePresence>
                                {props?.errors?.trip_id && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.trip_id}
                                    </motion.div>
                                )}
                            </AnimatePresence>                                         
                        </motion.div>
                    )} 
                </AnimatePresence> 
            </div>

            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_collection")}
                </div>
                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("delivery.bill_reference_number")}
                        placeholder={""}
                        isRequired={true}
                        errorMsg={props?.errors?.bill_reference_number}
                        onChange={(e) => {update("bill_reference_number", e?.target?.value)}}
                    />
                </div>
                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("delivery.bill_amount")}
                        placeholder={""}
                        isRequired={true}
                        errorMsg={props?.errors?.bill_amount}
                        onChange={(e) => {update("bill_amount", e?.target?.value)}}
                    />
                </div>
                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("delivery.collection_amount")}
                        placeholder={""}
                        isRequired={true}
                        errorMsg={props?.errors?.collection_amount}
                        onChange={(e) => {update("collection_amount", e?.target?.value)}}
                    />
                </div>
            </div>

            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_sender")}
                </div>                
                <div className="mb-7">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("common.first_name")}
                            isRequired={true}
                            onChange={(e) => {update("sender_first_name", e?.target?.value)}}
                        /> 
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("common.last_name")}
                            isRequired={true}
                            onChange={(e) => {update("sender_last_name", e?.target?.value)}}
                        />             
                    </div>
                    <AnimatePresence>
                        {props?.errors?.sender_name && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm`}
                            >
                                {props?.errors?.sender_name}
                            </motion.div>
                        )}
                    </AnimatePresence>  
                </div>
                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("common.email")}
                        placeholder={"email@example.com"}
                        isRequired={true}
                        errorMsg={props?.errors?.sender_email}
                        onChange={(e) => {update("sender_email", e?.target?.value)}}
                    />
                </div>
                <div className="mb-7 w-[50%]">
                    <div className="pb-2">
                        {i18n.t("common.mobile")}
                        <span className="text-accent-regular">*</span>
                    </div>
                    <div dir="ltr" className="flex gap-2">
                        <div className="w-[150px] text-sm">
                            <InputDropdown
                                theme={props?.prefs?.theme}
                                list={props?.countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
                                listValue={data?.sender_mobile_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isDataLoading}
                                isListSorted={true}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("sender_mobile_code", value)}}
                            />                        
                        </div>                    
                        <div className="w-full">
                            <InputText 
                                type={"text"}
                                errorClassName={`text-status-danger-regular ${props?.prefs?.dir === "rtl" ? "text-end" : "text-start"}`}
                                enableAutoComplete={false}
                                placeholder={"5XXXXXXXXX"}
                                onChange={(e) => {update("sender_mobile", e?.target?.value)}}
                            />
                        </div>
                    </div> 
                    <AnimatePresence>
                    {props?.errors?.sender_mobile && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm mt-2`}
                        >
                            {props?.errors?.sender_mobile}
                        </motion.div>
                    )}
                    </AnimatePresence>   
                </div>
                <div className="mb-7">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("delivery.lat")}
                            errorMsg={props?.errors?.sender_lat}
                            onChange={(e) => {update("sender_lat", e?.target?.value)}}
                        /> 
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("delivery.long")}
                            errorMsg={props?.errors?.sender_long}
                            onChange={(e) => {update("sender_long", e?.target?.value)}}
                        />             
                    </div> 
                </div>
                <div className="mb-7 w-[50%]">
                    <InputTextArea 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("common.address")}
                        errorMsg={props?.errors?.sender_address}
                        onChange={(e) => {update("sender_address", e?.target?.value)}}
                    />
                </div>                
            </div>
            
            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_recipient")}
                </div>                
                <div className="mb-7">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("common.first_name")}
                            isRequired={true}
                            onChange={(e) => {update("recipient_first_name", e?.target?.value)}}
                        /> 
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("common.last_name")}
                            isRequired={true}
                            onChange={(e) => {update("recipient_last_name", e?.target?.value)}}
                        />             
                    </div>
                    <AnimatePresence>
                        {props?.errors?.recipient_name && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm`}
                            >
                                {props?.errors?.recipient_name}
                            </motion.div>
                        )}
                    </AnimatePresence>  
                </div>
                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("common.email")}
                        placeholder={"email@example.com"}
                        isRequired={true}
                        errorMsg={props?.errors?.recipient_email}
                        onChange={(e) => {update("recipient_email", e?.target?.value)}}
                    />
                </div>
                <div className="mb-7 w-[50%]">
                    <div className="pb-2">
                        {i18n.t("common.mobile")}
                        <span className="text-accent-regular">*</span>
                    </div>
                    <div dir="ltr" className="flex gap-2">
                        <div className="w-[150px] text-sm">
                            <InputDropdown
                                list={props?.countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
                                listValue={data?.recipient_mobile_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isDataLoading}
                                isListSorted={true}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("recipient_mobile_code", value)}}
                            />                        
                        </div>                    
                        <div className="w-full">
                            <InputText 
                                type={"text"}
                                errorClassName={`text-status-danger-regular ${props?.prefs?.dir === "rtl" ? "text-end" : "text-start"}`}
                                enableAutoComplete={false}
                                placeholder={"5XXXXXXXXX"}
                                onChange={(e) => {update("recipient_mobile", e?.target?.value)}}
                            />
                        </div>
                    </div> 
                    <AnimatePresence>
                    {props?.errors?.recipient_mobile && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm mt-2`}
                        >
                            {props?.errors?.recipient_mobile}
                        </motion.div>
                    )}
                    </AnimatePresence>   
                </div>
                <div className="mb-7">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("delivery.lat")}
                            errorMsg={props?.errors?.recipient_lat}
                            onChange={(e) => {update("recipient_lat", e?.target?.value)}}
                        /> 
                        <InputText 
                            type={"text"}
                            enableAutoComplete={false}
                            label={i18n.t("delivery.long")}
                            errorMsg={props?.errors?.recipient_long}
                            onChange={(e) => {update("recipient_long", e?.target?.value)}}
                        />             
                    </div>
                </div>
                <div className="mb-7 w-[50%]">
                    <InputTextArea 
                        type={"text"}
                        enableAutoComplete={false}
                        label={i18n.t("common.address")}
                        errorMsg={props?.errors?.recipient_address}
                        onChange={(e) => {update("recipient_address", e?.target?.value)}}
                    />
                </div> 
            </div>

            <div className="pb-10">   
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_handling")}
                </div> 
                <div className="mb-7">    
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputDate
                            theme={props?.prefs?.theme}
                            label={i18n.t("delivery.pickup_time_starts")}
                            dateFormat={`dd MMMM yyyy ${i18n.t("time.at")} h:mm a`}
                            futureDateOnly={true}
                            locale={props?.prefs?.lang}
                            showTimeSelect={true}
                            isDisabled={props?.isDataLoading}
                            errorMsg={props?.errors?.preferred_pickup_time_from}
                            onChange={(value) => {update("preferred_pickup_time_from", value);}}
                        /> 
                        <InputDate
                            theme={props?.prefs?.theme}
                            label={i18n.t("delivery.pickup_time_ends")}
                            dateFormat={`dd MMMM yyyy ${i18n.t("time.at")} h:mm a`}
                            futureDateOnly={true}
                            locale={props?.prefs?.lang}
                            showTimeSelect={true}
                            isDisabled={props?.isDataLoading}
                            errorMsg={props?.errors?.preferred_pickup_time_to}
                            onChange={(value) => {update("preferred_pickup_time_to", value);}}
                        />                         
                    </div>
                </div> 
                <div className="mb-7">    
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputDate
                            theme={props?.prefs?.theme}
                            label={i18n.t("delivery.dropoff_time_starts")}
                            dateFormat={`dd MMMM yyyy ${i18n.t("time.at")} h:mm a`}
                            futureDateOnly={true}
                            locale={props?.prefs?.lang}
                            showTimeSelect={true}
                            isDisabled={props?.isDataLoading}
                            errorMsg={props?.errors?.preferred_delivery_time_from}
                            onChange={(value) => {update("preferred_delivery_time_from", value);}}
                        /> 
                        <InputDate
                            theme={props?.prefs?.theme}
                            label={i18n.t("delivery.dropoff_time_ends")}
                            dateFormat={`dd MMMM yyyy ${i18n.t("time.at")} h:mm a`}
                            futureDateOnly={true}
                            locale={props?.prefs?.lang}
                            showTimeSelect={true}
                            isDisabled={props?.isDataLoading}
                            errorMsg={props?.errors?.preferred_delivery_time_to}
                            onChange={(value) => {update("preferred_delivery_time_to", value);}}
                        />                         
                    </div>
                </div>
            </div>

            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_items")}
                </div>                
                <div className="border-[1px] border-neutral-high-dark rounded-lg p-5 shadow-sm">
                    <div className="p-0">
                        {
                        (data?.items?.length - removedItems?.length === 0)
                        ?
                            <div className="text-center text-neutral-low-light">
                                {i18n.t("error.no_items")}
                            </div>
                        :
                            data?.items?.map((item, index) => {
                                return (

                                    (!removedItems?.includes(index))
                                    ?
                                        <div key={index} className="flex gap-2 mb-2">
                                            <div className="grow my-auto">
                                                <InputText 
                                                    type={"text"}
                                                    enableAutoComplete={false}
                                                    isDisabled={props?.isDataLoading}
                                                    placeholder={i18n.t("delivery.item_name")}
                                                    onChange={(e) => updateItem(index, "name", e?.target?.value)}
                                                />
                                            </div>
                                            <div className="grow my-auto">
                                                <InputText 
                                                    type={"text"}
                                                    enableAutoComplete={false}
                                                    isDisabled={props?.isDataLoading}
                                                    placeholder={i18n.t("delivery.item_serial_number")}
                                                    onChange={(e) => updateItem(index, "serial_number", e?.target?.value)}
                                                />                                                            
                                            </div>
                                            <div className="my-auto">
                                                <Button 
                                                    type="neutral-low-outline" 
                                                    className="min-w-[50px]" 
                                                    icon={(props?.prefs?.theme === "dark") ? TrashLightIconImage : TrashDarkIconImage}
                                                    iconHover={(props?.prefs?.theme === "dark") ? TrashDarkIconImage : TrashLightIconImage}
                                                    isLoading={props?.isDataLoading}  
                                                    isDisabled={props?.isDataLoading} 
                                                    onClick={() => removeItem(index)}
                                                />
                                            </div>
                                        </div> 
                                    :
                                        null                                       
                                )
                            })
                        }
                    </div>
                    <div className="flex justify-end mt-4 pt-4 border-t-[1px] border-neutral-high-dark">
                        <Button 
                            type="neutral-low-solid" 
                            className="min-w-[50px]" 
                            icon={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                            iconHover={(props?.prefs?.theme === "dark") ? AddLightIconImage : AddDarkIconImage}
                            isLoading={props?.isDataLoading}  
                            isDisabled={props?.isDataLoading} 
                            onClick={() => addItem()}
                        />                                            
                    </div>                    
                </div>
                <AnimatePresence>
                    {props?.errors?.items && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm my-2`}
                        >
                            {props?.errors?.items}
                        </motion.div>
                    )}
                </AnimatePresence>                 
            </div>
            
            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.cat_skills")}
                </div>                
                <div className="border-[1px] border-neutral-high-dark rounded-lg p-5 shadow-sm">
                    {(props?.isSkillListLoading)
                    ? 
                        <Skelton className="bg-secondary-light mb-5" count={5}/>
                    :
                    (props?.skillList?.filter((item) => item?.type?.toLowerCase() === data?.post_type?.toLowerCase())?.length === 0)
                    ?
                        i18n.t("error.missing_skills")
                    :
                        props?.skillList?.filter((item) => item?.type?.toLowerCase() === data?.post_type?.toLowerCase())?.map((item, index) => {
                            return (
                                <div key={index} className={(index !== props?.skillList?.length -1) ? "pb-5" : "p-0"}>
                                    <InputCheckBox
                                        containerClassName=""
                                        label={item?.title}
                                        value={item?.value}
                                        isChecked={data?.skills?.indexOf(item?.value) !== -1}
                                        handleValueChange={() => {toggleSkill(item?.value)}}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="pb-10">
                <div className="mb-5 font-primary">
                    {i18n.t("delivery.comment")}
                </div>                
                <div className="mb-7">
                    <InputTextArea 
                        type={"text"}
                        enableAutoComplete={false}
                        placeholder={""}
                        errorMsg={props?.errors?.comment}
                        onChange={(e) => {update("comment", e?.target?.value)}}
                    />
                </div>                
            </div>

            <div className="flex justify-end">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isDataLoading}  isDisabled={false} onClick={next}/>
            </div>             
       </div>
    );
}

export default View;        