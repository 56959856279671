import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';

import { Context } from './util/context';

import "./assets/style/global.css";
import "./assets/style/override.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter basename='/'>
		<Context>
			<App/>
		</Context>
	</BrowserRouter>
);