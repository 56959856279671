import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import Stepper from '../../view/component/stepper';
import PasswordReset01 from '../../view/dialog/passwordReset01';
import PasswordReset02 from '../../view/dialog/passwordReset02';
import PasswordReset03 from '../../view/dialog/passwordReset03';

const Dialog = props => 
{
    const totalSteps = 2;
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const closeDialog = () =>
    {
        context?.setDialog(null);
    };

    const goPrev = (newData) =>
    {
        setData({...data, ...newData});

        if(currentStep > 1)
        {
            setCurrentStep(currentStep-1);
        }
    };

    const goNext = (newData) =>
    {
        setData({...data, ...newData});

        
        if(currentStep === 1)
        {
            processStep1({...data, ...newData})
        }
        else if(currentStep === 2)
        {
            processStep2({...data, ...newData})
        }
    };

    const processStep1 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(!Validator.notEmpty(stepData?.email))
        {
            newErrors["email"] = i18n.t('error.invalid_email');            
        }
        else if(!Validator.email(stepData?.email))
        {
            newErrors["email"] = i18n.t('error.invalid_email');
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            params = 
            {
                email: stepData?.email?.toLowerCase(),
                callback_type: "reset_password"
            };

            isMounted && HTTP.post(Endpoint?.crm?.authorization?.email, params).then(response =>
            {
                if(response?.is_successful)
                {
                    setCurrentStep(currentStep+1);

                    setLoading(false);
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });            
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep2 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(!Validator.notEmpty(stepData?.otp))
        {
            newErrors["otp"] = i18n.t('error.invalid_otp');            
        }

        if(!Validator.notEmpty(stepData?.password))
        {
            newErrors["password"] = i18n.t('error.invalid_new_password');            
        }
        else if(!Validator.longPassword(stepData?.password))
        {
            newErrors["password"] = i18n.t('error.short_password');            
        }

        if(!Validator.notEmpty(stepData?.password_confirmation) || stepData?.password !== stepData?.password_confirmation)
        {
            newErrors["password_confirmation"] = i18n.t('error.passwords_do_not_match');            
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            params = 
            {
                email: data?.email?.toLowerCase(),
                callback_type: "reset_password",
                authorization_code: stepData?.otp,
                password:stepData?.password
            };

            isMounted && HTTP.put(Endpoint?.crm?.authorization?.email, params).then(response =>
            {
                if(response?.is_successful)
                {
                    setCurrentStep(currentStep+1);

                    setLoading(false);
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });            
        }
        else
        {
            setErrors(newErrors);
        }        
    };

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <>
            <Stepper
                total={totalSteps}
                current={currentStep}
                className="px-7 pb-7"
            />
            {(currentStep === 1)
            ?
                <PasswordReset01
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={data}
                    errors={errors}
                    prefs={context?.prefs}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 2)
            ?            
                <PasswordReset02
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={data}
                    errors={errors}
                    prefs={context?.prefs}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            <PasswordReset03
                closeDialog={closeDialog}
            />                
            }
        </>
    );
}

export default Dialog;