import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';
import Map from '../component/map';

const View = (props) =>
{
    const [isOpen, setOpen] = useState(false);

    return( 
        <div id="section-10" className="font-secondary">
            <svg viewBox="0 0 1920 326" width="100%" preserveAspectRatio="none" className="p-0">
                <path className="fill-secondary-dark" d="M 1919 327 C 1719 121 1709 -24 1169 100 L 0 330 V 330 H 1920 Z"/>
            </svg>
            <div className="bg-secondary-dark px-10 pt-12">
                <div className="container max-w-screen-lg mx-auto">
                    <div className="font-primary text-secondary-lightest text-xl text-center pb-24">
                        {i18n.t("home.section_10_title_01")}
                    </div>   
                </div>

                <AnimatePresence>
                {!isOpen &&
                    <motion.div 
                        style={{overflow:"hidden"}}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1}}
                        exit={{ opacity: 0 }}
                        transition={{ duration:  Constant?.animation?.speed?.fast/1000}}
                        className="text-center"
                    >
                        <div className="container max-w-screen-lg mx-auto pb-[150px]">
                            <Button type="primary-solid" text={i18n.t("home.section_10_button_01")} onClick={() => {setOpen(true)}} className="px-[40px]"/>
                        </div>                        
                    </motion.div>
                }
                </AnimatePresence>
                
                <AnimatePresence>
                {isOpen &&
                    <motion.div
                        style={{overflow:"hidden"}}
                        initial={{ opacity: 0, height:0 }}
                        animate={{ opacity: 1, height:"auto"}}
                        exit={{ opacity: 0, height:0 }}
                        transition={{ delay: Constant?.animation?.speed?.fast/1000, duration:  Constant?.animation?.speed?.fast/1000}}
                    >
                        <div className="container max-w-screen-lg px-5 mx-auto pb-[150px]">
                            <div className="pb-8 gap-10 md:gap-96 flex justify-between">
                                <InputDropdown
                                    selectorClassName={"border-primary-regular bg-gradient-to-b from-secondary-lightest from-0% to-secondary-regular to-100%"}
                                    listClassName={"bg-secondary-lightest"}
                                    itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                    searchbarClassName={"bg-secondary-light"}
                                    list={props?.countryList}
                                    listValue={props?.country}
                                    searchInputPlaceholder={i18n.t("common.search_generic")}
                                    isDisabled={props?.isLoading}
                                    isListSorted={true}
                                    isListReversed={false}
                                    isSearchable={false}
                                    isCompact={false}
                                    handleValueUpdate={(value) => props?.setCountry(value)}   
                                />

                                <InputDropdown
                                    selectorClassName={"border-primary-regular bg-gradient-to-b from-secondary-lightest from-0% to-secondary-regular to-100%"}
                                    listClassName={"bg-secondary-lightest"}
                                    itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                    searchbarClassName={"bg-secondary-light"}
                                    list={props?.zoneList}
                                    listValue={props?.zone}
                                    searchInputPlaceholder={i18n.t("common.search_generic")}
                                    isDisabled={props?.isLoading}
                                    isListSorted={true}
                                    isListReversed={false}
                                    isSearchable={props?.zoneList?.length > 3}
                                    isCompact={props?.zoneList?.length > 3}
                                    handleValueUpdate={(value) => props?.setZone(value)}   
                                />
                            </div>

                            <div className="map-home-view relative h-[40vh] lg:h-[60vh] rounded-2xl shadow-xl shadow-shadow-regular">
                                <Map
                                    initZoom={10}
                                    minZoom={9}
                                    maxZoom={20}
                                    showZoomControl={true}
                                    showMapTypeControl={false}
                                    showScaleControl={false}
                                    showStreetViewControl={false}
                                    showRotateControl={false}
                                    showFullscreenControl={false}
                                    showClickableIcons={false}
                                    showScrollwheel={false}                        
                                    lat={props?.zoneList?.filter((zone) => zone?.value === props?.zone)?.[0]?.center_lat}
                                    long={props?.zoneList?.filter((zone) => zone?.value === props?.zone)?.[0]?.center_long}
                                    theme={"extra_dark"}
                                    fence={props?.zoneList?.filter((zone) => zone?.value === props?.zone)?.[0]?.geofence}
                                />
                            </div>
                        </div>                        
                    </motion.div>
                }
                </AnimatePresence>
            </div>
        </div>
    );
}

export default View;
