import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import i18n from '../../util/i18n';
import Constant from '../../config/constant';

import Image from '../component/image';
import SkeltonView from '../component/skelton';

import ChevronDownIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronUpIconImage from '../../assets/vector/icon_chevron_up_dark.svg';

const View = (props) =>
{
    const [shownItems, setShownItems] = useState([]);

    const hideAndShow = (section, question) =>
    {
        let list = [...shownItems];

        let index = list?.indexOf(`${section}_${question}`);

        if(index >= 0)
        {
            list?.splice(index, 1);
        }
        else
        {
            list.push(`${section}_${question}`);
        }

        setShownItems(list);
    };

    return( 
            <div id="static-page" className="font-secondary">
                {props?.isLoading ? <div className="container max-w-screen-lg mx-auto px-5 pt-[150px] min-h-[500px]"><SkeltonView className="bg-secondary-light mb-5" count={20}/></div> : null}

                {!props?.isLoading ? <div className="container max-w-screen-lg mx-auto px-5 pt-[150px] min-h-[500px]">
                    <div className="font-primary text-xl leading-10 pt-[50px]">
                        {i18n.t("page.faqs")}
                    </div>
                    <div className="text-md leading-10 pt-[25px]">
                        {props?.list?.sort((a, b) => a.sort_id - b.sort_id).map((section, sectionIndex) => 
                        {
                            return (
                                <div key={sectionIndex} className="mb-32">
                                    <div className="font-primary mb-5">
                                        {section?.[`section_title_${props?.prefs?.lang}`]}
                                    </div>
                                    <div>
                                    {section?.content?.sort((a, b) => a.sort_id - b.sort_id).map((question, questionIndex) => 
                                        {
                                            return(
                                                <div key={questionIndex}>
                                                    <motion.div
                                                        className="bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light mb-5 p-5 cursor-pointer transition-all duration-normal ease-in-out" onClick={() => hideAndShow(sectionIndex, questionIndex)}
                                                    >
                                                        <div className="flex justify-between gap-5">
                                                            <div className="my-auto font-primary">
                                                                {question?.[`question_${props?.prefs?.lang}`]}
                                                            </div>
                                                            <div className="my-auto">
                                                                <Image src={shownItems?.includes(`${sectionIndex}_${questionIndex}`) ? ChevronUpIconImage : ChevronDownIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                                            </div>
                                                        </div>
                                                        <AnimatePresence>
                                                            {shownItems?.includes(`${sectionIndex}_${questionIndex}`) && (
                                                                <motion.div
                                                                    style={{ overflow:"hidden" }}
                                                                    initial={{ height:0, opacity:0 }}
                                                                    animate={{ height: "auto", opacity:1 }}
                                                                    exit={{ height:0, opacity:0 }}
                                                                    transition={{ duration: Constant?.animation?.speed?.fast/1000, ease: easeInOut }}
                                                                >
                                                                    <div className="pt-5">
                                                                        {question?.[`answer_${props?.prefs?.lang}`]}
                                                                    </div>
                                                                </motion.div>
                                                            )}  
                                                        </AnimatePresence>
                                                    </motion.div>
                                                </div>
                                            )
                                    })}
                                    </div>
                                </div>)
                            })}
                    </div>             
                </div> : null}

                <svg viewBox="0 0 1920 326" width="100%" preserveAspectRatio="none" className="p-0">
                    <path className="fill-secondary-dark" d="M 1919 327 C 1719 121 1709 -24 1169 100 L 0 330 V 330 H 1920 Z"/>
                </svg>
            </div>
    );
}

export default View;
