import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import BankAccountCreate from '../../view/dialog/bankAccountCreate';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [banksList, setBanksList] = useState([]);
    const [errors, setErrors] = useState({});
    
    const context = useContext(Context);

    const submit = (newData) =>
    {
        let newErrors = {};

        if(!Validator.notEmpty(newData?.bank_code))
        {
            newErrors["bank_code"] = i18n.t('error.invalid_bank_code');            
        }
        
        if(!Validator.notEmpty(newData?.holder_name))
        {
            newErrors["holder_name"] = i18n.t('error.invalid_holder_name');
        }
        
        if(!Validator.notEmpty(newData?.bank_account_number))
        {
            newErrors["bank_account_number"] = i18n.t('error.invalid_bank_account_number');
        }
        
        if(!Validator.notEmpty(newData?.bank_iban_number))
        {
            newErrors["bank_iban_number"] = i18n.t('error.invalid_bank_iban_number');
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setLoading(true);

            let data = {};
    
            data["type_code"]  = "merchant";
            data["bank_code"]  = newData?.bank_code;
            data["bank_account_number"] = newData?.bank_account_number;
            data["bank_iban_number"] = newData?.bank_iban_number;
            data["holder_name"] = newData?.holder_name;

            isMounted && HTTP.post(Endpoint?.fms?.bankAccount?.data, data).then(response =>
            {
                if(response?.is_successful)
                {    
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);                   
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                    setLoading(false);
                }
            });
        }  
        else
        {
            setErrors(newErrors);
        }             
    };

    useEffect(() =>
    {
        setLoading(true);

        isMounted && HTTP.get(Endpoint?.cms?.bank?.list).then(response =>
        {
            setBanksList(response?.data?.list?.map((bank) =>
            {
                return {
                    title: bank?.[`name_${context?.prefs?.lang}`],
                    value: bank?.bank_code
                };
            }));
            
            setLoading(false);
        });
    }, [isMounted, context?.prefs?.lang]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <BankAccountCreate
            isLoading={isLoading}
            errors={errors}
            banksList={banksList}
            prefs={context?.prefs}
            submit={submit}
        />
    );
}

export default Dialog;