import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import Table from '../component/table';
import Button from '../component/button';

const View = (props) =>
{
    return( 
        <div className={`h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-gradient-regular via-gradient-dark to-gradient-darkest flex flex-col`}>
            <div className="border-t-[1px] border-neutral-high-regular grow text-neutral-low-dark overflow-scroll mt-[150px] lg:mt-[92px]" data-lenis-prevent>
                <div className="p-5 transition ease-in-out duration-normal">
            <div className="mb-10 font-primary text-lg">
                {i18n.t("page.dashboard_search")} ({Formatter?.stringToNumberWithCommas(props?.list?.length)})
            </div>
            <div className="mb-5">
                <Table
                    labels={
                    [
                        {order:1, id:"status_code", title:"", dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:true},
                        {order:2, id:"status_name", title:i18n.t("delivery.status_code"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minXL:true},
                        {order:3, id:"handling", title:i18n.t("delivery.handling"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minMD:true},
                        {order:4, id:"tracking_number", title:i18n.t("delivery.tracking_number"), dialogID:"DeliveryInfo", dialogTitle:i18n.t("page.delivery_info"), dialogDataID:"delivery_id", isStatusIndicator:false},
                        {order:5, id:"bill_reference_number", title:i18n.t("delivery.bill_reference_number"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minLG:true},
                        {order:6, id:"captain_name", title:i18n.t("delivery.captain"), dialogID:"UserInfo", dialogTitle:i18n.t("page.user_info"), dialogDataID:"captain_id", isStatusIndicator:false}
                    ]}
                    list={props?.list}
                    prefs={props?.prefs}
                    showDialog={props?.showDialog}
                    isLoading={props?.isLoading}
                />            
            </div>
            <div className="flex justify-between gap-1 pt-4">
                <div className="p-0">
                    <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={props?.listPage <= 1} onClick={props?.goPrev}/>
                </div>
                <div className="p-0">
                    <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading} isDisabled={props?.isLoading || props?.list?.length === 0 || props?.list?.length < props?.recordsPerPage} onClick={props?.goNext}/>
                </div> 
            </div>
                </div>   
            </div>
        </div>
    );
}

export default View;    