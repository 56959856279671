import { useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';

import Image from '../component/image';

import ImageForSection03AndElement01 from '../../assets/image/section_03_01.webp';
import ImageForSection03AndElement02 from '../../assets/image/section_03_02.webp';
import ImageForSection03AndElement03 from '../../assets/image/section_03_03.webp';

const View = (props) =>
{
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll(
    {
        target: targetRef,
        offset: ["start end", "end start"]
    });

    const firstCardTranslateX = useTransform(scrollYProgress, [0.1, 0.3], [70, 0]);
    const firstCardOpacity = useTransform(scrollYProgress, [0.1, 0.3], [0.5, 1]);

    const secondCardTranslateX = useTransform(scrollYProgress, [0.2, 0.5], [-70, 0]);
    const secondCardOpacity = useTransform(scrollYProgress, [0.2, 0.5], [0.5, 1]);

    const thirdCardTranslateX = useTransform(scrollYProgress, [0.4, 0.6], [70, 0]);
    const thirdCardOpacity = useTransform(scrollYProgress, [0.4, 0.6], [0.5, 1]);

    return(
        <div ref={targetRef} id="section-3" className="container max-w-screen-lg mx-auto py-[100px] px-10 font-secondary">

            <motion.div style={{ translateX:firstCardTranslateX, opacity:firstCardOpacity }} className="flex flex-wrap md:flex-nowrap gap-12 mb-[100px]">
                <div className="flex w-full md:w-auto justify-center items-center text-center">
                    <Image src={ImageForSection03AndElement01} alt="" className="m-auto transition-all duration-normal ease-in-out max-h-40 md:max-h-60"/>
                </div>
                <div className="w-full my-auto p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                    <div className="font-primary text-xl leading-10 mb-5 text-center md:text-start">
                        {i18n.t("home.section_03_title_01")}
                    </div>
                    <div className="text-md leading-10 lg:leading-[50px] text-center md:text-start">
                        {i18n.t("home.section_03_content_01")}
                    </div>
                </div>
            </motion.div>

            <motion.div style={{ translateX:secondCardTranslateX, opacity:secondCardOpacity }} className="flex flex-wrap-reverse md:flex-nowrap gap-12 mb-[100px]">                    
                <div className="w-full my-auto p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                    <div className="font-primary text-xl leading-10 mb-5 text-center md:text-start">
                        {i18n.t("home.section_03_title_02")}
                    </div>
                    <div className="text-md leading-10 lg:leading-[50px] text-center md:text-start">
                        {i18n.t("home.section_03_content_02")}
                    </div>
                </div>   
                <div className="flex w-full md:w-auto justify-center items-center text-center">
                    <Image src={ImageForSection03AndElement02} alt="" className="max-h-52 md:max-h-80 m-auto transition-all duration-normal ease-in-out"/>
                </div>                                     
            </motion.div>

            <motion.div style={{ translateX:thirdCardTranslateX, opacity:thirdCardOpacity }} className="flex flex-wrap md:flex-nowrap gap-12">
                <div className="flex w-full md:w-auto justify-center items-center text-center">
                    <Image src={ImageForSection03AndElement03} alt="" className="max-h-48 md:max-h-80 m-auto transition-all duration-normal ease-in-out"/>
                </div>
                <div className="w-full my-auto p-8 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light">
                    <div className="font-primary text-xl leading-10 mb-5 text-center md:text-start">
                        {i18n.t("home.section_03_title_03")}
                    </div>
                    <div className="text-md leading-10 lg:leading-[50px] text-center md:text-start">
                        {i18n.t("home.section_03_content_03")}
                    </div>
                </div>
            </motion.div>                
        </div>
    );
}

export default View;
