
import {Polyline} from '@react-google-maps/api';

const View = (props) =>
{
    const decodePolyline = require('decode-google-map-polyline');

    const fenceOptions =
    {
        fillColor: (props?.context?.prefs?.theme === "default" || props?.context?.prefs?.theme === "light") ? "#000000" : "#ffd400",
        fillOpacity: 0,
        strokeColor: (props?.context?.prefs?.theme === "default" || props?.context?.prefs?.theme === "light") ? "#000000" : "#ffd400",
        strokeOpacity: 1,
        strokeWeight: 3,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
    };

    return (<Polyline path={decodePolyline(props?.path)} options={fenceOptions}/>);
}

export default View;