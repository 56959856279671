import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';
import Formatter from '../../util/formatter';

const View = (props) =>
{
    const [subscriptionCode, setSubscriptionCode] = useState(props?.data?.subscription_code ?? "basic");
    const [renewalTerm, setRenewalTerm] = useState(1);
    
    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="mb-7">
                <div className="mb-2">
                    {i18n.t("subscription.current_subscription")}
                    <span className="text-accent-regular">*</span>
                </div>
                <InputDropdown
                    list={props?.subscriptionList}
                    listValue={subscriptionCode}
                    isDisabled={props?.isLoading}
                    isListSorted={false}
                    isListReversed={false}
                    isSearchable={false}
                    isCompact={false}
                    theme={props?.prefs?.theme}
                    handleValueUpdate={(value) => {setSubscriptionCode(value)}}                    
                /> 
                <AnimatePresence>
                    {props?.errors?.subscription_code && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm my-2`}
                        >
                            {props?.errors?.subscription_code}
                        </motion.div>
                    )}
                </AnimatePresence>                  
            </div>
            <div className="mb-7">
                <div className="mb-2">
                    {i18n.t("common.duration_in_months")}
                    <span className="text-accent-regular">*</span>
                </div>
                <InputDropdown
                    list={[
                        {title:'1 ' + i18n.t("common.month_singular"), value:1},
                        {title:'3 ' + i18n.t("common.month_plural_below_11"), value:3},
                        {title:'6 ' + i18n.t("common.month_plural_below_11"), value:6},
                        {title:'9 ' + i18n.t("common.month_plural_below_11"), value:9},
                        {title:'12 ' + i18n.t("common.month_plural_above_11"), value:12}
                    ]}
                    listValue={renewalTerm}
                    isDisabled={props?.isLoading}
                    isListSorted={false}
                    isListReversed={false}
                    isSearchable={false}
                    isCompact={false}
                    theme={props?.prefs?.theme}
                    handleValueUpdate={(value) => {setRenewalTerm(value)}}                    
                />
            </div>  
            <div className="flex justify-end pt-7">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={`${props?.data?.subscription_code === subscriptionCode ? i18n.t("common.renew") : i18n.t("common.upgrade")} ${(props?.isLoading) ? "" : Formatter?.stringToNumberWithCommasAndDicimals(((props?.subscriptionList?.filter((item) => item?.value?.toLowerCase() === subscriptionCode?.toLowerCase())?.[0]?.amount) ?? 0)*renewalTerm) + " " + i18n.t("currency." + props?.prefs?.currency)}`} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.next(subscriptionCode, renewalTerm)}/>
            </div>                                
        </div>
    );
}

export default View;        