import { useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';
import useMediaQuery from '../../hook/useMediaQuery';

import Image from '../component/image';

import ImageForSection07AndElement01 from '../../assets/image/section_07_01.webp';
import ImageForSection07AndElement02 from '../../assets/image/section_07_02.webp';
import ImageForSection07AndElement03 from '../../assets/image/section_07_03.webp';
import ImageForSection07AndElement04 from '../../assets/image/section_07_04.webp';
import ImageForSection07AndElement05 from '../../assets/image/section_07_05.webp';
import ImageForSection07AndElement06 from '../../assets/image/section_07_06.webp';

const View = (props) =>
{
    const isScreenXSmall  = useMediaQuery("(max-width: 400px)");
    const isScreenSmall   = useMediaQuery("(max-width: 640px)");
    const isScreenMedium  = useMediaQuery("(max-width: 768px)");
    const isScreenLarge   = useMediaQuery("(max-width: 1024px)");
    const isScreenXLarge  = useMediaQuery("(max-width: 1280px)");
    const isScreen2XLarge = useMediaQuery("(max-width: 1536px)");
    const targetRef       = useRef(null);

    const { scrollYProgress } = useScroll(
    {
        target: targetRef,
        layoutEffect: false,
        offset: ["start start", "end end"]
    });

    const scrollBarTranslateX = useTransform(scrollYProgress, [0, 0.15, 1], ["0%", "0%", `${props?.prefs?.dir === "rtl" ? "" : "-"}${isScreenXSmall ? "95%" : isScreenSmall ? "88%" : isScreenMedium ? "80%" : isScreenLarge ? "70%" : isScreenXLarge ? "70%" : isScreen2XLarge ? "60%" : "50%"}`]);

    return(
        <div id="section-7" className="font-secondary transition-colors duration-middle ease-in min-h-screen pt-24">
            <div className="relative pb-grid-xxl ps-grid-md md:ps-grid-xxl">
                <div ref={targetRef} className="w-auto h-[500vh] lg:h-[200vh]">
                    <div className="sticky top-[20%] pe-grid-lg py-grid-xxl flex gap-grid-lg justify-start">
                        <motion.div style={{translateX:scrollBarTranslateX}} className="flex gap-grid-lg justify-start">
                            
                            <div className={`flex min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="m-auto pe-grid-xxl md:px-grid-sm text-xl font-primary leading-10 md:leading-[70px]">
                                    {i18n.t("home.section_07_title_01")}
                                </div>
                            </div>   

                            <div className={`relative flex flex-col justify-between p-8 pt-6 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="p-0">
                                    <div className="mb-4">
                                        <Image src={ImageForSection07AndElement01} alt="" className="h-14 w-auto transition-all duration-normal ease-in-out"/>
                                    </div>
                                    <div className="text-lg font-primary mb-4">
                                        {i18n.t("home.section_07_title_02")}
                                    </div>
                                    <div className="leading-10">
                                        {i18n.t("home.section_07_content_02")}
                                    </div>    
                                </div>
                            </div>

                            <div className={`relative flex flex-col justify-between p-8 pt-6 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="p-0">
                                    <div className="mb-4">
                                        <Image src={ImageForSection07AndElement02} alt="" className="h-14 w-auto transition-all duration-normal ease-in-out"/>
                                    </div>
                                    <div className="text-lg font-primary mb-4">
                                        {i18n.t("home.section_07_title_03")}
                                    </div>
                                    <div className="leading-10">
                                        {i18n.t("home.section_07_content_03")}
                                    </div>    
                                </div>
                            </div>

                            <div className={`relative flex flex-col justify-between p-8 pt-6 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="p-0">
                                    <div className="mb-4">
                                        <Image src={ImageForSection07AndElement03} alt="" className="h-14 w-auto transition-all duration-normal ease-in-out"/>
                                    </div>
                                    <div className="text-lg font-primary mb-4">
                                        {i18n.t("home.section_07_title_04")}
                                    </div>
                                    <div className="leading-10">
                                        {i18n.t("home.section_07_content_04")}
                                    </div>    
                                </div>
                            </div>

                            <div className={`relative flex flex-col justify-between p-8 pt-6 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="p-0">
                                    <div className="mb-4">
                                        <Image src={ImageForSection07AndElement04} alt="" className="h-14 w-auto transition-all duration-normal ease-in-out"/>
                                    </div>
                                    <div className="text-lg font-primary mb-4">
                                        {i18n.t("home.section_07_title_05")}
                                    </div>
                                    <div className="leading-10">
                                        {i18n.t("home.section_07_content_05")}
                                    </div>    
                                </div>
                            </div>

                            <div className={`relative flex flex-col justify-between p-8 pt-6 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="p-0">
                                    <div className="mb-4">
                                        <Image src={ImageForSection07AndElement05} alt="" className="h-14 w-auto transition-all duration-normal ease-in-out"/>
                                    </div>
                                    <div className="text-lg font-primary mb-4">
                                        {i18n.t("home.section_07_title_06")}
                                    </div>
                                    <div className="leading-10">
                                        {i18n.t("home.section_07_content_06")}
                                    </div>    
                                </div>
                            </div>

                            <div className={`relative flex flex-col justify-between p-8 pt-6 bg-secondary-lightest rounded-xl shadow-2xl shadow-shadow-light min-w-[400px] min-h-[375px] max-w-[400px] max-h-[375px]`}>
                                <div className="p-0">
                                    <div className="mb-4">
                                        <Image src={ImageForSection07AndElement06} alt="" className="h-14 w-auto transition-all duration-normal ease-in-out"/>
                                    </div>
                                    <div className="text-lg font-primary mb-4">
                                        {i18n.t("home.section_07_title_07")}
                                    </div>
                                    <div className="leading-10">
                                        {i18n.t("home.section_07_content_07")}
                                    </div>    
                                </div>
                            </div>
                                                                                                                                                                                        

                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default View;
