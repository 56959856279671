import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import Image from './image';

import TickIconImage from '../../assets/vector/icon_check_light.svg';

const View = (props) =>
{
    return(
        <div className={`${props?.className} bg-primary-regular`}>
            <div  className="relative h-8">
                <div className="absolute w-[calc(100%-6px)] right-1 border-2 top-[calc(50%-2.5px)] z-0 border-secondary-darkest"/>
                <div className="absolute w-full flex justify-between z-2">
                    {[...Array(props?.total)].map((_, index) =>
                    {
                        return (
                            <motion.div key={index} className={`w-8 h-8 rounded-full border-4 border-secondary-darkest transition-all duration-normal ease-in-out flex ${(props?.current-1 >= index) ? "bg-secondary-darkest" : "bg-secondary-lightest"}`}>
                                <motion.div className={`relative flex justify-center items-center m-auto font-primary transition-all duration-normal ease-in-out text-sm ${(props?.current-1 >= index) ? "text-secondary-lightest" : "text-secondary-darkest"}`}>
                                    <AnimatePresence>
                                        {props?.current-1 > index && (
                                            <motion.div
                                                initial={{ opacity: 0, scale: 2.5 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 0.5 }}
                                                transition={{ duration: Constant?.animation?.speed?.fast/1000, ease: easeInOut }}
                                                className="flex"
                                            >
                                                <Image src={TickIconImage} alt="" className="h-[14px] w-[14px] m-auto"/>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                    <AnimatePresence>
                                        {props?.current-1 <= index && (
                                            <motion.div
                                                initial={{ opacity: 0, scale: 2.5 }}
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 0.5 }}
                                                transition={{ duration: Constant?.animation?.speed?.fast/1000, ease: easeInOut }}
                                                className="absolute flex"
                                            >
                                                <div className="m-auto">{index + 1}</div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            </motion.div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default View;
