import { useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import i18n from '../../util/i18n';

import Constant from '../../config/constant';

import Image from './image';

import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';

const View = (props) =>
{
    const selectorRef = useRef(null);
    const listRef = useRef(null);

    const [isListOpen, setListOpen] = useState(false);

    const [searchKeyword, setSearchKeyword] = useState("");

    const clickOutside = (e) =>
    {
        if(!listRef.current?.contains(e?.target) && !selectorRef.current?.contains(e?.target))
        {
            setListOpen(false);
        }
    };

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);

        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    return (
        <div className={`${props?.selectorClassName ?? "text-neutral-low-dark bg-neutral-high-lightest border-neutral-high-regular"} relative border-2 hover:border-primary-regular w-full rounded-lg transition-all duration-normal ease-in-out focus:shadow-xl focus:shadow-shadow-light ${(props?.isDisabled) ? "cursor-not-allowed" : "cursor-pointer"}`}>
            <div className="flex justify-between gap-5 px-3 py-2" onClick={() => {if(!props?.isDisabled) {setListOpen(!isListOpen);}}} ref={selectorRef}>
                <div className={(props?.isDisabled) ? `m-auto w-full opacity-50 whitespace-nowrap` : `m-auto w-full whitespace-nowrap`}>
                    {props?.list?.filter((item) => item?.value === props?.listValue)?.[0]?.title ?? props?.selectorPlaceholder?? i18n.t("common.select")}&nbsp;
                </div>
                <div className={(props?.isDisabled) ? `m-auto opacity-50 w-[16px]` : `m-auto w-[16px]`}>
                    {(props?.theme === "dark")
                    ?
                        <Image src={isListOpen ? ChevronUpLightIconImage : ChevronDownLightIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                    :
                        <Image src={isListOpen ? ChevronUpDarkIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                    }
                </div>
            </div>
            <div className={`absolute start-0 z-40 ${(props?.listWidth) ?? "w-full"}`}>
                <AnimatePresence>
                    {isListOpen &&
                    <motion.div
                        ref={listRef}
                        style={{overflow:"hidden"}}
                        initial={{ opacity: 0, height:0 }}
                        animate={{ opacity: 1, height:"auto"}}
                        exit={{ opacity: 0, height:0 }}
                        transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                        className={`${props?.listClassName ?? "bg-neutral-high-lightest w-full shadow-sm shadow-shadow-light rounded-lg mt-3 border-neutral-high-light border-[1px]"} w-full shadow-sm shadow-shadow-light rounded-lg mt-3`}
                    >  
                        <div style={(props?.isCompact) ? {height: "165px", overflow:"scroll"} : {height:"auto"}}>
                            {(props?.isListSorted)
                            ?
                                props?.list?.filter((a) => searchKeyword?.length === 0 || a?.title?.toLowerCase()?.includes(searchKeyword?.toLowerCase())).sort((a, b) => a?.title?.localeCompare(b?.title)).map((item, index) =>
                                {
                                    return (
                                        <div key={index} onClick={() => {props?.handleValueUpdate(item?.value); setListOpen(false);}} className={(index !== props?.list?.length -1) ? `${props?.itemClassName ?? "border-neutral-high-light hover:bg-neutral-high-light"} p-3 border-b-[1px] transition-all duration-normal ease-in-out` : `${props?.itemClassName ?? "border-neutral-high-light hover:bg-neutral-high-light"} px-3 py-2 transition-all duration-normal ease-in-out`}>
                                            {item?.title}
                                        </div>
                                    )
                                })
                            :
                                props?.list?.filter((a) => searchKeyword?.length === 0 || a?.title?.toLowerCase()?.includes(searchKeyword?.toLowerCase())).map((item, index) =>
                                {
                                    return (
                                        <div key={index} onClick={() => {props?.handleValueUpdate(item?.value); setListOpen(false);}} className={(index !== props?.list?.length -1) ? `${props?.itemClassName ?? "border-neutral-high-light hover:bg-neutral-high-light"} p-3 border-b-[1px] transition-all duration-normal ease-in-out` : `${props?.itemClassName ?? "border-neutral-high-light hover:bg-neutral-high-light"} px-3 py-2 transition-all duration-normal ease-in-out`}>
                                            <div className={`w-full ${(props?.isListReversed) ? "text-end" : "text-start"}`}>
                                                {item?.title}
                                            </div>
                                        </div>
                                    )
                                })                             
                            }
                        </div>

                        {(props?.isSearchable)
                        ?
                            <div className={`${props?.searchbarClassName ?? "bg-neutral-high-light"} p-3`}>
                                <input 
                                    name="custom-dropdown-text-field" 
                                    autoComplete="off" 
                                    className="bg-secondary-lightest p-2 w-full rounded-lg" 
                                    type="text" 
                                    placeholder={props?.searchInputPlaceholder}
                                    onChange={(event) => {setSearchKeyword(event?.target?.value)}}
                                    disabled={props?.isDisabled}
                                />
                            </div>
                        :
                            null
                        }
                    </motion.div>}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default View;