import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import Context from '../../util/context';
import i18n from '../../util/i18n';
import HTTP from '../../util/http';

import DashboardSettings from '../../view/page/dashboardSettings';

const Controller = props => 
{
    const [isLoading, setLoading] = useState(true);
    const [isMounted, setMounted] = useState(true);

    const [merchantInfoHasBeenLoaded, setMerchantInfoHasBeenLoaded] = useState(false);
    const [zoneListHasBeenLoaded, setZoneListHasBeenLoaded] = useState(false);
    const [tripListHasBeenLoaded, setTripListHasBeenLoaded] = useState(false);
    const [bankAccountListHasBeenLoaded, setBankAccountListHasBeenLoaded] = useState(false);

    const [merchantInfo, setMerchantInfo] = useState({});
    const [zoneList, setZoneList] = useState([]);
    const [tripList, setTripList] = useState([]);
    const [bankAccountList, setBankAccountList] = useState([]);

    const context = useContext(Context);

    const showMerchantInfoDialog = (tab) =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("MerchantInfo", i18n.t("page.merchant_account"), "bg-neutral-high-lightest", "sm", false, false, true, false, {tab:tab, merchantInfo:merchantInfo});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const showMerchantSubscriptionDialog = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("MerchantSubscription", i18n.t("page.subscription"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const showMerchantPublicDeliveryDialog = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("MerchantPublicDelivery", i18n.t("page.public_delivery"), "bg-neutral-high-lightest", "sm", false, false, true, false, {merchantInfo:merchantInfo});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const showZoneCreateDialog = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("ZoneCreate", i18n.t("page.zone_create"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    const showZoneInfoDialog = (id) =>
    {
        context?.setDialog("ZoneInfo", i18n.t("page.zone_info"), "bg-neutral-high-lightest", "sm", true, false, true, false, {id:id});
    };

    const showTripCreateDialog = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("TripInfo", i18n.t("page.trip_create"), "bg-neutral-high-lightest", "md", true, false, true, false, {});
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    const showTripInfoDialog = (id) =>
    {
        context?.setDialog("TripInfo", i18n.t("page.trip_info"), "bg-neutral-high-lightest", "md", true, false, true, false, {id:id});
    };

    const showBankAccountCreateDialog = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            context?.setDialog("BankAccountCreate", i18n.t("page.bank_account_create"), "bg-neutral-high-lightest", "sm");
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }
    };

    const showBankAccountInfoDialog = (info) =>
    {
        context?.setDialog("BankAccountInfo", i18n.t("page.bank_account_info"), "bg-neutral-high-lightest", "sm", true, false, true, false, info);
    };

    const generateKey = () =>
    {
        setLoading(true);

        isMounted && HTTP.put(Endpoint?.crm?.merchant?.apiKey).then(response =>
        {
            if(response?.is_successful)
            {
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                setTimeout(() =>
                {                
                    window.location.reload();  
                
                }, Constant.animation?.speed?.normal);                
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setLoading(false);
            }
        });        
    };

    const copy = (text) =>
    {
        navigator.clipboard.writeText(text);

        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.copy_successful"));
    };

    useEffect(() =>
    { 
        if(props?.tab?.toLowerCase() === "zone" && !zoneListHasBeenLoaded)
        {
            setLoading(true);

            let params = {};

            params["country_code"] = context?.prefs?.merchant_country_code;
            params["set_type"] = "full";
            params["type_code"] = "private";

            isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
            {
                if(response?.is_successful && response?.data?.list)
                {
                    setZoneList(response?.data?.list);
                    setZoneListHasBeenLoaded(true);
                }
    
                setLoading(false);
            });
        }
        else if(props?.tab?.toLowerCase() === "trip" && !tripListHasBeenLoaded)
        {
            setLoading(true);

            let params = {};

            params["set_type"] = "full";

            isMounted && HTTP.get(Endpoint?.oms?.trip?.list, params).then(response =>
            {
                if(response?.is_successful && response?.data?.list)
                {
                    setTripList(response?.data?.list);
                    setTripListHasBeenLoaded(true);
                }
    
                setLoading(false);
            });
        }
        else if(props?.tab?.toLowerCase() === "bank-account" && !bankAccountListHasBeenLoaded)
            {
                setLoading(true);
    
                let params = {};
    
                params["type_code"] = "merchant";
    
                isMounted && HTTP.get(Endpoint?.fms?.bankAccount?.list, params).then(response =>
                {
                    if(response?.is_successful && response?.data?.list)
                    {
                        setBankAccountList(response?.data?.list);
                        setBankAccountListHasBeenLoaded(true);
                    }
        
                    setLoading(false);
                });
            }        
        else if(!merchantInfoHasBeenLoaded)
        {
            setLoading(true);

            isMounted && HTTP.get(Endpoint?.crm?.merchant?.data).then(response =>
            {
                if(response?.is_successful && response?.data?.info)
                {
                    setMerchantInfo(response?.data?.info);
                    setMerchantInfoHasBeenLoaded(true);
                }

                setLoading(false);
            });
        }

    }, [isMounted, props?.tab, context?.prefs?.merchant_country_code, merchantInfoHasBeenLoaded, tripListHasBeenLoaded, zoneListHasBeenLoaded, bankAccountListHasBeenLoaded]);

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_settings');
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DashboardSettings
            tab={props?.tab ?? "account"}
            prefs={context?.prefs}
            isLoading={isLoading}
            merchantInfo={merchantInfo}
            zoneList={zoneList}
            tripList={tripList}
            bankAccountList={bankAccountList}
            copy={copy}
            showMerchantInfoDialog={showMerchantInfoDialog}
            showMerchantSubscriptionDialog={showMerchantSubscriptionDialog}
            showMerchantPublicDeliveryDialog={showMerchantPublicDeliveryDialog}
            showZoneCreateDialog={showZoneCreateDialog}
            showTripCreateDialog={showTripCreateDialog}
            showZoneInfoDialog={showZoneInfoDialog}
            showTripInfoDialog={showTripInfoDialog}
            showBankAccountCreateDialog={showBankAccountCreateDialog}
            showBankAccountInfoDialog={showBankAccountInfoDialog}
            generateKey={generateKey}
        />
    );
}

export default Controller;