
import { useState, useEffect } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import Image from '../component/image';
import InputDropdown from '../component/inputDropdown';
import InputText from '../component/inputText';

import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';
import TrashDarkIconImage from '../../assets/vector/icon_trash_dark.svg';
import TrashLightIconImage from '../../assets/vector/icon_trash_light.svg';
import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';

import Button from '../component/button';

const View = (props) =>
{
    const [viewDelayRate, setViewDelayRate] = useState(Constant?.animation?.speed?.fast/1000);
    const [data, setData] = useState(props?.data ?? {});
    const [errors, setErrors] = useState([]);
    const [removedRules, removeRule] = useState([]);
    const [openedSections, openSection] = useState([]);
    const sections = [
        {
            title:i18n.t("trip.commission_based_on_time_and_is_successful"),
            key:"time_successful"
        },
        {
            title:i18n.t("trip.commission_based_on_time_and_is_unsuccessful"),
            key:"time_failed"
        },
        {
            title:i18n.t("trip.commission_based_on_distance_and_is_successful"),
            key:"distance_successful"
        },
        {
            title:i18n.t("trip.commission_based_on_distance_and_is_unsuccessful"),
            key:"distance_failed"
        }
    ];

    const toggleSection = (key) =>
    {
        let clone = [...openedSections];

        let index = openedSections?.indexOf(key);

        if(index !== -1)
        {
            clone?.splice(index, 1);
        }
        else
        {
            clone?.push(key);
        }

        openSection(clone);
    };

    const handleAdd = (key) =>
    {
        let clone = {...data};

        const keys = key?.split('_');

        clone[keys?.[0]][keys?.[1]].push({
            from:undefined,
            to:undefined,
            amount:undefined,
            period:"Infinity",
            _id:"temp_" + (Math.floor(Date.now() / 1000)) + "" + (Math.floor(100000 + Math.random() * 900000))
        });

        setData(clone);
    };

    const handleValueChange = (key, index, value) =>
    {
        let clone = {...data};
        
        const keys = key?.split('_');
        
        clone[keys?.[0]][keys?.[1]][index][keys?.[2]] = value;

        if(keys?.[2] === "period")
        {
            clone[keys?.[0]][keys?.[1]][index]["to"] = undefined;
        }
        
        setData(clone);
    };

    const handleRemove = (key, index) =>
    {
        const keys = key?.split('_');
        
        if(!removedRules?.includes(data[keys?.[0]]?.[keys?.[1]]?.[index]?._id))
        {
            removeRule([...removedRules, ...[data[keys?.[0]]?.[keys?.[1]]?.[index]?._id]]);
        }
    };

    const progress = (direction) =>
    {
        let clone = {};
        let newErrors = {};

        if(props?.commissionTypeCode === "time" || props?.commissionTypeCode === "distance_and_time")
        {
            if(!data?.["time"]?.["successful"] || !data?.["time"]?.["failed"] || data?.["time"]?.["successful"]?.length === 0 || data?.["time"]?.["failed"]?.length === 0)
            {
                newErrors["commission_overall"] = i18n.t("error.invalid_time_commission");
            }
        }

        if(props?.commissionTypeCode === "distance" || props?.commissionTypeCode === "distance_and_time")
        {
            if(!data?.["distance"]?.["successful"] || !data?.["distance"]?.["failed"] || data?.["distance"]?.["successful"]?.length === 0 || data?.["distance"]?.["failed"]?.length === 0)
            {
                newErrors["commission_overall"] = i18n.t("error.invalid_distance_commission");
            }
        }

        Object.keys(data)?.forEach(key0 =>
        {
            clone[key0] = {};

            Object.keys(data?.[key0])?.forEach(key1 =>
            {
                clone[key0][key1] = [];

                Object.keys(data?.[key0]?.[key1])?.forEach(key2 =>
                {
                    if(!removedRules.includes(data?.[key0]?.[key1]?.[key2]?.["_id"]))
                    {
                        let tempRule = {};

                        if(data?.[key0]?.[key1]?.[key2]?.from === undefined || data?.[key0]?.[key1]?.[key2]?.from === null || data?.[key0]?.[key1]?.[key2]?.from === "" || data?.[key0]?.[key1]?.[key2]?.from?.length < 0)
                        {
                            newErrors[key0 + "_" + key1] = i18n.t("error.all_fields_are_required");
                        }
                        else
                        {
                            tempRule["from"] = parseFloat(data?.[key0]?.[key1]?.[key2]?.["from"]);
                        }

                        if(!data?.[key0]?.[key1]?.[key2]?.to || data?.[key0]?.[key1]?.[key2]?.to === "")
                        {
                            tempRule["to"] = "Infinity";
                        }
                        else
                        {
                            tempRule["to"] = parseFloat(data?.[key0]?.[key1]?.[key2]["to"]);
                        }

                        if(data?.[key0]?.[key1]?.[key2]?.amount === undefined || data?.[key0]?.[key1]?.[key2]?.amount === null || data?.[key0]?.[key1]?.[key2]?.amount === "" || data?.[key0]?.[key1]?.[key2]?.amount?.length < 0)
                        {
                            newErrors[key0 + "_" + key1] = i18n.t("error.all_fields_are_required");
                        }                        
                        else
                        {
                            tempRule["amount"] = parseFloat(data?.[key0]?.[key1]?.[key2]?.["amount"]);
                        }

                        clone[key0][key1].push(tempRule);
                    }
                });
            });
        }); 
        
        if(Object.keys(newErrors)?.length > 0)
        {
            setErrors(newErrors);
            
            props?.showErrorNotification(newErrors["commission_overall"] ?? i18n.t("error.some_fields_are_missing"));

            props?.scrollToTop();
        }
        else
        {            
            setErrors([]);

            if(direction === "prev")
            {
               props?.prev(clone);
            }
            else if(direction === "next")
            {
                props?.next(clone);
            }
        }
    };

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setViewDelayRate(0);

        }, Constant?.animation?.speed?.slow);

        let clone = [];

        if(props?.data?.time?.successful && props?.data?.time?.successful?.length > 0)
        {
            clone.push("time_successful");
        }

        if(props?.data?.time?.failed && props?.data?.time?.failed?.length > 0)
        {
            clone.push("time_failed");
        }
    
        if(props?.data?.distance?.successful && props?.data?.distance?.successful?.length > 0)
        {
            clone.push("distance_successful");
        }
        
        if(props?.data?.distance?.failed && props?.data?.distance?.failed?.length > 0)
        {
            clone.push("distance_failed");
        }

        openSection(clone)

        return () => clearTimeout(timer);

    }, [props?.data?.time?.successful, props?.data?.time?.failed, props?.data?.distance?.successful, props?.data?.distance?.failed]);
    
    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
                {i18n.t("trip.tip_05")}
            </div>
            {sections?.map((section, index) =>
                {
                    return (
                        <div key={index} className="border-[1px] border-neutral-high-dark rounded-lg mb-7 shadow-sm">
                            <div className={`flex justify-between p-4 cursor-pointer transition duration-normal ease-in-out font-bold hover:bg-neutral-high-light ${openedSections?.includes(section?.key) ? "rounded-t-lg border-b-[1px] border-neutral-high-dark" : "rounded-lg"}`} onClick={() => toggleSection(section?.key)}>
                                <div>
                                {section?.title}
                                </div>
                                <div className="my-auto">
                                    {openedSections?.includes(section?.key)
                                    ?
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronUpLightIconImage : ChevronUpDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    :
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronDownLightIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    }
                                </div>
                            </div>
                            <AnimatePresence>
                                {openedSections?.includes(section?.key) && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ delay: viewDelayRate, duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    >
                                        <div className="w-full p-4">
                                            <table className='w-full'>
                                                <tbody>
                                                    {(data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.filter(item => (!removedRules?.includes(item?._id)))?.length > 0)
                                                    ?
                                                        <>
                                                            {data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.map((rule, index) =>
                                                            {
                                                                return (
                                                                    (!removedRules?.includes(rule?._id))
                                                                    ?
                                                                        <tr key={index}>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                {i18n.t("trip.commission_rule_from")}
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap min-w-[60px]">
                                                                                <InputText 
                                                                                    type={"text"}
                                                                                    enableAutoComplete={false}
                                                                                    isDisabled={props?.isLoading}
                                                                                    defaultValue={rule?.from}
                                                                                    onChange={(e) => handleValueChange(`${section.key}_from`, index, e?.target?.value)}
                                                                                /> 
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                <InputDropdown
                                                                                    list={[{title:section?.key?.split('_')?.[0] === "distance" ? i18n.t("distance.km") : i18n.t("time.hour"), value:""}]}
                                                                                    listValue={""}
                                                                                    isDisabled={true}
                                                                                    isListSorted={false}
                                                                                    isListReversed={false}
                                                                                    isSearchable={false}
                                                                                    isCompact={false}
                                                                                    theme={props?.prefs?.theme}
                                                                                    handleValueUpdate={{}}
                                                                                /> 
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                {i18n.t("trip.commission_rule_to")}
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                <div className="flex gap-2">

                                                                                        {(data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.[index]?.period === "fixed")
                                                                                        ?
                                                                                            <div className="w-[80px]">
                                                                                                <InputText 
                                                                                                    type={"text"}
                                                                                                    enableAutoComplete={false}
                                                                                                    isDisabled={props?.isLoading}
                                                                                                    defaultValue={rule?.to}
                                                                                                    onChange={(e) => handleValueChange(`${section.key}_to`, index, e?.target?.value)}
                                                                                                /> 
                                                                                            </div>
                                                                                        :
                                                                                            null
                                                                                        }
                                                                                        <div className={(data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.[index]?.period === "fixed") ? "w-[120px]" : "w-full"}>
                                                                                            <InputDropdown
                                                                                                list={[
                                                                                                        {title:section?.key?.split('_')?.[0] === "distance" ? i18n.t("distance.km") : i18n.t("time.hour"), value:"fixed"}, 
                                                                                                        {title:i18n.t("time.infinity"), value:"Infinity"}
                                                                                                    ]}
                                                                                                listValue={(data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.[index]?.period) ? data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.[index]?.period : (data?.[section?.key?.split('_')?.[0]]?.[section?.key?.split('_')?.[1]]?.[index]?.to) ? "fixed" : "Infinity"}
                                                                                                isDisabled={props?.isLoading}
                                                                                                isListSorted={false}
                                                                                                isListReversed={false}
                                                                                                isSearchable={false}
                                                                                                isCompact={false}
                                                                                                theme={props?.prefs?.theme}
                                                                                                handleValueUpdate={(value) => handleValueChange(`${section.key}_period`, index, value)}
                                                                                            />                                                           
                                                                                        </div>                                                                            
                                                                                </div>
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                {i18n.t("trip.commission_rule_captain_gets")}
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap min-w-[60px]">
                                                                                <InputText 
                                                                                    type={"text"}
                                                                                    enableAutoComplete={false}
                                                                                    isDisabled={props?.isLoading}
                                                                                    defaultValue={rule?.amount}
                                                                                    onChange={(e) => handleValueChange(`${section.key}_amount`, index, e?.target?.value)}
                                                                                />
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                {i18n.t("currency." + props?.prefs?.currency)}
                                                                            </td>
                                                                            <td className="my-auto py-2 px-1 whitespace-nowrap">
                                                                                <Button 
                                                                                    type="neutral-low-outline" 
                                                                                    className="min-w-[50px]" 
                                                                                    icon={(props?.prefs?.theme === "dark") ? TrashLightIconImage : TrashDarkIconImage}
                                                                                    iconHover={(props?.prefs?.theme === "dark") ? TrashDarkIconImage : TrashLightIconImage}
                                                                                    isLoading={props?.isLoading}  
                                                                                    isDisabled={props?.isLoading} 
                                                                                    onClick={() => handleRemove(section.key, index)}
                                                                                />                                                                    
                                                                            </td>
                                                                        </tr>
                                                                    :
                                                                        null
                                                                )
                                                            })}
                                                            <tr>
                                                                <td colSpan={9} className='w-full pt-4'>                                                                        
                                                                    <AnimatePresence>
                                                                        {errors?.[`${section?.key}`] && (
                                                                            <motion.div 
                                                                                initial={{ height:0, opacity:0 }}
                                                                                animate={{ height: "auto", opacity:1 }}
                                                                                exit={{ height:0, opacity:0 }}
                                                                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                                                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                                                            >
                                                                                {errors?.[`${section?.key}`]}
                                                                            </motion.div>
                                                                        )}
                                                                    </AnimatePresence>                                                          
                                                                </td>
                                                            </tr>                                                                
                                                        </>
                                                    :
                                                        <tr>
                                                            <td colSpan={9} className='w-full'>
                                                                <div className="w-full text-center text-neutral-low-light">
                                                                    {i18n.t("trip.no_commission_rules")}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex justify-end mx-4 mb-4 pt-4 border-t-[1px] border-neutral-high-dark">
                                            <Button 
                                                type="neutral-low-solid" 
                                                className="min-w-[50px]" 
                                                icon={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                                                iconHover={(props?.prefs?.theme === "dark") ? AddLightIconImage : AddDarkIconImage}
                                                isLoading={props?.isLoading}  
                                                isDisabled={props?.isLoading} 
                                                onClick={() => handleAdd(section?.key)}
                                            />
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>                            
                        </div>
                    )
                })
            }  
            <div className="flex justify-between">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => progress("prev")}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => progress("next")}/>
            </div>                                                
        </div>
    );
}

export default View;
