import i18n from '../../util/i18n';

import Header from '../component/header';
import Footer from '../component/footer';
import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';

const View = (props) =>
{
    return( 
        <div className="bg-primary-regular flex flex-col h-screen justify-between">
            <Header
                changeLang={props?.changeLang}
                prefs={props?.prefs}
                isHome={false}
                isFixed={false}
                isMinimal={true}
            />
            <div className="w-[350px] flex flex-col gap-grid-md mx-auto">
                <InputDropdown
                    selectorClassName={"bg-secondary-light py-1 mb-2 border-secondary-light shadow-2xl shadow-shadow-dark"}
                    listClassName={"bg-secondary-lightest"}
                    itemClassName={"border-secondary-light hover:bg-secondary-light py-3"}
                    searchbarClassName={"bg-secondary-light"}
                    list={props?.list}
                    selectorPlaceholder={i18n.t("form.select_merchant_account")}
                    searchInputPlaceholder={i18n.t("common.search_generic")}
                    isDisabled={props?.isLoading}
                    isListSorted={true}
                    isListReversed={false}
                    isSearchable={props?.list?.length > 3}
                    isCompact={false}
                    handleValueUpdate={(value) => {props?.requestAccess(value)}}
                /> 
                <Button type="secondary-solid" className="w-[100%] p-1" text={"+ " + i18n.t("form.create_merchant_account")} isLoading={props?.isLoading} onClick={props?.createMerchantAccount}/>
            </div>
            <Footer
                prefs={props?.prefs}
                isMinimal={true}
            />
        </div>
    );
}

export default View;
