const SkeltonView = (props) =>
{
    return (
        <div>
            {
                [...Array(props?.count ?? 1)].map(function(item, i)
                {
                    return <div key={i} className={`skeleton skeleton-animation rounded-full ${props?.className}`}/>;
                }) 
            }                  
        </div>
    )
}

export default SkeltonView;