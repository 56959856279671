import { useContext } from 'react';

import Context from '../../util/context';
import i18n from '../../util/i18n';

import UserOnboarding from '../../view/dialog/userOnboarding';

const Dialog = props => 
{
    const context = useContext(Context);

    const onboardMerchant = () =>
    {
        context?.setDialog("Signup", i18n.t("page.signup_as_merchant"), "bg-secondary-lightest", "sm", false, false, false, false, {persona:"owner", subscription_code:"basic", merchant_code:null, invitation_code:null, email:null, action:"create_merchant_account"});
    };

    const onboardCaptain = () =>
    {
        context?.setDialog("Signup", i18n.t("page.signup_as_captain"), "bg-secondary-lightest", "sm", false, false, false, false, {persona:"captain", subscription_code:null, merchant_code:null, invitation_code:null, email:null, action:"create_captain_profile"});
    };

    return (
        <UserOnboarding
            onboardMerchant={onboardMerchant}
            onboardCaptain={onboardCaptain}
        />
    );
}

export default Dialog;