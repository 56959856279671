import { useId } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

const View = (props) =>
{
    const randomID = useId();

    return(
        <div className={`${props?.containerClassName} w-full`}>
            {(props?.label) ? <div className={`${props?.labelClassName ?? "text-neutral-low-dark"} mb-2 `}>{props?.label}{(props?.isRequired) ? <span className="text-accent-regular">*</span> : null}</div> : null}
            <input
                id={`text_` + randomID}
                type={props?.type}
                maxLength={props?.maxLength ?? ""}
                disabled={props?.isDisabled}
                placeholder={props?.placeholder}
                autoComplete={props?.enableAutoComplete ? "true" : "new-password"} 
                className={`${props?.inputClassName ?? "text-neutral-low-dark bg-neutral-high-lightest border-neutral-high-regular"} ${props?.isDisabled ? "cursor-not-allowed opacity-50 bg-neutral-high-regular" : ""} ${props?.isFullyRounded ? "rounded-full" : "rounded-lg"} w-full font-secondary px-3 py-2 outline-none transition-all duration-normal ease-in-out border-2 focus:border-primary-regular focus:shadow-xl focus:shadow-shadow-light"`}
                defaultValue={props?.defaultValue ?? undefined}
                value={props?.value ?? undefined}
                ref={props?.reference ?? undefined}
                onChange={(e) => {if(props?.onChange) {props?.onChange(e)}}}
                onPaste={(e) => {if(props?.onPaste) {props?.onPaste(e)}}}
                onKeyDown={(e) => {if(props?.onKeyDown) {props?.onKeyDown(e)}}}
            />
            <AnimatePresence>
            {props?.errorMsg && (
                <motion.div 
                    initial={{ height:0, opacity:0 }}
                    animate={{ height: "auto", opacity:1 }}
                    exit={{ height:0, opacity:0 }}
                    transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                    className={`${props?.errorClassName ?? "text-status-danger-regular"} font-secondary mt-2`}
                >
                    {props?.errorMsg}
                </motion.div>
            )}
            </AnimatePresence>
        </div>
    );
}

export default View;
