import { useEffect, useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import { useNavigate, NavLink } from "react-router-dom";
import { Link } from 'react-scroll';

import i18n from '../../util/i18n';
import Constant from '../../config/constant';

import Image from './image';
import Button from './button';

import LogoArImage from '../../assets/image/logo_horizontal_dark_ar.webp';
import LogoEnImage from '../../assets/image/logo_horizontal_dark_en.webp';
import HamburgerIconImage from '../../assets/vector/icon_hamburger_dark.svg';
import CloseIconImage from '../../assets/vector/icon_close_dark.svg';

const View = (props) =>
{
    const navigate = useNavigate();

    const [scrollPosition, setScrollPosition]    = useState(0);
    const [isNavMenuCollaposed, collapseNavMenu] = useState(true);

    const handleScroll = () =>
    {
        setScrollPosition(window.scrollY);
    };

    useEffect(() =>
    {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () =>
        {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return(
        <div className={`${(props?.isFixed ? "fixed " : "")}w-full bg-primary-regular transition duration-normal ease-in-out ${(scrollPosition > 50 || !isNavMenuCollaposed) ? " bg-primary-regular shadow-[0_20px_20px_1px_rgba(var(--color-secondary-darkest),20%)]" : ""}`} style={{zIndex:9}}>
            <div className="flex flex-row justify-between lg:container lg:max-w-screen-lg lg:mx-auto py-5 px-10">
                <div className="my-auto">

                    {props?.isHome
                    ?
                        <Link to="section-1" spy={true} smooth={true} offset={-100} duration={Constant?.animation?.speed?.normal} onClick={() => {collapseNavMenu(true)}}>
                            <Image src={props?.prefs?.lang === "ar" ? LogoArImage : LogoEnImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                        </Link>
                    :
                        <NavLink to="/">
                            <Image src={props?.prefs?.lang === "ar" ? LogoArImage : LogoEnImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                        </NavLink>
                    }
                </div>

                {(!props?.isMinimal)
                ?
                    <div className="my-auto hidden lg:block">
                        <div className="flex gap-12">
                            <div className="my-auto">
                                <Button type="naked" text={i18n.t("page.signup_as_captain")} onClick={() => {collapseNavMenu(true); props?.showCaptainSignUpDialog();}}/>
                            </div>
                            <div className="my-auto">
                                <Button type="naked" text={i18n.t("page.signup_as_merchant")} onClick={() => {collapseNavMenu(true); props?.showMerchantSignUpDialog();}}/>
                            </div>
                            <div className="my-auto">
                                    {props?.prefs?.is_logged_in !== "1"
                                    ?
                                        <Button type="naked" text={i18n.t("page.dashboard")} onClick={props?.showLoginDialog}/>
                                    :
                                        <NavLink to="/directory">
                                            <Button type="naked" text={i18n.t("page.dashboard")} onClick={() => {}}/>
                                        </NavLink>
                                    }
                            </div>                            
                            <div className="my-auto">
                                <Button type="naked" text={i18n.t(`lang.${props?.prefs?.lang === "ar" ? "en" : "ar"}`)} onClick={props?.changeLang}/>
                            </div>
                            <div className="my-auto">
                                <Button type="secondary-solid" text={i18n.t("home.start_now")} onClick={() => {collapseNavMenu(true); props?.showMerchantSignUpDialog();}} className="px-[30px]"/>
                            </div>
                        </div>
                    </div>
                :
                    null
                }

                {(!props?.isMinimal)
                ?
                    <div className="my-auto lg:hidden py-[9px]">   
                        {(isNavMenuCollaposed)
                        ?
                            <div className="opacity-25 hover:opacity-10" onClick={() => {collapseNavMenu(false)}}>
                                <Image src={HamburgerIconImage} alt="" className="h-[30px] w-[30px] cursor-pointer transition duration-normal ease-in-out"/>
                            </div>
                        :
                            <div className="opacity-25 hover:opacity-10 cursor-pointer transition duration-normal ease-in-out" onClick={() => {collapseNavMenu(true)}}>
                                <Image src={CloseIconImage} alt="" className="h-[30px] w-[30px] cursor-pointer transition duration-normal ease-in-out"/>
                            </div>
                        }
                    </div>
                :
                    null
                }

                {(props?.isMinimal)
                ?
                    <div className="my-auto">
                        <Button type="naked" text={i18n.t(`lang.${props?.prefs?.lang === "ar" ? "en" : "ar"}`)} onClick={props?.changeLang}/>
                    </div>
                :
                    null
                }
            </div>
            <AnimatePresence >
                {!isNavMenuCollaposed && (
                    <motion.div
                        style={{ overflow: "hidden" }}
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: Constant?.animation?.speed?.fast/1000, ease: easeInOut }}
                        className="bg-primary-regular lg:hidden"
                    >
                        <div className="p-5">
                            <div className="p-4 border-b-2 border-primary-dark hover:bg-primary-light transition duration-normal ease-in-out" onClick={() => {collapseNavMenu(true); props?.showCaptainSignUpDialog();}}>
                                {i18n.t("page.signup_as_captain")}
                            </div>
                            <div className="p-4 border-b-2 border-primary-dark hover:bg-primary-light transition duration-normal ease-in-out" onClick={() => {collapseNavMenu(true); props?.showMerchantSignUpDialog();}}>
                                {i18n.t("page.signup_as_merchant")}
                            </div>  

                            {(props?.prefs?.is_logged_in !== "1")
                            ?
                                <div className="p-4 border-b-2 border-primary-dark hover:bg-primary-light transition duration-normal ease-in-out" onClick={() => {collapseNavMenu(true); props?.showLoginDialog();}} >
                                    {i18n.t(`page.dashboard`)}
                                </div>
                            :
                                <div className="p-4 border-b-2 border-primary-dark hover:bg-primary-light transition duration-normal ease-in-out" onClick={() => {collapseNavMenu(true); navigate("/directory")}}>
                                    {i18n.t("page.dashboard")}
                                </div>
                            }

                            <div className="p-4 hover:bg-primary-light transition duration-normal ease-in-out" onClick={() => {collapseNavMenu(true); props?.changeLang();}}>
                                {i18n.t(`lang.${props?.prefs?.lang === "ar" ? "en" : "ar"}`)}
                            </div>                                                       
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default View;
