import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import Stepper from '../../view/component/stepper';
import TripInfo01 from '../../view/dialog/tripInfo01';
import TripInfo02 from '../../view/dialog/tripInfo02';
import TripInfo03 from '../../view/dialog/tripInfo03';
import TripInfo04 from '../../view/dialog/tripInfo04';
import TripInfo05 from '../../view/dialog/tripInfo05';
import TripInfo06 from '../../view/dialog/tripInfo06';

const Dialog = props => 
{
    const totalSteps = 6;
    const [currentStep, setCurrentStep] = useState(1);
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [tripInfo, setTripInfo] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [pricingData, setPricingData] = useState({});
    const [errors, setErrors] = useState({});
    const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState(false);

    const context = useContext(Context);

    const scrollToTop = () =>
    {
        props?.scrollToTop();
    };

    const showErrorNotification = (msg) =>
    {
        context?.setNotification("error", i18n.t("error.title"), msg);
    };

    const prev = (newData) =>
    {
        props?.scrollToTop();

        setTripInfo({...tripInfo, ...newData});

        if(currentStep > 1)
        {
            setCurrentStep(currentStep-1);
        }
    };

    const next = (newData) =>
    {
        props?.scrollToTop();

        if(currentStep === 1)
        {
            setTripInfo({...tripInfo, ...newData});

            processStep1({...tripInfo, ...newData});
        }
        else if(currentStep === 2)
        {
            setTripInfo({...tripInfo, ...{"checkpoint": newData}});

            processStep2({...tripInfo, ...{"checkpoint": newData}});
        }
        else if(currentStep === 3)
        {
            setTripInfo({...tripInfo, ...{"sla": newData}});

            processStep3({...tripInfo, ...{"sla": newData}});
        }
        else if(currentStep === 4)
        {
            setTripInfo({...tripInfo, ...{"checklist": newData}});

            processStep4({...tripInfo, ...{"checklist": newData}});
        }
        else if(currentStep === 5)
        {
            setTripInfo({...tripInfo, ...{"commission": newData}});

            processStep5({...tripInfo, ...{"commission": newData}});
        }
        else if(currentStep === 6)
        {
            processStep6();
        }
    };

    const processStep1 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(!Validator.notEmpty(stepData?.name_ar))
        {
            newErrors["name_ar"] = i18n.t('error.invalid_name_ar');            
        }

        if(!Validator.notEmpty(stepData?.name_en))
        {
            newErrors["name_en"] = i18n.t('error.invalid_name_en');
        }
        
        if(!Validator.notEmpty(stepData?.auto_assignment_type_code))
        {
            newErrors["auto_assignment_type_code"] = i18n.t('error.invalid_auto_assignment_type_code');
        }    
        
        if(!Validator.notEmpty(stepData?.commission_type_code))
        {
            newErrors["commission_type_code"] = i18n.t('error.invalid_commission_type_code');            
        }

        if(!Validator.notEmpty(stepData?.survey_code))
        {
            newErrors["survey_code"] = i18n.t('error.invalid_survey_code');            
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            params["name_ar"] = stepData?.name_ar;
            params["name_en"] = stepData?.name_en;
            params["auto_assignment_type_code"] = stepData?.auto_assignment_type_code;
            params["commission_type_code"] = stepData?.commission_type_code;
            params["survey_code"] = stepData?.survey_code;

            if(props?.dialog?.metadata?.id)
            {
                params["trip_id"] = props?.dialog?.metadata?.id;

                isMounted && HTTP.put(Endpoint?.oms?.trip?.data, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        setShouldRefreshOnClose(true);

                        setCurrentStep(currentStep+1);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                    }
    
                    setLoading(false);
                });   
            }
            else
            {                
                isMounted && HTTP.post(Endpoint?.oms?.trip?.data, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        setShouldRefreshOnClose(true);

                        params["checkpoint"] = [];
                        params["sla"] = [];
                        params["checklist"] = [];
                        params["commission"] = {time:{successful:[], failed:[]}, distance:{successful:[], failed:[]}};

                        setTripInfo({...tripInfo, ...params, ...{"trip_id":response?.data?.info?.trip_id}});

                        setCurrentStep(currentStep+1);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                    }
    
                    setLoading(false);
                });             
            }
        }
        else
        {
            setErrors(newErrors);
        }
    };
    
    const processStep2 = (stepData) =>
    {
        let params = {};

        params["trip_id"] = props?.dialog?.metadata?.id ?? tripInfo?.trip_id;

        try
        {
            params["structure"] = JSON.stringify(stepData?.checkpoint);
        }
        catch (error){}

        setLoading(true);

        isMounted && HTTP.post(Endpoint?.oms?.trip?.checkpoint, params).then(response =>
        {
            if(response?.is_successful)
            {
                setShouldRefreshOnClose(true);

                setCurrentStep(currentStep+1);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setLoading(false);
        });
    };

    const processStep3 = (stepData) =>
    {
        let params = {};

        params["trip_id"] = props?.dialog?.metadata?.id ?? tripInfo?.trip_id;

        try
        {
            params["structure"] = JSON.stringify(stepData?.sla);
        }
        catch (error){}

        setLoading(true);

        isMounted && HTTP.post(Endpoint?.oms?.trip?.sla, params).then(response =>
        {
            if(response?.is_successful)
            {
                setShouldRefreshOnClose(true);

                setCurrentStep(currentStep+1);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setLoading(false);
        });
    };
        
    const processStep4 = (stepData) =>
    {
        let params = {};

        params["trip_id"] = props?.dialog?.metadata?.id ?? tripInfo?.trip_id;

        try
        {
            params["structure"] = JSON.stringify(stepData?.checklist);
        }
        catch (error){}

        setLoading(true);

        isMounted && HTTP.post(Endpoint?.oms?.trip?.checklist, params).then(response =>
        {
            if(response?.is_successful)
            {
                setShouldRefreshOnClose(true);

                setCurrentStep(currentStep+1);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setLoading(false);
        });
    };
         
    const processStep5 = (stepData) =>
    {
        let params = {};

        params["trip_id"] = props?.dialog?.metadata?.id ?? tripInfo?.trip_id;

        try
        {
            params["structure"] = JSON.stringify(stepData?.commission);
        }
        catch (error){}

        setLoading(true);

        isMounted && HTTP.post(Endpoint?.oms?.trip?.commission, params).then(response =>
        {
            if(response?.is_successful)
            {
                let pricing = {};

                isMounted && HTTP.get(Endpoint?.crm?.merchant?.data, {pricing_code:"delivery"}).then(response =>
                {
                    pricing["subscription_code"] = response?.data?.info?.subscription?.subscription_code
                    pricing["base_fee"] = response?.data?.info?.subscription?.private_delivery_fee

                    isMounted && HTTP.get(Endpoint?.cms?.pricing?.data, {pricing_code:"delivery"}).then(response =>
                    {
                        setPricingData({...pricing, ...response?.data?.info});
    
                        setShouldRefreshOnClose(true);
    
                        setCurrentStep(currentStep+1);
    
                        setLoading(false);
                    });
                }); 
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setLoading(false);
            }
        });
    };

    const processStep6 = () =>
    {
        let params = {};

        params["trip_id"] = props?.dialog?.metadata?.id ?? tripInfo?.trip_id;
        params["status_code"] = "published";

        setLoading(true);

        isMounted && HTTP.put(Endpoint?.oms?.trip?.status, params).then(response =>
        {
            if(response?.is_successful)
            {
                context?.setDialog(null);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setLoading(false);
        });
    };

    const remove = () =>
    {
        let params = {};

        params["trip_id"] = props?.dialog?.metadata?.id;

        setLoading(true);

        isMounted && HTTP.delete(Endpoint?.oms?.trip?.data, params).then(response =>
        {
            if(response?.is_successful)
            {
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                setTimeout(() =>
                {
                    context?.setDialog(null);   
                
                    window.location.reload();  
                
                }, Constant.animation?.speed?.normal);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setLoading(false);
            }
        });
    };

    useEffect(() =>
    {
        if(props?.dialog?.metadata?.id)
        {
            setLoading(true);

            let data = {};
    
            data["trip_id"] = props?.dialog?.metadata?.id;
    
            isMounted && HTTP.get(Endpoint?.oms?.trip?.data, data).then(response =>
            {
                if(response?.is_successful && response?.data?.info)
                {                    
                    Object.keys(response?.data?.info?.["commission"])?.forEach(key0 =>
                    {
                        Object.keys(response?.data?.info?.["commission"]?.[key0])?.forEach(key1 =>
                        {
                            Object.keys(response?.data?.info?.["commission"]?.[key0]?.[key1])?.forEach(key2 =>
                            {
                                response.data.info["commission"][key0][key1][key2]["_id"]    = "temp_" + (Math.floor(Date.now() / 1000)) + "" + (Math.floor(100000 + Math.random() * 900000));
                                response.data.info["commission"][key0][key1][key2]["period"] = "fixed";

                                if(response?.data?.info?.["commission"]?.[key0]?.[key1]?.[key2]?.["to"] === "Infinity")
                                {
                                    response.data.info["commission"][key0][key1][key2]["to"] = undefined;
                                    response.data.info["commission"][key0][key1][key2]["period"] = "Infinity";
                                }
                            });
                        });
                    });

                    setTripInfo(response.data.info);
                }
    
                isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
                {
                    setCountryList(response?.data?.list);
                    
                    setLoading(false);
                });
            });
        }

    }, [isMounted, context?.prefs?.lang, props?.dialog?.metadata?.id]);

    useEffect(() =>
    {
        return () =>
        {
            if(shouldRefreshOnClose)
            {
                window.location.reload();            
            }
        };

    }, [shouldRefreshOnClose]); 

    useEffect(() =>
    {
        return () =>
        {            
            setMounted(false);
        };

    }, []);        

    return (
        <>
            <Stepper
                total={totalSteps}
                current={currentStep}
                className="px-7 pb-7"
            />
            {(currentStep === 1)
            ?
                <TripInfo01
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    errors={errors}
                    prefs={context?.prefs}
                    data={tripInfo}
                    isDeletable={(props?.dialog?.metadata?.id) ? true : false}
                    scrollToTop={scrollToTop}
                    showErrorNotification={showErrorNotification}
                    remove={remove}
                    next={next}
                />                
            :
            (currentStep === 2)
            ?
                <TripInfo02
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    errors={errors}
                    prefs={context?.prefs}
                    data={tripInfo?.checkpoint}
                    scrollToTop={scrollToTop}
                    showErrorNotification={showErrorNotification}
                    prev={prev}
                    next={next}
                />  
            :
            (currentStep === 3)
            ?                
                <TripInfo03
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    errors={errors}
                    prefs={context?.prefs}
                    data={tripInfo?.sla}
                    countryList={countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
                    scrollToTop={scrollToTop}
                    showErrorNotification={showErrorNotification}
                    prev={prev}
                    next={next}
                />
            :
            (currentStep === 4)
            ?                
                <TripInfo04
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    errors={errors}
                    prefs={context?.prefs}
                    data={tripInfo?.checklist}
                    scrollToTop={scrollToTop}
                    showErrorNotification={showErrorNotification}
                    prev={prev}
                    next={next}
                />
            :
            (currentStep === 5)
            ?                
                <TripInfo05
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    errors={errors}
                    prefs={context?.prefs}
                    data={tripInfo?.commission}
                    commissionTypeCode={tripInfo?.commission_type_code}
                    scrollToTop={scrollToTop}
                    showErrorNotification={showErrorNotification}
                    prev={prev}
                    next={next}
                />
            :
            (currentStep === 6)
            ?                
                <TripInfo06
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    errors={errors}
                    prefs={context?.prefs}
                    data={tripInfo}
                    pricingData={pricingData}
                    scrollToTop={scrollToTop}
                    showErrorNotification={showErrorNotification}
                    prev={prev}
                    next={next}
                />
            :
                null
            }
        </>
    );
}

export default Dialog;