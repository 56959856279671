import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';

import Image from '../component/image';

import ImageForSection02AndElement01 from '../../assets/image/section_02_01.webp';
import ImageForSection02AndElement02 from '../../assets/image/section_02_02.webp';
import ImageForSection02AndElement03 from '../../assets/image/section_02_03.webp';
import ImageForSection02AndElement04 from '../../assets/image/section_02_04.webp';
import ImageForSection02AndElement05 from '../../assets/image/section_02_05.webp';
import ImageForSection02AndElement06 from '../../assets/image/section_02_06.webp';
import ImageForSection02AndElement07 from '../../assets/image/section_02_07.webp';


const View = (props) =>
{
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll(
    {
        target: targetRef,
        offset: ["start end", "end start"]
    });

    const astronautScale      = useTransform(scrollYProgress, [0, 0.3], [0.8, 1]);
    const astronautTranslateY = useTransform(scrollYProgress, [0, 0.3], [100, 0]);
    const astronautOpacity    = useTransform(scrollYProgress, [0, 0.3], [0, 1]);

    const textScale      = useTransform(scrollYProgress, [0.15, 0.3], [0.8, 1]);
    const textTranslateY = useTransform(scrollYProgress, [0.15, 0.3], [100, 0]);
    const textOpacity    = useTransform(scrollYProgress, [0.15, 0.3], [0, 1]);
    
    const thumbnailScale      = useTransform(scrollYProgress, [0.3, 0.4], [0.8, 1]);
    const thumbnailTranslateY = useTransform(scrollYProgress, [0.3, 0.4], [100, 0]);
    const thumbnailOpacity    = useTransform(scrollYProgress, [0.3, 0.4], [0, 1]);

    const planetEarthTranslateX = useTransform(scrollYProgress, [0.15, 0.2], [100, 0]);
    const planetEarthTranslateY = useTransform(scrollYProgress, [0.15, 0.2], [-100, 0]);
    const planetEarthOpacity    = useTransform(scrollYProgress, [0, 0.2], [-50, 1]);

    const planetMarsTranslateX = useTransform(scrollYProgress, [0.15, 0.2], [70, 0]);
    const planetMarsTranslateY = useTransform(scrollYProgress, [0.15, 0.2], [-200, 0]);
    const planetMarsOpacity    = useTransform(scrollYProgress, [0, 0.2], [-50, 1]);

    const planetSaturnTranslateY = useTransform(scrollYProgress, [0.15, 0.2], [-50, 0]);
    const planetSaturnOpacity    = useTransform(scrollYProgress, [0, 0.2], [-50, 1]);

    const moonTranslateX = useTransform(scrollYProgress, [0.15, 0.2], [-100, 0]);
    const moonTranslateY = useTransform(scrollYProgress, [0.15, 0.2], [-200, 0]);
    const moonOpacity    = useTransform(scrollYProgress, [0, 0.2], [-50, 1]);

    const planetNeptuneTranslateX = useTransform(scrollYProgress, [0.15, 0.2], [-70, 0]);
    const planetNeptuneTranslateY = useTransform(scrollYProgress, [0.15, 0.2], [-100, 0]);
    const planetNeptuneOpacity    = useTransform(scrollYProgress, [0, 0.2], [-50, 1]);
    
    return(
        <div ref={targetRef} id="section-2" className="container max-w-screen-lg mx-auto px-10 pt-36">   
            <div className="relative">
                <div className="flex pb-24 w-full absolute">
                    <motion.div style={{ translateX:planetEarthTranslateX, translateY:planetEarthTranslateY, opacity:planetEarthOpacity }} className="hidden sm:inline-block m-auto mt-52 transition-all duration-normal ease-in-out">
                        <Image src={ImageForSection02AndElement02} alt="" className="max-h-20"/>
                    </motion.div>
                    <motion.div style={{ translateX:planetMarsTranslateX, translateY:planetMarsTranslateY, opacity:planetMarsOpacity }} className="hidden sm:inline-block m-auto mt-12 transition-all duration-normal ease-in-out">
                        <Image src={ImageForSection02AndElement03} alt="" className="hidden sm:inline-block m-auto mt-12 transition-all duration-normal ease-in-out max-h-20"/>
                    </motion.div>
                    <motion.div style={{ translateY:planetSaturnTranslateY, opacity:planetSaturnOpacity }} className="hidden sm:inline-block m-auto mt-12 transition-all duration-normal ease-in-out">
                        <Image src={ImageForSection02AndElement04} alt="" className="hidden sm:inline-block m-auto mt-0 transition-all duration-normal ease-in-out max-h-20"/>
                    </motion.div>
                    <motion.div style={{ translateX:moonTranslateX, translateY:moonTranslateY, opacity:moonOpacity }} className="hidden sm:inline-block m-auto mt-12 transition-all duration-normal ease-in-out">
                        <Image src={ImageForSection02AndElement05} alt="" className="hidden sm:inline-block m-auto mt-36 transition-all duration-normal ease-in-out max-h-20"/>
                    </motion.div>
                    <motion.div style={{ translateX:planetNeptuneTranslateX, translateY:planetNeptuneTranslateY, opacity:planetNeptuneOpacity }} className="hidden sm:inline-block m-auto mt-12 transition-all duration-normal ease-in-out">
                        <Image src={ImageForSection02AndElement06} alt="" className="hidden sm:inline-block m-auto mt-80 transition-all duration-normal ease-in-out max-h-20"/>
                    </motion.div>
                </div>                
            </div>

            <div className="pb-20 pt-48">
                <motion.div style={{ scale:astronautScale, translateY:astronautTranslateY, opacity:astronautOpacity }} className="pb-10">
                    <Image src={ImageForSection02AndElement01} alt="" className="m-auto transition-all duration-normal ease-in-out max-h-36 md:max-h-40"/>
                </motion.div>
                <motion.div style={{ scale:textScale, translateY:textTranslateY, opacity:textOpacity }} className="m-auto text-xl font-primary leading-10 md:leading-[70px] text-center">
                    {i18n.t("home.section_02_title_01")}
                </motion.div>
            </div>

            <motion.div style={{ scale:thumbnailScale, translateY:thumbnailTranslateY, opacity:thumbnailOpacity }} className="text-center pb-[80px]">
                <Image src={ImageForSection02AndElement07} alt="" className="rounded-3xl shadow-2xl shadow-shadow-light m-auto transition-all duration-normal ease-in-out"/>
            </motion.div>
        </div>
    );
}

export default View;
