import {Marker, InfoWindow} from '@react-google-maps/api';
import { useContext } from 'react';

import Context from '../../util/context';

const View = (props) =>
{
    const context = useContext(Context);

    const infoOptions =
    {
        enableEventPropagation: true,
        disableAutoPan:true,
        boxStyle: {
            content: "",
            minWidth: "30px"
        },
        closeBoxURL: ""
    };

    const handleMarkerClick = (point) =>
    {
        context?.setDialog(props?.markerDialogInfo?.id ?? "", 
            props?.markerDialogInfo?.title ?? "", 
            props?.markerDialogInfo?.bgColor ?? "", 
            props?.markerDialogInfo?.size ?? "sm", 
            props?.markerDialogInfo?.isScrollable ?? false, 
            props?.markerDialogInfo?.isFullscreen ?? false, 
            props?.markerDialogInfo?.isClosable ?? false,
            props?.markerDialogInfo?.shouldRefreshOnClose ?? false, 
            {id:point?.[props?.markerDialogDataID]}
        );
    };

    return (
        props?.data?.map((point, i) =>
        {
            return (
                (props?.showTitle)
                ?
                    <Marker key={i}>
                        <InfoWindow position={{ lat: parseFloat(point["lat"]), lng: parseFloat(point["long"]) }} options={infoOptions}>
                            <div 
                                className="gm-body font-secondary text-xs px-5 pt-0 pb-[15px] min-w-[80px] text-center" 
                                onClick={() => {if(point?.[props?.markerDialogDataID]) {handleMarkerClick(point)}}}
                            >
                                {point["title"]}
                            </div>
                        </InfoWindow>
                    </Marker> 
                :
                    <Marker
                        key={i} 
                        onClick={() => {handleMarkerClick(point)}}
                        animation={window.google.maps.Animation.DROP}
                        icon={{url:(point?.type === "user") ? "/image/map_marker_car_dark.png" : (context?.prefs?.theme === "dark") ? "/image/map_marker_dark.png" : `/image/map_marker_light.png`, scaledSize: new window.google.maps.Size(point?.type === "user" ? 13 : 30, 30)}}
                        position={{ lat: parseFloat(point["lat"]), lng: parseFloat(point["long"]) }}
                    />
            )
        })
    );
}

export default View;