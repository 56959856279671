import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import ZoneInfo from '../../view/dialog/zoneInfo';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [zoneInfo, setZoneInfo] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const submit = (newData) =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {        
            let newErrors = {};

            if(newData?.country_code && !Validator.notEmpty(newData?.country_code))
            {
                newErrors["country_code"] = i18n.t('error.invalid_country_code');            
            }
            
            if(newData?.name_ar && !Validator.notEmpty(newData?.name_ar))
            {
                newErrors["name_ar"] = i18n.t('error.invalid_name_ar');
            }
            
            if(newData?.name_en && !Validator.notEmpty(newData?.name_en))
            {
                newErrors["name_en"] = i18n.t('error.invalid_name_en');
            }
            
            if(newData?.center_lat && !Validator.notEmpty(newData?.center_lat))
            {
                newErrors["center_lat"] = i18n.t('error.invalid_center_lat');
            }
            else if(newData?.center_lat && !Validator.numeric(newData?.center_lat))
            {
                newErrors["center_lat"] = i18n.t('error.invalid_center_lat');
            }        

            if(newData?.center_long && !Validator.notEmpty(newData?.center_long))
            {
                newErrors["center_long"] = i18n.t('error.invalid_center_long');
            }
            else if(newData?.center_long && !Validator.numeric(newData?.center_long))
            {
                newErrors["center_long"] = i18n.t('error.invalid_center_long');
            } 

            if(newData?.geofence && !Validator.notEmpty(newData?.geofence))
            {
                newErrors["geofence"] = i18n.t('error.invalid_geofence');
            }

            if(Object.keys(newErrors)?.length === 0)
            {
                setLoading(true);

                let data = {};
        
                data["zone_id"] = props?.dialog?.metadata?.id;

                if(newData?.name_ar)
                {
                    data["name_ar"]  = newData?.name_ar;
                }

                if(newData?.name_en)
                {
                    data["name_en"] = newData?.name_en;                
                }

                if(newData?.center_lat)
                {
                    data["center_lat"] = newData?.center_lat;                
                }
                        
                if(newData?.center_long)
                {
                    data["center_long"] = newData?.center_long;                
                }  

                if(newData?.geofence)
                {
                    data["geofence"] = newData?.geofence;
                }

                isMounted && HTTP.put(Endpoint?.oms?.zone?.data, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                        setTimeout(() =>
                        {
                            context?.setDialog(null);   
                        
                            window.location.reload();  
                        
                        }, Constant.animation?.speed?.normal);                  
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
        
                        setLoading(false);
                    }
                });
            }  
            else
            {
                setErrors(newErrors);
            } 
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }                           
    };
    
    const remove = () =>
    {
        if(["owner","maintainer"]?.includes(context?.prefs?.user_role_code?.toLowerCase()))
        {
            setLoading(true);

            let data = {};

            data["zone_id"] = props?.dialog?.metadata?.id;

            isMounted && HTTP.delete(Endpoint?.oms?.zone?.data, data).then(response =>
            {
                if(response?.is_successful)
                {    
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);                  
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });
        }
        else
        {
            context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
        }        
    };

    useEffect(() =>
    {
        setLoading(true);
        
        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            let data = {};

            data["zone_id"] = props?.dialog?.metadata?.id;

            isMounted && HTTP.get(Endpoint?.oms?.zone?.data, data).then(response =>
            {
                if(response?.is_successful && response?.data?.info)
                {    
                    setZoneInfo(response?.data?.info);
                    
                    setLoading(false);
                }       
                
            });
        });            

    }, [isMounted, context?.prefs?.lang, props?.dialog?.metadata?.id]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <ZoneInfo
            isLoading={isLoading}
            prefs={context?.prefs}
            data={zoneInfo}
            errors={errors}
            countryList={countryList?.map((country) => {return {title:`${country?.[`name_${context?.prefs?.lang}`]}`, value:country?.country_code}})}
            submit={submit}
            remove={remove}
        />
    );
}

export default Dialog;