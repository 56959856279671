import { useState, useEffect, useContext } from "react";

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import DeliveryFileUpload from '../../view/dialog/deliveryFileUpload';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [privateZoneList, setPrivateZoneList] = useState([]);
    const [publicZoneList, setPublicZoneList] = useState([]);
    const [tripList, setTripList] = useState([]);
    const [errors, setErrors]     = useState({});

    const context = useContext(Context);

    const downloadSample = () =>
    {
        window.open(Constant?.url?.file?.sample_delivery_upload_xlsx, "_blank")
    };

    const next = (data) =>
    {
        let newErrors = {};

        if(!Validator.notEmpty(data?.post_file))
        {
            newErrors["post_file"] = i18n.t('error.invalid_file');            
        }

        if(!Validator.notEmpty(data?.zone_id))
        {
            newErrors["zone_id"] = i18n.t('error.invalid_zone');            
        }           

        if(data["post_type"]?.toLowerCase() === "private" && !Validator.notEmpty(data?.trip_id))
        {
            newErrors["trip_id"] = i18n.t('error.invalid_trip');            
        }  

        if(Object?.keys(newErrors)?.length === 0)
        {        
            let params = 
            {
                post_file: data["post_file"],
                post_type: data["post_type"] ?? "public",
                zone_id: data["zone_id"],
                trip_id: data["trip_id"],
                post_format: "file"
            };

            console.log(params)

            setLoading(true);

            isMounted && HTTP.post(Endpoint?.oms?.delivery?.data, params).then(response =>
            {
                if(response?.is_successful)
                {
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);                    
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });            
        }
        else
        {
            setErrors(newErrors);
        }
    };

    useEffect(() =>
    {
        setLoading(true);

        let params = {
            "country_code": context?.prefs?.merchant_country_code,
            "set_type": "full",
            "type_code": "private"
        };
        
        isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
        {
            setPrivateZoneList(response?.data?.list);

            params["type_code"] = "public";

            isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
            {
                setPublicZoneList(response?.data?.list);
            
                isMounted && HTTP.get(Endpoint?.oms?.trip?.list, params).then(response =>
                {
                    setTripList(response?.data?.list);
                
                    setLoading(false);
                });
            });  
        });            

    }, [isMounted, context?.prefs?.merchant_country_code]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DeliveryFileUpload
            prefs={context?.prefs}
            isLoading={isLoading}
            privateZoneList={privateZoneList?.map((zone) => {return {title:`${zone?.[`name_${context?.prefs?.lang}`]}`, value:zone?.zone_id}})}
            publicZoneList={publicZoneList?.map((zone) => {return {title:`${zone?.[`name_${context?.prefs?.lang}`]}`, value:zone?.zone_id}})}
            tripList={tripList?.map((trip) => {return {title:`${trip?.[`name_${context?.prefs?.lang}`]}`, value:trip?.trip_id}})}
            errors={errors}
            next={next}
            downloadSample={downloadSample}
        />
    );
}

export default Dialog;