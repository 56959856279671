import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">
                {(props?.data?.persona?.toLowerCase() === "captain")
                ?
                    <>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("form.you_will_work_as")}
                            </div>
                            <InputDropdown
                                selectorClassName={"bg-secondary-light mb-2"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={[{title:i18n.t("common.employment_type_freelancer"), value:"freelancer"}, {title:i18n.t("common.employment_type_fte"), value:"fte"}]}
                                listValue={data?.employment_type ?? props?.data?.employment_type}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("employment_type", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.employment_type && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.employment_type}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div> 
                        <div className="mb-7">
                            <AnimatePresence>
                            {(data?.employment_type?.toLowerCase() === "fte" || props?.data?.employment_type?.toLowerCase() === "fte") && (
                                <motion.div 
                                    initial={{ height:0, opacity:0 }}
                                    animate={{ height: "auto", opacity:1 }}
                                    exit={{ height:0, opacity:0 }}
                                    transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                >                
                                <InputText 
                                    type={"text"}
                                    containerClassName="mb-2"
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    enableAutoComplete={false}
                                    label={i18n.t("common.merchant_code")}
                                    placeholder={i18n.t("form.letters_and_numbers_only")}
                                    value={data?.merchant_code ?? props?.data?.merchant_code}
                                    errorMsg={props?.errors?.merchant_code}
                                    onChange={(e) => {update("merchant_code", e?.target?.value)}}
                                /> 
                                </motion.div>
                            )}
                            </AnimatePresence>
                        </div> 
                    </>  
                :
                <div className="mb-7">
                    <div className="mb-2">
                        {i18n.t("common.subscription")}
                    </div>
                    <InputDropdown
                        selectorClassName={"bg-secondary-light mb-2"}
                        listClassName={"bg-secondary-lightest"}
                        itemClassName={"border-secondary-light hover:bg-secondary-light"}
                        searchbarClassName={"bg-secondary-light"}
                        list={[{title:i18n.t("subscription.basic"), value:"basic"}, {title:i18n.t("subscription.essentials"), value:"essentials"}, {title:i18n.t("subscription.premium"), value:"premium"}, {title:i18n.t("subscription.enterprise"), value:"enterprise"}]}
                        listValue={data?.subscription_code ?? props?.data?.subscription_code}
                        searchInputPlaceholder={i18n.t("common.search_generic")}
                        isDisabled={props?.isLoading}
                        isListSorted={false}
                        isListReversed={false}
                        isSearchable={false}
                        isCompact={false}
                        handleValueUpdate={(value) => {update("subscription_code", value)}}
                    />                                
                    <AnimatePresence>
                        {props?.errors?.subscription_code && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.subscription_code}
                            </motion.div>
                        )}
                    </AnimatePresence>                        
                </div>                    
                }
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={props?.isPrevAllowed} onClick={() => props?.goPrev({...props?.data, ...data})}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
