import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';
import Formatter from '../../util/formatter';

import Stepper from '../../view/component/stepper';
import Signup01 from '../../view/dialog/signup01';
import Signup02 from '../../view/dialog/signup02';
import Signup03 from '../../view/dialog/signup03';
import Signup04 from '../../view/dialog/signup04';
import Signup05 from '../../view/dialog/signup05';
import Signup06 from '../../view/dialog/signup06';
import Signup07 from '../../view/dialog/signup07';
import Signup08 from '../../view/dialog/signup08';

const Dialog = props => 
{
    const totalSteps = props?.dialog?.metadata?.persona?.toLowerCase() === "captain" ? 7 : props?.dialog?.metadata?.persona?.toLowerCase() === "owner" ? 6 : 2;
    const action = props?.dialog?.metadata?.action ?? null;
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [countryList, setCountryList] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [currentStep, setCurrentStep] = useState((action === "update_account" || action === "update_account_and_captain_profile") ? 2 : (action === "update_captain_profile") ? 5 : (action === "create_merchant_account" || action === "create_captain_profile") ? 3 : 1);
    const [data, setData] = useState({...props?.dialog?.metadata, mobile_code:Constant?.mobile_code?.default});
    const [userInfo, setUserInfo] = useState({});
    const [errors, setErrors] = useState({});

    const context = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();

    const closeDialog = () =>
    {
        context?.setDialog(null);
    };

    const goPrev = (newData) =>
    {
        setData({...data, ...newData});

        if(currentStep > 1)
        {
            setCurrentStep(currentStep-1);
        }
    };

    const goNext = (newData) =>
    {
        setData({...data, ...newData});
        
        if(currentStep === 1)
        {
            processStep1({...data, ...newData})
        }
        else if(currentStep === 2)
        {
            processStep2({...data, ...newData})
        }
        else if(currentStep === 3)
        {
            processStep3({...data, ...newData})
        }
        else if(currentStep === 4)
        {
            processStep4({...data, ...newData})
        }
        else if(currentStep === 5)
        {
            processStep5({...data, ...newData})
        }
        else if(currentStep === 6)
        {
            processStep6({...data, ...newData})
        }
        else if(currentStep === 7)
        {
            processStep7({...data, ...newData})
        }
    };

    const processStep1 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(!Validator.notEmpty(stepData?.email))
        {
            newErrors["email"] = i18n.t('error.invalid_email');            
        }
        else if(!Validator.email(stepData?.email))
        {
            newErrors["email"] = i18n.t('error.invalid_email');
        }
        
        if(!Validator.notEmpty(stepData?.mobile))
        {
            newErrors["mobile"] = i18n.t('error.invalid_mobile');
        }
        
        if(!Validator.numeric(stepData?.mobile))
        {
            newErrors["mobile"] = i18n.t('error.invalid_mobile');
        }        
        
        if(!Validator.notEmpty(stepData?.mobile_code))
        {
            newErrors["mobile"] = i18n.t('error.invalid_mobile');
        }
        
        if(!Validator.numeric(stepData?.mobile_code))
        {
            newErrors["mobile"] = i18n.t('error.invalid_mobile');
        }        
        
        if(!Validator.notEmpty(stepData?.password))
        {
            newErrors["password"] = i18n.t('error.invalid_password');            
        }
        else if(!Validator.longPassword(stepData?.password))
        {
            newErrors["password"] = i18n.t('error.short_password');
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            params = 
            {
                email: stepData?.email?.toLowerCase(),
                mobile: stepData?.mobile,
                mobile_code: stepData?.mobile_code,
                password: stepData?.password
            };

            if(props?.dialog?.metadata?.invitation_code)
            {
                if(["maintainer", "observer"]?.includes(props?.dialog?.metadata?.persona?.toLowerCase()))
                {
                    params["invitation_code"] = props?.dialog?.metadata?.invitation_code;
                }
            }

            isMounted && HTTP.post(Endpoint?.crm?.user?.data, params).then(response =>
            {
                if(response?.is_successful)
                {
                    context?.setPrefs(
                    {
                        "access_token": response?.data?.info?.access_token,
                        "refresh_token": response?.data?.info?.refresh_token
                    });

                    setCurrentStep(currentStep+1);

                    setLoading(false);
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep2 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(!Validator.notEmpty(stepData?.first_name))
        {
            newErrors["name"] = i18n.t('error.invalid_first_name');            
        }
        else if(!Validator.notEmpty(stepData?.last_name))
        {
            newErrors["name"] = i18n.t('error.invalid_last_name');            
        }

        if(!Validator.notEmpty(stepData?.gender))
        {
            newErrors["gender"] = i18n.t('error.invalid_gender');            
        }
        else if(!["male", "female"].includes(stepData?.gender?.toLowerCase()))
        {
            newErrors["gender"] = i18n.t('error.invalid_gender');            
        }
        
        if(!Validator.notEmpty(stepData?.nationality_code))
        {
            newErrors["nationality_code"] = i18n.t('error.invalid_nationality');            
        }
        
        if(!Validator.notEmpty(stepData?.government_id))
        {
            newErrors["government_id"] = i18n.t('error.invalid_government_id');            
        }        

        if(props?.dialog?.metadata?.merchant_code)
        {
            setData({...data, ...{merchant_code:props?.dialog?.metadata?.merchant_code, employment_type:"fte"}});
        }  

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            params = 
            {
                first_name: stepData?.first_name,
                last_name: stepData?.last_name,
                gender: stepData?.gender,
                nationality_code: stepData?.nationality_code,
                government_id: stepData?.government_id
            };

            context?.setPrefs(
            {
                "user_first_name": stepData?.first_name,
                "user_last_name": stepData?.last_name,
                "user_avatar": ""
            });

            isMounted && HTTP.put(Endpoint?.crm?.user?.data, params).then(response =>
            {
                if(response?.is_successful)
                {
                    if(action === "update_account")
                    {
                        isMounted && HTTP.delete(Endpoint?.crm?.user?.token); 

                        context.clearPrefs();
    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.login_again"));
    
                        context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");  
                    }
                    else if(action === "update_account_and_captain_profile")
                    {
                        setCurrentStep(5);
            
                        setLoading(false);
                    }
                    else
                    {
                        if(["maintainer", "observer"]?.includes(props?.dialog?.metadata?.persona?.toLowerCase()))
                        {
                            context?.setPrefs({"is_logged_in": "1"});                            
                    
                            navigate("/directory");
            
                            context?.setDialog(null);                        
                        }
                        else
                        {
                            setCurrentStep(currentStep+1);
            
                            setLoading(false);
                        }
                    }
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep3 = (stepData) =>
    {
        let newErrors = {};

        if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
        {
            if(!Validator.notEmpty(stepData?.subscription_code))
            {
                newErrors["subscription_code"] = i18n.t('error.invalid_subscription_code');            
            }
        }
        else
        {
            if(!Validator.notEmpty(stepData?.employment_type))
            {
                newErrors["employment_type"] = i18n.t('error.invalid_employement_type');            
            }
            else if(!["freelancer", "fte"]?.includes(stepData?.employment_type?.toLowerCase()))
            {
                newErrors["employment_type"] = i18n.t('error.invalid_employement_type');
            }
            else if(stepData?.employment_type?.toLowerCase() === "fte" && !Validator.notEmpty(stepData?.merchant_code))
            {
                newErrors["merchant_code"] = i18n.t('error.invalid_merchant_code');
            }
            else if(stepData?.employment_type?.toLowerCase() === "fte" && !Validator.code(stepData?.merchant_code))
            {
                newErrors["merchant_code"] = i18n.t('error.invalid_merchant_code');
            }

            if(stepData?.employment_type?.toLowerCase() === "freelancer")
            {
                let newData = {};

                newData["merchant_code"] = "lastmile";

                setData({...data, ...stepData, ...newData});
            }
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setCurrentStep(currentStep+1);
    
            setErrors({});
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep4 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
        {
            if(!Validator.notEmpty(stepData?.country_code))
            {
                newErrors["country_code"] = i18n.t('error.invalid_country');            
            }

            if(!Validator.notEmpty(stepData?.legal_name))
            {
                newErrors["legal_name"] = i18n.t('error.invalid_legal_name');            
            }

            if(!Validator.notEmpty(stepData?.display_name))
            {
                newErrors["display_name"] = i18n.t('error.invalid_display_name');            
            }
        }
        else
        {
            if(!Validator.notEmpty(stepData?.country_code))
            {
                newErrors["country_code"] = i18n.t('error.invalid_country');            
            }
            
            if(!Validator.notEmpty(stepData?.zone_id))
            {
                newErrors["zone_id"] = i18n.t('error.invalid_zone');
            }
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
            {
                setCurrentStep(currentStep+1);
            }
            else
            {
                setLoading(true);

                params = 
                {
                    merchant_code: data["merchant_code"],
                    role_code: "captain",
                    country_code: stepData["country_code"],
                    currency_code: "sar",
                    zone_id: stepData["zone_id"]
                };

                isMounted && HTTP.post(Endpoint?.crm?.profile?.data, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        setCurrentStep(currentStep+1);
        
                        setLoading(false);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                        setLoading(false);
                    }
                });
            }
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep5 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
        {
            if(!Validator.notEmpty(stepData?.cr_number))
            {
                newErrors["cr_number"] = i18n.t('error.invalid_cr_number');            
            }

            if(!Validator.notEmpty(stepData?.cr_start_date))
            {
                newErrors["cr_start_date"] = i18n.t('error.invalid_cr_start_date');            
            }
            else if(Validator.futureDate(stepData?.cr_start_date))
            {
                newErrors["cr_end_date"] = i18n.t('error.invalid_date_not_in_past');            
            }

            if(!Validator.notEmpty(stepData?.cr_end_date))
            {
                newErrors["cr_end_date"] = i18n.t('error.invalid_cr_end_date');            
            }
            else if(!Validator.futureDate(stepData?.cr_end_date))
            {
                newErrors["cr_end_date"] = i18n.t('error.invalid_date_not_in_future');            
            }

            if(!Validator.notEmpty(stepData?.cr_img))
            {
                newErrors["cr_img"] = i18n.t('error.invalid_cr_img');            
            }
        }
        else
        {
            if(!Validator.notEmpty(stepData?.driver_license_code))
            {
                newErrors["driver_license_code"] = i18n.t('error.invalid_driver_license_code');            
            }
            
            if(!Validator.notEmpty(stepData?.driver_license_number))
            {
                newErrors["driver_license_number"] = i18n.t('error.invalid_driver_license_number');
            }

            if(!Validator.notEmpty(stepData?.driver_license_img))
            {
                newErrors["driver_license_img"] = i18n.t('error.invalid_driver_license_img');
            }

            if(!Validator.notEmpty(stepData?.driver_license_expiration_date))
            {
                newErrors["driver_license_expiration_date"] = i18n.t('error.invalid_driver_license_expiration_date');
            }
            else if(!Validator.futureDate(stepData?.driver_license_expiration_date))
            {
                newErrors["driver_license_expiration_date"] = i18n.t('error.invalid_date_not_in_future');            
            }
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
            {
                setCurrentStep(currentStep+1);
            }
            else
            {
                setLoading(true);
                
                params = 
                {
                    driver_license_code: stepData["driver_license_code"],
                    driver_license_number: stepData["driver_license_number"],
                    driver_license_img: stepData["driver_license_img"],
                    driver_license_expiration_date: Formatter.dateToTimestamp(stepData["driver_license_expiration_date"], "date")
                };

                if(typeof(stepData?.driver_license_img) === "string")
                {
                    delete params?.["driver_license_img"];
                }

                isMounted && HTTP.post(Endpoint?.crm?.profile?.driverLicense, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        setCurrentStep(currentStep+1);
        
                        setLoading(false);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                        setLoading(false);
                    }
                });
            }
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep6 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
        {
            if(!Validator.notEmpty(stepData?.merchant_code) || !Validator.code(stepData?.merchant_code))
            {
                newErrors["merchant_code"] = i18n.t('error.invalid_merchant_code');            
            }
        }
        else
        {
            if(!Validator.notEmpty(stepData?.vehicle_registration_code))
            {
                newErrors["vehicle_registration_code"] = i18n.t('error.invalid_vehicle_registration_code');            
            }

            if(!Validator.notEmpty(stepData?.vehicle_registration_number))
            {
                newErrors["vehicle_registration_number"] = i18n.t('error.invalid_vehicle_registration_number');
            }

            if(!Validator.notEmpty(stepData?.vehicle_plate_number))
            {
                newErrors["vehicle_plate_number"] = i18n.t('error.invalid_vehicle_plate_number');
            }

            if(!Validator.notEmpty(stepData?.vehicle_registration_img))
            {
                newErrors["vehicle_img"] = i18n.t('error.invalid_vehicle_registration_img');
            }
            else if(!Validator.notEmpty(stepData?.vehicle_img))
            {
                newErrors["vehicle_img"] = i18n.t('error.invalid_vehicle_img');
            }

            if(!Validator.notEmpty(stepData?.vehicle_registration_expiration_date))
            {
                newErrors["vehicle_registration_expiration_date"] = i18n.t('error.invalid_vehicle_registration_expiration_date');
            }
            else if(!Validator.futureDate(stepData?.vehicle_registration_expiration_date))
            {
                newErrors["vehicle_registration_expiration_date"] = i18n.t('error.invalid_date_not_in_future');            
            }
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            if(props?.dialog?.metadata?.persona?.toLowerCase() === "owner")
            {
                params = 
                {
                    merchant_code: stepData["merchant_code"],
                    subscription_code: data["subscription_code"],
                    legal_name: data["legal_name"],
                    display_name: data["display_name"],
                    country_code: data["country_code"],
                    currency_code: "sar",
                    contact_email: data["email"] ?? userInfo["email"],
                    contact_mobile_code: data["mobile_code"] ?? userInfo["mobile_code"],
                    contact_mobile: data["mobile"] ?? userInfo["mobile"],
                    lang_code: context?.prefs?.lang,
                    communication_method: "email"
                };

                isMounted && HTTP.post(Endpoint?.crm?.merchant?.data, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        context?.setPrefs(
                        {
                            "access_token": response?.data?.info?.access_token,
                            "refresh_token": response?.data?.info?.refresh_token,
                            "merchant_id": response?.data?.info?.merchant_id,
                            "merchant_country_code": data["country_code"],
                            "merchant_display_name": data["display_name"],
                            "user_role_code": "owner"                           
                        });

                        params = 
                        {
                            cr_number: data["cr_number"],
                            cr_img: data["cr_img"],
                            cr_start_date: Formatter.dateToTimestamp(data["cr_start_date"], "date"),
                            cr_end_date: Formatter.dateToTimestamp(data["cr_end_date"], "date")
                        }

                        isMounted && HTTP.post(Endpoint?.crm?.merchant?.cr, params).then(response =>
                        {
                            if(response?.is_successful)
                            {
                                if(location.pathname?.toLowerCase() === "/directory")
                                {
                                    navigate(0);
                                }
                                else
                                {
                                    context?.setPrefs({"is_logged_in": "1"});
                            
                                    navigate("/directory");
                    
                                    context?.setDialog(null);            
                                }
                            }
                            else
                            {
                                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            
                                setLoading(false);
                            }
                        });
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                        setLoading(false);
                    }
                });
            }
            else
            {
                params = 
                {
                    vehicle_registration_code: stepData["vehicle_registration_code"],
                    vehicle_registration_number: stepData["vehicle_registration_number"],
                    vehicle_plate_number: stepData["vehicle_plate_number"],
                    vehicle_registration_img: stepData["vehicle_registration_img"],
                    vehicle_img: stepData["vehicle_img"],
                    vehicle_registration_expiration_date: Formatter.dateToTimestamp(stepData["vehicle_registration_expiration_date"], "date")
                };

                if(typeof(stepData?.vehicle_registration_img) === "string")
                {
                    delete params?.["vehicle_registration_img"];
                }

                if(typeof(stepData?.vehicle_img) === "string")
                {
                    delete params?.["vehicle_img"];
                }

                isMounted && HTTP.post(Endpoint?.crm?.profile?.vehicleRegistration, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        setCurrentStep(currentStep+1);
        
                        setLoading(false);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                        setLoading(false);
                    }
                });
            }
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const processStep7 = (stepData) =>
    {
        let newErrors = {};
        let params = {};

        if(props?.dialog?.metadata?.persona?.toLowerCase() === "captain")
        {
            if(!Validator.notEmpty(stepData?.insurance_company_name))
            {
                newErrors["insurance_company_name"] = i18n.t('error.invalid_insurance_company_name');            
            }
            
            if(!Validator.notEmpty(stepData?.insurance_code))
            {
                newErrors["insurance_code"] = i18n.t('error.invalid_insurance_code');
            }
            else if(!Validator.notEmpty(stepData?.insurance_policy_number))
            {
                newErrors["insurance_code"] = i18n.t('error.invalid_insurance_policy_number');
            }

            if(!Validator.notEmpty(stepData?.insurance_card_img))
            {
                newErrors["insurance_card_img"] = i18n.t('error.invalid_insurance_card_img');
            }

            if(!Validator.notEmpty(stepData?.insurance_expiration_date))
            {
                newErrors["insurance_expiration_date"] = i18n.t('error.invalid_insurance_expiration_date');
            }
            else if(!Validator.futureDate(stepData?.insurance_expiration_date))
            {
                newErrors["insurance_expiration_date"] = i18n.t('error.invalid_date_not_in_future');            
            }
            
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});

            setLoading(true);

            if(props?.dialog?.metadata?.persona?.toLowerCase() === "captain")
            {
                params = 
                {
                    insurance_company_name: stepData["insurance_company_name"],
                    insurance_code: stepData["insurance_code"],
                    insurance_policy_number: stepData["insurance_policy_number"],
                    insurance_card_img: stepData["insurance_card_img"],
                    insurance_expiration_date: Formatter.dateToTimestamp(stepData["insurance_expiration_date"], "date")
                };

                if(typeof(stepData?.insurance_card_img) === "string")
                {
                    delete params?.["insurance_card_img"];
                }

                isMounted && HTTP.post(Endpoint?.crm?.profile?.insurance, params).then(response =>
                {
                    if(response?.is_successful)
                    {
                        if(["update_account_and_captain_profile","update_account","update_captain_profile"].includes(action))
                        {
                            isMounted && HTTP.delete(Endpoint?.crm?.user?.token); 
    
                            context.clearPrefs();
        
                            context?.setNotification("success", i18n.t("message.title"), i18n.t("message.login_again"));
        
                            context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");  
                        }
                        else
                        {
                            setCurrentStep(currentStep+1);
                        }
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                        setLoading(false);
                    }
                });
            }
        }
        else
        {
            setErrors(newErrors);
        }
    };

    const updateZoneList = (countryCode) =>
    {
        setLoading(true);

        let params = 
        {
            merchant_code: data?.["merchant_code"],
            country_code: countryCode?.toLowerCase(),
            type_code: "private",
            set_type: "full"
        };

        isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
        {
            setZoneList(response?.data?.list);
            
            setLoading(false);
        });
    };

    useEffect(() =>
    {
        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            if(props?.dialog?.metadata?.action)
            {
                isMounted && HTTP.get(Endpoint?.crm?.user?.data).then(response =>
                {
                    if(response?.data?.info?.profiles?.captain)
                    {  
                        let temp = {};

                        if(response?.data?.info?.profiles?.captain?.driver_license_expiration_date)
                        {
                            const [year, month, day] = response?.data?.info?.profiles?.captain?.driver_license_expiration_date?.split('-').map(Number)
                            temp["driver_license_expiration_date"] = Formatter.stringToDate(year, month, day);
                        }

                        if(response?.data?.info?.profiles?.captain?.vehicle_registration_expiration_date)
                        {
                            const [year, month, day] = response?.data?.info?.profiles?.captain?.vehicle_registration_expiration_date?.split('-').map(Number)
                            temp["vehicle_registration_expiration_date"] = Formatter.stringToDate(year, month, day);
                        }

                        if(response?.data?.info?.profiles?.captain?.insurance_expiration_date)
                        {
                            const [year, month, day] = response?.data?.info?.profiles?.captain?.insurance_expiration_date?.split('-').map(Number)
                            temp["insurance_expiration_date"] = Formatter.stringToDate(year, month, day);
                        }

                        const account = {...response?.data?.info};
                        const profile = {...response?.data?.info?.profiles?.captain, ...temp};
                        delete account?.profiles;

                        setUserInfo({...account, ...profile});
                    }
                    else
                    {
                        setUserInfo(response?.data?.info);
                    }

                    setLoading(false);
                });
            }
            else
            {
                setLoading(false);
            } 
        });
    }, [isMounted, props?.dialog?.metadata?.action]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <>
            <Stepper
                total={totalSteps}
                current={currentStep}
                className="px-7 pb-7"
            />
            {(currentStep === 1)
            ?
                <Signup01
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    countryList={countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 2)
            ?
                <Signup02
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    countryList={countryList?.map((country) => {return {title:`${country?.[`name_${context?.prefs?.lang}`]}`, value:country?.country_code}})}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 3)
            ?
                <Signup03
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    isPrevAllowed={(action !== "update_captain_profile")}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 4)
            ?
                <Signup04
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    countryList={countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`${country?.[`name_${context?.prefs?.lang}`]}`, value:country?.country_code}})}
                    zoneList={zoneList?.map((zone) => {return {title:`${zone?.[`name_${context?.prefs?.lang}`]}`, value:zone?.zone_id}})}
                    updateZoneList={updateZoneList}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 5)
            ?
                <Signup05
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 6)
            ?
                <Signup06
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
            (currentStep === 7)
            ?
                <Signup07
                    isLoading={isLoading}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    data={{...userInfo, ...data}}
                    errors={errors}
                    prefs={context?.prefs}
                    goNext={goNext}
                    goPrev={goPrev}
                />
            :
                <Signup08
                    closeDialog={closeDialog}
                />
            }
        </>
    );
}

export default Dialog;