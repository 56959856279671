import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">

                <div className="mb-7">
                    <div className="grid grid-cols-2 gap-5 mb-2">
                        <InputText 
                            type={"text"}
                            inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                            labelClassName="text-secondary-darkest"
                            errorClassName="text-status-danger-regular"
                            enableAutoComplete={false}
                            label={i18n.t("common.first_name")}
                            placeholder={i18n.t("common.first_name")}
                            value={data?.first_name ?? props?.data?.first_name}
                            onChange={(e) => {update("first_name", e?.target?.value)}}
                        /> 
                        <InputText 
                            type={"text"}
                            inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                            labelClassName="text-secondary-darkest"
                            errorClassName="text-status-danger-regular"
                            enableAutoComplete={false}
                            label={i18n.t("common.last_name")}
                            placeholder={i18n.t("common.last_name")}
                            value={data?.last_name ?? props?.data?.last_name}
                            onChange={(e) => {update("last_name", e?.target?.value)}}
                        />             
                    </div>
                    <AnimatePresence>
                        {props?.errors?.name && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm`}
                            >
                                {props?.errors?.name}
                            </motion.div>
                        )}
                    </AnimatePresence>  
                </div>

                <div className="mb-7">
                    <div className="mb-2">
                        {i18n.t("common.gender")}
                    </div>
                    <InputDropdown
                        selectorClassName={"bg-secondary-light mb-2"}
                        listClassName={"bg-secondary-lightest"}
                        itemClassName={"border-secondary-light hover:bg-secondary-light"}
                        searchbarClassName={"bg-secondary-light"}
                        list={[{title:i18n.t("common.male"), value:"male"}, {title:i18n.t("common.female"), value:"female"}]}
                        listValue={data?.gender ?? props?.data?.gender}
                        searchInputPlaceholder={i18n.t("common.search_generic")}
                        isDisabled={props?.isLoading}
                        isListSorted={false}
                        isListReversed={false}
                        isSearchable={false}
                        isCompact={false}
                        handleValueUpdate={(value) => {update("gender", value)}}
                    />
                    <AnimatePresence>
                        {props?.errors?.gender && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm`}
                            >
                                {props?.errors?.gender}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>             

                <div className="mb-7">
                    <div className="mb-2">
                        {i18n.t("common.nationality")}
                    </div>
                    <InputDropdown
                        selectorClassName={"bg-secondary-light mb-2"}
                        listClassName={"bg-secondary-lightest"}
                        itemClassName={"border-secondary-light hover:bg-secondary-light"}
                        searchbarClassName={"bg-secondary-light"}
                        list={props?.countryList}
                        listValue={data?.nationality_code ?? props?.data?.nationality_code}
                        searchInputPlaceholder={i18n.t("common.search_generic")}
                        isDisabled={props?.isLoading}
                        isListSorted={true}
                        isListReversed={false}
                        isSearchable={true}
                        isCompact={true}
                        handleValueUpdate={(value) => {update("nationality_code", value)}}
                    />
                    <AnimatePresence>
                        {props?.errors?.nationality && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm`}
                            >
                                {props?.errors?.nationality}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>

                <div className="mb-7">
                    <InputText 
                        type={"text"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.government_id")}
                        placeholder={i18n.t("common.government_id")}
                        value={data?.government_id ?? props?.data?.government_id}
                        errorMsg={props?.errors?.government_id}
                        onChange={(e) => {update("government_id", e?.target?.value)}}
                    />              
                </div>     
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={true} onClick={() => {}}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
