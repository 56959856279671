import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import i18n from '../../util/i18n';

import Error from '../../view/page/error';

const Controller = props => 
{
    const navigate = useNavigate();

    const redirect = () =>
    {
        navigate("/");       
    };

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('error.404');

    }, []);

    return (
        <Error
            type = "404"
            label = {i18n.t("common.go_home")}
            msg = {i18n.t("error.404")}
            action = {redirect}
        />
    );
}

export default Controller;