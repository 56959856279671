import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import Table from '../component/table';
import Button from '../component/button';
import InputDate from '../component/inputDate';
import Skelton from '../component/skelton';

const View = (props) =>
{
    return( 
        <div className={`h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-gradient-regular via-gradient-dark to-gradient-darkest flex flex-col`}>
            <div className="border-t-[1px] border-neutral-high-regular grow text-neutral-low-dark overflow-scroll mt-[150px] lg:mt-[92px]" data-lenis-prevent>
                <div className="p-5 transition ease-in-out duration-normal">
                    <div className="mb-5 font-primary text-lg">
                        {i18n.t("page.dashboard_wallet")}
                    </div>
                    <div className="flex flex-wrap sm:flex-nowrap justify-between gap-1 mb-5 items-end">
                        <div className="bg-neutral-high-light transition ease-in-out duration-normal rounded-xl p-5 shadow-xl">
                            <div className="mb-5 text-sm">
                                {i18n.t("wallet.balance")}
                            </div>
                            <div className="mb-5 flex items-end gap-1">
                                {(props?.isWalletInfoLoading)
                                ?
                                    <div className="w-full my-4">
                                        <Skelton className="bg-secondary-regular" count={1}/>
                                    </div>
                                :
                                    <>
                                        <div className="text-xl">
                                            {Formatter?.stringToNumberWithCommasAndDicimals(props?.walletInfo?.balance)}
                                        </div>
                                        <div className="text-sm pb-2">
                                            {i18n.t(`currency.${props?.walletInfo?.currency_code_display?.toLowerCase()}`)}
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="pb-5 md:pb-0 flex gap-2">
                                <Button 
                                    type="neutral-low-solid" 
                                    className="min-w-[120px]" 
                                    text={i18n.t("page.wallet_credit")} 
                                    isLoading={props?.isWalletInfoLoading} 
                                    onClick={props?.credit}
                                />
                                <Button 
                                    type="neutral-low-solid" 
                                    className="min-w-[120px]" 
                                    text={i18n.t("page.wallet_debit")}
                                    isLoading={props?.isWalletInfoLoading} 
                                    onClick={props?.debit}
                                />
                            </div>
                        </div>
                        <div className="flex gap-2 sm:justify-end w-full mt-5">
                            <InputDate
                                theme={props?.prefs?.theme}                    
                                inputClassName="text-neutral-low-dark border-neutral-high-regular bg-neutral-high-light active:bg-neutral-high-light hover:bg-neutral-high-light w-full"
                                dateFormat="MMMM YYYY"
                                locale={props?.prefs?.lang}
                                showTimeSelect={false}
                                showMonthYearPicker={true}
                                isDisabled={props?.isListLoading}
                                defaultValue={new Date()}
                                onChange={props?.setListFilterValue}
                            />                                                    
                        </div>
                    </div>
                    <div className="mb-5">
                        <Table
                            labels={
                            [
                                {order:1, id:"posted_at", title:i18n.t("wallet_transaction.posted_at"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false},
                                {order:5, id:"status_code", title:i18n.t("wallet_transaction.status_code"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minLG:true},
                                {order:2, id:"reference_type", title:i18n.t("wallet_transaction.reference_type"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minLG:true},
                                {order:3, id:"credit", title:i18n.t("wallet_transaction.credit"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false},
                                {order:4, id:"debit", title:i18n.t("wallet_transaction.debit"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false}
                            ]}
                            list={props?.list}
                            prefs={props?.prefs}
                            showDialog={props?.showDialog}
                            isLoading={props?.isListLoading}
                        />            
                    </div>
                </div>
            </div>
        </div>   
    );
}

export default View;    