import { useState } from 'react';

import i18n from '../../util/i18n';

import Skelton from '../component/skelton';
import Button from '../component/button';
import InputTextArea from '../component/inputTextArea';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState({});

    const update = (id, type, answer) =>
    {
        let temp = {...data};

        temp[id] = {"question_id":id, "answer_type":type, "answer":answer};

        setData(temp);
    };

    const save = () =>
    {
        let temp = [];

        Object.keys(data)?.forEach(key =>
        {
            temp.push(data[key]);
        });

        props?.save(temp);
    };

    return(
        
        <div className="font-secondary text-sm text-neutral-low-darkest p-7">
            {(props?.isLoading)
            ?
                <div className="pt-5">
                    <Skelton className="bg-secondary-light mb-5" count={5}/>
                </div>
            :
            (props?.data?.questions)
            ?
                <div>
                    <div className="font-primary pb-7">
                        {props?.data?.[`survey_title_${props?.prefs?.lang}`]}
                    </div>
                    <div>
                        {props?.data?.questions?.map((question, index) => 
                        {
                            return (
                                <div key={index} className="mb-7">
                                    <div className="mb-3">
                                        {question?.[`question_${props?.prefs?.lang}`]}
                                    </div>
                                    <div>
                                        {(question?.answer_type?.toLowerCase() === "rating")
                                        ?
                                            <div className="p-0 w-[200px]">
                                                <InputDropdown
                                                    list={
                                                        [{title:`1- ${i18n.t("survey.rating_1")}`, value:"1"}, 
                                                         {title:`2- ${i18n.t("survey.rating_2")}`, value:"2"},
                                                         {title:`3- ${i18n.t("survey.rating_3")}`, value:"3"},
                                                         {title:`4- ${i18n.t("survey.rating_4")}`, value:"4"},
                                                         {title:`5- ${i18n.t("survey.rating_5")}`, value:"5"}]
                                                    }
                                                    listValue={data[question?._id]?.answer ?? ""}
                                                    isDisabled={props?.isLoading}
                                                    isListSorted={false}
                                                    isListReversed={false}
                                                    isSearchable={false}
                                                    isCompact={false}
                                                    theme={props?.prefs?.theme}
                                                    handleValueUpdate={(value) => {update(question?._id, question?.answer_type?.toLowerCase(), value)}}
                                                />                                                
                                            </div>
                                        :
                                        (question?.answer_type?.toLowerCase() === "text")
                                        ?
                                            <div className="p-0">
                                                <InputTextArea 
                                                    type={"text"}
                                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                                    labelClassName="text-secondary-darkest"
                                                    errorClassName="text-status-danger-regular"
                                                    rows={3}
                                                    enableAutoComplete={false}
                                                    defaultValue={""}
                                                    onChange={(e) => {update(question?._id, question?.answer_type?.toLowerCase(), e?.target?.value)}}
                                                /> 
                                            </div>                                           
                                        :
                                            null
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex justify-end pt-7">
                        <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={save}/>
                    </div>                     
                </div>
            :
                null
            }
        </div>
    )
}

export default View;