import Button from '../component/button';
import Image from '../component/image';

import Error404 from '../../assets/image/error_404.webp';
import ErrorNoInternet from '../../assets/image/error_no_internet.webp';

const View = (props) =>
{
    return( 
        <div className="bg-primary-regular h-screen flex">
            <div className="m-auto">
                <div className="text-center flex">
                    {(props?.type === "404") ? <Image src={Error404} alt="" className="h-60 mx-auto"/> : (props?.type === "no_internet") ? <Image src={ErrorNoInternet} alt="" className="h-60 mx-auto"/> : null}
                </div>
                <div className="text-center font-secondary text-lg mt-24 mb-8">
                    {props?.msg}
                </div>
                <div className="text-center">
                    <Button type="secondary-solid" text={props?.label} onClick={() => props?.action()}/>
                </div>
            </div>
        </div>
    );
}

export default View;
