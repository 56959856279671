import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import UserAccount from '../../view/dialog/userAccount';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [tab, setTab] = useState("account");
    const [errors, setErrors] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [data, setData] = useState({});
    const [viewID, setViewID] = useState(Math.random());

    const context = useContext(Context);

    const changeTab = (tab) =>
    {
        setTab(tab);
        setViewID(Math.random());
    };

    const save = (newData) =>
    {
        let newErrors = {};
        let data      = {};

        if(tab === "account")
        {
            if(!Validator.notEmpty(newData?.email))
            {
                newErrors["email"] = i18n.t('error.invalid_email');            
            }
            else if(!Validator.email(newData?.email))
            {
                newErrors["email"] = i18n.t('error.invalid_email');
            } 
            
            if(!Validator.notEmpty(newData?.mobile))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }
            
            if(!Validator.numeric(newData?.mobile))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }        
            
            if(!Validator.notEmpty(newData?.mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }
            
            if(!Validator.numeric(newData?.mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }   
            
            data["email"] = newData?.email;
            data["mobile"] = newData?.mobile;
            data["mobile_code"] = newData?.mobile_code;

            if(Object.keys(newErrors)?.length === 0)
            {
                setErrors({});
                setLoading(true);
    
                isMounted && HTTP.put(Endpoint?.crm?.user?.data, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));  
                        
                        context?.setDialog(null);                        
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                    }
    
                    setLoading(false);
                });
            }
            else
            {
                setErrors(newErrors);
            }             
        }
        else if(tab === "personal")
        {
            if(!Validator.notEmpty(newData?.first_name))
            {
                newErrors["name"] = i18n.t('error.invalid_first_name');            
            }
            else if(!Validator.notEmpty(newData?.last_name))
            {
                newErrors["name"] = i18n.t('error.invalid_last_name');            
            }
    
            if(!Validator.notEmpty(newData?.gender))
            {
                newErrors["gender"] = i18n.t('error.invalid_gender');            
            }
            else if(!["male", "female"].includes(newData?.gender?.toLowerCase()))
            {
                newErrors["gender"] = i18n.t('error.invalid_gender');            
            }
            
            if(!Validator.notEmpty(newData?.nationality_code))
            {
                newErrors["nationality_code"] = i18n.t('error.invalid_nationality');            
            }
            
            if(!Validator.notEmpty(newData?.government_id))
            {
                newErrors["government_id"] = i18n.t('error.invalid_government_id');            
            }    
            
            data["first_name"] = newData?.first_name;
            data["last_name"] = newData?.last_name;
            data["gender"] = newData?.gender;
            data["nationality_code"] = newData?.nationality_code;  
            data["government_id"] = newData?.government_id;  
            
            if(Object.keys(newErrors)?.length === 0)
            {
                setErrors({});
                setLoading(true);
    
                if(newData?.avatar_img)
                {
                    isMounted && HTTP.post(Endpoint?.crm?.user?.avatar, {avatar_img:newData?.avatar_img}).then(response =>
                    {
                        if(response?.is_successful && response?.data?.info?.avatar_img)
                        {  
                            context?.setPrefs({"user_avatar": response?.data?.info?.avatar_img});            
                        }
                    });                    
                }

                isMounted && HTTP.put(Endpoint?.crm?.user?.data, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));  
                        
                        context?.setDialog(null);                        
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                    }
    
                    setLoading(false);
                });
            }
            else
            {
                setErrors(newErrors);
            }             
        }
        else if(tab === "password")
        {
            if(!Validator.notEmpty(newData?.password))
            {
                newErrors["password"] = i18n.t('error.invalid_password');            
            }
            else if(!Validator.longPassword(newData?.password))
            {
                newErrors["password"] = i18n.t('error.short_password');
            }  
            
            if(!Validator.notEmpty(newData?.password_new))
            {
                newErrors["password_new"] = i18n.t('error.invalid_password');            
            }
            else if(!Validator.longPassword(newData?.password_new))
            {
                newErrors["password_new"] = i18n.t('error.short_password');
            }
            else if(newData?.password_new === newData?.password)
            {
                newErrors["password_new"] = i18n.t('error.new_password_similar_to_old');
            }   

            if(!Validator.notEmpty(newData?.password_new_confirmation))
            {
                newErrors["password_new_confirmation"] = i18n.t('error.invalid_password');            
            }
            else if(!Validator.longPassword(newData?.password_new_confirmation))
            {
                newErrors["password_new_confirmation"] = i18n.t('error.short_password');
            } 
            else if(newData?.password_new_confirmation !== newData?.password_new)
            {
                newErrors["password_new_confirmation"] = i18n.t('error.passwords_do_not_match');
            } 
            
            data["old_password"] = newData?.password;  
            data["new_password"] = newData?.password_new;  

            if(Object.keys(newErrors)?.length === 0)
            {
                setErrors({});
                setLoading(true);
    
                isMounted && HTTP.post(Endpoint?.crm?.user?.password, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));        
                        
                        context?.setDialog(null);                        
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                    }
    
                    setLoading(false);
                });
            }
            else
            {
                setErrors(newErrors);
            }             
        }       
    };

    useEffect(() =>
    {
        isMounted && HTTP.get(Endpoint?.crm?.user?.data).then(response =>
        {
            if(response?.is_successful && response?.data?.info)
            {        
                setData(response?.data?.info);
            }            

            isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
            {
                if(response?.is_successful && response?.data?.list)
                {        
                    setCountryList(response?.data?.list);
                }  

                setLoading(false);
            });
        });

    }, [isMounted]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <UserAccount
            key={viewID}
            isLoading={isLoading}
            prefs={context?.prefs}
            tab={tab}
            countryList={countryList}
            errors={errors}
            setTab={changeTab}
            save={save}
            data={data}
        />
    );
}

export default Dialog;