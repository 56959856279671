import React, { useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Image from './image';

import i18n from '../../util/i18n';

import Constant from '../../config/constant';

import AvatarImage from '../../assets/image/avatar.webp';
import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';

const View = (props) =>
{
    const selectorRef = useRef(null);
    const listRef = useRef(null);

    const [isListOpen, setListOpen] = useState(false);

    const clickOutside = (e) =>
    {
        if(!listRef.current?.contains(e?.target) && !selectorRef.current?.contains(e?.target))
        {
            setListOpen(false);
        }
    };

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);

        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    return (
        <div className="text-neutral-low-dark relative">
            <div className={`h-[50px] flex bg-neutral-high-regular active:bg-neutral-high-light hover:bg-neutral-high-dark cursor-pointer rounded-full transition duration-normal ease-in-out ${props?.className ?? ""}`} onClick={() => {setListOpen(!isListOpen)}} ref={selectorRef}>
                <div className="m-[7px] flex">
                    {(props?.imageURL)
                    ?
                        <Image src={props?.imageURL} alt="" className="h-[36px] w-[36px] rounded-full m-auto"/>
                    :
                        <div className="h-[36px] w-[36px] flex m-auto bg-neutral-high-light rounded-full">
                            <Image src={AvatarImage} alt="" className="h-[28px] w-[28px] m-auto"/>
                        </div>
                    }
                </div>
                <div className="ms-[7px] me-[35px] my-[7px] py-[2px] hidden lg:flex lg:justify-between lg:flex-col">
                    <div className="text-xs font-primary">
                        {props?.userFullName}
                    </div>
                    <div className="text-xs">
                        {props?.merchantName}
                    </div>
                </div>
                <div className="my-[7px] mx-[15px] hidden sm:flex">
                    {(props?.prefs?.theme === "light")
                    ?
                        <Image src={isListOpen ? ChevronUpDarkIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>                
                    :
                        <Image src={isListOpen ? ChevronUpLightIconImage : ChevronDownLightIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>                
                    }
                </div>
            </div>

            {(isListOpen)
            ?
                <div className={`absolute w-[225px] ${(props?.prefs?.dir === "rtl") ? "left-0" : "right-0"} top-[100%] z-30 text-sm`}>
                    <AnimatePresence>
                        {isListOpen &&
                        <motion.div
                            ref={listRef}
                            style={{overflow:"hidden"}}
                            initial={{ opacity: 0, height:0 }}
                            animate={{ opacity: 1, height:"auto"}}
                            exit={{ opacity: 0, height:0 }}
                            transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                            className={`bg-neutral-high-lightest w-full shadow-sm shadow-shadow-light rounded-lg mt-3 border-neutral-high-light border-[1px]`}
                        >
                            <div className="p-3 rounded-t-lg transition-all duration-normal ease-in-out cursor-pointer hover:bg-neutral-high-light border-neutral-high-light border-b-[1px]" onClick={() => {setListOpen(false); props?.openDialog("UserAccount", i18n.t("page.my_account"))}}>
                                {i18n.t("page.my_account")}
                            </div>
                            <div className="p-3 transition-all duration-normal ease-in-out cursor-pointer hover:bg-neutral-high-light border-neutral-high-light border-b-[1px]" onClick={() => {setListOpen(false); props?.redirectTo("/directory")}}>
                                {i18n.t("page.directory")}
                            </div>
                            <div className="p-3 transition-all duration-normal ease-in-out cursor-pointer hover:bg-neutral-high-light border-neutral-high-light border-b-[1px]" onClick={() => {setListOpen(false); props?.changeLang()}}>
                                {(props?.prefs?.lang === "ar") ? i18n.t("lang.en") : i18n.t("lang.ar")}
                            </div>
                            <div className="p-3 rounded-b-lg transition-all duration-normal ease-in-out cursor-pointer hover:bg-neutral-high-light" onClick={() => {setListOpen(false); props?.redirectTo("/logout")}}>
                                {i18n.t("page.logout")}
                            </div>
                        </motion.div>}
                    </AnimatePresence>
                </div>
            :
                null
            }
        </div>
    )
}

export default View;