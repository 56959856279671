import { useState } from "react";

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">

                <div className="mb-7">
                    <InputText 
                        type={"text"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.email")}
                        placeholder={"email@example.com"}
                        defaultValue={data?.email}
                        errorMsg={props?.errors?.email}
                        onChange={(e) => {update("email", e?.target?.value)}}
                    />
                </div>
              
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={true} onClick={() => {}}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext(data)}/>
            </div>  
        </div>
    );
}

export default View;
