import Constant from '../config/constant';

const LocalStorage =
{
    init:function()
    {
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        localStorage.setItem("lang", Constant?.lang?.default);
        localStorage.setItem("dir", Constant?.direction?.default);
        localStorage.setItem("dir_alt", Constant?.direction?.alternative);
        localStorage.setItem("theme", Constant?.theme?.default);
        localStorage.setItem("currency", Constant?.currency?.default);
        localStorage.setItem("is_logged_in", "0");
        localStorage.setItem("user_first_name", "");
        localStorage.setItem("user_last_name", "");
        localStorage.setItem("user_avatar", "");
        localStorage.setItem("user_role_code", "");
        localStorage.setItem("merchant_id", "");
        localStorage.setItem("merchant_wallet_id", "");
        localStorage.setItem("merchant_display_name", "");
        localStorage.setItem("merchant_country_code", "sa");
        localStorage.setItem("notification_last_update", "");
        localStorage.setItem("map_data", "{}");
    },

    set:function(key, value)
    {
        if(key === "lang")
        {
            if(Constant?.lang?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), Constant?.lang?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());
            }
        }
        else if(key === "dir")
        {
            if(Constant?.direction?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(),Constant?.direction?.default);
                localStorage.setItem("dir_alt", Constant?.alternative?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());

                if(value?.toLowerCase() === "ltr")
                {
                    localStorage.setItem("dir_alt","rtl");
                }
                else
                {
                    localStorage.setItem("dir_alt","ltr");
                }
            }
        }
        else if(key === "theme")
        {
            if(Constant?.theme?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), Constant?.theme?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());
            }
        }
        else if(key === "currency" && Constant?.currency?.values?.indexOf(value?.toLowerCase()) === -1)
        {
            if(Constant?.currency?.values?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), Constant?.currency?.default);
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value?.toLowerCase());
            }
        }
        else if(key === "is_logged_in")
        {
            if(["0", "1"]?.indexOf(value?.toLowerCase()) === -1)
            {
                localStorage.setItem(key?.toLowerCase(), "0");
            }
            else
            {
                localStorage.setItem(key?.toLowerCase(), value);
            }
        }
        else
        {
            localStorage.setItem(key?.toLowerCase(), value);
        }
    },

    unset:function(key)
    {
        localStorage.removeItem(key);
    },

    get:function(key)
    {
        return (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) ? localStorage.getItem(key) : undefined;
    },

    getAll:function()
    {
        if(!localStorage.getItem("dir"))
        {
            this.init();
        }

        var keys = Object.keys(localStorage);
        var values = {};

        for(var key of keys)
        {            
            values[key] = localStorage.getItem(key);
        }

        return values;
    },

    destroy:function()
    {
        localStorage.clear();

        this.init();
    }
}

export default LocalStorage;