import { useCallback, useEffect } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import Image from '../component/image';

import CloseIconImage from '../../assets/vector/icon_close_dark.svg';

const View = (props) =>
{
    const escape = useCallback((event) =>
    {
        if (event.key === "Escape")
        {
            props?.close()
        }
      }, [props]);
    
    useEffect(() =>
    {
            document.addEventListener("keydown", escape, false);

        return () => {
            document.removeEventListener("keydown", escape, false);
        };
    }, [escape]);

    return(
        <AnimatePresence  mode="wait" initial={false}>
            {props?.dialog?.id && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: Constant?.animation?.speed?.normal/1000, ease: easeInOut }}
                    className="z-40 fixed top-0 left-0 w-full h-full overflow-y-auto bg-backdrop-regular backdrop-blur-md"
                    onClick={props?.close}
                >
                    <div ref={props?.dialogRef} className="h-full w-full overflow-scroll p-5 flex justify-center items-center">       
                        <motion.div
                                initial={{ opacity: 0, translateY: -2000 }}
                                animate={{ opacity: 1, translateY: 0 }}
                                exit={{ opacity: 0, translateY: -2000 }}
                                transition={{ duration: 0.7, ease: easeInOut }}
                                className={`${props?.dialog?.bg_color ?? "bg-neutral-high-regular"} rounded-xl shadow-2xl shadow-shadow-light m-auto ${props?.dialog?.is_fullscreen ? " w-full max-w-screen-xl mx-auto" : props?.dialog?.size === "sm" ? " w-[90%] lg:w-[500px]" : " w-[90%] lg:w-[800px]"}`}
                                onClick={(event) => { event.stopPropagation() }}
                            >
                            <div className="rounded-t-xl bg-primary-regular flex justify-between py-4 px-7">
                                <div className="my-auto font-primary">
                                    {props?.dialog?.title}
                                </div>
                                
                                {(props?.dialog?.is_closable)
                                ?
                                    <div className="opacity-25 hover:opacity-10 cursor-pointer transition duration-normal ease-in-out my-auto" onClick={props?.close}>
                                        <Image src={CloseIconImage} alt="" className="h-[36px] w-[36px] cursor-pointer transition duration-normal ease-in-out"/>
                                    </div>    
                                :
                                    <></>                        
                                }
                            </div>
                            <div data-lenis-prevent>
                                {props?.children}
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default View;
