import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import Context from '../../util/context';
import HTTP from '../../util/http';
import i18n from '../../util/i18n';

import DeliverySurvey from '../../view/dialog/deliverySurvey';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const context = useContext(Context);

    const save = (data) =>
    {
        if(data?.length > 0)
        {
            setLoading(true);

            let params =
            {
                "tracking_number":props?.dialog?.metadata?.tracking_number,
                "pin":props?.dialog?.metadata?.pin,
                "survey_code":"lastmile_exit_recipient",
                "answers":JSON.stringify(data)
            };

            isMounted && HTTP.post(Endpoint?.cms?.survey?.response, params).then(response =>
            {
                if(response?.is_successful)
                {        
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);                     
                } 
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }                
            });        
        }
        else
        {
            context?.setNotification("error", i18n.t("error.title"), i18n.t("error.empty_survey"));
        }
    };

    useEffect(() =>
    {
        if(props?.dialog?.metadata?.tracking_number && props?.dialog?.metadata?.pin)
        {
            setLoading(true);

            let params =
            {
                "tracking_number":props?.dialog?.metadata?.tracking_number,
                "pin":props?.dialog?.metadata?.pin,
                "survey_code":"lastmile_exit_recipient"
            };
    
            isMounted && HTTP.get(Endpoint?.cms?.survey?.data, params).then(response =>
            {
                if(response?.is_successful && response?.data?.info)
                {        
                    setData(response?.data?.info);
                    
                    setLoading(false);
                } 
                else
                {
                    window.location.replace('./error/404');
                }                
            });
        }

    }, [isMounted, props?.dialog?.metadata?.tracking_number, props?.dialog?.metadata?.pin]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DeliverySurvey
            isLoading={isLoading}
            prefs={context?.prefs}
            data={data}
            save={save}
        />
    );
}

export default Dialog;