import React, { useMemo } from "react";

const View = (props) =>
{
    const { src, className} = props;

    const memoizedImage = useMemo(() =>
    {
        const img = new Image();

        img.src = src;
        img.alt = "";

        if(className)
        {
            img.className = className;
        }

        return img;

    }, [src, className]);

    return <img src={memoizedImage.src} alt={memoizedImage.alt} className={memoizedImage.className} onClick={(e) => {if(props?.onClick) {props?.onClick(e)}}}
    loading="lazy"/>;
};

export default View;