import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import i18n from '../../util/i18n';
import Context from '../../util/context';
import Endpoint from '../../config/endpoint';
import HTTP from '../../util/http';

import FAQs from '../../view/page/faqs';
import Header from '../../view/component/header';
import Footer from '../../view/component/footer';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState({});

    const context  = useContext(Context);
    const navigate = useNavigate();

    const changeLang = () =>
    {
        if(context?.prefs?.lang === "ar")
        {
            context?.setPrefs({"lang": "en"});            

            i18n.changeLanguage("en");
        }
        else
        {
            context?.setPrefs({"lang": "ar"});            

            i18n.changeLanguage("ar");
        }
    };

    const showLoginDialog = () =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");
        }
        else
        {
            navigate("/directory");
        }
    };

    const showSignupDialog = (persona, subscriptionType = null) =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("Signup", i18n.t("page.signup"), "bg-secondary-lightest", "sm", false, false, true, false, {persona:persona, subscriptionType:subscriptionType, merchant_code:null, invitation_code:null, email:null, action:null});
        }
        else
        {
            navigate("/directory");
        }        
    };

    const showPasswordResetDialog = (persona) =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("PasswordReset", i18n.t("page.reset_password"), "bg-secondary-lightest", "sm");
        }
        else
        {
            navigate("/directory");
        }
    };

    useEffect(() =>
    {
        setLoading(true);

        isMounted && HTTP.get(Endpoint?.cms?.faq.list).then(response =>
        {
            setList(response?.data?.list);

            setLoading(false);
        });

    }, [isMounted, context?.prefs?.lang]);

    useEffect(() =>
    {  
        const checkConnection = (isOnline) =>
        {
            if(!isOnline)
            {
                navigate("/error/disconnected");
            }     
        };

        window.addEventListener('online', () => checkConnection(true));
        window.addEventListener('offline', () => checkConnection(false));

        return () =>
        {
            window.removeEventListener('online', () => checkConnection(true));
            window.removeEventListener('offline', () => checkConnection(false));
        };
    }, [navigate]);
    
    useEffect(() =>
    {
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.faqs');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <>
            <Header
                changeLang={changeLang}
                showLoginDialog={showLoginDialog}
                showCaptainSignUpDialog={() => showSignupDialog("captain")}
                showMerchantSignUpDialog={() => showSignupDialog("owner", "basic")}
                prefs={context?.prefs}
                isHome={false}
                isFixed={true}
                isMinimal={false}
            /> 
            <FAQs
                prefs={context?.prefs}
                isLoading={isLoading}
                list={list}
            />
            <Footer
                showLoginDialog={showLoginDialog}
                showCaptainSignUpDialog={() => showSignupDialog("captain")}
                showMerchantSignUpDialog={() => showSignupDialog("owner", "basic")}
                showPasswordResetDialog={showPasswordResetDialog}
                prefs={context?.prefs}
            />                    
        </>
    );
}

export default Controller;