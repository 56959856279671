import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';

import i18n from "../../util/i18n";
import Formatter from "../../util/formatter";

import Skelton from "./skelton";


const View = (props) =>
{
    const loadingRef = useRef(null);

    return (
        <table className="table-auto w-full bg-neutral-high-lightest rounded-xl shadow-xl">
            <thead>
                <tr className="rounded-xl">
                    {props?.labels?.sort((a,b) => a?.order > b?.order)?.map((label, index) =>
                    {
                        return(
                        <th key={index} className={`${(label?.minXL) ? "hidden xl:table-cell" : (label?.minLG) ? "hidden lg:table-cell" : (label?.minMD) ? "hidden md:table-cell" : (label?.minSM) ? "hidden sm:table-cell" : (label?.minXS) ? "hidden xs:table-cell" : ""} text-center p-4 bg-primary-regular text-secondary-dark font-normal ${(index === 0 && props?.prefs?.dir === "rtl") ? "rounded-tr-xl" : (index === 0 && props?.prefs?.dir === "ltr") ? "rounded-tl-xl" : (index === props?.labels?.length-1 && props?.prefs?.dir === "rtl") ? "rounded-tl-xl" : (index === props?.labels?.length-1 && props?.prefs?.dir === "ltr") ? "rounded-tr-xl" : ""}`}>
                            {(label?.isStatusIndicator) ? "" : label?.title}
                        </th>)
                    })}
                </tr>
            </thead>
            <tbody>

                <AnimatePresence>
                {props?.isLoading &&
                    <tr>
                        <td colSpan={props?.labels?.length} className="p-5 text-center" >
                                <motion.div 
                                    ref={loadingRef}
                                    initial={{ opacity: 0, height:0}}
                                    animate={{ opacity: 0.9, height:"auto"}}
                                    exit={{ opacity: 0, height:0}}    
                                    transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}  
                                    className="overflow-hidden"
                                >
                                    <Skelton className="bg-secondary-regular my-5" count={5}/>
                                </motion.div>
                        </td>
                    </tr>}
                </AnimatePresence> 

                {(!props?.isLoading && props?.list?.length === 0)
                ?
                    <tr>
                        <td colSpan={props?.labels?.length} className="p-5 text-center">
                            {i18n.t("common.no_results_found")}                              
                        </td>
                    </tr>
                :
                (!props?.isLoading && props?.list?.length > 0)
                ?
                    props?.list?.map((delivery, rowIndex) =>
                    {
                        return(
                            <tr key={rowIndex}>
                                {props?.labels?.sort((a,b) => a?.order > b?.order)?.map((label, cellIndex) =>
                                {
                                    return(
                                        <td key={cellIndex} className={`transition ease-in-out duration-normal ${(label?.minXL) ? "hidden xl:table-cell" : (label?.minLG) ? "hidden lg:table-cell" : (label?.minMD) ? "hidden md:table-cell" : (label?.minSM) ? "hidden sm:table-cell" : (label?.minXS) ? "hidden xs:table-cell" : ""} text-center p-4 ${(rowIndex%2 !== 0) ? "bg-neutral-high-light" : "bg-neutral-high-lightest"} ${(rowIndex === props?.list?.length-1 && cellIndex === 0 && props?.prefs?.dir === "rtl") ? "rounded-br-xl" : (rowIndex === props?.list?.length-1 && cellIndex === 0 && props?.prefs?.dir === "ltr") ? "rounded-bl-xl" : (rowIndex === props?.list?.length-1 && cellIndex === props?.labels?.length-1 && props?.prefs?.dir === "rtl") ? "rounded-bl-xl" : (rowIndex === props?.list?.length-1 && cellIndex === props?.labels?.length-1 && props?.prefs?.dir ===  "ltr") ? "rounded-br-xl" : ""}`}>
                                            {(label?.isStatusIndicator) ? <div className={`h-[10px] w-[10px] ${Formatter?.statusCodeToColorCode(delivery?.[label?.id])} rounded-full`}/> : <div onClick={() => {if(label?.dialogID && delivery?.[label?.dialogDataID]){props?.showDialog(label?.dialogID, label?.dialogTitle, delivery?.[label?.dialogDataID])}}} className={(label?.dialogID && delivery?.[label?.dialogDataID]) ? "underline cursor-pointer" : ""}>{delivery?.[label?.id]}</div>}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                :
                    null
                }
            </tbody>
        </table>
    )
}

export default View;