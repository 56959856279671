import i18next from 'i18next';
import { initReactI18next } from "react-i18next";

import ar from '../locale/ar/string.json';
import en from '../locale/en/string.json';

const resources =
{
	ar:
	{
		translation: ar
	},
    en:
	{
		translation: en
	}
};

i18next.use(initReactI18next).init(
{
	lng: localStorage.getItem('lang') ?? 'ar',
	debug: false,
	resources: resources
});

export default i18next;