import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import UserInfo from '../../view/dialog/userInfo';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isDataLoading, setDataLoading] = useState(false);
    const [isRoleLoading, setRoleLoading] = useState(false);
    const [isWalletLoading, setWalletLoading] = useState(false);
    const [isZoneListLoading, setZoneListLoading] = useState(false);
    const [isCountryListLoading, setCountryListLoading] = useState(false);
    const [isSkillListLoading, setSkillListLoading] = useState(false);
    const [isDeliveryListLoading, setDeliveryListLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [walletInfo, setWalletInfo] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [deliveryList, setDeliveryList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [roleCode, setRoleCode] = useState(undefined)

    const context = useContext(Context);

    const updateCaptainProfile = (key, value) =>
    {
        setDataLoading(true);

        let data = {};
        data["user_id"] = props?.dialog?.metadata?.id;
        data["role_code"] = "captain";
        data[key] = value;

        isMounted && HTTP.put(Endpoint?.crm?.profile?.data, data).then(response =>
        {
            if(response?.is_successful)
            {    
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));  
                
                let temp = {...userInfo};

                if(!temp["profiles"]?.["captain"])
                {
                    temp["profiles"]["captain"] = {};
                }
        
                temp["profiles"]["captain"][key] = value;

                setUserInfo(temp);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setDataLoading(false);
        });
    };

    const updateSkill = (skillID) =>
    {
        setDataLoading(true);

        let temp = {...userInfo};
        
        if(!temp["profiles"]?.["captain"])
        {
            temp["profiles"]["captain"] = {};
        }

        let skills = [];

        if(temp["profiles"]?.["captain"]?.["skills"])
        {
            skills = [...temp["profiles"]["captain"]["skills"]];
        }

        let index = skills.indexOf(skillID);
        let data = {};
        data["user_id"] = props?.dialog?.metadata?.id;
        data["role_code"] = "captain";
        data["skill_id"] = skillID;

        if(index !== -1)
        {
            skills = skills.filter(skill => skill !== skillID);

            isMounted && HTTP.delete(Endpoint?.crm?.profile?.skill, data).then(response =>
            {
                if(response?.is_successful)
                {    
                    temp["profiles"]["captain"]["skills"] = skills;

                    setUserInfo(temp);                    
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                }
    
                setDataLoading(false);
            });            
        }
        else
        {
            skills.push(skillID);

            isMounted && HTTP.post(Endpoint?.crm?.profile?.skill, data).then(response =>
            {
                if(response?.is_successful)
                {    
                    temp["profiles"]["captain"]["skills"] = skills;

                    setUserInfo(temp);                    
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                }
    
                setDataLoading(false);
            });             
        }
    };

    const updateRole = (newRoleCode) =>
    {
        setRoleLoading(true);

        let params = {};
        params["user_id"] = props?.dialog?.metadata?.id;
        params["role_code"] = newRoleCode;

        isMounted && HTTP.put(Endpoint?.crm?.merchant?.user, params).then(response =>
        {
            if(response?.is_successful)
            {
                setRoleCode(newRoleCode);
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
            }

            setRoleLoading(false);
        });         
    };

    const remove = () =>
    {
        setDataLoading(true);
        setRoleLoading(true);
        setWalletLoading(true);
        setZoneListLoading(true);
        setCountryListLoading(true);
        setSkillListLoading(true);
        setDeliveryListLoading(true);       

        let params = {};
        params["user_id"] = props?.dialog?.metadata?.id;

        isMounted && HTTP.delete(Endpoint?.crm?.merchant?.user, params).then(response =>
        {
            if(response?.is_successful)
            {
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                setTimeout(() =>
                {
                    context?.setDialog(null);   
                
                    window.location.reload();  
                
                }, Constant.animation?.speed?.normal);                
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setDataLoading(false);
                setRoleLoading(false);
                setWalletLoading(false);
                setZoneListLoading(false);
                setCountryListLoading(false);
                setSkillListLoading(false);
                setDeliveryListLoading(false);                  
            }
        });  
    };        

    const showDelivery = (deliveryID) =>
    {
        window.scrollTo(0, 0);

        context?.setDialog("DeliveryInfo", i18n.t("page.delivery_info"), "bg-neutral-high-lightest", "sm", true, true, true, false, {id:deliveryID});
    };

    const resetRiskLimit = (newRiskLimit) =>
    {
        if(!Validator.notEmpty(newRiskLimit) || !Validator.numeric(newRiskLimit) || newRiskLimit < 0)
        {
            context?.setNotification("error", i18n.t("error.title"), i18n.t("error.invalid_risk_limit"));
        }        
        else
        {
            setWalletLoading(true);

            isMounted && HTTP.post(Endpoint?.fms?.riskLimit?.data, {owner_id:props?.dialog?.metadata?.id, type_code:"user", amount:newRiskLimit}).then(response =>
            {
                if(response?.is_successful)
                {    
                    setWalletInfo({risk_limit:newRiskLimit});
                          
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                }

                setWalletLoading(false);
            });
        }          
    };

    const preventAdminAction = () =>
    {
        context?.setNotification("error", i18n.t("error.observer_not_allowed_title"), i18n.t("error.observer_not_allowed_msg"));
    };

    useEffect(() =>
    {  
        setDataLoading(true);
        setRoleLoading(true);
        setZoneListLoading(true);
        setCountryListLoading(true);
        setSkillListLoading(true);

        let params = {};

        if(props?.dialog?.metadata?.id)
        {    
            params["user_id"] = props?.dialog?.metadata?.id;
    
            isMounted && HTTP.get(Endpoint?.crm?.user?.data, params).then(response =>
            {
                if(response?.is_successful && response?.data?.info)
                {                    
                    setUserInfo(response.data.info);

                    if(response.data.info?.profiles?.captain)
                    {
                        setWalletLoading(true);
                        setDeliveryListLoading(true);

                        isMounted && HTTP.get(Endpoint?.fms?.riskLimit?.data, {owner_id:props?.dialog?.metadata?.id, type_code:"user"}).then(response =>
                        {
                            setWalletInfo({risk_limit:response.data.info?.risk_limit});
                            
                            setWalletLoading(false);
                        });

                        isMounted && HTTP.get(Endpoint?.oms?.user?.delivery, {user_id:props?.dialog?.metadata?.id}).then(response =>
                        {
                            setDeliveryList(response.data.list);
                            
                            setDeliveryListLoading(false);
                        });                        
                    }
                }

                setDataLoading(false);
            });
        }

        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            setCountryListLoading(false);
        });

        isMounted && HTTP.get(Endpoint?.crm?.skill?.list, {skill_type:"private"}).then(response =>
        {            
            setSkillList(response?.data?.list);

            setSkillListLoading(false);
        });

        params                 = {};
        params["country_code"] = context?.prefs?.merchant_country_code;
        params["set_type"]     = "full";
        params["type_code"]    = "private";

        isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setZoneList(response?.data?.list);
            }

            setZoneListLoading(false);
        });

        params            = {};
        params["user_id"] = props?.dialog?.metadata?.id;

        isMounted && HTTP.get(Endpoint?.crm?.user?.merchant, params).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                const item = response?.data?.list?.find(item => item?.merchant_id === context?.prefs?.merchant_id);

                if(item)
                {
                    setRoleCode(item?.role_code);
                }
            }

            setRoleLoading(false);
        }); 

        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            setCountryListLoading(false);
        });        
        
    }, [isMounted, props?.dialog?.metadata?.id, context?.prefs?.merchant_country_code, context?.prefs?.merchant_id]);
        
    useEffect(() =>
    {
        return () =>
        {            
            setMounted(false);
        };

    }, []);   

    return (
        <UserInfo
            isDataLoading={isDataLoading}
            isRoleLoading={isRoleLoading}
            isWalletLoading={isWalletLoading}
            isZoneListLoading={isZoneListLoading}
            isCountryListLoading={isCountryListLoading}
            isSkillListLoading={isSkillListLoading}
            isDeliveryListLoading={isDeliveryListLoading}
            prefs={context?.prefs}
            data={userInfo}
            walletData={walletInfo}
            roleCode={roleCode}
            skillList={skillList?.map((skill) => {return {title:skill?.["name_" + context?.prefs?.lang], value:skill?.skill_id}})}
            zoneList={zoneList?.map((zone) => {return {title:zone?.["name_" + context?.prefs?.lang], value:zone?.zone_id}})}
            countryList={countryList?.map((country) => {return {title:country?.["name_" + context?.prefs?.lang], value:country?.country_code}})}
            deliveryList={deliveryList}
            updateCaptainProfile={updateCaptainProfile}
            updateSkill={updateSkill}
            updateRole={updateRole}
            remove={remove}
            showDelivery={showDelivery}
            resetRiskLimit={resetRiskLimit}
            preventAdminAction={preventAdminAction}
        />
    );
}

export default Dialog;