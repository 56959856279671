import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState({});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="mb-7">
                <div className="mb-2">
                    {i18n.t("common.bank_name")}
                </div>
                <InputDropdown
                    list={props?.banksList}
                    listValue={data?.bank_code}
                    searchInputPlaceholder={i18n.t("common.search_generic")}
                    isDisabled={props?.isLoading}
                    isListSorted={true}
                    isListReversed={false}
                    isSearchable={true}
                    isCompact={true}
                    theme={props?.prefs?.theme}
                    handleValueUpdate={(value) => {update("bank_code", value)}}
                />                                
                <AnimatePresence>
                    {props?.errors?.bank_code && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm mb-2`}
                        >
                            {props?.errors?.bank_code}
                        </motion.div>
                    )}
                </AnimatePresence>                
            </div>
            <div className="mb-7">
                <InputText 
                    type={"text"}
                    containerClassName="mb-5"
                    enableAutoComplete={false}
                    label={i18n.t("common.bank_account_holder")}
                    errorMsg={props?.errors?.holder_name}
                    onChange={(e) => {update("holder_name", e?.target?.value)}}
                />                                     
            </div>
            <div className="mb-7 flex gap-2">
                <InputText 
                    type={"text"}
                    containerClassName="mb-5"
                    enableAutoComplete={false}
                    label={i18n.t("common.bank_account")}
                    errorMsg={props?.errors?.bank_account_number}
                    onChange={(e) => {update("bank_account_number", e?.target?.value)}}
                />                                     

                <InputText 
                    type={"text"}
                    containerClassName="mb-5"
                    enableAutoComplete={false}
                    label={i18n.t("common.bank_iban")}
                    errorMsg={props?.errors?.bank_iban_number}
                    onChange={(e) => {update("bank_iban_number", e?.target?.value)}}
                />                                     
            </div> 
            <div className="flex justify-end">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.add")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.submit(data)}/>
            </div>                                     
        </div>
    );
}

export default View;
