import axios from 'axios';

import LocalStorage from './localStorage';
import i18n from './i18n';

import Constant from '../config/constant';
import Endpoint from '../config/endpoint';

const HTTP =
{
    get:async function(url, data = {}, header = undefined)
    {
        return await this.createRequest("get", url, data, header);
    },

    post:async function(url, data = {}, header = undefined)
    {
        return await this.createRequest("post", url, data, header);
    },

    put:async function(url, data = {}, header = undefined)
    {
        return await this.createRequest("put", url, data, header);
    },

    delete:async function(url, data = {}, header = undefined)
    {
        return await this.createRequest("delete", url, data, header);
    },

    createRequest:async function(type, url, data, header)
    {
        let payload =
        {
            method: type,
            url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
            headers: header ?? this.parseHeader(type?.toLowerCase() === "post")
        }

        if(type?.toLowerCase() === "post")
        {
            payload["data"] = data;
        }
        else
        {
            payload["params"] = data;
        }

        return axios(payload).then(response =>
        {
            return this.parseResponse(response);
        })
        .catch(error =>
        {
            return this.handleError(error);
        });
    },

    retryExpiredRequest:async function(type, url, data, params, errorCode)
    {
        return axios(
        {
            method: "put",
            url: `${process.env.REACT_APP_API_BASE_URL}/${Endpoint.crm.user.token}`,
            headers: this.parseHeader(false),
            params: {refresh_token: LocalStorage.get("refresh_token")}
        })
        .then(response =>
        {
            if(response?.data?.data?.info?.access_token && response?.data?.data?.info?.refresh_token)
            {
                LocalStorage.set("access_token", response?.data?.data?.info?.access_token);
                LocalStorage.set("refresh_token", response?.data?.data?.info?.refresh_token);
                
                return axios(
                {
                    method: type,
                    url: url,
                    headers: this.parseHeader(type?.toLowerCase() === "post"),
                    data: data,
                    params: params
                })
                .then(response =>
                {
                    return this.parseResponse(response);
                })
                .catch(error =>
                {
                    return this.handleError(error);
                });
            }
            else
            {
                return {
                    error_code: errorCode,
                    error_msg: i18n.t("error.session_expired"),
                    is_successful: false
                };
            }
        })
        .catch(error =>
        {
            return {
                error_code: errorCode,
                error_msg: i18n.t("error.session_expired"),
                is_successful: false
            };
        });
    },

    parseHeader: function(isPost)
    {
        let header =
        {
            "Accept": "*/*",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",                
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-api-version': process.env.REACT_APP_API_VERSION,
            'x-api-endpoint': process.env.REACT_APP_API_ENDPOINT,
            'x-api-lang': LocalStorage?.get("lang"),
            'x-api-token': LocalStorage?.get("access_token")
        };

        if(isPost)
        {
            header["Content-Type"] = "multipart/form-data";
        }

        return header
    },

    parseResponse: function(response)
    {
        return (response?.data) ??
        {
            is_successful: false,
            error_code: 1000,
            error_msg: i18n.t("error.generic")
        }
    },

    handleError:async function(error)
    {
        if(error?.response?.data)
        {
            if(error?.response?.data?.error_code && Constant?.interceptor?.token?.includes(parseInt(error?.response?.data?.error_code)))
            {
                return await this.retryExpiredRequest(error.response.config.method?.toLowerCase(), error.config.url, error.config.data ? JSON.parse(error.config.data) : {}, error.config.params, parseInt(error?.response?.data?.error_code));
            }
            else
            {
                return error?.response?.data;
            }
        }
        else
        {
            return {
                is_successful: false,
                error_code: 1000,
                error_msg: i18n.t("error.generic")
            };
        }
    },
}

export default HTTP;