import { PieChart, Pie, Cell } from 'recharts';

import Constant from '../../config/constant';

import Formatter from '../../util/formatter';

const View = (props) =>
{
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) =>
    {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            (percent > 0)
            ?
                <text x={x} y={y} fill={(props?.theme === "light") ? "#000000" : "#ffffff"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
                </text>
            :
                null
        )
    };

    return (
        <div className="flex flex-col">
            <div className="flex justify-center">
                <PieChart width={220} height={220}>
                    <Pie
                        data={props?.data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        innerRadius={60}
                        dataKey="value"
                    >
                        {props?.data?.map((entry, index) =>
                        (
                            <Cell key={`cell-${index}`} fill={Constant?.color?.chart?.default[index % Constant?.color?.chart?.default?.length]}/>
                        ))}
                    </Pie>
                </PieChart>
            </div>
            <div className="text-start flex flex-col gap-3 mt-2 mb-5">
                {props?.data?.map((entry, index) =>
                (
                    <div className="flex gap-2" key={`${index}`}>
                        <div className="w-[10px] h-[10px] my-auto rounded-full" style={{backgroundColor:Constant?.color?.chart?.default[index % Constant?.color?.chart?.default?.length]}}/>
                        <div className="my-auto">{entry?.name} ({Formatter?.stringToNumberWithCommas(entry?.value)})</div>
                    </div>
                ))}                
            </div>
        </div>
    )
}

export default View;