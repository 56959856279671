import { useId } from 'react';

const View = (props) =>
{
    const randomID = useId();

    return(
        <div className={`${props?.containerClassName} w-full flex`}>
            <div className="pe-3">
                <input type="checkbox" name="checkbox" id={randomID} value={props?.value} className="accent-primary-regular" checked={props?.isChecked} onChange={(e) => {props?.handleValueChange(e)}}/>
            </div>
            <div>
                <label htmlFor={randomID}>{props?.label}</label> 
            </div>           
        </div>
    );
}

export default View;
