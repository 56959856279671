import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import UserSkill from '../../view/dialog/userSkill';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [renderID, setRenderID] = useState(0);
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const add = (data) =>
    {
        let newErrors = {};

        if(!Validator.notEmpty(data?.name_ar))
        {
            newErrors["name_ar"] = i18n.t('error.invalid_name_ar');            
        }
        else if(!Validator.notEmpty(data?.name_en))
        {
            newErrors["name_en"] = i18n.t('error.invalid_name_en');
        }

        if(Object.keys(newErrors)?.length === 0)            
        {
            setErrors({});

            setLoading(true);

            let params =
            {
                name_ar:data?.name_ar?.toLowerCase(),
                name_en:data?.name_en?.toLowerCase()
            };

            isMounted && HTTP.post(Endpoint?.crm?.skill?.data, params).then(response =>
            {                
                if(response?.is_successful)
                {        
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setRenderID(Math.random());
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setLoading(false);
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }   
    };

    const remove = (index) =>
    {
        setLoading(true);

        let params =
        {
            skill_id:index
        };

        isMounted && HTTP.delete(Endpoint?.crm?.skill?.data, params).then(response =>
        {                
            if(response?.is_successful)
            {        
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                setRenderID(Math.random());
            }
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setLoading(false);
            }
        });
    };

    useEffect(() =>
    {
        setLoading(true);

        isMounted && HTTP.get(Endpoint?.crm?.skill?.list).then(response =>
        {                
            if(response?.is_successful && response?.data?.list)
            {        
                setList(response?.data?.list);         
            }

            setLoading(false);
        });

    }, [isMounted, renderID]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);    

        
    return (
        <UserSkill
            key={renderID}
            isLoading={isLoading}
            prefs={context?.prefs}
            list={list}
            errors={errors}
            add={add}
            remove={remove}
        />
    );
}

export default Dialog;