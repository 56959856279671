import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const Dialog = props => 
{
    const [data, setData] = useState({
		email:""
	});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

	return (
        <div className="font-secondary text-sm text-neutral-low-dark">
            <div className="flex w-full">
				<div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "multiple") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("multiple")}}>
                    <div className="m-auto p-4">{i18n.t("user.invite_multiple_user")}</div>
                </div>				
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "single") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("single")}}>
                    <div className="m-auto p-4">{i18n.t("user.invite_single_user")}</div>
                </div>
            </div>            
            <div className="px-7 pt-10 pb-7 font-secondary text-sm">
                {(props?.tab === "multiple")
                ?
					<div className="font-secondary text-sm text-neutral-low-dark">
						<div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
							{i18n.t("user.tip_01")}
						</div>
						<div className="pb-7">
							<InputText 
								type={"text"}
								enableAutoComplete={false}
								isDisabled={true}
								label={i18n.t("common.url_invitation")}
								defaultValue={props?.invitationURL}
								onChange={(e) => {}}
							/>                                                                       
						</div>                                            
					</div>                    
                :
                (props?.tab === "single")
                ?
					<div className="font-secondary text-sm text-neutral-low-dark">
						<div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
							{i18n.t("user.tip_02")}
						</div>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("common.role")}
                            </div>
                            <InputDropdown
                                list={[
                                        {title:i18n.t("common.maintainer"), value:"maintainer"}, 
                                        {title:i18n.t("common.observer"), value:"observer"}
                                    ]}
                                listValue={data?.role_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("role_code", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.role_code && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.role_code}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div> 							
						<div className="pb-7">
							<InputText 
								type={"text"}
                                placeholder="email@example.com"
								enableAutoComplete={false}
								isDisabled={props?.isLoading}
								label={i18n.t("common.email")}
								errorMsg={props?.errors?.email}
								value={data?.email}
								onChange={(e) => {update("email", e?.target?.value)}}
							/>                                                                       
						</div>   					                                         
					</div>     					
                :
                    null
                }

                <div className="flex justify-end">
                    <Button type="neutral-low-solid" className="min-w-[120px]" text={(props?.tab === "multiple") ? i18n.t("common.copy") : (props?.tab === "single") ? i18n.t("common.send") : i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.invite(data)}/>
                </div>
            </div>
        </div>
	);
}

export default Dialog;