
import { useState, useEffect } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import Image from '../component/image';
import InputText from '../component/inputText';

import ChevronDownDarkIconImage from '../../assets/vector/icon_chevron_down_dark.svg';
import ChevronUpDarkIconImage from '../../assets/vector/icon_chevron_up_dark.svg';
import ChevronDownLightIconImage from '../../assets/vector/icon_chevron_down_light.svg';
import ChevronUpLightIconImage from '../../assets/vector/icon_chevron_up_light.svg';
import TrashDarkIconImage from '../../assets/vector/icon_trash_dark.svg';
import TrashLightIconImage from '../../assets/vector/icon_trash_light.svg';
import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';

import Button from '../component/button';

const View = (props) =>
{
    const [viewDelayRate, setViewDelayRate] = useState(Constant?.animation?.speed?.fast/1000);
    const [data, setData] = useState(props?.data ?? []);
    const [errors, setErrors] = useState([]);
    const [removedChecklists, removeChecklist] = useState([]);
    const [openedSections, openSection] = useState(data?.map(item => item.status_code));
    const sections = [
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_assigned") + ")",
            key:"assigned"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_confirmed") + ")",
            key:"confirmed"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_dispatched") + ")",
            key:"dispatched"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_delivered") + ")",
            key:"delivered"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_complete") + ")",
            key:"complete"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_rejected_by_customer_before_dispatch") + ")",
            key:"rejected_by_customer_before_dispatch"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_rejected_by_customer_after_dispatch") + ")",
            key:"rejected_by_customer_after_dispatch"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_returned_after_rejection") + ")",
            key:"returned_after_rejection"
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_onhold") + ")",
            key:"onhold"            
        },
        {
            title:i18n.t("trip.when_delivery_status_is") + " (" + i18n.t("delivery.status_cancelled") + ")",
            key:"cancelled"            
        }
    ];

    const toggleSection = (key) =>
    {
        let clone = [...openedSections];

        let index = openedSections?.indexOf(key);

        if(index !== -1)
        {
            clone?.splice(index, 1);
        }
        else
        {
            clone?.push(key);
        }

        openSection(clone);
    };

    const handleAdd = (statusCode) =>
    {
        let clone = [...data];

        clone.push({
            name_ar:null,
            name_en:null,
            status_code:statusCode,
            _id:"temp_" + (Math.floor(Date.now() / 1000)) + "" + (Math.floor(100000 + Math.random() * 900000))
        });

        setData(clone);
    };

    const handleValueChange = (index, key, value) =>
    {
        let clone = [...data];

        if(clone[index])
        {
            clone[index][key] = value;
        }

        setData(clone);
    };

    const handleRemove = (index) =>
    {          
        if(!removedChecklists?.includes(data[index]?._id))
        {
            removeChecklist([...removedChecklists, ...[data[index]?._id]]);
        }
    };   
    
    const progress = (direction) =>
    {
        let newErrors = {};
        let clone = []

        data?.forEach(checklist =>
        {
            if(!removedChecklists.includes(checklist?._id))
            {
                let tempChecklist = {...checklist};

                if(!tempChecklist?.name_ar || tempChecklist?.name_ar === "" || tempChecklist?.name_ar?.length === 0 || 
                !tempChecklist?.name_en || tempChecklist?.name_en === "" || tempChecklist?.name_en?.length === 0)
                {
                    newErrors[checklist?.status_code] = i18n.t("error.all_fields_are_required");
                }
                
                delete tempChecklist?._id;

                clone.push(tempChecklist);
            }
        });

        if(Object.keys(newErrors)?.length > 0)
        {
            setErrors(newErrors);
            
            props?.showErrorNotification(i18n.t("error.some_fields_are_missing"));

            props?.scrollToTop();
        }
        else
        {
            setErrors([]);

            if(direction === "prev")
            {
                props?.prev(clone);
            }
            else if(direction === "next")
            {
                props?.next(clone);
            }
        }        
    };

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setViewDelayRate(0);

        }, Constant?.animation?.speed?.slow);
    
        return () => clearTimeout(timer);
    }, []);

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
                {i18n.t("trip.tip_04")}
            </div>
            {sections?.map((section, index) =>
                {
                    return (
                        <div key={index} className="border-[1px] border-neutral-high-dark rounded-lg mb-7 shadow-sm">
                            <div className={`flex justify-between p-4 cursor-pointer transition duration-normal ease-in-out font-bold hover:bg-neutral-high-light ${openedSections?.includes(section?.key) ? "rounded-t-lg border-b-[1px] border-neutral-high-dark" : "rounded-lg"}`} onClick={() => toggleSection(section?.key)}>
                                <div>
                                {section?.title}
                                </div>
                                <div className="my-auto">
                                    {openedSections?.includes(section?.key)
                                    ?
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronUpLightIconImage : ChevronUpDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    :
                                        <Image src={props?.prefs?.theme === "dark" ? ChevronDownLightIconImage : ChevronDownDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                    }
                                </div>
                            </div>
                            <AnimatePresence>
                                {openedSections?.includes(section?.key) && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ delay: viewDelayRate, duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    >
                                        <div className="p-4 w-full">
                                            {(data?.filter(item => (item?.status_code === section?.key && !removedChecklists?.includes(item?._id)))?.length > 0)
                                            ?
                                                <>
                                                    {data?.map((checklist, key) =>
                                                    {
                                                        return (
                                                        (checklist?.status_code === section?.key && !removedChecklists?.includes(checklist?._id))
                                                        ?
                                                            <div key={key} className="flex gap-2 mb-2">
                                                                <div className="grow my-auto">
                                                                    <InputText 
                                                                        type={"text"}
                                                                        enableAutoComplete={false}
                                                                        isDisabled={props?.isLoading}
                                                                        defaultValue={checklist?.name_ar}
                                                                        onChange={(e) => handleValueChange(key, "name_ar", e?.target?.value)}
                                                                    />
                                                                </div>
                                                                <div className="grow my-auto">
                                                                    <InputText 
                                                                        type={"text"}
                                                                        enableAutoComplete={false}
                                                                        isDisabled={props?.isLoading}
                                                                        defaultValue={checklist?.name_en}
                                                                        onChange={(e) => handleValueChange(key, "name_en", e?.target?.value)}
                                                                    />                                                            
                                                                </div>
                                                                <div className="my-auto">
                                                                    <Button 
                                                                        type="neutral-low-outline" 
                                                                        className="min-w-[50px]" 
                                                                        icon={(props?.prefs?.theme === "dark") ? TrashLightIconImage : TrashDarkIconImage}
                                                                        iconHover={(props?.prefs?.theme === "dark") ? TrashDarkIconImage : TrashLightIconImage}
                                                                        isLoading={props?.isLoading}  
                                                                        isDisabled={props?.isLoading} 
                                                                        onClick={() => handleRemove(key)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        :
                                                            null
                                                    )})}

                                                    <AnimatePresence>
                                                        {errors?.[`${section?.key}`] && (
                                                            <motion.div 
                                                                initial={{ height:0, opacity:0 }}
                                                                animate={{ height: "auto", opacity:1 }}
                                                                exit={{ height:0, opacity:0 }}
                                                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                                            >
                                                                {errors?.[`${section?.key}`]}
                                                            </motion.div>
                                                        )}
                                                    </AnimatePresence>                                                       
                                                </>
                                            :
                                                <div className="w-full text-center text-neutral-low-light">
                                                    {i18n.t("trip.no_checklist")}
                                                </div>
                                            }
                                        </div>
                                        <div className="flex justify-end mx-4 mb-4 pt-4 border-t-[1px] border-neutral-high-dark">
                                            <Button 
                                                type="neutral-low-solid" 
                                                className="min-w-[50px]" 
                                                icon={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                                                iconHover={(props?.prefs?.theme === "dark") ? AddLightIconImage : AddDarkIconImage}
                                                isLoading={props?.isLoading}  
                                                isDisabled={props?.isLoading} 
                                                onClick={() => handleAdd(section?.key)}
                                            />                                            
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>                            
                        </div>
                    )
                })
            }  
            <div className="flex justify-between">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => progress("prev")}/>
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => progress("next")}/>
            </div>                                                
        </div>
    );
}

export default View;
