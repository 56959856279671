import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import ZoneCreate from '../../view/dialog/zoneCreate';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [countryList, setCountryList] = useState([]);
    const [errors, setErrors] = useState({});

    const context = useContext(Context);

    const submit = (newData) =>
    {
        let newErrors = {};

        if(!Validator.notEmpty(newData?.country_code))
        {
            newErrors["country_code"] = i18n.t('error.invalid_country_code');            
        }
        
        if(!Validator.notEmpty(newData?.name_ar))
        {
            newErrors["name_ar"] = i18n.t('error.invalid_name_ar');
        }
        
        if(!Validator.notEmpty(newData?.name_en))
        {
            newErrors["name_en"] = i18n.t('error.invalid_name_en');
        }
        
        if(!Validator.notEmpty(newData?.center_lat))
        {
            newErrors["center_lat"] = i18n.t('error.invalid_center_lat');
        }
        else if(!Validator.numeric(newData?.center_lat))
        {
            newErrors["center_lat"] = i18n.t('error.invalid_center_lat');
        }        

        if(!Validator.notEmpty(newData?.center_long))
        {
            newErrors["center_long"] = i18n.t('error.invalid_center_long');
        }
        else if(!Validator.numeric(newData?.center_long))
        {
            newErrors["center_long"] = i18n.t('error.invalid_center_long');
        } 

        if(!Validator.notEmpty(newData?.geofence))
        {
            newErrors["geofence"] = i18n.t('error.invalid_geofence');
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setLoading(true);

            let data = {};
    
            data["country_code"] = newData?.country_code;
            data["name_ar"]  = newData?.name_ar;
            data["name_en"] = newData?.name_en;                
            data["center_lat"] = newData?.center_lat;                
            data["center_long"] = newData?.center_long;                
            data["geofence"] = newData?.geofence;

            isMounted && HTTP.post(Endpoint?.oms?.zone?.data, data).then(response =>
            {
                if(response?.is_successful)
                {    
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);                 
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
    
                    setLoading(false);
                }
            });
        }  
        else
        {
            setErrors(newErrors);
        }                         
    };
    

    useEffect(() =>
    {
        setLoading(true);
        
        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            setLoading(false);
        });

    }, [isMounted]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <ZoneCreate
            isLoading={isLoading}
            prefs={context?.prefs}
            errors={errors}
            countryList={countryList?.map((country) => {return {title:`${country?.[`name_${context?.prefs?.lang}`]}`, value:country?.country_code}})}
            submit={submit}
        />
    );
}

export default Dialog;