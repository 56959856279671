import {Polygon} from '@react-google-maps/api';

const View = (props) =>
{
    const fenceOptions =
    {
        fillColor: (props?.context?.prefs?.theme === "default" || props?.context?.prefs?.theme === "light") ? "#000000" : "#ffd400",
        fillOpacity: 0.2,
        strokeColor: "#ffd400",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
    };

    var fence = [];

    for(var point of props?.data)
    {
        fence.push({ lat: parseFloat(point?.["lat"]), lng: parseFloat(point?.["long"]) });
    };
    
    return <Polygon path={fence} options={fenceOptions}/>;
}

export default View;