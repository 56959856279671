const endpoint =
{
    bi: {
        snapshot: {
            data: `bi/snapshot/data`
       },
    },
    cms: {
        announcement: {
            list: `cms/announcement/list`
        },
        bank: {
            list: `cms/bank/list`
        },
        bankAccount: {
            list: `cms/bankAccount/list`
        },
        country: {
            list: `cms/country/list`
        },
        currency: {
            list: `cms/currency/list`
        },
        faq: {
            list: `cms/faq/list`
        },
        page: {
            data: `cms/page/data`
        },
        pricing: {
            data: `cms/pricing/data`
        },
        survey: {
            data: `cms/survey/data`,
            response: `cms/survey/response`
        },
        metadata: {
            survey: `cms/metadata/survey`
        }
    },
    crm: {
        authorization: {
            email: `crm/authorization/email`,
            sms: `crm/authorization/sms`
        },
        merchant: {
            apiKey: `crm/merchant/apiKey`,
            cr: `crm/merchant/cr`,
            data: `crm/merchant/data`,
            fleet: `crm/merchant/fleet`,
            subscription: `crm/merchant/subscription`,
            user: `crm/merchant/user`
        },
        skill: {
            data: `crm/skill/data`,
            list: `crm/skill/list`
        },
        user: {
            access: `crm/user/access`,
            avatar: `crm/user/avatar`,
            data: `crm/user/data`,
            invitation: `crm/user/invitation`,
            merchant: `crm/user/merchant`,
            password: `crm/user/password`,
            shift: `crm/user/shift`,
            token: `crm/user/token`
        },
        profile: {
            data: `crm/profile/data`,
            driverLicense: `crm/profile/driverLicense`,
            vehicleRegistration: `crm/profile/vehicleRegistration`,
            insurance: `crm/profile/insurance`,
            preferences: `crm/profile/preferences`,
            skill: `crm/profile/skill`,
            shift: `crm/profile/shift`
        }
    },
    fms: {
        bankAccount: {
            data: `fms/bankAccount/data`,
            list: `fms/bankAccount/list`
        },
        credit: {
            redirection: `fms/credit/redirection`
        },
        debit: {
            directDeposit: `fms/debit/directDeposit`
        },
        history: {
            balance: `fms/history/balance`,
            riskLimit: `fms/history/riskLimit`
        },
        riskLimit:
        {
            data: `fms/riskLimit/data`
        },
        wallet: {
            data: `fms/wallet/data`,
        }
    },
    oms: {
        authorization: {
            email: `oms/authorization/email`,
            sms: `oms/authorization/sms`
        },
        collection: {
            list: `oms/collection/list`,
            data: `oms/collection/data`,
            approval: `oms/collection/approval`
        },
        delivery: {
            checklist: `oms/delivery/checklist`,
            data: `oms/delivery/data`,
            list: `oms/delivery/list`,
            scan: `oms/delivery/scan`,
            status: `oms/delivery/status`
        },
        map: {
            captain: `oms/map/captain`,
            merchant: `oms/map/merchant`
        },
        optimizer: {
            route: `oms/optimizer/route`
        },        
        trip: {
            checklist: `oms/trip/checklist`,
            checkpoint: `oms/trip/checkpoint`,
            commission: `oms/trip/commission`,
            data: `oms/trip/data`,
            list: `oms/trip/list`,
            sla: `oms/trip/sla`,
            status: `oms/trip/status`
        },
        user: {
            delivery: `oms/user/delivery`,
        },
        zone: {
            list: `oms/zone/list`,
            data: `oms/zone/data`
        }
    }
};

export default endpoint;