import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import Context from '../../util/context';
import HTTP from '../../util/http';
import i18n from '../../util/i18n';

import DeliveryLocationUpdate from '../../view/dialog/deliveryLocationUpdate';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [mapCenter] = useState({lat:Constant?.map?.center?.lat, long:Constant?.map?.center?.long});
    const [markLocation, setMarkLocation] = useState({lat:Constant?.map?.center?.lat, long:Constant?.map?.center?.long});

    const context = useContext(Context);

    const handleDragging = (newLocation) =>
    {
        setMarkLocation(newLocation);
    };
    
    const save = () =>
    {
        setLoading(true);

        let params =
        {
            "tracking_number":props?.dialog?.metadata?.tracking_number,
            "pin":props?.dialog?.metadata?.pin,
            "recipient_lat":markLocation?.lat,
            "recipient_long":markLocation?.long
        };

        isMounted && HTTP.put(Endpoint?.oms?.delivery?.data, params).then(response =>
        {
            if(response?.is_successful)
            {        
                context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                setTimeout(() =>
                {
                    context?.setDialog(null);   
                
                    window.location.reload();  
                
                }, Constant.animation?.speed?.normal);                     
            } 
            else
            {
                context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                setLoading(false);
            }  
        });         
    };
      
    useEffect(() =>
    {
        if(props?.dialog?.metadata?.tracking_number)
        {
            setLoading(true);

            let params =
            {
                "tracking_number":props?.dialog?.metadata?.tracking_number,
            };
    
            isMounted && HTTP.get(Endpoint?.oms?.delivery?.data, params).then(response =>
            {
                if(response?.is_successful)
                {                            
                    setLoading(false);
                } 
                else
                {
                    window.location.replace('./error/404');
                }                
            });
        }

    }, [isMounted, props?.dialog?.metadata?.tracking_number]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DeliveryLocationUpdate
            isLoading={isLoading}
            prefs={context?.prefs}
            mapCenter={mapCenter}
            handleDragging={handleDragging}
            save={save}
        />
    );
}

export default Dialog;