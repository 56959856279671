import i18n from '../../util/i18n';

import Image from '../component/image';

import PlayIconImage from '../../assets/vector/icon_play_dark.svg';

const View = (props) =>
{
    return(
        <div id="section-1" className="pt-[100px] bg-primary-regular font-secondary">
            <div className="container max-w-screen-lg mx-auto py-5 px-10">
                <div className="max-w-[800px] mt-[50px] lg:mt-[100px] mb-[100px] lg:mb-0">
                    <div className="font-primary text-xl leading-10 mb-5">
                        {i18n.t("home.section_01_title_01")}
                    </div>
                    <div className="text-lg leading-10 lg:leading-[50px]">
                        {i18n.t("home.section_01_content_01")}
                    </div>
                </div>
                <div className="flex justify-end">
                    <div className="hero-button-wrapper transition-all duration-normal ease-in-out mt-[-50px] lg:mt-[-20px] xl:mt-0 2xl:mt-[3%]">
                        <div className="hero-button font-primary text-md bg-secondary-lightest transition-all duration-normal ease-in-out" onClick={props?.showIntroVideoDialog}>
                            <Image src={PlayIconImage} alt="" className="h-[32px] w-[32px] m-auto"/>
                        </div>
                    </div>
                </div>                     
            </div>
            <div className="p-0">                   
                <svg viewBox="0 0 1920 329" width="100%" preserveAspectRatio="none" className="p-0">
                    {(props?.prefs?.dir === "rtl")
                    ?
                        <path className="fill-secondary-lightest" d="M 1920 0 L 1615 212 a 556.46 556.46 0 0 1 -436 70 L 0 0 V 330 H 1920 Z"/>
                    :
                        <path className="fill-secondary-lightest" d="M 1920 0 L 949 290 a 556.46 556.46 0 0 1 -400 -27 L 0 0 V 330 H 1920 Z"/>
                    }
                </svg>
            </div>
        </div>
    );
}

export default View;
