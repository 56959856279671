import { useState, useEffect, useContext } from 'react';

import Context from '../../util/context';
import Validator from '../../util/validator';
import i18n from '../../util/i18n';

import WalletCredit from '../../view/dialog/walletCredit';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [tab, setTab] = useState("new");
    const [errors, setErrors] = useState({});
    const [viewID, setViewID] = useState(Math.random());
    const defaultAmount = 100;

    const context = useContext(Context);

    const changeTab = (tab) =>
    {
        setTab(tab);
        setViewID(Math.random());
    };

    const setAmount = (amount) => 
    {
        let newErrors = {};

        if(!Validator.notEmpty(amount))
        {
            newErrors["amount"] = i18n.t('error.invalid_amount');
        }
        else if(!Validator.numeric(amount))
        {
            newErrors["amount"] = i18n.t('error.invalid_amount');
        }
        else if(amount <= 0)
        {
            newErrors["amount"] = i18n.t('error.invalid_amount');
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            document.getElementsByClassName("mysr-form-button")[0].innerHTML = "";
            document.getElementsByClassName("mysr-form-button")[0].disabled = false;
            window?.Moyasar.setAmount(amount*100);
        }
        else
        {
            document.getElementsByClassName("mysr-form-button")[0].disabled = true;
            document.getElementsByClassName("mysr-form-button")[0].innerHTML = i18n.t("common.pay");
        }

        setErrors(newErrors);
    };

    useEffect(() =>
    {  
        window?.Moyasar.init(
        {
            element: '.form-payment-credit-wallet',
            language: 'ar',
            amount: defaultAmount*100,
            currency: 'SAR',
            description: 'Wallet Topup',
            publishable_api_key: process.env.REACT_APP_MOYASAR_API_KEY,
            callback_url: 'https://rest-dev.lastmile.sa/v1/fms/credit/redirection',
            methods: ['creditcard'],
            supported_networks: ['mada', 'visa', 'mastercard', 'amex'],
            metadata:
            {
                'wallet_id': context?.prefs?.merchant_wallet_id,
                'callback_type': 'topup'
            }                    
        });

    }, [isMounted, context?.prefs?.merchant_wallet_id]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <WalletCredit
            key={viewID}
            tab={tab}
            prefs={context?.prefs}
            errors={errors}
            defaultAmount={defaultAmount}
            walletInfo={props?.dialog?.metadata?.walletInfo}
            setTab={changeTab}    
            setAmount={setAmount}  
        />
    );
}

export default Dialog;