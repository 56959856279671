import { useState } from 'react';

import Formatter from '../../util/formatter';
import i18n from '../../util/i18n';

import InputText from '../component/inputText';

const View = (props) =>
{
    const [amount, setAmount] = useState(props?.defaultAmount);

    const handleChange = (newAmount) =>
    {
        const altNumbers = {"٠":0, "١":1, "٢":2, "٣":3, "٤":4, "٥":5, "٦":6, "٧":7, "٨":8, "٩":9};

        for(const [key, value] of Object.entries(altNumbers))
        {
            const regex = new RegExp(key, 'g');
            
            newAmount = newAmount.replace(regex, value);
        }

        props?.setAmount(newAmount);

        setAmount(newAmount);
    }

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="text-center">
                {i18n.t("wallet.balance")}
            </div>
            <div className="font-primary text-center text-lg mb-7">
                {Formatter.stringToNumberWithCommasAndDicimals(props?.walletInfo?.balance)} {i18n.t("currency." + props?.prefs?.currency)}
            </div>            
            <div className="mb-[0px] md:w-[340px] m-auto">
                <InputText 
                    type={"text"}
                    enableAutoComplete={false}
                    inputClassName="bg-secondary-lightest text-secondary-darkest placeholder-secondary-regular h-[40px] border-[1px] shadow shadow-shadow-lightest"
                    placeholder={i18n.t("common.amount")}
                    label={i18n.t("common.amount")}
                    isRequired={true}
                    value={amount}
                    errorMsg={props?.errors?.amount}
                    onChange={(e) => {handleChange(e?.target?.value)}}
                />
                <div className="mt-7">
                    {i18n.t("common.card_holder")}
                    <span className="text-accent-regular">*</span>
                </div>
            </div>      
            <div id={`form-payment-credit-wallet-${props?.prefs?.dir}`}>
                <div className="form-payment-credit-wallet"/>
            </div>
        </div>
    );
}

export default View;
