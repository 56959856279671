import i18n from '../../util/i18n';

import Formatter from '../../util/formatter';

import Image from '../component/image';
import Button from '../component/button';

import ChevronLeftDarkIconImage from '../../assets/vector/icon_chevron_left_dark.svg';
import ChevronLeftLightIconImage from '../../assets/vector/icon_chevron_left_light.svg';
import ChevronRightDarkIconImage from '../../assets/vector/icon_chevron_right_dark.svg';
import ChevronRightLightIconImage from '../../assets/vector/icon_chevron_right_light.svg';
import DangerDarkIconImage from '../../assets/vector/icon_danger_dark.svg';
import DangerLightIconImage from '../../assets/vector/icon_danger_light.svg';
import WarningDarkIconImage from '../../assets/vector/icon_warning_dark.svg';
import WarningLightIconImage from '../../assets/vector/icon_warning_light.svg';
import MessageDarkIconImage from '../../assets/vector/icon_messsage_dark.svg';
import MessageLightIconImage from '../../assets/vector/icon_messsage_light.svg';

const View = (props) =>
{
    const handleClick = (item) =>
    {
        if(item?.destination === "url_internal")
        {
            props?.internalRedirect(item?.url);
        }
        else if(item?.destination === "url_external")
        {
            props?.externalRedirect(item?.url);
        }
        else if(item?.destination === "dialog")
        {
            props?.showDialog(item?.id, item?.title, item?.bgColor, item?.size, item?.isScrollable, item?.isFullscreen, item?.isClosable, item?.shouldRefreshOnClose, item?.metadata);
        }       
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            {(props?.notifications?.length > 0)
            ?
                <>
                    {props?.notifications?.map((item, index) =>
                    {
                        return (
                            <div 
                                key={index}
                                className={`py-5 ${(item?.destination) ? "cursor-pointer hover:opacity-40" : ""}  transition duration-normal ease-in-out ${(index === props?.notifications?.length-1) ? "" : "border-b-[1px] border-neutral-high-regular"}`}
                                onClick={() => handleClick(item)}
                            >
                                <div className="flex">
                                    <div className="m-auto">
                                        <div className="rounded-full w-20px h-20px p-2 m-auto bg-neutral-high-regular">
                                            {(item?.type === "danger")
                                            ?
                                                <Image src={(props?.prefs?.theme === "light") ? DangerDarkIconImage : DangerLightIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                            :
                                            (item?.type === "warning")
                                            ?
                                                <Image src={(props?.prefs?.theme === "light") ? WarningDarkIconImage : WarningLightIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                            :
                                            (item?.type === "message")
                                            ?                                        
                                                <Image src={(props?.prefs?.theme === "light") ? MessageDarkIconImage : MessageLightIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>
                                            :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="flex-grow px-7">
                                        <div className="mb-2">
                                            {item?.msg}
                                        </div>
                                        <div className="text-xs text-neutral-low-light">
                                            {Formatter?.dateToString(item?.date)}
                                        </div>
                                    </div>
                                    <div className="m-auto">
                                        {
                                            (item?.destination && props?.prefs?.theme === "light")
                                        ?
                                            <Image src={(props?.prefs?.dir === "rtl") ? ChevronLeftDarkIconImage : ChevronRightDarkIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>                
                                        :
                                            (item?.destination && props?.prefs?.theme === "dark")
                                        ?
                                            <Image src={(props?.prefs?.dir === "rtl") ? ChevronLeftLightIconImage : ChevronRightLightIconImage} alt="" className="h-[16px] w-[16px] m-auto"/>                
                                        :
                                            null
                                        }                                    
                                    </div>                                
                                </div>
                            </div>                      
                        )
                    })}
                    <div className="flex justify-end pt-5">
                        <Button type="neutral-low-solid" text={i18n.t("common.mark_all_read")} isLoading={false}  isDisabled={false} onClick={props?.markAllAsRead}/>
                    </div>               
                </>
            :
                <div className="text-center py-10">
                    {i18n.t("error.missing_notifications")}
                </div>
            }           
        </div>
    );
}

export default View;
