import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';
import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';
import Image from '../component/image';
import Tooltip from '../component/tooltip';

import DollarDarkIcon from '../../assets/vector/icon_dollar_dark.svg';
import DollarLightIcon from '../../assets/vector/icon_dollar_light.svg';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="border-b-[1px] border-neutral-high-light mb-8 pb-4 text-justify leading-8">
                {i18n.t("trip.tip_01")}
            </div>
            <div className="pb-7">
                <div className="mb-7 flex gap-2">
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        isDisabled={props?.isLoading}
                        label={i18n.t("common.name_ar")}
                        value={data?.name_ar ?? props?.data?.name_ar}
                        errorMsg={props?.errors?.name_ar}
                        onChange={(e) => {update("name_ar", e?.target?.value)}}
                    />                                     
                    <InputText 
                        type={"text"}
                        enableAutoComplete={false}
                        isDisabled={props?.isLoading}
                        label={i18n.t("common.name_en")}
                        value={data?.name_en ?? props?.data?.name_en}
                        errorMsg={props?.errors?.name_en}
                        onChange={(e) => {update("name_en", e?.target?.value)}}
                    />                                     
                </div> 
                <div className="mb-7">
                    <div className="mb-2">
                        {i18n.t("trip.commission_type")}
                    </div>
                    <div className="w-[50%]">                        
                        <InputDropdown
                            list={[{title:i18n.t("common.none"), value:"none"}, {title:i18n.t("trip.time"), value:"time"}, {title:i18n.t("trip.distance"), value:"distance"}, {title:i18n.t("trip.distance_and_time"), value:"distance_and_time"}]}
                            listValue={data?.commission_type_code ?? props?.data?.commission_type_code}
                            searchInputPlaceholder={i18n.t("common.search_generic")}
                            isDisabled={props?.isLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {update("commission_type_code", value)}}                    
                        />
                    </div>
                    <AnimatePresence>
                        {props?.errors?.commission_type_code && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.commission_type_code}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div className="mb-7">
                    <div className="mb-2">
                        <div className="flex gap-1 mb-2">
                            <div className="my-auto">
                                {i18n.t("trip.auto_assignment_type")}
                            </div>
                            <div className="my-auto">
                                <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                    <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                </Tooltip>
                            </div>
                        </div>
                    </div>                    
                    <div className="w-[50%]">
                        <InputDropdown
                            list={[{title:i18n.t("common.none"), value:"none"}, {title:i18n.t("trip.random"), value:"random"}, {title:i18n.t("trip.last_logged_in"), value:"last_logged_in"}, {title:i18n.t("trip.least_number_of_orders"), value:"least_number_of_orders"}, {title:i18n.t("trip.nearest"), value:"nearest"}, {title:i18n.t("trip.round_robin"), value:"round_robin"}]}
                            listValue={data?.auto_assignment_type_code ?? props?.data?.auto_assignment_type_code}
                            searchInputPlaceholder={i18n.t("common.search_generic")}
                            isDisabled={props?.isLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {update("auto_assignment_type_code", value)}}                    
                        />
                    </div>
                    <AnimatePresence>
                        {props?.errors?.auto_assignment_type_code && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.auto_assignment_type_code}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>                
                <div className="mb-7">
                    <div className="mb-2">
                        <div className="flex gap-1 mb-2">
                            <div className="my-auto">
                                {i18n.t("trip.survey")}
                            </div>
                            <div className="my-auto">
                                <Tooltip text={i18n.t("trip.additional_cost")} className="p-2 bg-secondary-darkest text-secondary-lightest text-sm rounded-lg shadow-xl shadow-shadow-regular w-[200px] leading-6">
                                    <Image src={props?.prefs?.theme === "light" ? DollarDarkIcon : DollarLightIcon} alt="" className="h-[16px] w-[16px] m-auto cursor-pointer"/>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="w-[50%]">  
                        <InputDropdown
                            list={[{title:i18n.t("common.none"), value:"none"}, {title:i18n.t("trip.lastmile_exit_recipient"), value:"lastmile_exit_recipient"}, {title:i18n.t("trip.lastmile_exit_sender"), value:"lastmile_exit_sender"}, {title:i18n.t("trip.lastmile_exit_captain"), value:"lastmile_exit_captain"}]}
                            listValue={data?.survey_code ?? props?.data?.survey_code}
                            searchInputPlaceholder={i18n.t("common.search_generic")}
                            isDisabled={props?.isLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {update("survey_code", value)}}
                        />
                    </div>
                    <AnimatePresence>
                        {props?.errors?.survey_code && (
                            <motion.div 
                                initial={{ height:0, opacity:0 }}
                                animate={{ height: "auto", opacity:1 }}
                                exit={{ height:0, opacity:0 }}
                                transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                className={`text-status-danger-regular font-secondary text-sm mb-2`}
                            >
                                {props?.errors?.survey_code}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
            <div className={(props?.isDeletable) ? "flex justify-between" : "flex justify-end"}>
                {(props?.isDeletable)
                ?
                    <Button type="accent-outline" className="min-w-[120px]" text={i18n.t("common.remove")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.remove()}/>
                :
                    null
                
                }
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={() => props?.next(data)}/>
            </div>                                              
        </div>
    );
}

export default View;
