const View = (props) =>
{
    const { text, children, className } = props;

    return (
        <div className="tooltip">
            {children}
            <span className={`tooltipText transition-all duration-normal ease-in-out ${className}`}>{text}</span>
        </div>
    )
}

export default View;