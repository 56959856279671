import { useState, useEffect, useContext } from 'react';

import Constant from '../../config/constant';
import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Formatter from '../../util/formatter';

import MerchantSubscription from '../../view/dialog/merchantSubscription';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});

    const context = useContext(Context); 
    
    const next = (subscriptionCode, renewalTerm) =>
    {
        if(subscriptionCode?.toLowerCase() === "basic")
        {
            setErrors({"subscription_code":i18n.t("error.baisc_subscription_renewal")});
        }
        else
        {
            setLoading(true);                

            isMounted && HTTP.put(Endpoint?.crm?.merchant?.subscription, {subscription_code:subscriptionCode, renewal_term:renewalTerm}).then(response =>
            {
                if(response?.is_successful)
                {
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  

                    }, Constant.animation?.speed?.normal);                
                }
                else
                {
                    setLoading(false);

                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
                }            
            });
        }
    }

    useEffect(() =>
    {
        setLoading(true);                

        isMounted && HTTP.get(Endpoint?.cms?.pricing?.data, {pricing_code:"subscription"}).then(response =>
        {
            if(response?.is_successful && response?.data?.info)
            {
                setSubscriptionList(
                [
                    {
                        title:`${i18n.t("subscription.basic")} (${Formatter?.stringToNumberWithCommasAndDicimals(response?.data?.info?.basic_fee)} ${i18n.t("currency." + context?.prefs?.currency)} ${i18n.t("subscription.per_month")})`,
                        value:"basic",
                        amount:response?.data?.info?.basic_fee
                    },
                    {
                        title:`${i18n.t("subscription.essentials")} (${Formatter?.stringToNumberWithCommasAndDicimals(response?.data?.info?.essentials_fee)} ${i18n.t("currency." + context?.prefs?.currency)} ${i18n.t("subscription.per_month")})`,
                        value:"essentials",
                        amount:response?.data?.info?.essentials_fee
                    },
                    {
                        title:`${i18n.t("subscription.premium")} (${Formatter?.stringToNumberWithCommasAndDicimals(response?.data?.info?.premium_fee)} ${i18n.t("currency." + context?.prefs?.currency)} ${i18n.t("subscription.per_month")})`,
                        value:"premium",
                        amount:response?.data?.info?.premium_fee
                    },
                    {
                        title:`${i18n.t("subscription.enterprise")} (${Formatter?.stringToNumberWithCommasAndDicimals(response?.data?.info?.enterprise_fee)} ${i18n.t("currency." + context?.prefs?.currency)} ${i18n.t("subscription.per_month")})`,
                        value:"enterprise",
                        amount:response?.data?.info?.enterprise_fee
                    }
                ]);        
                
                isMounted && HTTP.get(Endpoint?.crm?.merchant?.data, {pricing_code:"subscription"}).then(response =>
                {     
                    if(response?.is_successful && response?.data?.info?.subscription?.subscription_code)
                    {
                        setData(response?.data?.info?.subscription)
                    }   

                    setLoading(false);                
                });
            }
        });

    }, [isMounted, context?.prefs?.currency]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <MerchantSubscription
            prefs={context?.prefs}
            isLoading={isLoading}
            subscriptionList={subscriptionList}
            data={data}
            errors={errors}
            next={next}          
        />         
    );
}

export default Dialog;