import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

import i18n from '../../util/i18n';

import Image from '../component/image';

import ImageForSection06AndElement01 from '../../assets/image/section_06_01.webp';

const View = (props) =>
{
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll(
    {
        target: targetRef,
        offset: ["start end", "end start"]
    });

    const textScale      = useTransform(scrollYProgress, [0.1, 0.3], [0.8, 1]);
    const textTranslateY = useTransform(scrollYProgress, [0.1, 0.3], [100, 0]);
    const textOpacity    = useTransform(scrollYProgress, [0.1, 0.3], [0.4, 1]);
    
    const thumbnailScale      = useTransform(scrollYProgress, [0.2, 0.4], [0.8, 1]);
    const thumbnailTranslateY = useTransform(scrollYProgress, [0.2, 0.4], [100, 0]);
    const thumbnailOpacity    = useTransform(scrollYProgress, [0.2, 0.4], [0.4, 1]);

    return(
        <div ref={targetRef} id="section-6" className="w-full font-secondary">
            <div className="p-0"> 
                <svg viewBox="0 0 1920 329" width="100%" preserveAspectRatio="none" className="p-0">
                    <path className="fill-secondary-light" d="M 1919 327 C 1609 230 1276 -138 326 199 L 0 330 V 330 H 1920 Z"/>
                </svg>
            </div>
            <div className="bg-secondary-light px-10">
                <div className="container max-w-screen-lg mx-auto">
                    <motion.div style={{ scale:textScale, translateY:textTranslateY, opacity:textOpacity }} className="text-secondary-darkest text-center font-primary text-xl pb-24">
                        {i18n.t("home.section_06_title_01")}
                    </motion.div>
                    <motion.div style={{ scale:thumbnailScale, translateY:thumbnailTranslateY, opacity:thumbnailOpacity }} className="text-center pb-[80px]">
                        <Image src={ImageForSection06AndElement01} alt="" className="rounded-3xl shadow-2xl shadow-shadow-light m-auto transition-all duration-normal ease-in-out"/>
                    </motion.div>
                </div>
            </div>
            <div className="p-0 bg-secondary-light"> 
                <svg viewBox="0 0 1920 329" width="100%" preserveAspectRatio="none" className="p-0">
                    <path className="fill-secondary-lightest" d="M 1920 0 L 732 288 a 556.46 556.46 0 0 1 -600 -154 L 0 0 V 330 H 1920 Z"/>
                </svg>
            </div>
        </div>
    );
}

export default View;
