import useMediaQuery from '../../hook/useMediaQuery';

import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import Image from '../component/image';
import Skelton from '../component/skelton';
import Badge from '../component/badge';
import Button from '../component/button';
import Table from '../component/table';
import ChartHorizontalBar from '../component/chartHorizontalBar';
import ChartLine from '../component/chartLine';
import ChartPie from '../component/chartPie';
import ChartRing from '../component/chartRing';
import ChartVerticalBar from '../component/chartVerticalBar';

import ArrowUpPositiveIconImage from '../../assets/vector/icon_arrow_up_positive.svg';
import ArrowUpIconNegativeImage from '../../assets/vector/icon_arrow_up_negative.svg';
import ArrowDownPositiveIconImage from '../../assets/vector/icon_arrow_down_positive.svg';
import ArrowDownNegativeIconImage from '../../assets/vector/icon_arrow_down_negative.svg';
import CalendarDarkIconImage from '../../assets/vector/icon_calendar_dark.svg';
import CalendarLightIconImage from '../../assets/vector/icon_calendar_light.svg';

const View = (props) =>
{
    const isScreenMedium  = useMediaQuery("(max-width: 768px)");
    const isScreenLarge   = useMediaQuery("(max-width: 1023px)");

    const getChange = (current, past, isReversed) =>
	{
        if(!props?.isSnapshotLoading)
        {
            let percentage = 0;
            let change     = 0;

            if(parseFloat(current) && parseFloat(past))
            {
                change = parseFloat(current) - parseFloat(past);

                if(past > 0)
                {
                    percentage = Math.round((change/past)*100);
                }
                else
                {
                    percentage = 0;
                }

            }
            
            return <div className={((percentage > 0 && !isReversed) || (percentage < 0 && isReversed)) ? "text-status-success-regular flex justify-center" : ((percentage > 0 && isReversed) || (percentage < 0 && !isReversed)) ? "text-status-danger-regular flex justify-center" : "text-neutral-high-darkest"}>
                        {(percentage !== 0) ? <Image src={(percentage > 0 && !isReversed) ? ArrowUpPositiveIconImage : (percentage > 0 && isReversed) ? ArrowUpIconNegativeImage : (percentage < 0 && !isReversed) ? ArrowDownNegativeIconImage : ArrowDownPositiveIconImage} alt="" className="h-[14px] w-[14px] md:h-[18px] md:w-[18px] my-auto"/> : null}
                        <div>
                            {`${Formatter.stringToNumberWithCommas(percentage)}%`}
                        </div>
                    </div>;
        }
        else
        {
            return <Skelton className="bg-secondary-regular max-w-[100px] mx-auto" count={1}/>;
        }
	};

    return(
        <div className={`h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-gradient-regular via-gradient-dark to-gradient-darkest flex flex-col`}>
            <div className="border-t-[1px] border-neutral-high-regular grow text-neutral-low-dark overflow-scroll mt-[150px] lg:mt-[92px]" data-lenis-prevent>
                <div className="flex flex-col px-5 transition ease-in-out duration-normal">
                    <div className="flex justify-between gap-5 mt-7 mb-10">
                        <div className="flex flex-col">
                            <div className="p-0 font-primary text-lg mb-4">
                                {i18n.t("overview.hi")}
                            </div>  
                            <div className="flex gap-2">
                                <div className="my-auto">
                                    <Image src={(props?.prefs?.theme === "light") ? CalendarDarkIconImage : CalendarLightIconImage} alt="" className="w-[32px]"/>                      
                                </div>
                                {(props?.isSnapshotLoading)
                                ?
                                    <div className="w-[250px] my-auto pb-2">
                                        <Skelton className="bg-secondary-regular w-[100%] m-auto" count={1}/>
                                    </div>
                                :
                                    <div className="my-auto pb-2">
                                        {Formatter?.dateToString(props?.snapshot?.last_updated_at ?? props?.snapshot?.created_at, "date")} ({`${i18n.t("common.last_updated_at")} ${Formatter?.dateToString(props?.snapshot?.last_updated_at ?? props?.snapshot?.created_at, "time")}`})
                                    </div>
                                }
                            </div>                                      
                        </div>                                  
                    </div>

                    <div className={`flex justify-between gap-5 ${isScreenMedium ? "flex-wrap" : ""}`}>
                        <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5">
                            <div className="flex justify-center">
                                <Badge type={"star"} theme={props?.prefs?.theme} isDark={true}/>
                            </div>
                            <div className="font-primary whitespace-nowrap">
                                {i18n.t("overview.summary_active_deliveries")}                        
                            </div>
                            <div className="p-0">
                                {(props?.isSnapshotLoading)
                                ?
                                    <Skelton className="bg-secondary-regular max-w-[100px] mx-auto" count={1}/>
                                :
                                    Formatter.stringToNumberWithCommas(props?.snapshot?.summary_active_deliveries?.current)
                                }
                            </div>
                            <div className="ltr">
                                {getChange(props?.snapshot?.summary_active_deliveries?.current, props?.snapshot?.summary_active_deliveries?.past, false)}
                            </div>
                        </div>

                        <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5">
                            <div className="flex justify-center">
                                <Badge type={"problem"} theme={props?.prefs?.theme} isDark={true}/>
                            </div>
                            <div className="font-primary whitespace-nowrap">
                                {i18n.t("overview.summary_delayed_deliveries")}                        
                            </div>
                            <div className="p-0">
                                {(props?.isSnapshotLoading)
                                ?
                                    <Skelton className="bg-secondary-regular max-w-[100px] mx-auto" count={1}/>
                                :
                                    Formatter.stringToNumberWithCommas(props?.snapshot?.summary_delayed_deliveries?.current)
                                }
                            </div>
                            <div className="ltr">
                                {getChange(props?.snapshot?.summary_delayed_deliveries?.current, props?.snapshot?.summary_delayed_deliveries?.past, true)}
                            </div>
                        </div>  

                        <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5">
                            <div className="flex justify-center">
                                <Badge type={"preparation-time"} theme={props?.prefs?.theme} isDark={true}/>
                            </div>
                            <div className="font-primary whitespace-nowrap">
                                {i18n.t("overview.summary_average_processing_time")}                        
                            </div>
                            <div className="p-0">
                                {(props?.isSnapshotLoading)
                                ?
                                    <Skelton className="bg-secondary-regular max-w-[100px] mx-auto" count={1}/>
                                :
                                    Formatter.stringToNumberWithCommas(props?.snapshot?.summary_average_processing_time?.current) + " " + i18n.t("time.minute")
                                }
                            </div>
                            <div className="ltr">
                                {getChange(props?.snapshot?.summary_average_processing_time?.current, props?.snapshot?.summary_average_processing_time?.past, true)}
                            </div>
                        </div> 

                        <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5">
                            <div className="flex justify-center">
                                <Badge type={"delivery-time"} theme={props?.prefs?.theme} isDark={true}/>
                            </div>
                            <div className="font-primary whitespace-nowrap">
                                {i18n.t("overview.summary_average_delivery_time")}                        
                            </div>
                            <div className="p-0">
                                {(props?.isSnapshotLoading)
                                ?
                                    <Skelton className="bg-secondary-regular max-w-[100px] mx-auto" count={1}/>
                                :
                                    Formatter.stringToNumberWithCommas(props?.snapshot?.summary_average_delivery_time?.current) + " " + i18n.t("time.minute")
                                }
                            </div>
                            <div className="ltr">
                                {getChange(props?.snapshot?.summary_average_delivery_time?.current, props?.snapshot?.summary_average_delivery_time?.past, true)}
                            </div>
                        </div>                                                                  
                    </div>

                    <div className={`flex gap-5 mt-14 ${isScreenLarge ? "flex-wrap" : ""}`}>
                        <div className="p-0 w-full lg:w-[65%] mb-10 lg:mb-0">
                            <div className="font-primary mb-3">
                                {i18n.t("overview.latest_delivery_list")}
                            </div>
                            <Table
                                labels={
                                [
                                    {order:1, id:"status_code", title:i18n.t("delivery.status_code"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:true},
                                    {order:2, id:"tracking_number", title:i18n.t("delivery.tracking_number"), dialogID:"DeliveryInfo", dialogTitle:i18n.t("page.delivery_info"), dialogDataID:"delivery_id", isStatusIndicator:false},
                                    {order:3, id:"bill_reference_number", title:i18n.t("delivery.bill_reference_number"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false, minXL:true},
                                    {order:4, id:"status_name", title:i18n.t("delivery.status_code"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false}
                                ]}
                                list={props?.deliveryList}
                                prefs={props?.prefs}
                                showDialog={props?.showDialog}
                                isLoading={props?.isDeliveryListLoading}
                            />
                        </div>

                        <div className="flex flex-col w-full lg:w-[35%] pb-5">

                            <div className="font-primary mb-3">
                                {i18n.t("overview.chart_subscription_usage")}
                            </div>
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 p-7 mb-5">
                                <div className="mt-1">
                                    {(props?.isSubscriptionInfoLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <>
                                            <div className="text-start font-primary text-lg">
                                                {i18n.t(`subscription.${props?.subscriptionInfo?.subscription_code}`)}
                                            </div>
                                            <div className="text-start mt-3 mb-10">
                                                {i18n.t("subscription.renew_at")} {Formatter.dateToString(props?.subscriptionInfo?.expires_at, "date_time")}
                                            </div>                                            
                                            <ChartHorizontalBar data={[{name:i18n.t("overview.subscription_legend_delivery_quota"), min:0, max:props?.subscriptionInfo?.delivery_quota, current:(props?.subscriptionInfo?.delivery_quota-props?.subscriptionInfo?.delivery_usage)}]} theme={props?.prefs?.theme}/>
                                            <div className="mt-7 text-end">
                                                <Button type={(props?.prefs?.theme === "light") ? "secondary-solid" : "primary-solid"} text={i18n.t("overview.upgrade_subscription")} onClick={() => {props?.upgradeSubscription()}}/>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="font-primary mt-10 mb-3">
                                {i18n.t("overview.chart_group_deliveries_by_status")}
                            </div>                 
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5 mb-5">
                                <div className="mt-2">
                                    {(props?.isSnapshotLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <ChartPie data={props?.snapshot?.chart_group_deliveries_by_status} theme={props?.prefs?.theme}/>
                                    }
                                </div>
                            </div> 

                            <div className="font-primary mt-10 mb-3">
                                {i18n.t("overview.chart_top_zones_by_total_deliveries")}
                            </div>  
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5 mb-5">
                                <div className="mt-2">
                                    {(props?.isSnapshotLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <ChartVerticalBar data={props?.snapshot?.chart_top_zones_by_total_deliveries} theme={props?.prefs?.theme}/>
                                    }
                                </div>
                            </div> 

                            <div className="font-primary mt-10 mb-3">
                                {i18n.t("overview.chart_group_deliveries_by_month")}
                            </div> 
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5 mb-5">
                                <div className="mt-2">
                                    {(props?.isSnapshotLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <ChartLine data={props?.snapshot?.chart_group_deliveries_by_month} theme={props?.prefs?.theme}/>
                                    }
                                </div>
                            </div> 

                            <div className="font-primary mt-10 mb-3">
                                {i18n.t("overview.chart_group_deliveries_by_day")}
                            </div>
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5 mb-5">
                                <div className="mt-2">
                                    {(props?.isSnapshotLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <ChartLine data={props?.snapshot?.chart_group_deliveries_by_day} theme={props?.prefs?.theme}/>
                                    }
                                </div>
                            </div> 

                            <div className="font-primary mt-10 mb-3">
                                {i18n.t("overview.chart_group_users_by_readiness")}
                            </div>
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5 mb-5">
                                <div className="mt-2">
                                    {(props?.isSnapshotLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <ChartRing data={props?.snapshot?.chart_group_users_by_readiness} theme={props?.prefs?.theme}/>
                                    }
                                </div>
                            </div> 

                            <div className="font-primary mt-10 mb-3">
                                {i18n.t("overview.chart_top_users_by_total_deliveries")}
                            </div>
                            <div className="bg-neutral-high-light shadow-xl transition ease-in-out duration-normal w-full rounded-xl flex flex-col gap-2 text-center p-5">
                                <div className="mt-2">
                                    {(props?.isSnapshotLoading)
                                    ?
                                        <Skelton className="bg-secondary-regular mx-auto my-5" count={4}/>
                                    :
                                        <ChartVerticalBar data={props?.snapshot?.chart_top_users_by_total_deliveries} theme={props?.prefs?.theme}/>
                                    }
                                </div>
                            </div>                                                                                                                        
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    );
}

export default View;
