import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import Context from '../../util/context';
import i18n from '../../util/i18n';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);

    const context  = useContext(Context);
    const params   = useParams();
    const navigate = useNavigate();

    useEffect(() =>
    {
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name');

        if(params?.param1?.toLowerCase() === "invite_user")
        {
            if(params?.param2 && params?.param3 && params?.param4 && params?.param5 && context?.prefs?.is_logged_in !== "1")
            {
                if(!["captain", "observer", "maintainer"].includes(params?.param4?.toLowerCase()))
                {
                    context?.setNotification("error", i18n.t("error.title"), i18n.t("error.invalid_invitation_url"));
                }
                else
                {
                    const dialogTitle = params?.param4?.toLowerCase() === "captain" ? i18n.t("page.signup_as_captain") : i18n.t("page.signup_as_employee");
    
                    context?.setDialog("Signup", dialogTitle, "bg-secondary-lightest", "sm", false, false, true, false, {persona:params?.param4?.toLowerCase(), subscription_code:null, merchant_code:params?.param3?.toLowerCase(), invitation_code:params?.param5?.toLowerCase(), email:params?.param2?.toLowerCase(), action:null});
                }
            }
    
            if(context?.prefs?.is_logged_in !== "1")
            {
                navigate("/");
            }
            else
            {
                navigate("/directory");
            }
        }
        else if(params?.param1?.toLowerCase() === "invite_captain")
        {
            if(params?.param2 && context?.prefs?.is_logged_in !== "1")
            {
                const dialogTitle = i18n.t("page.signup_as_captain");
    
                context?.setDialog("Signup", dialogTitle, "bg-secondary-lightest", "sm", false, false, true, false, {persona:"captain", subscription_code:null, merchant_code:params?.param2?.toLowerCase(), invitation_code:null, email:null, action:null});
            }
    
            if(context?.prefs?.is_logged_in !== "1")
            {
                navigate("/");
            }
            else
            {
                navigate("/directory");
            }
        }        
        else if(params?.param1?.toLowerCase() === "survey")
        {
            if(params?.param2 && params?.param3)
            {
                context?.setDialog("DeliverySurvey", i18n.t("page.survey"), "bg-secondary-lightest", "sm", false, false, true, false, {tracking_number:params?.param2, pin:params?.param3});
            }
    
            navigate("/"); 
        }
        else if(params?.param1?.toLowerCase() === "topup")
        {
            if(params?.param2 && params?.param3 && context?.prefs?.is_logged_in === "1")
            {
                if(!["successful", "failed"].includes(params?.param2?.toLowerCase()))
                {
                    context?.setNotification("error", i18n.t("error.title"), i18n.t("error.invalid_payment_url"));
                }                
                else
                {
                    if(["successful"].includes(params?.param2?.toLowerCase()))
                    {
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.payment_complete"));
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), i18n.t("error.invalid_payment_url"));
                    }
                }
            }
    
            if(context?.prefs?.is_logged_in !== "1")
            {
                context?.setNotification("error", i18n.t("error.title"), i18n.t("error.invalid_payment_url"));

                navigate("/");
            }
            else
            {
                navigate("/dashboard/wallet");
            }
        }
        else if(params?.param1?.toLowerCase() === "update_location")
        {
            if(params?.param2 && params?.param3)
            {
                context?.setDialog("DeliveryLocationUpdate", i18n.t("page.update_delivery_location"), "bg-secondary-lightest", "md", false, false, true, false, {tracking_number:params?.param2, pin:params?.param3});
            }
    
            navigate("/"); 
        }        
        else
        {
            if(context?.prefs?.is_logged_in !== "1")
            {
                navigate("/");
            }
            else
            {
                navigate("/directory");
            }       
        }

        return () =>
        {
            setMounted(false);
        };

    }, [context, isMounted, navigate, params]);

    return (<></>);
}

export default Controller;