import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Formatter from '../../util/formatter';

import Button from '../component/button';
import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import Skelton from '../component/skelton';

const View = (props) =>
{
    const [data, setData] = useState({});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="font-secondary text-sm text-neutral-low-dark">
            <div className="flex w-full">
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "new") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("new")}}>
                    <div className="m-auto p-4">{i18n.t("wallet.debit_request_new")}</div>
                </div>
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "history") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("history")}}>
                    <div className="m-auto p-4">{i18n.t("wallet.debit_request_history")}</div>
                </div>
            </div>            
            <div className="px-7 pt-10 pb-7 font-secondary text-sm">
            {(props?.tab === "new")
            ?
                <div>
                    <div className="text-center">
                        {i18n.t("wallet.balance")}
                    </div>
                    <div className="font-primary text-center text-lg">
                        {Formatter.stringToNumberWithCommasAndDicimals(props?.walletInfo?.balance)} {i18n.t("currency." + props?.prefs?.currency)}
                    </div>
                    <div className="mt-10"> 
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.amount")}
                                value={data?.amount ?? ""}
                                isRequired={true}
                                errorMsg={props?.errors?.amount}
                                onChange={(e) => {update("amount", e?.target?.value)}}
                            />
                        </div>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("common.bank_account")}
                                <span className="text-accent-regular">*</span>
                            </div>
                            <InputDropdown
                                list={props?.bankAccountList}
                                listValue={data?.bank_account_id}
                                isDisabled={props?.isBankAccountListLoading || props?.bankAccountList?.length === 0}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("bank_account_id", value)}}
                            />
                            <AnimatePresence>
                                {props?.errors?.bank_account_id && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm`}
                                    >
                                        {props?.errors?.bank_account_id}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div> 
                        <div className="flex justify-between pt-10">
                            <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("page.bank_account_create")} isLoading={props?.isWalletLoading}  isDisabled={props?.isWalletLoading} onClick={() => props?.createBankAccount()}/>
                            <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isWalletLoading}  isDisabled={props?.isWalletLoading} onClick={() => props?.next(data)}/>
                        </div>                                                   
                    </div>                    
                </div>
            :
            (props?.tab === "history")
            ?
                <div>
                    {(props?.isHistoryListLoading)
                    ?
                        <Skelton className="bg-secondary-light mb-5" count={5}/>
                    :
                    (props?.historyList?.filter(record=> record?.status_code === "reserved")?.length === 0)
                    ?
                        <div className="text-center my-20">
                            {i18n.t("common.no_results_found")}
                        </div>
                    :
                        <table className="rounded-lg text-left w-full shadow-sm mt-7">
                            <thead className="bg-neutral-high-light">
                                <th className="py-4 ps-4 text-center rounded-t-lg rounded-e-none"/>
                                <th className="whitespace-nowrap py-4 pe-1 px-2 text-center">
                                    {i18n.t("common.date")}
                                </th>
                                <th className="whitespace-nowrap py-4 pe-1 px-2 text-center">
                                    {i18n.t("common.bank_account")}
                                </th>
                                <th className="py-4 ps-4 text-center rounded-t-lg rounded-s-none">
                                    {i18n.t("common.amount")}
                                </th>                                                                 
                            </thead>
                            <tbody>
                                {props?.historyList?.filter(record=> record?.status_code === "reserved")?.map((item, index) =>
                                {
                                    return (
                                        <tr key={index} className={(index%2 !== 0) ? `bg-neutral-high-light` : `bg-neutral-high-lightest`}>     
                                            <td className={`p-4 text-center ${(index === props?.historyList?.filter(record=> record?.status_code === "reserved")?.length-1) ? "rounded-b-lg rounded-e-none" : ""}`}>                                      
                                                <div className="rounded-full w-[8px] h-[8px] bg-primary-regular"/>
                                            </td>                                       
                                            <td className="whitespace-nowrap py-4 px-2 text-center">
                                                {Formatter?.dateToString(item?.created_at, "date")}
                                            </td>    
                                            {/* <td className="py-4 px-2 text-center">                                          
                                                {item?.status_code ? i18n.t(`wallet_transaction.status_${item?.status_code}`) : ""}
                                            </td>*/}
                                            <td className="whitespace-nowrap py-4 px-2 text-center w-full">
                                                {Formatter.mask(item?.bank_account_number, 3)}
                                            </td>
                                            <td className={`whitespace-nowrap py-4 px-2 text-center ${(index === props?.historyList?.filter(record=> record?.status_code === "reserved")?.length-1) ? "rounded-b-lg rounded-s-none" : ""}`}>                                      
                                                {Formatter?.stringToNumberWithCommasAndDicimals(item?.amount)}{i18n.t("currency." + props?.prefs?.currency)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    }
                </div>    
            :
                null
            }        
            </div>                                   
        </div>
    );
}

export default View;
    