import { useState, useEffect, useContext } from 'react';
import { useOutletContext } from "react-router-dom";

import Context from '../../util/context';
import i18n from '../../util/i18n';

import DashboardLiveMap from '../../view/page/dashboardLiveMap';

const Controller = props => 
{
    const [isMenuShown, toggleMenu] = useState(true);
    
    const context = useContext(Context);

    const [type, setType, status, setStatus, zone, setZone, zoneList, isLoading] = useOutletContext();

    useEffect(() =>
    {        
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.dashboard_map');

    }, []);

    return (
        <DashboardLiveMap
            prefs={context?.prefs}
            isMenuShown={isMenuShown}
            isLoading={isLoading}
            type={type}
            status={status}
            zone={zone}
            zoneList={zoneList?.map((zone) => {return {title:zone?.[`name_${context?.prefs?.lang}`], value:zone?.zone_id}})}
            setType={setType}
            setStatus={setStatus}
            setZone={setZone}
            toggle={() => {toggleMenu(!isMenuShown)}}            
        />
    );
}

export default Controller;