import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import i18n from '../../util/i18n';
import Context from '../../util/context';
import Endpoint from '../../config/endpoint';
import HTTP from '../../util/http';

import StaticPage from '../../view/page/staticPage';
import Header from '../../view/component/header';
import Footer from '../../view/component/footer';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});

    const context  = useContext(Context);
    const params   = useParams();
    const navigate = useNavigate();

    const changeLang = () =>
    {
        if(context?.prefs?.lang === "ar")
        {
            context?.setPrefs({"lang": "en"});            

            i18n.changeLanguage("en");
        }
        else
        {
            context?.setPrefs({"lang": "ar"});            

            i18n.changeLanguage("ar");
        }
    };

    const showLoginDialog = () =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");
        }
        else
        {
            navigate("/directory");
        }
    };

    const showSignupDialog = (persona, subscriptionType = null) =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("Signup", i18n.t("page.signup"), "bg-secondary-lightest", "sm", false, false, true, false, {persona:persona, subscriptionType:subscriptionType, merchant_code:null, invitation_code:null, email:null, action:null});
        }
        else
        {
            navigate("/directory");
        }        
    };

    const showPasswordResetDialog = (persona) =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("PasswordReset", i18n.t("page.reset_password"), "bg-secondary-lightest", "sm");
        }
        else
        {
            navigate("/directory");
        }
    };

    useEffect(() =>
    {
        setLoading(true);

        document.title = i18n.t('app.name') + " | " +
                        `${(params?.page_code === "terms-of-use") ? i18n.t("page.terms_of_use") :
                        (params?.page_code === "privacy-policy") ? i18n.t("page.privacy_policy") :
                        (params?.page_code === "account-deletion") ? i18n.t("page.account_deletion") :
                        (params?.page_code === "captain-agreement") ? i18n.t("page.captain_agreement") :
                        (params?.page_code === "developer-docs") ? i18n.t("page.developer_documentation") :
                        (params?.page_code === "developer-sample") ? i18n.t("page.developer_sample_code") : ""}`;

        let pageCode = (params?.page_code === "terms-of-use") ? "terms_of_use" :
                       (params?.page_code === "privacy-policy") ? "privacy_policy" :
                       (params?.page_code === "account-deletion") ? "account_deletion" :
                       (params?.page_code === "captain-agreement") ? "captain_agreement" :
                       (params?.page_code === "developer-docs") ? "developer_documentation" :
                       (params?.page_code === "developer-sample") ? "developer_sample_code" : "";


        isMounted && HTTP.get(Endpoint?.cms?.page?.data, {page_code: pageCode}).then(response =>
        {
            if(response?.data?.info?.[`page_title_${context?.prefs?.lang?.toLowerCase()}`] && response?.data?.info?.[`page_content_${context?.prefs?.lang.toLowerCase()}`])
            {
                setPageData(
                {
                    title:response?.data?.info?.[`page_title_${context?.prefs?.lang?.toLowerCase()}`],
                    content:response?.data?.info?.[`page_content_${context?.prefs?.lang?.toLowerCase()}`],
                    last_update:response?.data?.info?.last_updated_at
                });

                setLoading(false);
            }
            else
            {
                navigate("/error/404");
            }
        });
    }, [isMounted, params?.page_code, context?.prefs?.lang, navigate]);

    useEffect(() =>
    {                
        window.addEventListener('offline', () => checkConnection());

        const checkConnection = (isOnline) =>
        {
            navigate("/error/disconnected");
        };

        return () =>
        {
            window.removeEventListener('offline', () => checkConnection());
        };
    }, [navigate]);
    
    useEffect(() =>
    {
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <>
            <Header
                changeLang={changeLang}
                showLoginDialog={showLoginDialog}
                showCaptainSignUpDialog={() => showSignupDialog("captain")}
                showMerchantSignUpDialog={() => showSignupDialog("owner", "basic")}
                prefs={context?.prefs}
                isHome={false}
                isFixed={true}
                isMinimal={false}
            /> 
            <StaticPage
                prefs={context?.prefs}
                isLoading={isLoading}
                pageData={pageData}
            />
            <Footer
                showLoginDialog={showLoginDialog}
                showCaptainSignUpDialog={() => showSignupDialog("captain")}
                showMerchantSignUpDialog={() => showSignupDialog("owner", "basic")}
                showPasswordResetDialog={showPasswordResetDialog}
                prefs={context?.prefs}
            />                    
        </>
    );
}

export default Controller;