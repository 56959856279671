import { useState, useContext } from 'react';
import { Marker } from '@react-google-maps/api';

import Context from '../../util/context';

const View = (props) =>
{
    const [location, setLocation] = useState({lat:props?.data?.lat, long:props?.data?.long});

    const context = useContext(Context);

    const handleDragging = (e) =>
    {
        setLocation({lat:e?.latLng?.lat(), long:e?.latLng?.lng()});

        if(props?.handleDragging)
        {
            props?.handleDragging({lat:e?.latLng?.lat(), long:e?.latLng?.lng()});        
        }
    };

    return (
        <Marker
            animation={window.google.maps.Animation.DROP}
            icon={{url:(context?.prefs?.theme === "dark") ? "/image/map_marker_dark.png" : "/image/map_marker_light.png", scaledSize: new window.google.maps.Size(30,30)}}
            position={{lat:parseFloat(location?.lat),lng:parseFloat(location?.long)}}
            draggable={true}
            onDragEnd={handleDragging}
        />
    );
}

export default View;