import { useState, useEffect, useContext } from 'react';

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';
import Formatter from '../../util/formatter';

import MerchantInfo from '../../view/dialog/merchantInfo';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [tab, setTab] = useState(props?.dialog?.metadata?.tab);
    const [errors, setErrors] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [data, setData] = useState({});
    const [viewID, setViewID] = useState(Math.random());

    const context = useContext(Context);

    const changeTab = (tab) =>
    {
        setTab(tab);
        setViewID(Math.random());
    };

    const save = (newData) =>
    {
        let newErrors = {};
        let data      = {};

        if(tab === "name")
        {
            if(!Validator.notEmpty(newData?.legal_name))
            {
                newErrors["legal_name"] = i18n.t('error.invalid_legal_name');            
            }

            if(!Validator.notEmpty(newData?.display_name))
            {
                newErrors["display_name"] = i18n.t('error.invalid_display_name');            
            }
            
            if(!Validator.notEmpty(newData?.contact_address))
            {
                newErrors["contact_address"] = i18n.t('error.invalid_contact_address');            
            }

            if(!Validator.notEmpty(newData?.communication_method) || !['email', 'sms']?.includes(newData?.communication_method))
            {
                newErrors["communication_method"] = i18n.t('error.communication_method');            
            }

            if(!Validator.notEmpty(newData?.lang_code) || !['ar', 'en']?.includes(newData?.lang_code))
            {
                newErrors["lang_code"] = i18n.t('error.lang_code');            
            }

            if(Object.keys(newErrors)?.length === 0)
            {
                setLoading(true);
    
                data["legal_name"]           = newData["legal_name"];
                data["display_name"]         = newData["display_name"];
                data["contact_address"]      = newData["contact_address"];
                data["communication_method"] = newData["communication_method"];
                data["lang_code"]            = newData["lang_code"];
    
                isMounted && HTTP.put(Endpoint?.crm?.merchant?.data, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                        setTimeout(() =>
                        {
                            context?.setDialog(null);   
                        
                            window.location.reload();  
                        
                        }, Constant.animation?.speed?.normal);                        
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
        
                        setLoading(false);
                    }
                });
            }
            else
            {
                setErrors(newErrors);
            }            
        }
        else if(tab === "contact")
        {
            if(!Validator.notEmpty(newData?.contact_first_name))
            {
                newErrors["contact_first_name"] = i18n.t('error.invalid_first_name');            
            }

            if(!Validator.notEmpty(newData?.contact_last_name))
            {
                newErrors["contact_last_name"] = i18n.t('error.invalid_last_name');            
            }  
            
            if(!Validator.notEmpty(newData?.contact_email))
            {
                newErrors["contact_email"] = i18n.t('error.invalid_email');            
            }
            
            if(!Validator.notEmpty(newData?.contact_mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');            
            }  
            else if(!Validator.numeric(newData?.contact_mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            } 

            if(!Validator.notEmpty(newData?.contact_mobile))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');            
            }  
            else if(!Validator.numeric(newData?.contact_mobile_code))
            {
                newErrors["mobile"] = i18n.t('error.invalid_mobile');
            }

            if(Object.keys(newErrors)?.length === 0)
            {
                setLoading(true);

                data["contact_first_name"]  = newData["contact_first_name"];
                data["contact_last_name"]   = newData["contact_last_name"];
                data["contact_email"]       = newData["contact_email"];
                data["contact_mobile_code"] = newData["contact_mobile_code"];
                data["contact_mobile"]      = newData["contact_mobile"];

                isMounted && HTTP.put(Endpoint?.crm?.merchant?.data, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                        setTimeout(() =>
                        {
                            context?.setDialog(null);   
                        
                            window.location.reload();  
                        
                        }, Constant.animation?.speed?.normal);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
        
                        setLoading(false);
                    }
                });
            }
            else
            {
                setErrors(newErrors);
            }         
        }
        else if(tab === "cr")
        {
            if(!Validator.notEmpty(newData?.cr_number))
            {
                newErrors["cr_number"] = i18n.t('error.invalid_cr_number');            
            }

            if(!Validator.notEmpty(newData?.cr_start_date))
            {
                newErrors["cr_start_date"] = i18n.t('error.invalid_cr_start_date');            
            }

            if(!Validator.notEmpty(newData?.cr_end_date))
            {
                newErrors["cr_end_date"] = i18n.t('error.invalid_cr_end_date');            
            }
            else if((typeof newData?.cr_end_date) !== "string" && !Validator.futureDate(newData?.cr_end_date))
            {
                newErrors["cr_end_date"] = i18n.t('error.invalid_date_not_in_future');            
            }

            if(Object.keys(newErrors)?.length === 0)
            {
                setLoading(true);
    
                data["cr_number"]     = newData["cr_number"];
                data["cr_start_date"] = Formatter.dateToTimestamp(newData["cr_start_date"], "date");
                data["cr_end_date"]   = Formatter.dateToTimestamp(newData["cr_end_date"], "date");
                
                if(newData?.["cr_img"] && typeof(newData?.["cr_img"]) !== "string")
                {
                    data["cr_img"] = newData["cr_img"];
                }

                
                isMounted && HTTP.post(Endpoint?.crm?.merchant?.cr, data).then(response =>
                {
                    if(response?.is_successful)
                    {    
                        context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                        setTimeout(() =>
                        {
                            context?.setDialog(null);   
                        
                            window.location.reload();  
                        
                        }, Constant.animation?.speed?.normal);
                    }
                    else
                    {
                        context?.setNotification("error", i18n.t("error.title"), response?.error_msg);
        
                        setLoading(false);
                    }
                });
            }
            else
            {
                setErrors(newErrors);
            }
        }

    };
    
    useEffect(() =>
    {
        let obj = {};

        if(props?.dialog?.metadata?.merchantInfo.cr_start_date)
        {
            const [year1, month1, day1] = props?.dialog?.metadata?.merchantInfo?.cr_start_date?.split('-').map(Number);
            obj["cr_start_date"] = Formatter.stringToDate(year1, month1, day1);
        }

        if(props?.dialog?.metadata?.merchantInfo.cr_end_date)
        {
            const [year2, month2, day2] = props?.dialog?.metadata?.merchantInfo?.cr_end_date?.split('-').map(Number);
            obj["cr_end_date"] = Formatter.stringToDate(year2, month2, day2);

        }

        setData({...props?.dialog?.metadata?.merchantInfo, ...obj});

    }, [props?.dialog?.metadata?.merchantInfo]);

    useEffect(() =>
    {
        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            setLoading(false);
        });
        
    }, [isMounted]);
        
    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <MerchantInfo
            key={viewID}
            isLoading={isLoading}
            prefs={context?.prefs}
            tab={tab}
            countryList={countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
            errors={errors}
            setTab={changeTab}
            save={save}
            data={data}
        />
    );
}

export default Dialog;