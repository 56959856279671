import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputDate from '../component/inputDate';
import InputFile from '../component/inputFile';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">
                {(props?.data?.persona?.toLowerCase() === "captain")
                ?
                    <>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("common.driver_license_type")}
                            </div>
                            <InputDropdown
                                selectorClassName={"bg-secondary-light mb-2"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={[
                                        {title:i18n.t("common.driver_license_type_private"), value:"private"}, 
                                        {title:i18n.t("common.driver_license_type_motorcycle"), value:"motorcycle"},
                                        {title:i18n.t("common.driver_license_type_trans_general"), value:"trans_general"},
                                        {title:i18n.t("common.driver_license_type_trans_heavy"), value:"trans_heavy"},
                                        {title:i18n.t("common.driver_license_type_trans_light"), value:"trans_light"}
                                    ]}
                                listValue={data?.driver_license_code ?? props?.data?.driver_license_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("driver_license_code", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.driver_license_code && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.driver_license_code}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div> 
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.driver_license_number")}
                                isDisabled={props?.isLoading}
                                value={data?.driver_license_number ?? props?.data?.driver_license_number}
                                errorMsg={props?.errors?.driver_license_number}
                                onChange={(e) => {update("driver_license_number", e?.target?.value)}}
                            />
                        </div>  
                        <div className="mb-7">
                            <InputFile 
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.driver_license_img")}
                                isDisabled={props?.isLoading}
                                errorMsg={props?.errors?.driver_license_img}
                                onChange={(file) => {update("driver_license_img", file)}}
                            />
                        </div> 
                        <div className="mb-7">
                            <InputDate
                                theme={props?.prefs?.theme}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                label={i18n.t("common.driver_license_exp_date")}
                                futureDateOnly={true}
                                locale={props?.prefs?.lang}
                                showTimeSelect={false}
                                isDisabled={props?.isLoading}
                                value={data?.driver_license_expiration_date ?? props?.data?.driver_license_expiration_date}
                                errorMsg={props?.errors?.driver_license_expiration_date}
                                onChange={(value) => {update("driver_license_expiration_date", value);}}
                            />
                        </div>                                                                  
                    </>   
                :
                    <>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.cr_number")}
                                isDisabled={props?.isLoading}
                                value={data?.cr_number ?? props?.data?.cr_number}
                                errorMsg={props?.errors?.cr_number}
                                onChange={(e) => {update("cr_number", e?.target?.value)}}
                            />
                        </div>   
                        <div className="mb-7">
                            <div className="grid grid-cols-2 gap-5 mb-2">
                                <InputDate
                                    theme={props?.prefs?.theme}
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    label={i18n.t("common.start_date")}
                                    pastDateOnly={true}
                                    locale={props?.prefs?.lang}
                                    showTimeSelect={false}
                                    isDisabled={props?.isLoading}
                                    value={data?.cr_start_date ?? props?.data?.cr_start_date}
                                    errorMsg={props?.errors?.cr_start_date}
                                    onChange={(value) => {update("cr_start_date", value);}}
                                />
                                <InputDate
                                    theme={props?.prefs?.theme}
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    label={i18n.t("common.end_date")}
                                    futureDateOnly={true}
                                    locale={props?.prefs?.lang}
                                    showTimeSelect={false}
                                    isDisabled={props?.isLoading}
                                    value={data?.cr_end_date ?? props?.data?.cr_end_date}
                                    errorMsg={props?.errors?.cr_end_date}
                                    onChange={(value) => {update("cr_end_date", value);}}
                                />
                            </div>
                        </div> 
                        <div className="mb-7">
                            <InputFile 
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.cr_image")}
                                isDisabled={props?.isLoading}
                                errorMsg={props?.errors?.cr_img}
                                onChange={(file) => {update("cr_img", file)}}
                            />
                        </div> 
                    </>                     
                }                
            </div>
            <div className="flex justify-between">
                {(props?.data?.persona?.toLowerCase() === "captain")
                ?             
                    <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={true} onClick={() => {}}/>
                :
                    <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={false} onClick={() => props?.goPrev({...props?.data, ...data})}/>
                }
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
