import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputDate from '../component/inputDate';
import InputFile from '../component/inputFile';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">
                {(props?.data?.persona?.toLowerCase() === "captain")
                ?
                    <>
                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("common.vehicle_type")}
                            </div>
                            <InputDropdown
                                selectorClassName={"bg-secondary-light mb-2"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={[
                                        {title:i18n.t("common.vehicle_type_sedan"), value:"sedan"}, 
                                        {title:i18n.t("common.vehicle_type_dokker"), value:"dokker"},
                                        {title:i18n.t("common.vehicle_type_minivan"), value:"minivan"},
                                        {title:i18n.t("common.vehicle_type_van"), value:"van"},
                                        {title:i18n.t("common.vehicle_type_closed_dyna_3_tons"), value:"closed_dyna_3_tons"},
                                        {title:i18n.t("common.vehicle_type_closed_dyna_4_tons"), value:"closed_dyna_4_tons"}, 
                                        {title:i18n.t("common.vehicle_type_closed_dyna_6_tons"), value:"closed_dyna_6_tons"},
                                        {title:i18n.t("common.vehicle_type_closed_dyna_8_tons"), value:"closed_dyna_8_tons"},
                                        {title:i18n.t("common.vehicle_type_closed_dyna_12_tons"), value:"closed_dyna_12_tons"},
                                        {title:i18n.t("common.vehicle_type_motorcycle"), value:"motorcycle"}
                                    ]}
                                listValue={data?.vehicle_registration_code ?? props?.data?.vehicle_registration_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={true}
                                isCompact={true}
                                handleValueUpdate={(value) => {update("vehicle_registration_code", value)}}
                            />                                
                            <AnimatePresence>
                                {props?.errors?.vehicle_registration_code && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.vehicle_registration_code}
                                    </motion.div>
                                )}
                            </AnimatePresence>                        
                        </div> 
                        <div className="mb-7">
                            <div className="grid grid-cols-2 gap-5 mb-2">
                                <InputText 
                                    type={"text"}
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    enableAutoComplete={false}
                                    label={i18n.t("common.vehicle_registration_number")}
                                    isDisabled={props?.isLoading}
                                    value={data?.vehicle_registration_number ?? props?.data?.vehicle_registration_number}
                                    errorMsg={props?.errors?.vehicle_registration_number}
                                    onChange={(e) => {update("vehicle_registration_number", e?.target?.value)}}
                                />
                                <InputText 
                                    type={"text"}
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular ltr"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    enableAutoComplete={false}
                                    label={i18n.t("common.vehicle_plate_number")}
                                    isDisabled={props?.isLoading}
                                    value={data?.vehicle_plate_number ?? props?.data?.vehicle_plate_number}
                                    errorMsg={props?.errors?.vehicle_plate_number}
                                    onChange={(e) => {update("vehicle_plate_number", e?.target?.value?.replace(/[^a-zA-Z0-9]/g, ''))}}
                                />                        
                            </div>
                        </div> 
                        <div className="mb-7">
                            <div className="grid grid-cols-2 gap-5 mb-2">
                                <InputFile 
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    enableAutoComplete={false}
                                    label={i18n.t("common.vehicle_registration_image")}
                                    isDisabled={props?.isLoading}
                                    onChange={(file) => {update("vehicle_registration_img", file)}}
                                />
                                <InputFile 
                                    inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                    labelClassName="text-secondary-darkest"
                                    errorClassName="text-status-danger-regular"
                                    enableAutoComplete={false}
                                    label={i18n.t("common.vehicle_image")}
                                    isDisabled={props?.isLoading}
                                    onChange={(file) => {update("vehicle_img", file)}}
                                />                                
                            </div>
                            <AnimatePresence>
                                {props?.errors?.vehicle_img && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm mb-2`}
                                    >
                                        {props?.errors?.vehicle_img}
                                    </motion.div>
                                )}
                            </AnimatePresence>                      
                        </div>  
                        <div className="mb-7">
                            <InputDate
                                theme={props?.prefs?.theme}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                label={i18n.t("common.vehicle_registration_exp_date")}
                                futureDateOnly={true}
                                locale={props?.prefs?.lang}
                                showTimeSelect={false}
                                isDisabled={props?.isLoading}
                                value={data?.vehicle_registration_expiration_date ?? props?.data?.vehicle_registration_expiration_date}
                                errorMsg={props?.errors?.vehicle_registration_expiration_date}
                                onChange={(value) => {update("vehicle_registration_expiration_date", value)}}
                            />
                        </div> 
                    </> 
                :
                    <>
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName="text-status-danger-regular"
                                enableAutoComplete={false}
                                label={i18n.t("common.merchant_code")}
                                placeholder={i18n.t("form.letters_and_numbers_only")}
                                defaultValue={data?.merchant_code}
                                errorMsg={props?.errors?.merchant_code}
                                onChange={(e) => {update("merchant_code", e?.target?.value)}}
                            />              
                        </div>
                        <div className="mb-7">
                            <div className="leading-8">
                                {i18n.t("form.merchant_code_details")}
                            </div>
                        </div>
                    </>
                }                                                              
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={false} onClick={() => props?.goPrev({...props?.data, ...data})}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
