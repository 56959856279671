import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';
import Skelton from '../component/skelton';

const View = (props) =>
{
    return(
        <div className="font-secondary text-sm text-neutral-low-darkest pb-7">          
            <div className="flex flex-col md:flex-row justify-end gap-2 py-7 px-7 m-0">

                {(["pending_assignment", "onhold", "new"]?.includes(props?.deliveryInfo?.status_code?.toLowerCase()) && (["owner", "maintainer"]?.includes(props?.prefs?.user_role_code?.toLowerCase())))
                ?
                    <div className="min-w-[190px]">
                        <InputDropdown
                            list={props?.assignee}
                            selectorPlaceholder={i18n.t("delivery.action_assign")}
                            searchInputPlaceholder={i18n.t("common.search_generic")}
                            isDisabled={props?.isDataLoading}
                            isListSorted={true}
                            isListReversed={false}
                            isSearchable={true}
                            isCompact={true}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {props?.assignCaptain(value)}}
                        />
                    </div>
                :
                    null
                }

                {(!["cancelled", "delivered", "complete", "pending_location", "pending_assignment"]?.includes(props?.deliveryInfo?.status_code?.toLowerCase()) && (["owner","maintainer"]?.includes(props?.prefs?.user_role_code?.toLowerCase())))
                ?
                    <div className="m-0">
                        <InputDropdown
                            listWidth={"min-w-[200px]"}
                            selectorPlaceholder={i18n.t("delivery.action_update_status")}
                            list={props?.nextStatusList}
                            isDisabled={props?.isDataLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {props?.updateStatus(value)}}
                        />
                    </div>
                :
                    null
                }

                {(!["cancelled", "delivered", "complete", "pending_location", "pending_assignment"]?.includes(props?.deliveryInfo?.status_code?.toLowerCase()) && (["owner","maintainer"]?.includes(props?.prefs?.user_role_code?.toLowerCase())))
                ?
                    <div>
                        <InputDropdown
                            list={[{title:i18n.t("delivery.send_to_captain"), value:"captain"}, {title:i18n.t("delivery.send_to_sender"), value:"sender"}, {title:i18n.t("delivery.send_to_recipient"), value:"recipient"}]}
                            selectorPlaceholder={i18n.t("delivery.action_send_otp")}
                            isDisabled={props?.isDataLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {props?.sendOTP(value)}}
                        />
                    </div>
                :
                    null
                }

                {(["delivered"]?.includes(props?.deliveryInfo?.status_code?.toLowerCase()))
                ?
                    <div>
                        <Button 
                            type="neutral-high-outline" 
                            className="min-w-[120px] text-neutral-low-darkest hover:text-neutral-low-darkest" 
                            text={i18n.t("page.cash_collection_info")} 
                            isLoading={props?.isDataLoading}  
                            isDisabled={props?.isDataLoading}
                            onClick={() => props?.viewCollectionInfo()}
                        />
                    </div>
                :
                    null
                } 

                {(!["cancelled", "pending_location", "pending_assignment"]?.includes(props?.deliveryInfo?.status_code?.toLowerCase()))
                ?
                    <div>
                        <InputDropdown
                            list={[{title:i18n.t("delivery.survey_of_recipient"), value:"recipient"}]}
                            selectorPlaceholder={i18n.t("delivery.action_send_feedback")}
                            isDisabled={props?.isDataLoading}
                            isListSorted={false}
                            isListReversed={false}
                            isSearchable={false}
                            isCompact={false}
                            theme={props?.prefs?.theme}
                            handleValueUpdate={(value) => {props?.showSurveyResults(value)}}
                        />
                    </div>
                :
                    null
                }                        
            </div>
            <div className="h-[350px] px-7">
                {props?.children}
            </div>              
            <div className="p-7 md:flex">
                <div className="w-full md:w-[60%] md:pe-5 py-5">
                    <table className="p-0 w-full">
                        <tbody>
                            <tr>
                                <td className="pb-5 font-primary whitespace-nowrap" colSpan={2}>
                                    {i18n.t("delivery.cat_general_info")}:
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.tracking_number")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.tracking_number ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.status_code")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.status_code ? i18n.t(`delivery.status_${props?.deliveryInfo?.status_code}`)  : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.handling")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (!props?.deliveryInfo?.created_by_merchant_id) ? i18n.t("common.unknown") : (props?.prefs?.merchant_id !== props?.deliveryInfo?.created_by_merchant_id) ? i18n.t("delivery.is_public") : (props?.deliveryInfo?.is_public) ? i18n.t("delivery.is_public") : i18n.t("delivery.is_private")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.who_created")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (!props?.deliveryInfo?.created_by_merchant_id) ? i18n.t("common.unknown") : (props?.prefs?.merchant_id === props?.deliveryInfo?.created_by_merchant_id) ? i18n.t("delivery.stakeholder_your_company") : i18n.t("delivery.stakeholder_is_other_merchant")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.how_created")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (!props?.deliveryInfo?.created_by_user_id) ? i18n.t("delivery.created_by_manual") : i18n.t("delivery.delivery.created_by_api")}
                                </td>
                            </tr>
                            <tr className="border-b-[1px] border-neutral-high-light">
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.who_is_delivering")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (!props?.deliveryInfo?.served_by_merchant_id) ? i18n.t("common.na") : (props?.prefs?.merchant_id === props?.deliveryInfo?.served_by_merchant_id) ? i18n.t("delivery.stakeholder_your_company") : i18n.t("delivery.stakeholder_is_other_merchant")}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 font-primary whitespace-nowrap" colSpan={2}>
                                    {i18n.t("delivery.cat_collection")}:
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.bill_reference_number")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.bill_reference_number ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.bill_amount")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.bill_amount ? `${Formatter?.stringToNumberWithCommasAndDicimals(props?.deliveryInfo?.bill_amount)} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr className="border-b-[1px] border-neutral-high-light">
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.collection_amount")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.collection_amount ? `${Formatter?.stringToNumberWithCommasAndDicimals(props?.deliveryInfo?.collection_amount)} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.none")}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 font-primary whitespace-nowrap" colSpan={2}>
                                    {i18n.t("delivery.cat_captain")}:
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.full_name")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.captain?.first_name && props?.deliveryInfo?.captain?.first_name) ? `${props?.deliveryInfo?.captain?.first_name} ${props?.deliveryInfo?.captain?.last_name}` : i18n.t("common.na")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.mobile")}:
                                </td>
                                <td className={`pb-5 w-full ${(props?.prefs?.dir === "rtl") ? "ltr text-right pe-5" : "ps-5"}`}>
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.captain?.mobile_code && props?.deliveryInfo?.captain?.mobile) ? <a className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 underline" href={`tel:+${props?.deliveryInfo?.captain?.mobile_code}${props?.deliveryInfo?.captain?.mobile}`}>+{props?.deliveryInfo?.captain?.mobile_code}{props?.deliveryInfo?.captain?.mobile}</a> : i18n.t("common.na")}
                                </td>
                            </tr>
                            <tr className="border-b-[1px] border-neutral-high-light">
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.email")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.captain?.email) ? <a className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 underline" href={`mailto:${props?.deliveryInfo?.captain?.email}`}>{props?.deliveryInfo?.captain?.email}</a> : i18n.t("common.na")}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 font-primary whitespace-nowrap" colSpan={2}>
                                    {i18n.t("delivery.cat_sender")}:
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.full_name")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.sender?.first_name && props?.deliveryInfo?.sender?.first_name) ? `${props?.deliveryInfo?.sender?.first_name} ${props?.deliveryInfo?.sender?.last_name}` : i18n.t("common.na")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.mobile")}:
                                </td>
                                <td className={`pb-5 w-full ${(props?.prefs?.dir === "rtl") ? "ltr text-right pe-5" : "ps-5"}`}>
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.sender?.mobile_code && props?.deliveryInfo?.sender?.mobile) ? <a className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 underline" href={`tel:+${props?.deliveryInfo?.sender?.mobile_code}${props?.deliveryInfo?.sender?.mobile}`}>+{props?.deliveryInfo?.sender?.mobile_code}{props?.deliveryInfo?.sender?.mobile}</a> : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.email")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.sender?.email) ?  <a className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 underline" href={`mailto:${props?.deliveryInfo?.sender?.email}`}>{props?.deliveryInfo?.sender?.email}</a> : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.address")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.sender?.address ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.lat")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.sender?.lat ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr className="border-b-[1px] border-neutral-high-light">
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.long")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.sender?.long ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 font-primary whitespace-nowrap" colSpan={2}>
                                    {i18n.t("delivery.cat_recipient")}:
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.full_name")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.recipient?.first_name && props?.deliveryInfo?.recipient?.first_name) ? `${props?.deliveryInfo?.recipient?.first_name} ${props?.deliveryInfo?.recipient?.last_name}` : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.mobile")}:
                                </td>
                                <td className={`pb-5 w-full ${(props?.prefs?.dir === "rtl") ? "ltr text-right pe-5" : "ps-5"}`}>
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.recipient?.mobile_code && props?.deliveryInfo?.recipient?.mobile) ? <a className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 underline" href={`tel:+${props?.deliveryInfo?.recipient?.mobile_code}${props?.deliveryInfo?.recipient?.mobile}`}>+{props?.deliveryInfo?.recipient?.mobile_code}{props?.deliveryInfo?.recipient?.mobile}</a> : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.email")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : (props?.deliveryInfo?.recipient?.email) ? <a className="transition duration-normal ease-in-out opacity-100 hover:opacity-50 underline" href={`mailto:${props?.deliveryInfo?.recipient?.email}`}>{props?.deliveryInfo?.recipient?.email}</a> : i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.address")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.recipient?.address ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.lat")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.recipient?.lat ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr className="border-b-[1px] border-neutral-high-light">
                                <td className="pb-5 whitespace-nowrap">
                                    {i18n.t("delivery.long")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.recipient?.long ?? i18n.t("common.unknown")}
                                </td>
                            </tr>
                            <tr>
                                <td className="py-5 font-primary whitespace-nowrap" colSpan={2}>
                                    {i18n.t("delivery.cat_handling")}:
                                </td>
                            </tr>
                            <tr>
                                <td className="pb-5 whitespace-nowrap align-top">
                                    {i18n.t("delivery.pickup_time")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    <div className="flex gap-1 mb-3">
                                        <div>{props?.isDataLoading ? "" : i18n.t("delivery.time_from")}</div>
                                        <div className="grow">{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.preferred_pickup_time_from ? Formatter.dateToString(props?.deliveryInfo?.preferred_pickup_time_from) : i18n.t("common.unknown")}</div>
                                    </div>
                                    <div className="flex gap-1">
                                        <div>{props?.isDataLoading ? "" : i18n.t("delivery.time_to")}</div>
                                        <div className="grow">{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.preferred_pickup_time_to ? Formatter.dateToString(props?.deliveryInfo?.preferred_pickup_time_to) : i18n.t("common.unknown")}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="border-b-[1px] border-neutral-high-light">
                                <td className="pb-5 whitespace-nowrap align-top">
                                    {i18n.t("delivery.dropoff_time")}:
                                </td>
                                <td className="pb-5 ps-5 w-full">
                                    <div className="flex gap-1 mb-3">
                                        <div>{props?.isDataLoading ? "" : i18n.t("delivery.time_from")}</div>
                                        <div className="grow">{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.preferred_delivery_time_from ? Formatter.dateToString(props?.deliveryInfo?.preferred_delivery_time_from) : i18n.t("common.unknown")}</div>
                                    </div>
                                    <div className="flex gap-1">
                                        <div>{props?.isDataLoading ? "" : i18n.t("delivery.time_to")}</div>
                                        <div className="grow">{props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : props?.deliveryInfo?.preferred_delivery_time_to ? Formatter.dateToString(props?.deliveryInfo?.preferred_delivery_time_to) : i18n.t("common.unknown")}</div>
                                    </div>
                                </td>
                            </tr>

                            {props?.isDataLoading || props?.deliveryInfo?.skills?.length === 0 || props?.skillList?.length === 0
                            ?
                                <tr className="border-b-[1px] border-neutral-high-light">
                                    <td className="py-5 font-primary whitespace-nowrap">
                                        {i18n.t("delivery.cat_skills")}:
                                    </td>
                                    <td className="p-5">
                                        {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : i18n.t("common.na")}
                                    </td>
                                </tr>
                            :

                                props?.skillList?.filter(skill => props?.deliveryInfo?.skills?.includes(skill.skill_id))?.map((record, index) =>
                                {
                                    return (
                                        <tr key={index} className={(index === props?.deliveryInfo?.skills?.length-1) ? "border-b-[1px] border-neutral-high-light" : ""}>
                                            {(index === 0)
                                            ?
                                                <td className="py-5 font-primary whitespace-nowrap">
                                                    {i18n.t("delivery.cat_skills")} ({props?.deliveryInfo?.skills?.length}):
                                                </td>
                                            :
                                                <td className="pb-5"/>
                                            }

                                            <td className={(index === 0) ? "py-5 ps-5 w-full" : "pb-5 ps-5 w-full"}>
                                                {record?.[`name_${props?.prefs?.lang}`]}
                                            </td>
                                        </tr>
                                    )}
                                )
                            }


                            {props?.isDataLoading || !props?.deliveryInfo?.items?.length || props?.deliveryInfo?.items?.length === 0
                            ?
                                <tr className="border-b-[1px] md:border-b-0 border-neutral-high-light">
                                    <td className="py-5 font-primary whitespace-nowrap">
                                        {i18n.t("delivery.cat_items")}:
                                    </td>
                                    <td className="p-5">
                                        {props?.isDataLoading ? <Skelton className="bg-secondary-light" count={1}/> : i18n.t("common.na")}
                                    </td>
                                </tr>
                            :
                                props?.deliveryInfo?.items?.map((record, index) =>
                                {
                                    return (
                                        <tr key={index} className={(index === props?.deliveryInfo?.items?.length-1) ? "border-b-[1px] md:border-b-0 border-neutral-high-light" : ""}>
                                            {(index === 0)
                                            ?
                                                <td className="py-5 font-primary whitespace-nowrap">
                                                    {i18n.t("delivery.cat_items")} ({props?.deliveryInfo?.items?.length}):
                                                </td>
                                            :
                                                <td className="pb-5"/>
                                            }

                                            <td className={(index === 0) ? "py-5 ps-5 w-full" : "pb-5 ps-5 w-full"}>
                                                {record?.name}
                                            </td>
                                        </tr>
                                    )
                                }
                            )}                           
                        </tbody>
                    </table>
                </div>
                <div className="w-full md:w-[40%] md:ps-5 py-5">
                    <div className="pb-5 font-primary whitespace-nowrap">
                        {i18n.t("delivery.cat_history")}
                    </div>
                    <div className="flex flex-col gap-5 ps-5 border-s-[1px] border-neutral-high-regular border-dashed h-full">
                    {props?.isDataLoading || !props?.deliveryInfo?.status_history?.length || props?.deliveryInfo?.status_history?.length=== 0
                            ?
                                (props?.isDataLoading) ? <Skelton className="bg-secondary-light mb-[200px]" count={1}/> : i18n.t("common.no_results_found")
                            :                        
                                props?.deliveryInfo?.status_history?.map((record, index) =>
                                {
                                    return (
                                        <div key={index} className="bg-neutral-high-light rounded-md p-5 relative">
                                            <div className="text-end mb-2 text-xs">
                                                {Formatter.dateToString(record?.created_at)}
                                            </div>                                               
                                            <div className="w-[14px] h-[14px] bg-neutral-high-light absolute rotate-45 start-[-7px]"/>
                                            <div className="w-[14px] h-[14px] bg-neutral-high-lightest border-[3px] border-neutral-high-light absolute rounded-full start-[-28px]"/>

                                            <div className="font-bold pb-2">
                                                {i18n.t(`delivery.status_${record?.status_code}`)}
                                            </div>
                                            <div className="pb-2">
                                                {i18n.t("delivery.history_change_by")} {(record?.changed_by_user_id && record?.changed_by_user_id === props?.deliveryInfo?.captain?.user_id) ? i18n.t("delivery.captain") : (record?.changed_by_user_id) ? i18n.t("delivery.history_change_by_admin") : i18n.t("delivery.history_change_by_system")}
                                            </div>
                                            {(record?.comment)
                                            ?
                                                <div className="py-4">
                                                    {record?.comment}
                                                </div>
                                            :
                                                null
                                            }                                     
                                        </div>)
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default View;