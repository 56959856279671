
import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import useMediaQuery from '../../hook/useMediaQuery';

import Map from '../../view/component/map';
import InputText from '../component/inputText';
import Image from '../component/image';
import Badge from '../component/badge';
import Avatar from '../component/avatar';
import SideMenu from '../component/sideMenu';
import SideMenuItem from '../component/sideMenuItem';

import SearchDarkIconImage from '../../assets/vector/icon_search_dark.svg';
import SearchLightIconImage from '../../assets/vector/icon_search_light.svg';
import LogoArDarkImage from '../../assets/image/logo_horizontal_dark_ar.webp';
import LogoArLightImage from '../../assets/image/logo_horizontal_light_ar.webp';
import LogoEnDarkImage from '../../assets/image/logo_horizontal_dark_en.webp';
import LogoEnLightImage from '../../assets/image/logo_horizontal_light_en.webp';
import HamburgerDarkIconImage from '../../assets/vector/icon_hamburger_dark.svg';
import HamburgerLightIconImage from '../../assets/vector/icon_hamburger_light.svg';
import CloseDarkIconImage from '../../assets/vector/icon_close_dark.svg';
import CloseLightIconImage from '../../assets/vector/icon_close_light.svg';

const View = (props) =>
{
    const headsupRef = useRef(null);
    const loadingRef = useRef(null);

    const [keyword, setKeyword] = useState("");
    const [isSideMenuShown, toggleSideMenu] = useState(false);
    const [isHeadsUpShown, toggleHeadsUp] = useState(true);

    const isScreenSmall = useMediaQuery("(max-width: 640px)");

    const handleKeyDown = (e) =>
	{
		if(e.key === 'Enter' && keyword?.length > 0)
		{
			props?.search(keyword)
		}
	};

    const handleNotificationClick = (item) =>
    {
        if(item?.destination === "url_internal")
        {
            props?.internalRedirect(item?.url);
        }
        else if(item?.destination === "url_external")
        {
            props?.externalRedirect(item?.url);
        }
        else if(item?.destination === "dialog")
        {
            props?.showDialog(item?.id, item?.title, item?.bgColor, item?.size, item?.isScrollable, item?.isFullscreen, item?.isClosable, item?.shouldRefreshOnClose, item?.metadata);
        }       
    };

    return(
        <>
            <div className={`bg-primary-regular h-screen flex flex-col transition ease-in-out duration-normal ${isHeadsUpShown && props?.headsup ? "pt-16 pb-5 px-5" : "p-5"}`}>
                <AnimatePresence mode="wait" initial={false}>
                    {isHeadsUpShown && props?.headsup &&                
                        <motion.div 
                            className="bg-blue-500 text-secondary-lightest flex mb-5 fixed left-0 w-screen p-3"
                            ref={headsupRef}
                            initial={{ top: -100 }}
                            animate={{ top: 0}}
                            exit={{ top: -100 }}
                            transition={{ duration:  Constant?.animation?.speed?.fastest/1000}}
                            onClick={props?.toggle}                        
                        >
                            <div className="w-[24px] m-auto">

                            </div>
                            <div className="grow text-center m-auto cursor-pointer" onClick={() => {handleNotificationClick(props?.headsup)}}>
                                {props?.headsup?.msg}
                            </div>
                            <div className="w-[24px] m-auto">
                                <Image src={CloseLightIconImage} alt="" className="h-[24px] w-[24px] cursor-pointer transition duration-normal ease-in-out opacity-90 hover:opacity-50" onClick={() => {toggleHeadsUp(!isHeadsUpShown)}}/>                       
                            </div>
                        </motion.div>
                    }  
                </AnimatePresence>
                <div className="bg-neutral-high-light h-full rounded-xl flex shadow-2xl shadow-shadow-regular transition ease-in-out duration-normal">
                    <div className="bg-neutral-high-light min-w-[270px] max-w-[270px] rounded-s-xl hidden lg:block text-sm relative transition ease-in-out duration-normal">
                        <div className={`bg-gradient-to-b blur-3xl opacity-50 from-primary-regular via-primary-regular to-accent-regular rounded-full w-[250px] h-[250px] absolute top-[50px] ${props?.prefs?.dir === "ltr" ? "left-[75%]" : "right-[75%]"}`}/>
                        <div className="m-5 mt-8">
                            <div className="mb-7">
                                <NavLink to="/dashboard/overview">
                                    {(props?.prefs?.theme === "light")
                                    ?
                                        <Image src={props?.prefs?.lang === "ar" ? LogoArDarkImage : LogoEnDarkImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                    :
                                        <Image src={props?.prefs?.lang === "ar" ? LogoArLightImage : LogoEnLightImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                    }
                                </NavLink>
                            </div>
                            <SideMenuItem text={i18n.t("page.dashboard_overview")} isActive={["/dashboard", "/dashboard/overview"]?.includes(props?.currentRoute)} icon={"overview"} onClick={() => props?.redirectTo("/dashboard/overview")} theme={props?.prefs?.theme}/>
                            <SideMenuItem text={i18n.t("page.dashboard_map")} isActive={props?.currentRoute?.startsWith("/dashboard/map")} icon={"map"} onClick={() => props?.redirectTo("/dashboard/map")} theme={props?.prefs?.theme}/>
                            <SideMenuItem text={i18n.t("page.dashboard_delivery_list")} isActive={props?.currentRoute?.startsWith("/dashboard/delivery")} icon={"delivery"} onClick={() => props?.redirectTo("/dashboard/delivery")} theme={props?.prefs?.theme}/>
                            <SideMenuItem text={i18n.t("page.dashboard_user")} isActive={props?.currentRoute?.startsWith("/dashboard/user")} icon={"user"} onClick={() => props?.redirectTo("/dashboard/user")} theme={props?.prefs?.theme}/>
                            <SideMenuItem text={i18n.t("page.dashboard_wallet")} isActive={props?.currentRoute?.startsWith("/dashboard/wallet")} icon={"wallet"} onClick={() => props?.redirectTo("/dashboard/wallet")} theme={props?.prefs?.theme}/>
                            <SideMenuItem text={i18n.t("page.dashboard_finance_manager")} isActive={props?.currentRoute?.startsWith("/dashboard/collection")} icon={"collection"} onClick={() => props?.redirectTo("/dashboard/collection")} theme={props?.prefs?.theme}/>
                            <SideMenuItem text={i18n.t("page.dashboard_settings")} isActive={props?.currentRoute?.startsWith("/dashboard/settings")} icon={"settings"} onClick={() => props?.redirectTo("/dashboard/settings")} theme={props?.prefs?.theme}/>
                        </div>
                    </div>
                    <div className="relative w-full rounded-xl">
                        
                        <div className="h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl">
                            <Map
                                className="h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl"
                                mapClassName="rounded-xl lg:rounded-s-none lg:rounded-e-xl"
                                initZoom={11}
                                minZoom={9}
                                maxZoom={20}
                                showZoomControl={true}
                                showMapTypeControl={false}
                                showScaleControl={false}
                                showStreetViewControl={false}
                                showRotateControl={false}
                                showFullscreenControl={false}
                                showClickableIcons={false}
                                showScrollwheel={false}                        
                                lat={props?.mapCenter?.lat}
                                long={props?.mapCenter?.long}
                                theme={props?.prefs?.theme}
                                markers={props?.mapType?.toLowerCase() === "delivery" ? props?.mapDeliveryList : props?.mapCaptainList}
                                markerTitleKey={props?.mapType?.toLowerCase() === "delivery" ? "tracking_number" : "first_name"}
                                markerDialogDataID={props?.mapType?.toLowerCase() === "delivery" ? "delivery_id" : "user_id"}
                                markerDialogInfo={props?.mapType?.toLowerCase() === "delivery" ? props?.mapDeliveryDialogInfo : props?.mapCaptainDialogInfo}  
                            /> 
                        </div>
                        
                        <div className={`${props?.currentRoute?.startsWith("/dashboard/map") ? "absolute top-0 start-0 w-full" : "absolute top-0 start-0 w-full h-full"}`}>
                            {props?.children}
                        </div> 
                        
                        <AnimatePresence>
                            {props?.isRouteChanging &&
                                <motion.div 
                                    className="absolute top-0 left-0 w-full h-full bg-neutral-high-lightest rounded-xl"
                                    initial={{ opacity: 0}}
                                    animate={{ opacity: 1}}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration:  Constant?.animation?.speed?.fast/1000}}                                
                                />
                            }
                        </AnimatePresence>     
                        

                        <div className="absolute top-0 left-0 w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl flex flex-col p-5 bg-gradient-to-b from-gradient-darkest to-gradient-lightest">
                            <div className="lg:hidden">
                                <div className="p-0 mb-5 flex justify-between">
                                    <div className="my-auto">
                                        <NavLink to="/dashboard/overview">
                                            {(props?.prefs?.theme === "light")
                                            ?
                                                <Image src={props?.prefs?.lang === "ar" ? LogoArDarkImage : LogoEnDarkImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                            :
                                                <Image src={props?.prefs?.lang === "ar" ? LogoArLightImage : LogoEnLightImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                            }
                                        </NavLink>
                                    </div>
                                    <div>
                                        <div className="opacity-25 hover:opacity-10" onClick={() => {toggleSideMenu(true)}}>
                                            <Image src={(props?.prefs?.theme === "light") ?  HamburgerDarkIconImage : HamburgerLightIconImage} alt="" className="h-[30px] w-[30px] cursor-pointer transition duration-normal ease-in-out"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between gap-3 ">
                                <div className="p-0 flex-grow lg:flex-none lg:w-[300px] xl:w-[400px] relative">
                                    <InputText 
                                        type={"text"}
                                        inputClassName="text-neutral-low-dark placeholder-neutral-high-darkest bg-neutral-high-light border-neutral-high-regular w-[100%] shadow-sm h-[50px]"
                                        enableAutoComplete={false}
                                        isFullyRounded={true}
                                        defaultValue={props?.keyword}
                                        placeholder={(isScreenSmall) ? i18n.t("common.search_generic") : i18n.t("common.search_delivery")}
                                        onChange={(e) => {setKeyword(e?.target?.value)}}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                    />
                                    <Image 
                                        src={(props?.prefs?.theme === "light") ? SearchDarkIconImage : SearchLightIconImage} 
                                        alt="" 
                                        className="h-[16px] w-[16px] cursor-pointer absolute top-[18px] end-5"
                                        onClick={(e) => {if(keyword?.length > 0) props?.search(keyword)}}
                                    />
                                </div>                                
                                <div className="p-0 flex gap-3 relative">
                                    <Badge onClick={props?.showNotificationCenter} type={"notification"} theme={props?.prefs?.theme} hasIndicator={(props?.notifications?.length > 0 && !props?.shouldSilenceNotifications)} className="shadow-sm"/>
                                    <Badge onClick={props?.toggleTheme} type={(props?.prefs?.theme === "light") ? "theme-dark" : "theme-light"} theme={props?.prefs?.theme} className="shadow-sm"/>
                                    <Avatar 
                                        className="shadow-sm"
                                        userFullName={(props?.prefs?.user_first_name && props?.prefs?.user_first_name?.length > 0 && props?.prefs?.user_last_name && props?.prefs?.user_last_name?.length > 0) ? `${props?.prefs?.user_first_name} ${props?.prefs?.user_last_name}` : i18n.t("common.unknown")} 
                                        imageURL={(props?.prefs?.user_avatar && props?.prefs?.user_avatar?.length > 0) ? props?.prefs?.user_avatar : null} 
                                        merchantName={props?.prefs?.merchant_display_name}
                                        openDialog={props?.openDialog}
                                        redirectTo={props?.redirectTo}
                                        prefs={props?.prefs}
                                        changeLang={props?.changeLang}
                                    />
                                </div>                            
                            </div>                        
                        </div>

                        <AnimatePresence>
                        {props?.isLoading &&
                            <motion.div 
                                ref={loadingRef}
                                initial={{ opacity: 0}}
                                animate={{ opacity: 0.9}}
                                exit={{ opacity: 0}}                
                                className="absolute top-0 start-0 z-20 w-full h-full bg-neutral-high-lightest transition duration-normal ease-in-out flex text-neutral-low-dark rounded-e-lg"
                            >
                                <div className="inline-block h-4 w-4 m-auto animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"/>
                                </div>
                            </motion.div>}
                        </AnimatePresence>               
                    </div>
                </div>
            </div>
            <SideMenu dir={props?.prefs?.dir} isShown={isSideMenuShown} toggle={() => {toggleSideMenu(!isSideMenuShown)}}>
                <div className={`${(props?.prefs?.theme === "light") ? "bg-glare" : ""} w-[100] h-[100%] px-5 pt-5 flex flex-col rounded-s-xl`}>
                    <div className="flex justify-end mb-5">
                        <Image src={(props?.prefs?.theme === "light") ?  CloseDarkIconImage : CloseLightIconImage} alt="" className="h-[30px] w-[30px] cursor-pointer transition duration-normal ease-in-out opacity-30 hover:opacity-10" onClick={() => {toggleSideMenu(false)}}/>
                    </div>
                    <div className="h-[50px] flex mb-5">
                        <div className="my-auto">
                            <NavLink to="/dashboard/overview" onClick={() => {toggleSideMenu(false)}}>
                                {(props?.prefs?.theme === "light")
                                ?
                                    <Image src={props?.prefs?.lang === "ar" ? LogoArDarkImage : LogoEnDarkImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                :
                                    <Image src={props?.prefs?.lang === "ar" ? LogoArLightImage : LogoEnLightImage} alt="" className="w-[150px] cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"/>
                                }
                            </NavLink>
                        </div>
                    </div>
                    <SideMenuItem text={i18n.t("page.dashboard_overview")} isActive={["/dashboard", "/dashboard/overview"]?.includes(props?.currentRoute)} icon={"overview"} onClick={() => {props?.redirectTo("/dashboard/overview"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                    <SideMenuItem text={i18n.t("page.dashboard_map")} isActive={props?.currentRoute?.startsWith("/dashboard/map")} icon={"map"} onClick={() => {props?.redirectTo("/dashboard/map"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                    <SideMenuItem text={i18n.t("page.dashboard_delivery_list")} isActive={props?.currentRoute?.startsWith("/dashboard/delivery")} icon={"delivery"} onClick={() => {props?.redirectTo("/dashboard/delivery"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                    <SideMenuItem text={i18n.t("page.dashboard_user")} isActive={props?.currentRoute?.startsWith("/dashboard/user")} icon={"user"} onClick={() => {props?.redirectTo("/dashboard/user"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                    <SideMenuItem text={i18n.t("page.dashboard_wallet")} isActive={props?.currentRoute?.startsWith("/dashboard/wallet")} icon={"wallet"} onClick={() => {props?.redirectTo("/dashboard/wallet"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                    <SideMenuItem text={i18n.t("page.dashboard_finance_manager")} isActive={props?.currentRoute?.startsWith("/dashboard/collection")} icon={"collection"} onClick={() => {props?.redirectTo("/dashboard/collection"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                    <SideMenuItem text={i18n.t("page.dashboard_settings")} isActive={props?.currentRoute?.startsWith("/dashboard/settings")} icon={"settings"} onClick={() => {props?.redirectTo("/dashboard/settings"); toggleSideMenu(false);}} theme={props?.prefs?.theme}/>
                </div>
            </SideMenu>            
        </>
    );
}

export default View;
