import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import i18n from '../../util/i18n';
import Formatter from '../../util/formatter';

import Button from '../component/button';
import Skelton from "../component/skelton";
import InputText from "../component/inputText";

import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';
import EyeClosedDarkIconImage from '../../assets/vector/icon_eye_closed_dark.svg';
import EyeClosedLightIconImage from '../../assets/vector/icon_eye_closed_light.svg';
import EyeOpenDarkIconImage from '../../assets/vector/icon_eye_open_dark.svg';
import EyeOpenLightIconImage from '../../assets/vector/icon_eye_open_light.svg';
import RefreshDarkIconImage from '../../assets/vector/icon_refresh_dark.svg';
import RefreshLightIconImage from '../../assets/vector/icon_refresh_light.svg';
import CopyDarkIconImage from '../../assets/vector/icon_copy_dark.svg';
import CopyLightIconImage from '../../assets/vector/icon_copy_light.svg';

const View = (props) =>
{
    const [shownKeys, setShownKeys] = useState([]);

    const navigate = useNavigate();

    return( 
        <div className={`h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-gradient-regular via-gradient-dark to-gradient-darkest flex flex-col`}>
            <div className="border-t-[1px] border-neutral-high-regular grow text-neutral-low-dark overflow-scroll mt-[150px] lg:mt-[92px]" data-lenis-prevent>
                <div className="p-5 transition ease-in-out duration-normal">
                    <div className="mb-5 font-primary text-lg">
                        {i18n.t("page.dashboard_settings")}
                    </div>
                    <div className="flex flex-wrap-reverse xl:flex-nowrap gap-5">
                        <div className="w-full xl:w-[70%] rounded-xl shadow-xl bg-neutral-high-lightest">
                            <div className="flex w-full">
                                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "account") ? "" : "bg-primary-regular cursor-pointer text-secondary-darkest"} ${(props?.prefs?.dir === "rtl") ? "rounded-tr-xl" : "rounded-tl-xl"}`} onClick={() => {navigate("/dashboard/settings/account")}}>
                                    <div className="m-auto p-5">{i18n.t("page.dashboard_settings_account")}</div>
                                </div>
                                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "zone") ? "" : "bg-primary-regular cursor-pointer text-secondary-darkest"}`} onClick={() => {navigate("/dashboard/settings/zone")}}>
                                    <div className="m-auto p-5">{i18n.t("page.dashboard_settings_zone")}</div>
                                </div>
                                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "trip") ? "" : "bg-primary-regular cursor-pointer text-secondary-darkest"}`} onClick={() => {navigate("/dashboard/settings/trip")}}>
                                    <div className="m-auto p-5">{i18n.t("page.dashboard_settings_trip")}</div>
                                </div>
                                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "bank-account") ? "" : "bg-primary-regular cursor-pointer text-secondary-darkest"} ${(props?.prefs?.dir === "rtl") ? "rounded-tl-xl" : "rounded-tr-xl"}`} onClick={() => {navigate("/dashboard/settings/bank-account")}}>
                                    <div className="m-auto p-5">{i18n.t("page.dashboard_settings_bank_account")}</div>
                                </div>
                            </div>

                            {(props?.tab === "account")
                            ?
                                <div className="p-10">
                                    <div className="p-0">                            
                                        <table className="p-0">
                                            <tbody>



                                                <tr>
                                                    <td className="pb-5 font-primary whitespace-nowrap" colSpan={2}>
                                                        {i18n.t("common.business_info")}
                                                    </td>
                                                    <td className="pb-5 text-end align-top ps-6" rowSpan={7}>
                                                        <Button 
                                                            type="neutral-low-solid" 
                                                            className="min-w-[90px]" 
                                                            text={i18n.t("common.edit")}
                                                            isLoading={props?.isLoading} 
                                                            onClick={() => {props?.showMerchantInfoDialog('name')}}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.display_name')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.display_name ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.legal_name')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.legal_name ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.merchant_code')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.merchant_code ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.address')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.contact_address ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>  
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.communication_method')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : (props?.merchantInfo?.communication_method === "sms") ? i18n.t("common.sms") : (props?.merchantInfo?.communication_method === "email") ? i18n.t("common.email") : i18n.t("common.unknown")}
                                                    </td>
                                                </tr>  
                                                <tr>
                                                    <td className="pb-10 font-bold whitespace-nowrap">
                                                        {i18n.t('common.lang')}:
                                                    </td>
                                                    <td className="pb-10 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : (props?.merchantInfo?.lang_code === "ar") ? i18n.t("lang.ar") : (props?.merchantInfo?.lang_code === "en") ? i18n.t("lang.en") : i18n.t("common.unknown")}
                                                    </td>
                                                </tr>




                                                <tr className="border-t-[1px] border-neutral-high-light">
                                                    <td className="pt-10 pb-5 font-primary whitespace-nowrap" colSpan={2}>
                                                        {i18n.t("common.contact_info")}
                                                    </td>
                                                    <td className="pt-10 pb-5 text-end align-top ps-6" rowSpan={4}>
                                                        <Button 
                                                            type="neutral-low-solid" 
                                                            className="min-w-[90px]" 
                                                            text={i18n.t("common.edit")}
                                                            isLoading={props?.isLoading} 
                                                            onClick={() => {props?.showMerchantInfoDialog('contact')}}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.spoc')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : (props?.merchantInfo?.contact_first_name && props?.merchantInfo?.contact_last_name) ? `${props?.merchantInfo?.contact_first_name} ${props?.merchantInfo?.contact_last_name}` : i18n.t("common.unknown")}
                                                    </td>
                                                </tr>  
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.mobile')}:
                                                    </td>
                                                    <td className={`pb-5 w-full min-w-[100px] ${(props?.prefs?.dir === "rtl") ? "ltr text-right pe-2" : "ps-2"}`}>
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : (props?.merchantInfo?.contact_mobile_code && props?.merchantInfo?.contact_mobile) ? `+${props?.merchantInfo?.contact_mobile_code}${props?.merchantInfo?.contact_mobile}` : i18n.t("common.unknown")}
                                                    </td>
                                                </tr>  
                                                <tr>
                                                    <td className="pb-10 font-bold whitespace-nowrap">
                                                        {i18n.t('common.email')}:
                                                    </td>
                                                    <td className="pb-10 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.contact_email ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>



                                                <tr className="border-t-[1px] border-neutral-high-light">
                                                    <td className="pt-10 pb-5 font-primary whitespace-nowrap" colSpan={2}>
                                                        {i18n.t("common.cr")}
                                                    </td>
                                                    <td className="pt-10 pb-5 text-end align-top ps-6" rowSpan={4}>
                                                        <Button 
                                                            type="neutral-low-solid" 
                                                            className="min-w-[90px]" 
                                                            text={i18n.t("common.edit")}
                                                            isLoading={props?.isLoading} 
                                                            onClick={() => {props?.showMerchantInfoDialog('cr')}}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.cr_number')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.cr_number ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.start_date')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : (props?.merchantInfo?.cr_start_date) ? Formatter.dateToString(props?.merchantInfo?.cr_start_date, "date") : i18n.t("common.unknown")}
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td className="pb-10 font-bold whitespace-nowrap">
                                                        {i18n.t('common.end_date')}:
                                                    </td>
                                                    <td className="pb-10 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : (props?.merchantInfo?.cr_end_date) ? Formatter.dateToString(props?.merchantInfo?.cr_end_date, "date") : i18n.t("common.unknown")}
                                                    </td>
                                                </tr> 



                                                <tr className="border-t-[1px] border-neutral-high-light">
                                                    <td className="pt-10 pb-5 font-primary whitespace-nowrap" colSpan={2}>
                                                        {i18n.t("page.subscription")}
                                                    </td>
                                                    <td className="pt-10 pb-5 text-end align-top ps-6" rowSpan={9}>
                                                        <Button 
                                                            type="neutral-low-solid" 
                                                            className="min-w-[90px]" 
                                                            text={i18n.t("common.edit")}
                                                            isLoading={props?.isLoading} 
                                                            onClick={props?.showMerchantSubscriptionDialog}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.subscription')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : i18n.t(`subscription.${props?.merchantInfo?.subscription?.subscription_code}`) ?? i18n.t("common.unknown")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.status')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.has_expired ? i18n.t("common.expired") : i18n.t("common.valid")}
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.expires_at')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.expires_at ? Formatter.dateToString(props?.merchantInfo?.subscription?.expires_at) : i18n.t("common.unknown")}
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.delivery_quota')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.delivery_quota >= 0 ? Formatter.stringToNumberWithCommas(props?.merchantInfo?.subscription?.delivery_quota) : i18n.t("common.unknown")}
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.delivery_usage')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.delivery_usage >= 0 ? Formatter.stringToNumberWithCommas(props?.merchantInfo?.subscription?.delivery_usage) : i18n.t("common.unknown")}
                                                    </td>
                                                </tr>                                                 
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.delivery_fee_private')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.private_delivery_fee >= 0 ? `${Formatter?.stringToNumberWithCommas(props?.merchantInfo?.subscription?.private_delivery_fee)} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.unknown")}
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.delivery_fee_public_successful')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.public_delivery_successful_fee >= 0 ? `${Formatter?.stringToNumberWithCommas(props?.merchantInfo?.subscription?.public_delivery_successful_fee)} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.unknown")}
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td className="pb-10 font-bold whitespace-nowrap">
                                                        {i18n.t('subscription.delivery_fee_public_failed')}:
                                                    </td>
                                                    <td className="pb-10 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.subscription?.public_delivery_failed_fee >= 0 ? `${Formatter?.stringToNumberWithCommas(props?.merchantInfo?.subscription?.public_delivery_failed_fee)} ${i18n.t("currency." + props?.prefs?.currency)}` : i18n.t("common.unknown")}
                                                    </td>
                                                </tr>



                                                <tr className="border-t-[1px] border-neutral-high-light">
                                                    <td className="pt-10 pb-5 font-primary whitespace-nowrap" colSpan={2}>
                                                        {i18n.t("page.public_delivery")}
                                                    </td>
                                                    <td className="pt-10 pb-5 text-end align-top ps-6" rowSpan={4}>
                                                        <Button 
                                                            type="neutral-low-solid" 
                                                            className="min-w-[90px]" 
                                                            text={i18n.t("common.edit")}
                                                            isLoading={props?.isLoading} 
                                                            onClick={props?.showMerchantPublicDeliveryDialog}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.public_delivery_calculation_type')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {props?.isLoading ? <Skelton className="bg-secondary-regular" count={1}/> : props?.merchantInfo?.public_delivery_commission_type ? i18n.t(`common.public_delivery_calculation_type_${props?.merchantInfo?.public_delivery_commission_type}`) :  i18n.t("common.unknown")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-5 font-bold whitespace-nowrap">
                                                        {i18n.t('common.public_delivery_captain_share')}:
                                                    </td>
                                                    <td className="pb-5 ps-2 w-full min-w-[100px]">
                                                        {
                                                            (props?.isLoading)
                                                            ?
                                                                <Skelton className="bg-secondary-regular" count={1}/>
                                                            :
                                                            (props?.merchantInfo?.public_delivery_commission_amount >= 0 && props?.merchantInfo?.public_delivery_commission_type === "fixed")
                                                            ?
                                                                `${props?.merchantInfo?.public_delivery_commission_amount} ${i18n.t("currency." + props?.prefs?.currency)}`
                                                            :
                                                            (props?.merchantInfo?.public_delivery_commission_amount >= 0 && props?.merchantInfo?.public_delivery_commission_type === "percentage")
                                                            ?
                                                                `${props?.merchantInfo?.public_delivery_commission_amount}%`
                                                            :
                                                                i18n.t("common.unknown")
                                                        }
                                                    </td>                                            
                                                </tr> 
                                                <tr>
                                                    <td className="pb-10 font-bold whitespace-nowrap">
                                                        {i18n.t('common.public_delivery_merchant_share')}:
                                                    </td>
                                                    <td className="pb-10 ps-2 w-full min-w-[100px]">
                                                        {
                                                            (props?.isLoading)
                                                            ?
                                                                <Skelton className="bg-secondary-regular" count={1}/>
                                                            :
                                                            (props?.merchantInfo?.public_delivery_commission_amount >= 0 && props?.merchantInfo?.public_delivery_commission_type === "fixed")
                                                            ?
                                                                i18n.t("common.public_delivery_share_is_remainder")
                                                            :
                                                            (props?.merchantInfo?.public_delivery_commission_amount >= 0 && props?.merchantInfo?.public_delivery_commission_type === "percentage")
                                                            ?
                                                                `${Math.abs(100-props?.merchantInfo?.public_delivery_commission_amount)}%`
                                                            :
                                                                i18n.t("common.unknown")
                                                        }                                                
                                                    </td>                                            
                                                </tr> 



                                                <tr className="border-t-[1px] border-neutral-high-light">
                                                    <td className="pt-10 pb-5 font-primary whitespace-nowrap" colSpan={3}>
                                                        {i18n.t("page.integration")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-bold whitespace-nowrap">
                                                        {i18n.t('integration.api_key')}:
                                                    </td>
                                                    <td className="ps-2 w-full min-w-[100px]" colSpan={2}>
                                                        <div className="flex gap-3 w-full">
                                                            <InputText 
                                                                type={shownKeys?.includes("api_key") ? "text" : "password"}
                                                                disabled={true}
                                                                autoComplete={false}
                                                                inputClassName="text-neutral-low-darkest bg-neutral-high-light border-neutral-high-regular active:border-neutral-high-regular hover:border-neutral-high-regular"
                                                                value={props?.merchantInfo?.api_key}
                                                                onChange={() => {}}
                                                            />
                                                            <Button 
                                                                type="neutral-low-solid" 
                                                                className="w-[70px] h-[42px]" 
                                                                icon={(props?.prefs?.theme === "dark" && shownKeys?.includes("api_key")) ? EyeOpenDarkIconImage : (props?.prefs?.theme === "dark" && !shownKeys?.includes("api_key")) ? EyeClosedDarkIconImage : (props?.prefs?.theme === "light" && shownKeys?.includes("api_key")) ? EyeOpenLightIconImage : EyeClosedLightIconImage}
                                                                iconHover={(props?.prefs?.theme === "dark" && shownKeys?.includes("api_key")) ? EyeOpenLightIconImage : (props?.prefs?.theme === "dark" && !shownKeys?.includes("api_key")) ? EyeClosedLightIconImage : (props?.prefs?.theme === "light" && shownKeys?.includes("api_key")) ? EyeOpenDarkIconImage : EyeClosedDarkIconImage}
                                                                isLoading={props?.isLoading} 
                                                                onClick={() => {if(shownKeys?.includes("api_key")){setShownKeys(shownKeys?.filter(key => key !== "api_key"));}else{setShownKeys(oldKeys => [...oldKeys, "api_key"]);}}}
                                                            />
                                                            <Button 
                                                                type="neutral-low-solid" 
                                                                className="w-[70px] h-[42px]" 
                                                                icon={(props?.prefs?.theme === "dark") ? CopyDarkIconImage : CopyLightIconImage}
                                                                iconHover={(props?.prefs?.theme === "light") ? CopyDarkIconImage : CopyLightIconImage} 
                                                                isLoading={props?.isLoading} 
                                                                onClick={() => props?.copy(props?.merchantInfo?.api_key)}
                                                            />
                                                            <Button 
                                                                type="neutral-low-solid" 
                                                                className="w-[70px] h-[42px]" 
                                                                icon={(props?.prefs?.theme === "dark") ? RefreshDarkIconImage : RefreshLightIconImage}
                                                                iconHover={(props?.prefs?.theme === "light") ? RefreshDarkIconImage : RefreshLightIconImage} 
                                                                isLoading={props?.isLoading} 
                                                                onClick={props?.generateKey}
                                                            />
                                                        </div>                                            
                                                    </td>
                                                </tr>



                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            :
                            (props?.tab === "zone")
                            ?
                                <div className="p-10">
                                    <div className="pb-5 text-end">
                                        <Button 
                                            type="neutral-low-outline" 
                                            text={i18n.t("common.add")}
                                            className="min-w-[90px]" 
                                            icon={(props?.prefs?.theme === "light") ? AddDarkIconImage : AddLightIconImage}                                     
                                            iconHover={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                                            isLoading={props?.isLoading} 
                                            onClick={props?.showZoneCreateDialog}
                                        />
                                    </div>
                                    <div className="p-0">
                                        {(props?.isLoading)
                                        ?
                                            <Skelton className="bg-secondary-regular mt-5" count={3}/>
                                        :
                                            <table className="p-0 w-full">
                                                <tbody>                                            
                                                    {(props?.zoneList?.length === 0)
                                                        ?
                                                            <tr>
                                                                <td className="text-center">
                                                                    {i18n.t("common.no_zone_found")}
                                                                </td>
                                                            </tr>
                                                        :
                                                            props?.zoneList?.map((zone, key) =>
                                                            {
                                                                return (
                                                                    <tr key={key} className={(key < props?.zoneList?.length-1) ? "border-b-[1px] border-neutral-high-light" : ""}>
                                                                        <td className="py-3 w-full">
                                                                            {zone?.[`name_${props?.prefs?.lang}`]}
                                                                        </td>
                                                                        <td className="py-3">
                                                                            <Button 
                                                                                type="neutral-low-solid" 
                                                                                className="min-w-[90px]" 
                                                                                text={i18n.t("common.edit")}
                                                                                isLoading={props?.isLoading} 
                                                                                onClick={() => {props?.showZoneInfoDialog(zone?.zone_id)}}
                                                                            />                                                        
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>                                             
                                        }
                                    </div>                            
                                </div>
                            :
                            (props?.tab === "trip")
                            ?
                                <div className="p-10">
                                    <div className="pb-5 text-end">
                                        <Button 
                                            type="neutral-low-outline" 
                                            className="min-w-[90px]" 
                                            icon={(props?.prefs?.theme === "light") ? AddDarkIconImage : AddLightIconImage}                                     
                                            iconHover={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                                            text={i18n.t("common.add")}
                                            isLoading={props?.isLoading} 
                                            onClick={props?.showTripCreateDialog}
                                        />
                                    </div>
                                    <div className="p-0">
                                        {(props?.isLoading)
                                        ?
                                            <Skelton className="bg-secondary-regular mt-5" count={3}/>
                                        :
                                            <table className="p-0 w-full">
                                                <tbody>                                            
                                                    {(props?.tripList?.length === 0)
                                                        ?
                                                            <tr>
                                                                <td className="text-center">
                                                                    {i18n.t("common.no_trip_found")}
                                                                </td>
                                                            </tr>
                                                        :
                                                            props?.tripList?.map((trip, key) =>
                                                            {
                                                                return (
                                                                    <tr key={key} className={(key < props?.tripList?.length-1) ? "border-b-[1px] border-neutral-high-light" : ""}>
                                                                        <td className="py-3 w-full">
                                                                            <div className="flex gap-2">
                                                                                <div className="my-auto">
                                                                                    {trip?.[`name_${props?.prefs?.lang}`]}
                                                                                </div>
                                                                                <div className={`my-auto px-2 py-1 font-primary text-xs rounded-md min-w-[50px] text-center ${!trip?.is_draft ? "bg-status-success-lightest text-status-success-darkest" : "bg-accent-lightest text-accent-regular"}`}>
                                                                                    {trip?.is_draft ? i18n.t("trip.draft") : i18n.t("trip.published")}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="py-3">
                                                                            <Button 
                                                                                type="neutral-low-solid" 
                                                                                className="min-w-[90px]" 
                                                                                text={i18n.t("common.edit")}
                                                                                isLoading={props?.isLoading} 
                                                                                onClick={() => {props?.showTripInfoDialog(trip?.trip_id)}}
                                                                            />                                                        
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>                                             
                                        }
                                    </div>                          
                                </div>
                            :
                            (props?.tab === "bank-account")
                            ?
                                <div className="p-10">
                                    <div className="pb-5 text-end">
                                        <Button 
                                            type="neutral-low-outline" 
                                            className="min-w-[90px]" 
                                            icon={(props?.prefs?.theme === "light") ? AddDarkIconImage : AddLightIconImage}                                     
                                            iconHover={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}                                    
                                            text={i18n.t("common.add")}
                                            isLoading={props?.isLoading} 
                                            onClick={props?.showBankAccountCreateDialog}
                                        />
                                    </div>
                                    <div className="p-0">
                                        {(props?.isLoading)
                                        ?
                                            <Skelton className="bg-secondary-regular mt-5" count={3}/>
                                        :
                                            <table className="p-0 w-full">
                                                <tbody>                                            
                                                    {(props?.bankAccountList?.length === 0)
                                                        ?
                                                            <tr>
                                                                <td className="text-center">
                                                                    {i18n.t("common.no_bank_account_found")}
                                                                </td>
                                                            </tr>
                                                        :
                                                            props?.bankAccountList?.map((bankAccount, key) =>
                                                            {
                                                                return (
                                                                    <tr key={key} className={(key < props?.bankAccountList?.length-1) ? "border-b-[1px] border-neutral-high-light" : ""}>
                                                                        <td className="py-3 w-full">
                                                                            <div className="flex gap-1">
                                                                                <div>
                                                                                    {i18n.t("common.bank_account_masked")}
                                                                                </div>
                                                                                <div className="ltr">
                                                                                    {Formatter.mask(bankAccount?.bank_account_number, 3)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="py-3">
                                                                            <Button 
                                                                                type="neutral-low-solid" 
                                                                                className="min-w-[90px]" 
                                                                                text={i18n.t("common.edit")}
                                                                                isLoading={props?.isLoading} 
                                                                                onClick={() => {props?.showBankAccountInfoDialog(bankAccount)}}
                                                                            />                                                        
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>                                             
                                        }
                                    </div>                          
                                </div>
                            :
                                null
                            }                                        
                        </div> 
                        <div className="w-full xl:w-[30%]">
                            <div className="rounded-xl shadow-xl bg-neutral-high-lightest transition ease-in-out duration-normal w-full p-7">
                                <div className="font-primary mb-5">
                                    {i18n.t("common.tips")}
                                </div>
                                <div className={`leading-6 md:leading-8 ${(props?.prefs?.lang === "ar") ? "text-justify" : ""}`}>
                                    {(props?.tab === "account") ? i18n.t("settings.tip_account") : (props?.tab === "zone") ? i18n.t("settings.tip_zone") : (props?.tab === "trip") ? i18n.t("settings.tip_trip") : (props?.tab === "bank-account") ? i18n.t("settings.tip_bank_account") : ""}
                                </div>
                            </div>
                        </div>                
                    </div>
                </div> 
            </div>
        </div>  
    );
}

export default View;    