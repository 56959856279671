import { useState, useEffect, useContext } from "react";

import Endpoint from '../../config/endpoint';
import Constant from '../../config/constant';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';
import Validator from '../../util/validator';

import DeliveryCreate from '../../view/dialog/deliveryCreate';

const Dialog = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isDataLoading, setDataLoading] = useState(false);
    const [isCountryListLoading, setCountryListLoading] = useState(false);
    const [isZoneListLoading, setZoneListLoading] = useState(false);
    const [isTripListLoading, setTripListLoading] = useState(false);
    const [isSkillListLoading, setSkillListLoading] = useState(false);
    const [privateZoneList, setPrivateZoneList] = useState([]);
    const [publicZoneList, setPublicZoneList] = useState([]);
    const [tripList, setTripList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [errors, setErrors]     = useState({});

    const context = useContext(Context);

    const next = (data) =>
    {
        let newErrors = {};

        if(!Validator.notEmpty(data?.zone_id))
        {
            newErrors["zone_id"] = i18n.t('error.invalid_zone');            
        }
        
        if(data?.post_type === "private" && !Validator.notEmpty(data?.trip_id))
        {
            newErrors["trip_id"] = i18n.t('error.invalid_trip');            
        }            

        if(!Validator.notEmpty(data?.bill_reference_number))
        {
            newErrors["bill_reference_number"] = i18n.t('error.invalid_bill_reference_number');            
        }
        
        if(!Validator.notEmpty(data?.bill_amount))
        {
            newErrors["bill_amount"] = i18n.t('error.invalid_bill_amount');            
        }            

        if(!Validator.notEmpty(data?.collection_amount))
        {
            newErrors["collection_amount"] = i18n.t('error.invalid_collection_amount');            
        }

        if(!Validator.notEmpty(data?.sender_first_name))
        {
            newErrors["sender_name"] = i18n.t('error.invalid_first_name');            
        }
        else if(!Validator.notEmpty(data?.sender_last_name))
        {
            newErrors["sender_name"] = i18n.t('error.invalid_last_name');            
        }
        
        if(!Validator.notEmpty(data?.sender_email))
        {
            newErrors["sender_email"] = i18n.t('error.invalid_email');            
        }   
        else if(!Validator.email(data?.sender_email))
        {
            newErrors["sender_email"] = i18n.t('error.invalid_email');            
        } 
        
        if(!Validator.notEmpty(data?.sender_mobile))
        {
            newErrors["sender_mobile"] = i18n.t('error.invalid_mobile');
        }
        else if(!Validator.numeric(data?.sender_mobile))
        {
            newErrors["sender_mobile"] = i18n.t('error.invalid_mobile');
        }        
        
        if(!Validator.notEmpty(data?.sender_mobile_code))
        {
            newErrors["sender_mobile"] = i18n.t('error.invalid_mobile');
        }
        else if(!Validator.numeric(data?.sender_mobile_code))
        {
            newErrors["sender_mobile"] = i18n.t('error.invalid_mobile');
        } 

        if(data?.sender_lat)
        {
            if(data?.sender_lat?.length === 0)
            {
                delete data?.sender_lat;
            }
            else
            {
                if(!Validator.notEmpty(data?.sender_lat))
                {
                    newErrors["sender_lat"] = i18n.t('error.invalid_center_lat');
                }
                else if(!Validator.numeric(data?.sender_lat))
                {
                    newErrors["sender_lat"] = i18n.t('error.invalid_center_lat');
                }  
            }
        }
        
        if(data?.sender_long)
        {
            if(data?.sender_long?.length === 0)
            {
                delete data?.sender_long;
            }
            else
            {
                if(!Validator.notEmpty(data?.sender_long))
                {
                    newErrors["sender_long"] = i18n.t('error.invalid_center_long');
                }
                else if(!Validator.numeric(data?.sender_long))
                {
                    newErrors["sender_long"] = i18n.t('error.invalid_center_long');
                }  
            }
        }        

        if(data?.sender_address)
        {
            if(data?.sender_address?.length === 0)
            {
                delete data?.sender_address;
            }
            else
            {
                if(!Validator.notEmpty(data?.sender_address))
                {
                    newErrors["sender_address"] = i18n.t('error.invalid_address');
                }
            }
        }

        if(!Validator.notEmpty(data?.recipient_first_name))
        {
            newErrors["recipient_name"] = i18n.t('error.invalid_first_name');            
        }
        else if(!Validator.notEmpty(data?.recipient_last_name))
        {
            newErrors["recipient_name"] = i18n.t('error.invalid_last_name');            
        }
        
        if(!Validator.notEmpty(data?.recipient_email))
        {
            newErrors["recipient_email"] = i18n.t('error.invalid_email');            
        }   
        else if(!Validator.email(data?.recipient_email))
        {
            newErrors["recipient_email"] = i18n.t('error.invalid_email');            
        } 
        
        if(!Validator.notEmpty(data?.recipient_mobile))
        {
            newErrors["recipient_mobile"] = i18n.t('error.invalid_mobile');
        }
        else if(!Validator.numeric(data?.recipient_mobile))
        {
            newErrors["recipient_mobile"] = i18n.t('error.invalid_mobile');
        }        
        
        if(!Validator.notEmpty(data?.recipient_mobile_code))
        {
            newErrors["recipient_mobile"] = i18n.t('error.invalid_mobile');
        }
        else if(!Validator.numeric(data?.recipient_mobile_code))
        {
            newErrors["recipient_mobile"] = i18n.t('error.invalid_mobile');
        } 

        if(data?.recipient_lat)
        {
            if(data?.recipient_lat?.length === 0)
            {
                delete data?.recipient_lat;
            }
            else
            {
                if(!Validator.notEmpty(data?.recipient_lat))
                {
                    newErrors["recipient_lat"] = i18n.t('error.invalid_center_lat');
                }
                else if(!Validator.numeric(data?.recipient_lat))
                {
                    newErrors["recipient_lat"] = i18n.t('error.invalid_center_lat');
                }  
            }
        }
        
        if(data?.recipient_long)
        {
            if(data?.recipient_long?.length === 0)
            {
                delete data?.recipient_long;
            }
            else
            {
                if(!Validator.notEmpty(data?.recipient_long))
                {
                    newErrors["recipient_long"] = i18n.t('error.invalid_center_long');
                }
                else if(!Validator.numeric(data?.recipient_long))
                {
                    newErrors["recipient_long"] = i18n.t('error.invalid_center_long');
                }  
            }
        }        

        if(data?.recipient_address)
        {
            if(data?.recipient_address?.length === 0)
            {
                delete data?.recipient_address;
            }
            else
            {
                if(!Validator.notEmpty(data?.recipient_address))
                {
                    newErrors["recipient_address"] = i18n.t('error.invalid_address');
                }
            }
        }     
        
        if(data?.preferred_pickup_time_from)
        {        
            if(!Validator.notEmpty(data?.preferred_pickup_time_from))
            {
                newErrors["preferred_pickup_time_from"] = i18n.t('error.invalid_date');            
            }
            else if(!Validator.futureDate(data?.preferred_pickup_time_from))
            {
                newErrors["preferred_pickup_time_from"] = i18n.t('error.invalid_date_not_in_future');            
            }
        }  
        
        if(data?.preferred_pickup_time_to)
        {        
            if(!Validator.notEmpty(data?.preferred_pickup_time_to))
            {
                newErrors["preferred_pickup_time_to"] = i18n.t('error.invalid_date');            
            }
            else if(!Validator.futureDate(data?.preferred_pickup_time_to))
            {
                newErrors["preferred_pickup_time_to"] = i18n.t('error.invalid_date_not_in_future');            
            }
        } 
        
        if(data?.preferred_delivery_time_from)
        {        
            if(!Validator.notEmpty(data?.preferred_delivery_time_from))
            {
                newErrors["preferred_delivery_time_from"] = i18n.t('error.invalid_date');            
            }
            else if(!Validator.futureDate(data?.preferred_delivery_time_from))
            {
                newErrors["preferred_delivery_time_from"] = i18n.t('error.invalid_date_not_in_future');            
            } 
        }
          
        if(data?.preferred_delivery_time_to)
        {        
            if(!Validator.notEmpty(data?.preferred_delivery_time_to))
            {
                newErrors["preferred_delivery_time_to"] = i18n.t('error.invalid_date');            
            }
            else if(!Validator.futureDate(data?.preferred_delivery_time_to))
            {
                newErrors["preferred_delivery_time_to"] = i18n.t('error.invalid_date_not_in_future');            
            }
        }                 

        if(data?.comment && data?.comment?.length === 0)
        {
            delete data?.comment;
        }

        for(var i = 0; i < data?.items?.length; i++)
        {
            let item = data?.items?.[i];

            if(!Validator.notEmpty(item?.name)|| !Validator.notEmpty(item?.serial_number))
            {
                newErrors["items"] = i18n.t('error.invalid_delivery_items_list');            
            }            
        }

        if(Object.keys(newErrors)?.length === 0)
        {
            setErrors({});
            setDataLoading(true);
            setCountryListLoading(true);
            setZoneListLoading(true);
            setTripListLoading(true);
            setSkillListLoading(true);  
            
            data["items"] = JSON.stringify(data?.["items"]);
            data["skills"] = JSON.stringify(data?.["skills"]);

            isMounted && HTTP.post(Endpoint?.oms?.delivery?.data, data).then(response =>
            {
                if(response?.is_successful)
                {                        
                    context?.setNotification("success", i18n.t("message.title"), i18n.t("message.generic"));

                    setTimeout(() =>
                    {
                        context?.setDialog(null);   
                    
                        window.location.reload();  
                    
                    }, Constant.animation?.speed?.normal);                  
                }
                else
                {
                    context?.setNotification("error", i18n.t("error.title"), response?.error_msg);

                    setDataLoading(false);
                    setCountryListLoading(false);
                    setZoneListLoading(false);
                    setTripListLoading(false);
                    setSkillListLoading(false);                        
                }
            });
        }
        else
        {
            setErrors(newErrors);
        }          
    };

    useEffect(() =>
    {
        setCountryListLoading(true);
        setZoneListLoading(true);
        setTripListLoading(true);
        setSkillListLoading(true);

        let params = {
            "country_code": context?.prefs?.merchant_country_code,
            "set_type": "full",
            "type_code": "private"
        };
        
        isMounted && HTTP.get(Endpoint?.oms?.trip?.list, {"set_type": "full"}).then(response =>
        {
            if(response?.is_successful && response?.data?.list)
            {
                setTripList(response?.data?.list?.map((trip) => {return {title:`${trip?.[`name_${context?.prefs?.lang}`]}`, value:trip?.trip_id}}));
            }
            
            setTripListLoading(false);
        });

        isMounted && HTTP.get(Endpoint?.crm?.skill?.list, {skill_type:"all"}).then(response =>
        {                
            if(response?.is_successful && response?.data?.list)
            {        
                setSkillList(response?.data?.list?.map((skill) => {return {title:skill?.["name_" + context?.prefs?.lang], value:skill?.skill_id, type:(skill?.is_public) ? "public" : "private"}})); 
            }

            setSkillListLoading(false);
        });        
        
        isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
        {
            setPrivateZoneList(response?.data?.list?.map((zone) => {return {title:`${zone?.[`name_${context?.prefs?.lang}`]}`, value:zone?.zone_id}}));

            params["type_code"] = "public";

            isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
            {
                setPublicZoneList(response?.data?.list?.map((zone) => {return {title:`${zone?.[`name_${context?.prefs?.lang}`]}`, value:zone?.zone_id}}));
            
                setZoneListLoading(false);
            });  
        });  
        
        isMounted && HTTP.get(Endpoint?.cms?.country?.list).then(response =>
        {
            setCountryList(response?.data?.list);

            setCountryListLoading(false);
        });

    }, [isMounted, context?.prefs?.merchant_country_code, context?.prefs?.lang]);

    useEffect(() =>
    {
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <DeliveryCreate
            prefs={context?.prefs}
            isDataLoading={isDataLoading}
            isCountryListLoading={isCountryListLoading}
            isZoneListLoading={isZoneListLoading}
            isTripListLoading={isTripListLoading}
            isSkillListLoading={isSkillListLoading}
            privateZoneList={privateZoneList}
            publicZoneList={publicZoneList}
            tripList={tripList}
            skillList={skillList}
            countryList={countryList}
            errors={errors}
            next={next}
        />
    );
}

export default Dialog;