import React, { useState, useId, useRef } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar-SA';
import moment from 'moment';

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Image from './image';
import CalendarDarkIconImage from '../../assets/vector/icon_calendar_dark.svg';
import CalendarLightIconImage from '../../assets/vector/icon_calendar_light.svg';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('ar', ar);

const View = (props) =>
{
    const datePickerRef = useRef(null);

    const [date, setDate] = useState((props?.defaultValue instanceof Date) ? props?.defaultValue : (typeof props?.defaultValue === "string") ? moment(props?.defaultValue, 'YYYY-MM-DD').toDate() : undefined);

    const randomID = useId();

    const renderMonthContent = (month, shortMonth, longMonth) =>
    {
        return <div className="p-2">{longMonth}</div>;
    };

    return(
        <div className={`${props?.containerClassName}`}>
            {(props?.label) ? <div className={`${props?.labelClassName ?? "text-neutral-low-dark"} mb-2 `}>{props?.label}{(props?.isRequired) ? <span className="text-accent-regular">*</span> : null}</div> : null}

            <div className="w-full">

                <div className="relative" onClick={() => {if(!props?.isDisabled) {datePickerRef?.current.setOpen(true)}}}>
                    <Image src={props?.theme === "light" ? CalendarDarkIconImage : CalendarLightIconImage} alt="" className="h-[24px] w-[24px] absolute top-[11px] end-[10px] z-[1]"/>
                </div>

                <div className="w-full">
                    <DatePicker
                        ref={datePickerRef}
                        id={`date_` + randomID}
                        className={`w-full ${props?.inputClassName ?? "text-neutral-low-dark bg-neutral-high-lightest border-neutral-high-regular"} ${props?.isDisabled ? "cursor-not-allowed opacity-50 bg-neutral-high-regular" : "cursor-pointer"} rounded-lg font-secondary px-3 py-2 outline-none transition-all duration-normal ease-in-out border-2 focus:border-primary-regular hover:border-primary-regular focus:shadow-xl focus:shadow-shadow-light"`}
                        dateFormat={(props?.dateFormat) ?? "yyyy-MM-dd"} 
                        locale={props?.locale} 
                        minDate={props?.futureDateOnly ? moment().toDate() : null} 
                        maxDate={props?.pastDateOnly ? moment().toDate() : null}
                        selected={date}
                        placeholderText={props?.placeholder ?? i18n.t("common.select")}
                        showMonthYearPicker={props?.showMonthYearPicker ?? false}
                        showQuarterYearPicker={props?.showQuarterYearPicker ?? false}
                        showWeekPicker={props?.showWeekPicker ?? false}
                        showYearPicker={props?.showYearPicker ?? false}
                        value={typeof props?.value === "object" ? moment(props?.value).format("YYYY-MM-DD") : props?.value}
                        renderMonthContent={renderMonthContent}
                        onChange={(e) => {props?.onChange(e); setDate(e)}}
                        showTimeSelect={props?.showTimeSelect}
                        disabled={props?.isDisabled}
                    />
                </div>
            </div>

            <AnimatePresence>
            {props?.errorMsg && (
                <motion.div 
                    initial={{ height:0, opacity:0 }}
                    animate={{ height: "auto", opacity:1 }}
                    exit={{ height:0, opacity:0 }}
                    transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                    className={`${props?.errorClassName ?? "text-status-danger-regular"} font-secondary mt-2`}
                >
                    {props?.errorMsg}
                </motion.div>
            )}
            </AnimatePresence>
        </div>
    );
}

export default View;
