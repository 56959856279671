const Validator =
{
    notEmpty(value)
    {
        return (value !== undefined && value !== null && value !== '' && value?.toString()?.trim()?.length > 0);
    },

    numeric(value)
    {
        return !isNaN(value?.toString()?.trim());
    },

    int(value)
    {
        return /^-?(0|[1-9]\d*)$/.test(value?.toString()?.trim());
    },

    code(value)
    {
        return /^[A-Za-z0-9_]*$/.test(value?.toString()?.trim());
    },

    array(value)
    {
        try
        {
            const parsedObject = JSON.parse(value);
            
            return Array.isArray(parsedObject);
        }
        catch (error)
        {
            return false;
        }
    },

    object(value)
    {
        try
        {
            const parsedObject = JSON.parse(value);

            return typeof parsedObject === 'object' && !Array.isArray(parsedObject);
        }
        catch(error)
        {
            return false;
        }
    },

    email(value)
    {
        return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value);
    },

    date(value)
    {
        return /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(value);
    },

    time(value)
    {
        return /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/.test(value);
    },

    timestamp(value)
    {
        return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/.test(value);
    },

    futureDate(date)
    {
        let dateNow = new Date();

        return(date > dateNow);
    },

    strongPassword(value)
    {
        return /^(?=.*\d)(?=.*[@#\-_$%^&+=§!\\?])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_$%^&+=§!\\?]{8,20}$/.test(value);
    },

    longPassword(value)
    {
        return value?.length >= 8;
    },

    boolean(value)
    {
        return value === 'true' || value === 'false' || value === '1' || value === '0' || value === true || value === false || value === 1 || value === 0 ? true : false;
    }
}

export default Validator;