import { useState } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">

                <div className="mb-7">
                    <InputText 
                        type={"text"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.email")}
                        placeholder={"email@example.com"}
                        defaultValue={data?.email}
                        errorMsg={props?.errors?.email}
                        onChange={(e) => {update("email", e?.target?.value)}}
                    />
                </div>

                <div className="mb-7">
                    <div className="pb-2">
                        {i18n.t("common.mobile")}
                    </div>
                    <div dir="ltr" className="flex gap-2">
                        <div className="w-[150px] text-sm">
                            <InputDropdown
                                selectorClassName={"bg-secondary-light"}
                                listClassName={"bg-secondary-lightest"}
                                itemClassName={"border-secondary-light hover:bg-secondary-light"}
                                searchbarClassName={"bg-secondary-light"}
                                list={props?.countryList}
                                listValue={data?.mobile_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={true}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("mobile_code", value)}}
                            />                        
                        </div>                    
                        <div className="w-full">
                            <InputText 
                                type={"text"}
                                inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                                labelClassName="text-secondary-darkest"
                                errorClassName={`text-status-danger-regular ${props?.prefs?.dir === "rtl" ? "text-end" : "text-start"}`}
                                enableAutoComplete={false}
                                placeholder={"5XXXXXXXXX"}
                                defaultValue={data?.mobile}
                                onChange={(e) => {update("mobile", e?.target?.value)}}
                            />
                        </div>
                    </div> 
                    <AnimatePresence>
                    {props?.errors?.mobile && (
                        <motion.div 
                            initial={{ height:0, opacity:0 }}
                            animate={{ height: "auto", opacity:1 }}
                            exit={{ height:0, opacity:0 }}
                            transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                            className={`text-status-danger-regular font-secondary text-sm mt-2`}
                        >
                            {props?.errors?.mobile}
                        </motion.div>
                    )}
                    </AnimatePresence>   
                </div>

                <div className="mb-7">
                    <InputText 
                        type={"password"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.password")}
                        placeholder={""}
                        errorMsg={props?.errors?.password}
                        onChange={(e) => {update("password", e?.target?.value)}}
                    /> 
                </div>               
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={true} onClick={() => {}}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext({...props?.data, ...data})}/>
            </div>  
        </div>
    );
}

export default View;
