import Image from './image';

import HomeDarkIcon from '../../assets/vector/icon_home_dark.svg';
import HomeLightIcon from '../../assets/vector/icon_home_light.svg';
import LocationDarkIcon from '../../assets/vector/icon_location_dark.svg';
import LocationLightIcon from '../../assets/vector/icon_location_light.svg';
import BoxDarkIcon from '../../assets/vector/icon_box_dark.svg';
import BoxLightIcon from '../../assets/vector/icon_box_light.svg';
import UserDarkIcon from '../../assets/vector/icon_user_dark.svg';
import UserLightIcon from '../../assets/vector/icon_user_light.svg';
import DollarDarkIcon from '../../assets/vector/icon_dollar_dark.svg';
import DollarLightIcon from '../../assets/vector/icon_dollar_light.svg';
import WalletDarkIcon from '../../assets/vector/icon_wallet_dark.svg';
import WalletLightIcon from '../../assets/vector/icon_wallet_light.svg';
import SettingsDarkIcon from '../../assets/vector/icon_setting_dark.svg';
import SettingsLightIcon from '../../assets/vector/icon_setting_light.svg';

const View = (props) =>
{
    return (
        <div className={(props?.isActive) ? `flex gap-5 bg-gradient-to-r from-primary-light to-primary-dark p-3 rounded-lg shadow-xl shadow-shadow-lightest cursor-pointer transition duration-normal ease-in-out opacity-100` : "flex gap-5 p-3 cursor-pointer transition duration-normal ease-in-out opacity-100 hover:opacity-50"} onClick={props?.onClick}>
            <Image 
                src={(props?.icon === "overview") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? HomeLightIcon : HomeDarkIcon 
                     :
                     (props?.icon === "map") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? LocationLightIcon : LocationDarkIcon 
                     :
                     (props?.icon === "delivery") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? BoxLightIcon : BoxDarkIcon
                     : 
                     (props?.icon === "user") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? UserLightIcon : UserDarkIcon 
                     : 
                     (props?.icon === "collection") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? DollarLightIcon : DollarDarkIcon 
                     : 
                     (props?.icon === "wallet") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? WalletLightIcon : WalletDarkIcon
                     : 
                     (props?.icon === "settings") 
                     ? 
                        (props?.theme === "dark" && !props?.isActive) ? SettingsLightIcon : SettingsDarkIcon 
                     : 
                        null
                    } 
                alt="" 
                className="h-[16px] w-[16px] my-auto"
            />
            <div className={`m-0 my-auto whitespace-nowrap ${(props?.isActive && props?.theme === "dark") ? "text-neutral-high-light" : "text-neutral-low-dark"}`}>
                {props?.text}
            </div>
        </div>
    )
}

export default View;