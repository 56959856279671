import Image from './image';

import ThemeSunDarkIconImage from '../../assets/vector/icon_sun_dark.svg';
import ThemeSunLightIconImage from '../../assets/vector/icon_sun_light.svg';
import ThemeMoonDarkIconImage from '../../assets/vector/icon_moon_dark.svg';
import ThemeMoonLightIconImage from '../../assets/vector/icon_moon_light.svg';
import NotificationDarkIconImage from '../../assets/vector/icon_notification_dark.svg';
import NotificationLightIconImage from '../../assets/vector/icon_notification_light.svg';

import DeliveryTimeDarkIconImage from '../../assets/vector/icon_delivery_time_dark.svg';
import DeliveryTimeLightIconImage from '../../assets/vector/icon_delivery_time_light.svg';
import PreparationTimeDarkIconImage from '../../assets/vector/icon_preparation_time_dark.svg';
import PreparationTimeLightIconImage from '../../assets/vector/icon_preparation_time_light.svg';
import ProblemDarkIconImage from '../../assets/vector/icon_problem_dark.svg';
import ProblemLightIconImage from '../../assets/vector/icon_problem_light.svg';
import StarDarkIconImage from '../../assets/vector/icon_star_dark.svg';
import StarLightIconImage from '../../assets/vector/icon_star_light.svg';

const View = (props) =>
{
    return (
        <div className={`w-[50px] h-[50px] rounded-full transition duration-normal ease-in-out relative flex bg-neutral-high-regular ${props?.className ?? ""} ${(props?.onClick) ? "hover:bg-neutral-high-dark cursor-pointer" : ""}`} onClick={() => { if(props?.onClick) {props?.onClick()}}}>
            {(props?.type === "notification")
            ?
                <Image src={(props?.theme === "light") ? NotificationDarkIconImage : NotificationLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
            (props?.type === "theme-dark")
            ?
                <Image src={(props?.theme === "light") ? ThemeMoonDarkIconImage : ThemeMoonLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
            (props?.type === "theme-light")
            ?
                <Image src={(props?.theme === "light") ? ThemeSunDarkIconImage : ThemeSunLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
            (props?.type === "delivery-time")
            ?
                <Image src={(props?.theme === "light") ? DeliveryTimeDarkIconImage : DeliveryTimeLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
            (props?.type === "preparation-time")
            ?
                <Image src={(props?.theme === "light") ? PreparationTimeDarkIconImage : PreparationTimeLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
            (props?.type === "problem")
            ?
                <Image src={(props?.theme === "light") ? ProblemDarkIconImage : ProblemLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
            (props?.type === "star")
            ?
                <Image src={(props?.theme === "light") ? StarDarkIconImage : StarLightIconImage} alt="" className="h-[20px] w-[20px] m-auto"/>
            :
                <></>
            }
            
            {(props?.hasIndicator) ? <div className="absolute bg-status-danger-regular rounded-full w-[7px] h-[7px] top-4 end-4"/> : null}
        </div>
    )
}

export default View;