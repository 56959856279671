import i18n from '../../util/i18n';

import Button from '../component/button';

const View = (props) =>
{
    return(
        <div className="p-7 font-secondary text-sm text-neutral-low-dark">
            <div className="mb-10 mt-[-30px]">
                {i18n.t("form.incomplete_signup")}
            </div>  
            <div className="mb-5 flex justify-center gap-5">
                <Button type="secondary-solid" className="w-[150px]" text={i18n.t("page.continue_merchant")} onClick={props?.onboardMerchant}/>
                <Button type="secondary-solid" className="w-[150px]" text={i18n.t("page.continue_as_captain")} onClick={props?.onboardCaptain}/>
            </div>  
        </div>
    );
}

export default View;
