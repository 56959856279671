import { useState } from "react";

import i18n from '../../util/i18n';

import InputText from '../component/inputText';
import Button from '../component/button';

const View = (props) =>
{
    const [data, setData] = useState(props?.data ?? {});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="p-7 font-secondary text-sm">
            <div className="pb-7">

                <div className="mb-7 w-[50%]">
                    <InputText 
                        type={"text"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={`${i18n.t("common.otp_sent_to")} ${data?.email ?? ""}`}
                        defaultValue={data?.otp}
                        errorMsg={props?.errors?.otp}
                        onChange={(e) => {update("otp", e?.target?.value)}}
                    />           
                </div>
                <div className="mb-7">
                    <InputText 
                        type={"password"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.password_new")}
                        defaultValue={data?.password}
                        errorMsg={props?.errors?.password}
                        onChange={(e) => {update("password", e?.target?.value)}}
                    />           
                </div>
                <div className="mb-7">
                    <InputText 
                        type={"password"}
                        inputClassName="text-secondary-darkest bg-secondary-light placeholder-secondary-regular"
                        labelClassName="text-secondary-darkest"
                        errorClassName="text-status-danger-regular"
                        enableAutoComplete={false}
                        label={i18n.t("common.password_new_confirmation")}
                        defaultValue={data?.password_confirmation}
                        errorMsg={props?.errors?.password_confirmation}
                        onChange={(e) => {update("password_confirmation", e?.target?.value)}}
                    />           
                </div>                                
              
            </div>
            <div className="flex justify-between">
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={false} onClick={() => props?.goPrev(data)}/>
                <Button type="secondary-solid" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.goNext(data)}/>
            </div>  
        </div>
    );
}

export default View;
