import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";

import AppConsumer from "../../util/context";

const Controller = props => 
{
    const context = useContext(AppConsumer);

    return (
        <HelmetProvider>
            <Helmet>
                <html lang={context?.prefs?.lang} dir={context?.prefs?.dir} data-theme={context?.prefs?.theme}/>
            </Helmet>
            <div className="font-secondary text-base text-secondary-darkest">
                <Outlet/>
            </div>
        </HelmetProvider>
    );
}

export default Controller;