import i18n from '../../util/i18n';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import Button from '../component/button';
import Map from '../component/map';

const View = (props) =>
{
    const handleDragging = (newLocation) =>
    {
        if(props?.handleDragging)
        {
            props.handleDragging(newLocation);
        }
    };

    return(
        <div className="font-secondary text-sm text-neutral-low-darkest p-7"> 
            <div className="p-0 relative">
                <Map
                    className="h-[300px] w-full rounded-xl"
                    mapClassName="rounded-xl"
                    initZoom={11}
                    minZoom={9}
                    maxZoom={20}
                    showZoomControl={true}
                    showMapTypeControl={false}
                    showScaleControl={false}
                    showStreetViewControl={false}
                    showRotateControl={false}
                    showFullscreenControl={false}
                    showClickableIcons={true}
                    showScrollwheel={false}
                    theme={"default"}
                    lat={props?.mapCenter?.lat}
                    long={props?.mapCenter?.long}
                    draggableMarker={props?.mapCenter}
                    handleDragging={handleDragging}
                />
                <AnimatePresence>
                    {props?.isLoading && (
                        <motion.div 
                        className="absolute h-full w-full top-0 bg-secondary-lightest"
                        initial={{ opacity:0 }}
                        animate={{ opacity:0.8 }}
                        exit={{ opacity:0 }}
                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}/>
                    )}
                </AnimatePresence>                                                                  
            </div>
            <div className="flex justify-end pt-7">
                <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={props?.isLoading} onClick={props?.save}/>
            </div>               
        </div>
    )
}

export default View;