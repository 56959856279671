import i18n from './i18n';

const Formatter =
{
    stringToBoolean: function(value)
    {
        return value === 'true' || value === '1' || value === true || value === 1;
    },

    stringToNumberWithCommas: function(number)
    {
        if (number !== undefined && number !== null)
        {
            return parseFloat(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else
        {
            return "";
        }
    },
    
    stringToNumberWithCommasAndDicimals: function(number)
    {
        if(number !== undefined && number !== null)
        {
            return parseFloat(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else
        {
            return "";
        }
    },

    stringToDate: function(year=1970, month=1, day=1, hour=0, minute=0, second=0)
    {
        const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
      
        if(isNaN(date.getTime()))
        {
          return new Date(Date.UTC(1970, 0, 1, 0, 0, 0));
        }
      
        return date;
    },

    dateToString(date, type = "date_time")
    {
        const months = [i18n.t("date.jan"), i18n.t("date.feb"), i18n.t("date.mar"), i18n.t("date.apr"), i18n.t("date.may"), i18n.t("date.jun"), i18n.t("date.jul"), i18n.t("date.aug"), i18n.t("date.sep"), i18n.t("date.oct"), i18n.t("date.nov"), i18n.t("date.dec")];
    
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        let hours = dateObj.getHours();
        const minutes = ('0' + dateObj.getMinutes()).slice(-2);
        const ampm = hours >= 12 ? i18n.t("time.pm") : i18n.t("time.am");
        hours = hours % 12;
        hours = hours ? hours : 12;

        return (type === "date") ? `${day} ${month} ${year}` : (type === "time") ? `${hours}:${minutes} ${ampm}` : `${day} ${month} ${year} ${i18n.t("time.at")} ${hours}:${minutes} ${ampm}`;
    },

    dateToTimestamp(date, type = "date_time")
    {    
        const dateObj = new Date(date);
        const day     = ('0' + dateObj.getDate()).slice(-2);
        const month   = ('0' + parseInt(dateObj.getMonth()+1)).slice(-2);
        const year    = dateObj.getFullYear();
        const hours   = ('0' + dateObj.getHours()).slice(-2);
        const minutes = ('0' + dateObj.getMinutes()).slice(-2);
        const seconds = ('0' + dateObj.getSeconds()).slice(-2);

        return (type === "date") ? `${year}-${month}-${day}` : (type === "time") ? `${hours}:${minutes}:${seconds}` : `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    dateToISOTimestamp(date)
    {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
        const timezoneOffset = -date.getTimezoneOffset();
        const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
        const timezoneOffsetMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
        const timezoneSign = timezoneOffset >= 0 ? '+' : '-';
    
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneOffsetHours}:${timezoneOffsetMinutes}`;
    },

    statusCodeToColorCode: function (statusCode)
    {
        if(["rejected_by_customer_before_dispatch", "rejected_by_customer_after_dispatch", "returned_after_rejection", "onhold"].includes(statusCode?.toLowerCase()))
        {
            return "bg-status-danger-regular";
        }
        else if(["pending_assignment", "pending_location", "new", "assigned", "confirmed", "dispatched", "delivered"].includes(statusCode?.toLowerCase()))
        {
            return "bg-primary-regular";
        } 
        else if(["complete"].includes(statusCode?.toLowerCase()))        
        {
            return "bg-status-success-regular";
        }                
        else
        {
            return "bg-neutral-high-dark";
        }
    },

    mask: function (str, numberOfShownCharacters = 3)
    {
        if (str.length < numberOfShownCharacters) {
            return str;
        }
    
        var lastDigits = str.slice(Math.abs(numberOfShownCharacters)*-1);
    
        return "*".repeat(str.length - numberOfShownCharacters) + lastDigits;
    }
}

export default Formatter;